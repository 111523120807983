import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import CompareTeam from '../../../HOC/SportsLeagueList/CompareTeam'
// import { getRandomColor, createImageFromInitials } from '../components/LetterImage'
import Loading from '../../../component/Loading'
// import { isUpperCase } from '../../../helper'
import PlayerImage from '../../../assests/images/PlayerImage.svg'
import ProfileImage from '../../../assests/images/profile.png'
import CaptainIcon from '../../../assests/images/captain-team-icon.svg'
import ViceCaptainIcon from '../../../assests/images/viceCaptain-team-icon.svg'
import { handleImgError } from '../../../helper'

const classNames = require('classnames')

function TeamComparePage (props) {
  const { location, opponentTeam, myTeam, team1, team2, team1ScorePoint, team2ScorePoint, url, loading, firstTeam, secondTeam } = props
  const [sortedOpponentTeam, setData] = useState([])
  const [firstRank, setFirstRank] = useState('')
  const [secondRank, setSecondRank] = useState('')
  useEffect(() => { // handle the response
    // if (sportsType) {
    //   const sport = match.params.sportsType
    //   isUpperCase(sport) && history.push(`/team-compare/${sport.toLowerCase()}/${match.params.id1}/${match.params.id2}`)
    // }
    if (location && location.state) {
      setFirstRank(location?.state?.firstRank)
      setSecondRank(location?.state?.secondRank)
    }
  }, [])
  useEffect(() => { // handle the response
    if (opponentTeam && opponentTeam.notCommon) {
      const data = opponentTeam && opponentTeam.notCommon && opponentTeam.notCommon.length !== 0 && opponentTeam.notCommon.sort((a, b) => (a.eRole > b.eRole) ? 1 : -1)
      setData(data)
    }
  }, [opponentTeam])

  return (
    <>
      {loading && <Loading />}
      <div className="user-container no-footer">
        <div className='team-compare-wrap'>
          <div className="team-compare-name d-flex bg-white">
            <div className="tc-box">
              <div className="tc-img">
                <img className='fixImgHeiWid' src={team1.sProPic ? url + team1.sProPic : ProfileImage} onError={(e) => handleImgError(e, ProfileImage)} alt="" />
              </div>
              <div className='team-name'>{team1.sName}</div>
              <p><FormattedMessage id="Welcome_Hash" /> {firstRank || (team1.nRank ? team1.nRank : firstTeam.nRank ? firstTeam.nRank : 0)}</p>
            </div>
            <div className="t-points text-center">
              <div className='d-flex justify-content-between'>
                <h2>{team1.sUserName}</h2>
                <h2>{team2.sUserName}</h2>
              </div>
              <p className="m-0"><FormattedMessage id="Common_TotalPoints" /></p>
              <div className="points-box d-flex">
                <i className="icon-compare"></i>
                <span className={classNames({ active: team1ScorePoint > team2ScorePoint })}>{team1ScorePoint}</span>
                <span className={classNames({ active: team2ScorePoint > team1ScorePoint })}>{team2ScorePoint}</span>
              </div>
            </div>
            <div className="tc-box">
              <div className="tc-img">
                <img className='fixImgHeiWid' src={team2.sProPic ? url + team2.sProPic : ProfileImage} onError={(e) => handleImgError(e, ProfileImage)} alt="" />
              </div>
              <div className='team-name'>{team2.sName}</div>
              <p><FormattedMessage id="Welcome_Hash" /> {secondRank || (team2.nRank ? team2.nRank : secondTeam.nRank ? secondTeam.nRank : 0)}</p>
            </div>
          </div>
          {((team1ScorePoint || team1ScorePoint === 0) && (team2ScorePoint || team2ScorePoint === 0)) &&
            <div className="twon-txt">
              {
                (team1ScorePoint > team2ScorePoint)
                  ? <p>{team1.sUserName} ({team1.sName}) <span className='won-by-color'><FormattedMessage id="User_leads_BY" /></span> <span>{team1ScorePoint - team2ScorePoint}</span><FormattedMessage id="Team_Pts" /> </p>
                  : (team1ScorePoint < team2ScorePoint)
                      ? <p>{team2.sUserName} ({team2.sName}) <span className='won-by-color'><FormattedMessage id="User_leads_BY" /></span> <span>{team2ScorePoint - team1ScorePoint}</span><FormattedMessage id="Team_Pts" /> </p>
                      : (team1ScorePoint === team2ScorePoint) && <p> <FormattedMessage id="User_BOTH_PLAYER" /> </p>
              }
            </div>
          }
        </div>
        <div className="compare-player">
          <h3 className='text-center'><FormattedMessage id="Captains_Vice_Captains" /></h3>
          {
            myTeam.nCapPoints > opponentTeam.nCapPoints
              ? <div className="point-title">
                  {team1.sUserName} ({team1.sName}){"'s"} scored <span>{myTeam.nCapPoints - opponentTeam.nCapPoints}</span> pts more
                </div>
              : myTeam.nCapPoints === opponentTeam.nCapPoints
                ? <div className="point-title">
                    {"Both team's players have same pts"}
                </div>
                : <div className="point-title">
                  {team2.sUserName} ({team2.sName}){"'s"} scored <span>{opponentTeam.nCapPoints - myTeam.nCapPoints}</span> pts more
                </div>
          }
          <div>
            <div className="p-c-box d-flex align-items-center">
              <div className="p-box d-flex align-items-center">
                <div className="img">
                  <img src={myTeam && myTeam.captain && myTeam.captain.sImage ? `${url}${myTeam.captain.sImage}` : PlayerImage} alt="" />
                  <span className="p"> <img src={CaptainIcon} alt="" /> </span>
                </div>
                <div className="p-name">
                  <h3>{myTeam && myTeam.captain && myTeam.captain.sName}</h3>
                  <p><span>{myTeam?.captain?.oTeam?.sShortName}</span> - {myTeam && myTeam.captain && myTeam.captain.eRole}</p>
                </div>
              </div>
              <div className="p-c-p-box justify-content-center d-flex">
                <span className={classNames({ active: myTeam && myTeam.captain && opponentTeam && opponentTeam.captain && (myTeam.captain.nScoredPoints > opponentTeam.captain.nScoredPoints) }) }>
                    {myTeam && myTeam.captain && (myTeam.captain.nScoredPoints * 2)}
                    <span className='calc-point'>{myTeam && myTeam.captain && (myTeam.captain.nScoredPoints)}X2</span>
                </span>
                <span className={classNames({ active: myTeam && myTeam.captain && opponentTeam && opponentTeam.captain && (myTeam.captain.nScoredPoints < opponentTeam.captain.nScoredPoints) }) }>
                  {opponentTeam && opponentTeam.captain && (opponentTeam.captain.nScoredPoints * 2)}
                  <span className='calc-point'>{opponentTeam && opponentTeam.captain && (opponentTeam.captain.nScoredPoints)}X2</span>
                </span>
              </div>
              <div className="p-box d-flex align-items-center">
                <div className="p-name text-right">
                  <h3>{opponentTeam && opponentTeam.captain && opponentTeam.captain.sName}</h3>
                  <p><span>{opponentTeam?.captain?.oTeam?.sShortName}</span> - {opponentTeam && opponentTeam.captain && opponentTeam.captain.eRole}</p>
                </div>
                <div className="img">
                  <img src={opponentTeam && opponentTeam.captain && opponentTeam.captain.sImage ? `${url}${opponentTeam.captain.sImage}` : PlayerImage} alt="" />
                  <span className="p"> <img src={CaptainIcon} alt="" /></span>
                </div>
              </div>
            </div>
            <div className="p-c-box d-flex align-items-center">
              <div className="p-box d-flex align-items-center">
                <div className="img">
                  <img src={myTeam && myTeam.captain && myTeam.viceCaptain.sImage ? `${url}${myTeam.viceCaptain.sImage}` : PlayerImage} alt="" />
                  <span className="p"><img src={ViceCaptainIcon} alt="" /></span>
                </div>
                <div className="p-name">
                  <h3>{myTeam && myTeam.viceCaptain && myTeam.viceCaptain.sName}</h3>
                  <p><span>{myTeam?.viceCaptain?.oTeam?.sShortName}</span> - {myTeam && myTeam.viceCaptain && myTeam.viceCaptain.eRole}</p>
                </div>
              </div>
              <div className="p-c-p-box justify-content-center d-flex">
                <span className={classNames({ active: myTeam && myTeam.viceCaptain && opponentTeam && opponentTeam.viceCaptain && (myTeam.viceCaptain.nScoredPoints > opponentTeam.viceCaptain.nScoredPoints) })}>
                  {myTeam && myTeam.viceCaptain && (myTeam.viceCaptain.nScoredPoints * 1.5)}
                  <span className='calc-point'>{myTeam && myTeam.viceCaptain && (myTeam.viceCaptain.nScoredPoints)}X1.5</span>
                </span>
                <span className={classNames({ active: myTeam && myTeam.viceCaptain && opponentTeam && opponentTeam.viceCaptain && (myTeam.viceCaptain.nScoredPoints < opponentTeam.viceCaptain.nScoredPoints) })}>
                  {opponentTeam && opponentTeam.viceCaptain && (opponentTeam.viceCaptain.nScoredPoints * 1.5)}
                  <span className='calc-point'>{opponentTeam && opponentTeam.viceCaptain && (opponentTeam.viceCaptain.nScoredPoints)}X1.5</span>
                </span>
              </div>
              <div className="p-box d-flex align-items-center">
                <div className="p-name text-right">
                  <h3>{opponentTeam && opponentTeam.viceCaptain && opponentTeam.viceCaptain.sName}</h3>
                  <p><span>{opponentTeam?.viceCaptain?.oTeam?.sShortName}</span> - {opponentTeam && opponentTeam.viceCaptain && opponentTeam.viceCaptain.eRole}</p>
                </div>
                <div className="img">
                  <img src={opponentTeam && opponentTeam.viceCaptain && opponentTeam.viceCaptain.sImage ? `${url}${opponentTeam.viceCaptain.sImage}` : PlayerImage} alt="" />
                  <span className="p"><img src={ViceCaptainIcon} alt="" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {myTeam && myTeam.notCommon && myTeam.notCommon.length > 0 &&
          <div className="compare-player">
            <h3 className='text-center'><FormattedMessage id="Different_Players" /></h3>
            {
              myTeam.nDifferentPoints > opponentTeam.nDifferentPoints
                ? <div className="point-title">
                  {team1.sUserName} ({team1.sName}){"'s"} players scored <span>{myTeam.nDifferentPoints - opponentTeam.nDifferentPoints}</span> pts more
                </div>
                : (myTeam.nDifferentPoints === opponentTeam.nDifferentPoints)
                    ? <div className="point-title">
                      {"Both team's players have same pts"}
                    </div>
                    : <div className="point-title">
                      {team2.sUserName} ({team2.sName}){"'s"} players scored <span>{opponentTeam.nDifferentPoints - myTeam.nDifferentPoints}</span> pts more
                    </div>
            }
            {/* {
              myTeam.nDifferentPoints > opponentTeam.nDifferentPoints
                ? <div className="point-title"> <span className='point-title-span-color'><FormattedMessage id="League_myPlayersLeadBy" /></span> <span> {myTeam && myTeam.nDifferentPoints && opponentTeam.nDifferentPoints && (myTeam.nDifferentPoints - opponentTeam.nDifferentPoints)} </span> <FormattedMessage id="Team_Pts" /> </div>
                : (myTeam.nDifferentPoints === opponentTeam.nDifferentPoints)
                    ? <div className="point-title"> <span className='point-title-span-color'><FormattedMessage id="League_bothPlayersLeadBy" /></span> <span>{myTeam && myTeam.nDifferentPoints}</span> <FormattedMessage id="Team_Pts" />  </div>
                    : <div className="point-title"> <span className='point-title-span-color'><FormattedMessage id="League_opponentPlayersLeadBy" /></span> <span>{myTeam && myTeam.nDifferentPoints && opponentTeam.nDifferentPoints && (opponentTeam.nDifferentPoints - myTeam.nDifferentPoints)}</span> <FormattedMessage id="Team_Pts" /> </div>
            } */}
            <div>
              {
                myTeam && myTeam.notCommon && myTeam.notCommon.length !== 0 && myTeam.notCommon.sort((a, b) => (a.eRole > b.eRole) ? 1 : -1).map((teamPlayer, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="p-c-box d-flex align-items-center">
                        <div className="p-box d-flex align-items-center">
                          <div className="img">
                            <img src={teamPlayer?.sImage ? `${url}${teamPlayer.sImage}` : PlayerImage} alt="" />
                          </div>
                          <div className="p-name">
                            <h3>{teamPlayer.sName}</h3>
                            <p><span>{teamPlayer?.oTeam?.sShortName}</span> - {teamPlayer?.eRole}</p>
                          </div>
                        </div>
                        <div className="p-c-p-box justify-content-center d-flex">
                          <span className={classNames({ active: sortedOpponentTeam?.length > 0 && teamPlayer?.nScoredPoints > sortedOpponentTeam[index]?.nScoredPoints })}>{teamPlayer?.nScoredPoints}</span>
                          <span className={classNames({ active: sortedOpponentTeam?.length > 0 && teamPlayer?.nScoredPoints < sortedOpponentTeam[index]?.nScoredPoints })}>{sortedOpponentTeam?.length > 0 && sortedOpponentTeam[index]?.nScoredPoints}</span>
                        </div>
                        <div className="p-box d-flex align-items-center">
                          <div className="p-name text-right">
                            <h3>{sortedOpponentTeam?.length > 0 && sortedOpponentTeam[index]?.sName}</h3>
                            <p><span>{sortedOpponentTeam[index]?.oTeam?.sShortName}</span> - {sortedOpponentTeam?.length > 0 && sortedOpponentTeam[index]?.eRole} </p>
                          </div>
                          <div className="img">
                            <img src={sortedOpponentTeam?.length > 0 && sortedOpponentTeam[index]?.sImage ? `${url}${sortedOpponentTeam[index]?.sImage}` : PlayerImage} alt="" />
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )
                })
              }
            </div>
          </div>
        }
        {myTeam && myTeam.common && myTeam.common.length > 0 &&
          <div className="compare-player">
            <h3 className='text-center'><FormattedMessage id="League_CommonPlayers" /></h3>
            <div className="point-title">
              Common players {myTeam.nCommonPoints} points
            </div>
            <div>
              {
                myTeam && myTeam.common && myTeam.common.length !== 0 && myTeam.common.sort((a, b) => (a.eRole > b.eRole) ? 1 : -1).map((teamPlayer, index) => {
                  return (
                    <Fragment key={index}>
                      <div className="p-c-box d-flex align-items-center">
                        <div className="p-box d-flex align-items-center">
                          <div className="img">
                            <img src={teamPlayer.sImage ? `${url}${teamPlayer.sImage}` : PlayerImage} alt="" />
                          </div>
                          <div className="p-name">
                            <h3>{teamPlayer.sName}</h3>
                            <p><span>{teamPlayer?.oTeam?.sShortName}</span> - {teamPlayer.eRole}</p>
                          </div>
                        </div>
                        <div className="p-c-p-box justify-content-center d-flex">
                          <span>{teamPlayer.nScoredPoints}</span>
                          <span>{teamPlayer.nScoredPoints}</span>
                        </div>
                        <div className="p-box d-flex align-items-center">
                          <div className="p-name text-right">
                            <h3>{teamPlayer.sName}</h3>
                            <p><span>{teamPlayer?.oTeam?.sShortName}</span> - {teamPlayer.eRole}</p>
                          </div>
                          <div className="img">
                            <img src={teamPlayer.sImage ? `${url}${teamPlayer.sImage}` : PlayerImage} alt="" />
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )
                })
              }
            </div>
          </div>
        }
      </div>
    </>
  )
}
TeamComparePage.propTypes = {
  onBackClick: PropTypes.string,
  url: PropTypes.string,
  loading: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      firstRank: PropTypes.object,
      secondRank: PropTypes.bool
    })
  }),
  myTeam: PropTypes.shape({
    common: PropTypes.shape([{
      length: PropTypes.string
    }]),
    notCommon: PropTypes.shape({
      length: PropTypes.string,
      map: PropTypes.func,
      sort: PropTypes.func
    }),
    nDifferentPoints: PropTypes.number,
    nCapPoints: PropTypes.number,
    nCommonPoints: PropTypes.number,
    viceCaptain: PropTypes.string,
    captain: PropTypes.shape([{
      sName: PropTypes.string,
      eRole: PropTypes.string
    }
    ])
  }),
  match: PropTypes.object,
  opponentTeam: PropTypes.object,
  team1: PropTypes.object,
  team1ScorePoint: PropTypes.number,
  team2ScorePoint: PropTypes.number,
  history: PropTypes.object,
  team2: PropTypes.object,
  firstTeam_id: PropTypes.string,
  secondTeam_id: PropTypes.string,
  sportsType: PropTypes.string,
  firstTeam: PropTypes.object,
  secondTeam: PropTypes.object
}
export default CompareTeam(TeamComparePage)
