/* eslint-disable multiline-ternary */
import React, { useState, Fragment, lazy, useRef, Suspense, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Scorecard from '../components/Scorecard'
// import ScoreCards from '../components/ScoreCard/ScoreCards'
import PlayerInfo2 from '../components/PlayerInfo2'
import SkeletonTeam from '../../../component/SkeletonTeam'
import SkeletonLeague from '../../../component/SkeletonLeague'
import Loading from '../../../component/Loading'
import { clickEventPost, isUpperCase } from '../../../helper'
import LeagueCompletedComponent from '../../../HOC/SportsLeagueList/LeagueCompleted'
import Trophy from '../../../assests/images/trophy.svg'
import { viewScoreCard } from '../../../redux/actions/scoreCard'
// import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'
// import ScoreCards from '../components/ScoreCard/index'
const PlayerStats = lazy(() => import('../components/PlayerStats'))
const League = lazy(() => import('../components/League'))
const MyTeam = lazy(() => import('../components/MyTeam'))
const classNames = require('classnames')

function LeaguesCompletedPage (props) {
  const { history, setActiveState, match, joinedLoading, matchDetails, loadingScorecard, currencyLogo, teamList, joinedLeagueList, matchPlayerList, playerScorePoints, loading, participate, activeTab, toggle, uniquePlayerList, url } = props
  const [playerId, setPlayerId] = useState('')
  const [matchType, setMatchType] = useState('')
  const [playerInfo, setPlayerInfo] = useState(false)
  const [finalData, setFinalData] = useState(false)
  const [winCount, setWinCount] = useState([])
  const [teamListLength, setTeamListLength] = useState(teamList)

  const scoreCardData = useSelector(state => state.scoreCard.scoreCard)
  const inningEleRef = useRef(null)

  const dispatch = useDispatch()

  const previousProps = useRef({
    joinedLeagueList, scoreCardData, teamList
  }).current

  useEffect(() => {
    setWinCount([]) // handle the response
    dispatch(viewScoreCard(match.params.id))
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      if (isUpperCase(sport)) {
        if (match.path === '/completedleagues/participated/:id/:sportsType') {
          setMatchType('completed')
          history.push(`/completedleagues/participated/${match.params.id}/${sport.toLowerCase()}`)
        } else {
          setMatchType('live')
          history.push(`/liveleague/${match.params.id}/${sport.toLowerCase()}`)
        }
      }
    }
  }, [])

  useEffect(() => { // handle the response
    if (previousProps.joinedLeagueList !== joinedLeagueList) {
      if (joinedLeagueList && joinedLeagueList.length > 0) {
        const count = joinedLeagueList && joinedLeagueList.filter(data => data.bWinningZone)
        setWinCount(count)
        count && count.length > 0 && count.map(data => {
          let finaldata1 = 0
          finaldata1 = finaldata1 + data.nTotalWinningInContest
          return setFinalData(finaldata1)
        })
      }
    }
    return () => {
      previousProps.joinedLeagueList = joinedLeagueList
    }
  }, [joinedLeagueList])

  useEffect(() => {
    if (previousProps.teamList !== teamList && teamList) {
      setTeamListLength(teamList)
    }

    return () => {
      previousProps.teamList = teamList
    }
  }, [teamList])

  useEffect(() => {
    if (previousProps.scoreCardData !== scoreCardData && scoreCardData !== null) {
      if (scoreCardData) {
        const fragment = document.createRange().createContextualFragment(scoreCardData)
        inningEleRef?.current?.append(fragment)
      }
    }
    return () => {
      previousProps.scoreCardData = scoreCardData
    }
  }, [scoreCardData])

  return (
    <>
      {(matchDetails?.oHomeTeam?.nScore || matchDetails?.oAwayTeam?.nScore) &&
        <Scorecard loadingScorecard={loadingScorecard} matchDetails={matchDetails} url={url} matchSport={matchDetails?.eCategory} />
      }
      <Nav className={`live-tabs justify-content-between bg-white ${matchDetails && matchDetails.bScorecardShow && 'four-tabs'}`}>
        <NavItem className={`text-center ${matchDetails?.bScorecardShow ? 'scorecards' : ''}`}>
          <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => {
            setActiveState('1')
            toggle('1')
            clickEventPost('mt_mc')
          }} ><FormattedMessage id="League_myContest" />{joinedLeagueList && ` (${joinedLeagueList.length})`}</NavLink>
        </NavItem>
        <NavItem className={`text-center ${matchDetails?.bScorecardShow ? 'scorecards' : ''}`}>
          <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => {
            setActiveState('2')
            toggle('2')
            clickEventPost('mt_myt')
          }} ><FormattedMessage id="League_myTeams" />{teamListLength && ` (${teamListLength.length})`}</NavLink>
        </NavItem>
        <NavItem className={`text-center ${matchDetails?.bScorecardShow ? 'scorecards' : ''}`}>
          <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => {
            setActiveState('3')
            toggle('3')
            clickEventPost('mt_ps')
          }} ><FormattedMessage id="League_playerStats" /></NavLink>
        </NavItem>
        {/* {matchDetails && matchDetails.bScorecardShow &&
        <NavItem className={`text-center ${matchDetails?.bScorecardShow ? 'scorecards' : ''}`}>
          <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => {
            setActiveState('4')
            toggle('4')
          }} >Scorecard</NavLink>
        </NavItem>
        } */}
        {
          matchDetails && matchDetails.bScorecardShow && (
            <NavItem className={`text-center ${matchDetails?.bScorecardShow ? 'scorecards' : ''}`}>
              <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => {
                setActiveState('4')
                toggle('4')
                clickEventPost('mt_sc')
              }} ><FormattedMessage id="Scorecard" /></NavLink>
            </NavItem>
          )
        }
      </Nav>
      {
        winCount && winCount.length && finalData && matchDetails && matchDetails.eStatus === 'CMP'
          ? (
            <div>
              <div className="mt-footer win-balance d-flex align-items-center justify-content-around">
                <span className='greenText'> <b> <FormattedMessage id="Congrats_you_win" /> {winCount && winCount.length} <FormattedMessage id="Win_contest" /> </b>
                <br/>
                <b className='blackText'> <img src={Trophy}></img> {currencyLogo} {finalData || '-'} </b>
                </span>
              </div>
            </div>
            )
          : ' '
      }
      {
        !playerInfo && (
        <div className={classNames('league-container', { 'league-completed': (!props.showScored) }, { 'shaw-won': (winCount && winCount.length && finalData && matchDetails && matchDetails.eStatus === 'CMP') })}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {loading || joinedLoading
                ? <SkeletonLeague leagueDetails length={4} />
                : (
                  <Card className="leagues-card border-0 bg-transparent">
                    {
                      joinedLeagueList && joinedLeagueList.length > 0
                        ? joinedLeagueList.map(LeagueData => {
                          return (
                          <Fragment key={`${LeagueData._id}`}>
                            <Suspense fallback={<Loading />}>
                              <div className="pb-3">
                                <League {...props} participated={participate} matchType='completed' data={LeagueData} key={LeagueData._id} />
                              </div>
                            </Suspense>
                          </Fragment>
                          )
                        }
                        )
                        : <div className="text-center">
                              <h3 className='mt-5'>
                                <FormattedMessage id="No_Contest" />
                              </h3>
                            </div>
                    }
                  </Card>
                  )}
            </TabPane>
            <TabPane tabId="2">
              {loading
                ? <SkeletonTeam length={3} />
                : teamList && teamList.length > 0
                  ? teamList.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((teamDetailsData, index) => {
                    return (
                    <Suspense key={teamDetailsData._id} fallback={<Loading />}>
                      <MyTeam matchType={matchType} {...props} points={true} playerScorePoints={(id) => playerScorePoints(id)} index={index} name="Cricket" teamDetails={teamDetailsData} key={teamDetailsData._id} allTeams={teamList} onPreviewTeam />
                    </Suspense>
                    )
                  })
                  : <div className="text-center">
                    <h3 className='mt-5'>
                      <FormattedMessage id="No_Team" />
                    </h3>
                  </div>
              }
            </TabPane>
            <TabPane tabId="3">
              <Suspense fallback={<Loading />}>
                <PlayerStats {...props} completed matchPlayerList={matchPlayerList} teamList={teamList} matchDetails={matchDetails}
                  uniqueList={uniquePlayerList} />
              </Suspense>
            </TabPane>
            <TabPane tabId="4">
              {scoreCardData &&
                <Suspense fallback={<Loading />}>
                  <div ref={inningEleRef} />
                </Suspense>
              }
            </TabPane>
          </TabContent>
        </div>
        )
      }
      {playerInfo
        ? (
          <Fragment>
            <PlayerInfo2 {...props} onBackClick={() => setPlayerInfo(false)}
                pId={playerId}
                setPlayerId={setPlayerId} matchPlayerList={matchPlayerList}/>
          </Fragment>
          ) : '' }
    </>
  )
}

LeaguesCompletedPage.propTypes = {
  matchDetails: PropTypes.object,
  teamList: PropTypes.array,
  joinedLeagueList: PropTypes.array,
  matchPlayerList: PropTypes.array,
  uniquePlayerList: PropTypes.array,
  playerScorePoints: PropTypes.func,
  showScored: PropTypes.bool,
  loading: PropTypes.bool,
  participate: PropTypes.bool,
  joinedLoading: PropTypes.bool,
  loadingScorecard: PropTypes.bool,
  activeTab: PropTypes.string,
  toggle: PropTypes.func,
  onUniquePlayers: PropTypes.func,
  setActiveState: PropTypes.func,
  currencyLogo: PropTypes.string,
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string,
    params: PropTypes.object
  }),
  history: PropTypes.shape({
    push: PropTypes.object
  }),
  url: PropTypes.string
}

export default LeagueCompletedComponent(LeaguesCompletedPage)
