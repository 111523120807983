import React, { Fragment } from 'react'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'
import { Card } from 'reactstrap'

function SkeletonUpcoming (props) {
  const { scoredData } = props
  return (
    <Fragment>
      {
        scoredData
          ? <Fragment>
            {Array(1)
              .fill()
              .map((item, index) => (
                <Card key={index} className='scorecard-cricket'>
                  <div className="match-box">
                    <div className="match-i">
                        <div className="team-wrap">
                          <div className="team-icon d-flex align-items-end">
                            <div className="team-img">
                              <Skeleton height={48} width={48} circle={true} />
                            </div>
                            <div className="team-detail">
                              <h3><Skeleton height={15} width={50} /></h3>
                            </div>
                          </div>

                          <div className="team-icon d-flex align-items-end">
                            <div className="team-detail text-right">
                              <h3><Skeleton height={15} width={50} /></h3>
                            </div>
                            <div className="team-img">
                              <Skeleton height={48} width={48} circle={true} />
                            </div>
                          </div>
                        </div>
                        <div className="scorecard-detail">
                          <div className="scorecard-text">
                            <Skeleton height={10} width={50} />
                          </div>

                          <Skeleton height={30} width={80} />
                          <div className="scorecard-text text-right">
                            <Skeleton height={10} width={50} />
                          </div>
                        </div>
                        <div className="footer-m d-flex align-items-center  justify-content-center">
                          <Skeleton height={10} width={200} />
                        </div>

                    </div>
                  </div>
                </Card>
              ))}
          </Fragment>
          : <Fragment>
        {Array(3)
          .fill()
          .map((item, index) => (
            <Card className="leagues-card border-0" key={index}>
              <div className="match-box p-0" key={index}>
                {/* <div className="match-i" key={index}>
                  <div className='m-name'>

                  </div>
                  <center>
                    <Skeleton height={15} width={200} />
                  </center>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="team d-flex align-items-center">
                      <div className="t-img"><Skeleton circle={true} className="border-0" height={60} width={60} /></div>
                      <div className="name">
                        <h3><Skeleton className="border-0" width={60} /></h3>
                      </div>
                    </div>
                    <div className="time"><Skeleton className="border-0" width={60} /></div>
                    <div className="team d-flex align-items-center">
                      <div className="name">
                        <h3><Skeleton className="border-0" width={60} /></h3>
                      </div>
                      <div className="t-img"><Skeleton circle={true} className="border-0" height={60} width={60} /></div>
                    </div>
                  </div>
                </div> */}
                <div className="match-i">
                  <div className='m-name'>
                    <Skeleton className="border-0" width={180} />
                  </div>
                  <div className="d-flex align-items-center justify-content-between only pt-2 pb-2">
                    <div className="team d-flex align-items-center">
                      <div className="t-img">
                        <Skeleton circle={true} className="border-0" height={48} width={48} />
                      </div>
                      <div className="name">
                        <Skeleton className="border-0" height={16} width={50} />
                      </div>
                    </div>
                    <div className="time"><Skeleton className="border-0" height={30} width={80} /> </div>
                    <div className="team d-flex align-items-center">
                      <div className="name">
                        <Skeleton className="border-0" height={16} width={50} />
                      </div>
                      <div className="t-img">
                        <Skeleton circle={true} className="border-0" height={48} width={48} />
                      </div>
                    </div>
                  </div>
                  <div className="footer-m d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column w-100">
                      <hr className="m-0 footer-hr" />
                      <ul className="d-flex align-items-center justify-content-between mt-2 mb-2">
                        <li>
                          <Skeleton className="border-0" height={12} width={25} />
                        </li>
                        <li>
                          <Skeleton className="border-0" height={12} width={25} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          ))}
          </Fragment>
      }
    </Fragment>
  )
}

SkeletonUpcoming.propTypes = {
  scoredData: PropTypes.bool
}
export default SkeletonUpcoming
