import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = useSelector(state => state.auth.token) || localStorage.getItem('Token')
  const maintenanceMode = useSelector(state => state.settings.maintenanceMode)

  return (
  <Route
    {...rest}
    render={props => (maintenanceMode?.bIsMaintenanceMode ? <Redirect to={{ pathname: '/maintenance-mode' }} /> : token ? <Component {...props} /> : <Redirect to={{ pathname: '/home' }} />)}
  />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired
}

export default PrivateRoute
