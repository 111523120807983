import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import UserHeader from '../../User/components/UserHeader'
import Loading from '../../../component/Loading'
import { FormattedMessage } from 'react-intl'
const HowToPlayPage = lazy(() => import('./HowToPlay'))

function HowToPlay (props) {
  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='Fantasy_How_To_Play' />} />
      <Suspense fallback={<Loading />}>
        <HowToPlayPage {...props}/>
      </Suspense>
    </>
  )
}

HowToPlay.propTypes = {
  match: PropTypes.object
}

export default HowToPlay
