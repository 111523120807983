import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyTeamList, getUserTeam, getCompareUserTeam, getMyTeamPlayerList, getDreamTeam, shareYourTeam, getShareTeam } from '../../redux/actions/team'
import { getMatchDetails } from '../../redux/actions/match'
import { autoPickedPlayer, listMatchPlayer } from '../../redux/actions/player'
import { getAllTeamLeaderBoardList, getMyTeamLeaderBoardList } from '../../redux/actions/leaderBoard'
import PropTypes from 'prop-types'
import qs from 'query-string'
import { ERROR_AUTO_PICK_LIST_PLAYER } from '../../redux/constants'

export const TeamList = (Component) => {
  const MyComponent = (props) => {
    const { match, activeTab, notCalling } = props
    const [userTeamData, setUserTeamData] = useState({})
    const [loading, setLoading] = useState(false)
    // const [url, setUrl] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [autoPickAnimation, setAutoPickAnimation] = useState(false)

    const dispatch = useDispatch()
    const userData = useSelector(state => state.profile.userInfo || state.auth.userData)
    const teamList = useSelector(state => state.team.teamList)
    const userTeam = useSelector(state => state.team.userTeam)
    const dreamTeam = useSelector(state => state.team.dreamTeam)
    const shareTeam = useSelector(state => state.team.shareTeam)
    const teamPlayerList = useSelector(state => state.team.teamPlayerList)
    const matchDetails = useSelector(state => state.match.matchDetails)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const userCompareTeam = useSelector(state => state.team.userCompareTeam)
    const matchPlayer = useSelector(state => state.player.matchPlayer)
    const autoPickPlayerData = useSelector(state => state.player.autoPickPlayerData)
    const errorMessage = useSelector(state => state.player.errorMessage)
    const myTeamsLeaderBoardList = useSelector(state => state.leaderBoard.myTeamsLeaderBoardList)
    const allLeaderBoardList = useSelector(state => state.leaderBoard.allLeaderBoardList)
    const nPutTime = useSelector(state => state.leaderBoard.nPutTime)
    const token = useSelector(state => state.auth.token)
    const activeSports = useSelector(state => state.activeSports.activeSports)
    const shareTeamResStatus = useSelector(state => state.team.shareTeamResStatus)
    const shareTeamRefferalCode = useSelector(state => state.team.shareTeamRefferalCode)
    const teamResMessage = useSelector(state => state.team.resMessage)
    const playerRoles = activeSports && activeSports.find(sport => sport.sKey === ((match.params.sportsType).toUpperCase())) && activeSports.find(sport => sport.sKey === ((match.params.sportsType).toUpperCase())).aPlayerRoles
    const previousProps = useRef({
      teamList, userTeam, activeTab, userCompareTeam, matchDetails, teamPlayerList, getUrlLink
    }).current

    const obj = qs.parse(location.search)

    useEffect(async () => {
      if (match && match.params && match.params.id) { // handle the initialize
        myTeamPlayerList(match.params.id)
        await !notCalling && myTeamList()
      }
      if (match && match.params && match.params.matchID && match.params.sportsType && token) {
        myTeamPlayerList(match.params.matchID)
        // dispatch(getMyTeamPlayerList(match.params.matchID, token))
        dispatch(getMatchDetails(match.params.matchID, match.params.sportsType, token))
        dispatch(getMyTeamList(match.params.matchID, token))
        setLoading(true)
      }
      // if (teamPlayerList && teamPlayerList.length >= 1 && (match && match.params && match.params.id && teamPlayerList[0].iMatchId !== match.params.id)) {
      //   await dispatch(getMyTeamPlayerList(match.params.id, token))
      // } else if (teamPlayerList && teamPlayerList.length >= 1 && (match && match.params && match.params.matchID && teamPlayerList[0].iMatchId !== match.params.matchID)) {
      //   await dispatch(getMyTeamPlayerList(match.params.matchID, token))
      // }
    }, [token, match.params.matchID, obj.refUrl])

    useEffect(() => {
      if (message) {
        setTimeout(() => {
          setModalOpen(false)
          setMessage('')
        }, 3000)
      }
    }, [message])

    useEffect(() => {
      setLoading(false)
      // if (previousProps.teamList !== teamList) { // handle the loader
      //   if (teamList) {
      //   }
      // }
      // return () => {
      //   previousProps.teamList = teamList
      // }
    }, [teamList])

    useEffect(() => {
      if (previousProps.userTeam !== userTeam) { // handle the loader
        if (userTeam) {
          setUserTeamData(userTeam)
          setLoading(false)
        }
      }
      return () => {
        previousProps.userTeam = userTeam
      }
    }, [userTeam])

    useEffect(() => {
      if (previousProps.teamPlayerList !== teamPlayerList) { // handle the loader
        if (teamPlayerList) {
          setLoading(false)
        }
      }
      return () => {
        previousProps.teamPlayerList = teamPlayerList
      }
    }, [teamPlayerList])

    useEffect(_ => {
      if (autoPickPlayerData?.length) {
        setTimeout(() => {
          setAutoPickAnimation(false)
          dispatch({ type: ERROR_AUTO_PICK_LIST_PLAYER, payload: { errorMessage: '' } })
        }, 4000)
      } else if (errorMessage) {
        setAutoPickAnimation(false)
        setMessage(errorMessage)
        setModalOpen(true)
      }
      return () => {
        previousProps.autoPickPlayerData = autoPickPlayerData
      }
    }, [autoPickPlayerData, errorMessage])

    function getUserTeamFun (userTeamId) {
      token && dispatch(getUserTeam(userTeamId, token))
    }

    function shareTeamFun (userTeamId) {
      token && dispatch(shareYourTeam(userTeamId, token))
    }

    function getShareTeamFun (userTeamId, refId) {
      token && dispatch(getShareTeam(userTeamId, refId, token))
    }

    function getUserCompareTeamFun (userTeamId) {
      token && dispatch(getCompareUserTeam(userTeamId, token))
    }

    function refreshContent () {
      if (match && match.params && (match.params.id || match.params.matchID) && token) {
        if (match.params.id) {
          dispatch(getMyTeamList(match.params.id, token))
        } else {
          dispatch(getMyTeamList(match.params.matchID, token))
        }
        setLoading(true)
      }
    }

    function getMatchPlayerList (Id) {
      if (Id) {
        token && dispatch(listMatchPlayer(Id, token))
      }
    }

    function myTeamList () {
      if (token && match.params.id) {
        dispatch(getMyTeamList(match.params.id, token))
        setLoading(true)
      }
    }

    function getDreamTeamFunc (matchID, token) {
      dispatch(getDreamTeam(matchID, token))
      setLoading(true)
    }

    function myTeamPlayerList (matchId) {
      dispatch(getMyTeamPlayerList(matchId, token))
      setLoading(true)
    }

    function getMyTeamLeaderBoardListFunc (leagueId) {
      dispatch(getMyTeamLeaderBoardList(leagueId, token))
    }

    function myAllTeamPagination (limit, offset, leagueId) {
      dispatch(getAllTeamLeaderBoardList(limit, offset, leagueId, token, nPutTime))
    }

    const onClickAutoPick = async ({ matchID }) => {
      dispatch(autoPickedPlayer(matchID, token))
    }

    return (
      <Component
        {...props}
        loading={loading}
        refreshContent={refreshContent}
        getUserTeam={getUserTeamFun}
        getUserCompareTeam={getUserCompareTeamFun}
        getMatchPlayerList={getMatchPlayerList}
        matchPlayer={matchPlayer}
        teamList={teamList}
        userTeam={userTeamData}
        setLoading={setLoading}
        token={token}
        matchDetails={matchDetails}
        teamPlayerList={teamPlayerList}
        myTeamList={myTeamList}
        url={getUrlLink}
        playerRoles={playerRoles}
        allLeaderBoardList={allLeaderBoardList}
        getMyTeamLeaderBoardListFunc={getMyTeamLeaderBoardListFunc}
        myAllTeamPagination={myAllTeamPagination}
        myTeamsLeaderBoardList={myTeamsLeaderBoardList}
        sportsType={match.params.sportsType}
        teamResMessage={teamResMessage}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        message={message}
        setMessage={setMessage}
        getDreamTeamFunc={getDreamTeamFunc}
        dreamTeam={dreamTeam}
        shareTeamFun={shareTeamFun}
        getShareTeamFun={getShareTeamFun}
        shareTeam={shareTeam}
        shareTeamResStatus={shareTeamResStatus}
        shareTeamRefferalCode={shareTeamRefferalCode}
        userData={userData}
        autoPickAnimation={autoPickAnimation}
        setAutoPickAnimation={setAutoPickAnimation}
        fetchAutoPickData={onClickAutoPick}
      />
    )
  }
  MyComponent.propTypes = {
    match: PropTypes.object,
    activeTab: PropTypes.string,
    notCalling: PropTypes.bool
  }
  return MyComponent
}

export default TeamList
