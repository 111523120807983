import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { viewScoreCard } from '../../../../redux/actions/scoreCard'

function ScoreCards (props) {
  const { match } = props
  const dispatch = useDispatch()
  const scoreCardData = useSelector(state => state.scoreCard.scoreCard)
  const inningEleRef = useRef('')
  const previousProps = useRef({ scoreCardData }).current

  useEffect(() => {
    dispatch(viewScoreCard(match?.params?.id))
  }, [])

  useEffect(() => {
    if (previousProps.scoreCardData !== scoreCardData) {
      if (scoreCardData && inningEleRef.current) {
        const fragment = document.createRange().createContextualFragment(scoreCardData)
        inningEleRef?.current?.append(fragment)
      }
    }
    return () => {
      previousProps.scoreCardData = scoreCardData
    }
  }, [scoreCardData])

  return (<div ref={inningEleRef} />)
}

ScoreCards.propTypes = {
  match: PropTypes.object
}
export default ScoreCards
