import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment'
import PlayerImage from '../../../assests/images/PlayerImage.svg'
import PlayerDetails from '../../../HOC/SportsLeagueList/PlayerDetails'
import Loading from '../../../component/SkeletonPlayerLeagueInfo'
// import { cricketStaticImage, footballStaticImage } from '../../../helper'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
// import noPlayerStatsLogo from '../../../assests/images/noplayer-stat-logo.svg'
// import info from '../../../assests/images/info-icon-gray.svg'
import CloseIcon from '../../../assests/images/close-icon.svg'
import { useSelector } from 'react-redux'
import { Button, CardFooter } from 'reactstrap'
import HomeTeam from '../../../assests/images/Team1.png'
import AwayTeam from '../../../assests/images/Team2.png'
import Lottie from 'react-lottie'
import noTournaments from '../../../assests/animations/no-tournaments.json'
import { allSportsRolesSingular, clickEventPost, handleImgError, pageViewPost } from '../../../helper'

function PlayerLeagueInfo (props) {
  const { playerData, seasonMatch, loading, match, onPlayerInfoClick, playerScorePoints, addFunc, onBackClick, showAddFunc } = props

  const [myPlayer, setMyPlayer] = useState({})
  const [totalPoint, setTotalPoint] = useState(0)

  const url = useSelector(state => state.url.getUrl)
  const playerList = useSelector(state => state.team.teamPlayerList)
  const matchDetails = useSelector(state => state.match.matchDetails)

  useEffect(() => {
    pageViewPost('ppb')
  }, [])

  useEffect(() => {
    if (playerList && playerData) {
      const player = playerList.find((p) => p._id === playerData._id)
      setMyPlayer(player)
    }
  }, [playerList, playerData])

  useEffect(() => {
    if (seasonMatch?.length > 0) {
      const sum = seasonMatch.reduce((sum, item) => {
        const updatedSum = sum + item.nScoredPoints
        return updatedSum
      }, 0)
      setTotalPoint(sum)
    }

    return () => {
      setTotalPoint(0)
    }
  }, [seasonMatch])

  function goToScoreList (match) {
    playerScorePoints(match._id)
    onPlayerInfoClick()
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noTournaments,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <Fragment>
      <div className="s-team-bg" onClick={() => onBackClick()}></div>
      <div className="filter-card player-info p-league-info pb-0">
        <div className='card-header d-flex align-items-center justify-content-between m-0'>
          <h2><FormattedMessage id="Player_Info" /></h2>
          <button onClick={() => onBackClick()} ><img src={CloseIcon}></img></button>
        </div>
        <div className={`popup-content ${matchDetails && matchDetails.eStatus === 'U' && (location.pathname.includes('/create-team/') || location.pathname.includes('/copy-team/')) ? 'footer-sticky' : ''}`} >
          <div className="container-inside">
            <div className='player-info-card'>
              <div className='player-detail'>
                <div className='player-img'>
                  <img src={playerData?.sImage && url ? `${url}${playerData?.sImage}` : PlayerImage} alt="" className="h-100 v-100 fullBorderRadius" />
                </div>
                <div className="player-data">
                  <h2>{playerData && playerData.sName ? playerData.sName : ''}</h2>
                  <div className='player-stat'>
                    <div className='d-flex'>
                      <p><FormattedMessage id="Player_points" /> <b>{totalPoint}</b></p>
                      <p><FormattedMessage id="Player_credits" /> <b>{playerData && playerData.nFantasyCredit ? playerData.nFantasyCredit : 0}</b></p>
                      {/* <p><FormattedMessage id="Player_selectedBy" /> <b>66%<FormattedMessage id="Common_perc" /></b></p> */}
                    </div>
                    {/* <div className='d-flex'>
                      <p><FormattedMessage id="Player_Captain_by" /> <b>123<FormattedMessage id="Common_perc" /></b></p>
                      <p><FormattedMessage id="Player_Vice_Captain_by" /> <b>123<FormattedMessage id="Common_perc" /></b></p>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='player-bats-info'>
                <div className='county-img mr-2'>
                  <img src={myPlayer && myPlayer?.oTeam?.sImage && url ? `${url}${myPlayer?.oTeam?.sImage}` : matchDetails?.oHomeTeam?.sShortName === myPlayer?.oTeam?.sShortName ? HomeTeam : AwayTeam} onError={(e) => handleImgError(e, (matchDetails?.oHomeTeam?.sShortName === myPlayer?.oTeam?.sShortName ? HomeTeam : AwayTeam))} alt="img" />
                </div>
                <span className='player-role'>{allSportsRolesSingular(playerData && playerData.eRole)}{playerData && playerData.aPlayingStyle.map((item, index) => {
                  return (
                    <span key={index}>
                      , {item}
                    </span>
                  )
                })}</span>
              </div>
              <div className="player-bats-info-two d-flex flex-wrap">
                <div className="player-bats-info-two-inner text-center">
                    <h4><FormattedMessage id="Match_played_txt" /></h4>
                    <span>{playerData && playerData.nMatchPlayed}</span>
                </div>
                <div className="player-bats-info-two-inner text-center">
                    <h4><FormattedMessage id="Avg_points_txt" /></h4>
                    <span>{playerData && playerData.nAvgScoredPoints.toFixed(2)}</span>
                </div>
                <div className="player-bats-info-two-inner text-center">
                    <h4><FormattedMessage id="Dream_team_txt" /></h4>
                    <span>{playerData && playerData.nInDreamTeam}<FormattedMessage id="Common_perc" /></span>
                </div>
              </div>
            </div>
            <div className="fantasy-stats">
              {seasonMatch && seasonMatch.length > 0 &&
                <h1 className='fantasy-stats-title text-capitalize'>
                  <FormattedMessage id="Player_matchWise" />
                </h1>
              }
              {
                loading
                  ? <Loading />
                  : seasonMatch && seasonMatch.length > 0 && seasonMatch.map(match2 => {
                    return (
                      <>
                        <div className="fantasy-stats-cards" onClick={() => goToScoreList(match2)} key={match._id}>
                          <div className="card-title d-flex align-items-center">
                              <span className='team-name mr-2'>
                                  <b>{match2 && match2.oMatch && match2.oMatch.sName}</b>
                                  {/* VS <b>AUS</b> */}
                              </span>
                              <span className='date'>{match2 && match2.oMatch && match2.oMatch.dStartDate && moment(match2.oMatch.dStartDate).format('ll')}</span>
                          </div>
                          <div className="player-bats-info-three d-flex flex-wrap">
                            <div className="player-bats-info-three-inner text-center">
                                <h4><FormattedMessage id="Player_selectedBy" /></h4>
                                <span>{match2 && match2.nSetBy && match2.nSetBy} <FormattedMessage id="Common_perc" /></span>
                            </div>
                            <div className="player-bats-info-three-inner text-center">
                                <h4><FormattedMessage id="League_Points" /></h4>
                                <span>{match2 && match2.nScoredPoints && match2.nScoredPoints}</span>
                            </div>
                            <div className="player-bats-info-three-inner text-center">
                                <h4><FormattedMessage id="Player_credits" /></h4>
                                <span>{match2 && match2.nFantasyCredit && match2.nFantasyCredit}</span>
                            </div>
                            <div className="player-bats-info-three-inner text-center">
                                <h4><FormattedMessage id="Batting_points_txt" /></h4>
                                <span>{match2 && match2.nBattingPts && match2.nBattingPts}</span>
                            </div>
                            <div className="player-bats-info-three-inner text-center">
                                <h4><FormattedMessage id="Bowling_points_txt" /></h4>
                                <span>{match2 && match2.nBollingPts && match2.nBollingPts}</span>
                            </div>
                            <div className="player-bats-info-three-inner text-center">
                                <h4><FormattedMessage id="Other_points_txt" /></h4>
                                <span>{match2 && match2.nOtherPts && match2.nOtherPts}</span>
                            </div>
                          </div>
                        </div>
                          {matchDetails && matchDetails.eStatus === 'U' && (location.pathname.includes('/create-team/') || location.pathname.includes('/copy-team/')) && addFunc &&
                    <div className='btn-bottom border-0  d-flex justify-content-center align-items-center'>
                      <Button color='primary' onClick={() => {
                        addFunc.func()
                        onBackClick()
                        if (showAddFunc) {
                          clickEventPost('tc_ad')
                        }
                      }} className="w-100">{showAddFunc ? <FormattedMessage id="Add_to_team_txt" /> : 'Remove from my team'}</Button>
                    </div>
                          }
                      </>
                    )
                  })
              }
            </div>

            {
                seasonMatch && !seasonMatch.length && (
                <div className="no-team noplayerinfo d-flex align-items-center justify-content-center fixing-width2 height-auto">
                  <div className='no-team-inside mb-4'>
                  {/* <img src={noPlayerStatsLogo}></img> */}
                    <Lottie width={197} options={defaultOptions} isClickToPauseDisabled={true} />
                    <h6 className='mt-4'>
                      <FormattedMessage id="No_Info_available" />
                    </h6>
                    <p className="mb-3"><FormattedMessage id="No_Info_available_des" /></p>
                    {matchDetails && matchDetails.eStatus === 'U' && (location.pathname.includes('/create-team/') || location.pathname.includes('/copy-team/')) && addFunc &&
                    <Button color='primary' onClick={() => {
                      addFunc.func()
                      onBackClick()
                    }} className="w-100">{showAddFunc ? <FormattedMessage id="Add_to_team_txt" /> : 'Remove from my team'}</Button>
                    }

                  </div>
                </div>)
              }
          </div>
          {matchDetails && matchDetails.eStatus === 'U' && !seasonMatch && (location.pathname.includes('/create-team/') || location.pathname.includes('/copy-team/')) && addFunc &&
            <CardFooter className='btn-bottom border-0  d-flex justify-content-center align-items-center'>
              <Button color='primary' onClick={() => {
                addFunc.func()
                onBackClick()
              }} className="w-100">{showAddFunc ? <FormattedMessage id="Add_to_team_txt" /> : 'Remove from my team'}</Button>
            </CardFooter>
          }
        </div>
      </div>
    </Fragment>
  )
}
PlayerLeagueInfo.propTypes = {
  onBackClick: PropTypes.func,
  onPlayerInfoClick: PropTypes.func,
  playerData: PropTypes.shape({
    _id: PropTypes.string,
    eRole: PropTypes.string,
    sName: PropTypes.string,
    nFantasyCredit: PropTypes.number,
    nScoredPoints: PropTypes.number,
    nSeasonPoints: PropTypes.number,
    sImage: PropTypes.string,
    sTeamName: PropTypes.string,
    nMatchPlayed: PropTypes.number,
    nAvgScoredPoints: PropTypes.number,
    nInDreamTeam: PropTypes.number,
    aPlayingStyle: PropTypes.array
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      matchID: PropTypes.string,
      playerId: PropTypes.string,
      userTeamID: PropTypes.string,
      sportsType: PropTypes.string
    }),
    _id: PropTypes.string
  }),
  history: PropTypes.object,
  nScoredPoints: PropTypes.number,
  seasonMatch: PropTypes.array,
  loading: PropTypes.bool,
  pId: PropTypes.string,
  playerScorePoints: PropTypes.func,
  addFunc: PropTypes.object,
  showAddFunc: PropTypes.bool
}
export default PlayerDetails(PlayerLeagueInfo)
