import React, { useState } from 'react'
// import LeagueHeader from '../components/LeagueHeader'
// import HomeFooter from '../components/HomeFooter'
import PropTypes from 'prop-types'
import Matches from './Matches'
import HomeHeader from '../components/HomeHeader'
function MyMatches (props) {
  const [mainIndex, setMainIndex] = useState(0)
  const [paymentSlide, setPaymentSlide] = useState(0)

  return (
    <>
      <HomeHeader
        {...props}
        setMainIndex={setMainIndex}
        paymentSlide={paymentSlide}
        setPaymentSlide={setPaymentSlide}
        showBalance
        sportsRequired/>
      <Matches
        {...props}
        mainIndex={mainIndex}
        paymentSlide={paymentSlide}
        setPaymentSlide={setPaymentSlide}
      />
      {/* <HomeFooter {...props} isPublic={props.match?.path === '/matches/:sportsType/v1'} /> */}
    </>
  )
}

MyMatches.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  pathName: PropTypes.string,
  mainIndex: PropTypes.string
}

export default MyMatches
