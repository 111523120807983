/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import qs from 'query-string'
import { Button, Card, CardFooter, CardHeader, CardBody, Alert, UncontrolledPopover, PopoverBody } from 'reactstrap'
import Lottie from 'react-lottie'
import yotubeIcon from '../../../assests/images/ic_live.svg'
import walletIcon from '../../../assests/images/ic_wallet.svg'
import howToPlayIcon from '../../../assests/images/HowToPlayButton.svg'
import infoIconBlue from '../../../assests/images/info-icon-blue.svg'
// import homeIcon from '../../../assests/images/homeIconWhite.svg'
import notificationIcon from '../../../assests/images/notificationIcon.svg'
import pointsIcon from '../../../assests/images/points-icon.svg'
import MatchDetails from '../../../HOC/SportsLeagueList/MatchDetails'
import { cricketStaticImage, footballStaticImage, maxValue, openWalletPost } from '../../../helper'
import Match from '../../../../src/views/Home/components/Match'
import CloseIcon from '../../../assests/images/close-icon.svg'
import PlayerImage from '../../../assests/images/PlayerImage.svg'
import timesUp from '../../../assests/animations/matchlive-pulsing.json'

function LeagueHeader (props) {
  const history = useHistory()
  const {
    data,
    search,
    token,
    onGetMatchDetails,
    setPaymentSlide,
    teamPlayerList,
    uniquePlayerList,
    uniquePlayerLeagueList,
    editBackCheck,
    backToSwitchTeam,
    editTeamTitle,
    allTeams,
    completed, goToBack, backTab, location, nextStep, setNextStep, setVideoStream, VideoStream, notShowing, match, insideLeagueDetails, onMatchPlayer, onUniquePlayer, onUniquePlayerLeague, Name, setBackModal, isSelectedPlayer, setMainIndex, backToLeagueSelectTeam, backToLeagueDetailsSelectTeam, backToCreateContest, backToJoinContest
  } = props
  const obj = qs.parse(location.search)
  const [time, setTime] = useState('')
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [intervalRef, setIntervalRef] = useState(null)
  const [goHome, setGoHome] = useState(false)
  const [lineups, setLineups] = useState(false)
  const [teamHome, setTeamHome] = useState([])
  const [teamAway, setTeamAway] = useState([])
  const [liveModal, setLiveModal] = useState(false)
  const url = useSelector(state => state.url.getUrl)
  const previousProps = useRef({ data }).current
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)
  useEffect(() => {
    if (match.params.id && token) {
      onGetMatchDetails(match.params.id)
    }
  }, [token])

  useEffect(() => {
    activeSports && activeSports.length !== 0 && activeSports.map((data2, index) => {
      const name = data2?.sKey.toLowerCase()
      match && match.params && match.params.sportsType === name && setMainIndex && setMainIndex(index + 1)
      return null
    })
  }, [activeSports])

  useEffect(() => {
    if (lineups && match?.params?.id) {
      if (insideLeagueDetails && match?.params?.id2) {
        onUniquePlayerLeague(match?.params?.id2)
      } else {
        token && onUniquePlayer(match?.params?.id)
      }
      (teamPlayerList?.length === 0 || !teamPlayerList) && onMatchPlayer(match?.params?.id)
    }
  }, [lineups])

  useEffect(() => {
    if (teamPlayerList?.length > 0 && lineups) {
      let home = {}
      let away = {}
      teamPlayerList.map(player => {
        if ((player?.oTeam?.iTeamId === data?.oHomeTeam?.iTeamId) && player.bShow) {
          // home.push(player)
          home = {
            ...home,
            [player.eRole]: Object.keys(home).includes(player.eRole) ? [...home[player.eRole], player] : [player]
          }
        } else if ((player?.oTeam?.iTeamId === data?.oAwayTeam?.iTeamId) && player.bShow) {
          away = {
            ...away,
            [player.eRole]: Object.keys(away).includes(player.eRole) ? [...away[player.eRole], player] : [player]
          }
        }
        return player
      })

      if (match.params.sportsType === 'cricket') {
        let teamHome = []
        const WK = home.WK ? home.WK.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const ALLR = home.ALLR ? home.ALLR.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const BATS = home.BATS ? home.BATS.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const BWL = home.BWL ? home.BWL.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamHome = [...WK, ...ALLR, ...BATS, ...BWL]
        setTeamHome(teamHome)

        let teamAway = []
        const WK2 = away.WK ? away.WK.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const ALLR2 = away.ALLR ? away.ALLR.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const BATS2 = away.BATS ? away.BATS.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const BWL2 = away.BWL ? away.BWL.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamAway = [...WK2, ...ALLR2, ...BATS2, ...BWL2]
        setTeamAway(teamAway)
      } else if (match.params.sportsType === 'football') {
        let teamHome = []
        const GK = home.GK ? home.GK.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const DEF = home.DEF ? home.DEF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const MID = home.MID ? home.MID.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const FWD = home.FWD ? home.FWD.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamHome = [...GK, ...DEF, ...MID, ...FWD]
        setTeamHome(teamHome)

        let teamAway = []
        const GK2 = away.GK ? away.GK.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const DEF2 = away.DEF ? away.DEF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const MID2 = away.MID ? away.MID.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const FWD2 = away.FWD ? away.FWD.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamAway = [...GK2, ...DEF2, ...MID2, ...FWD2]
        setTeamAway(teamAway)
      } else if (match.params.sportsType === 'basketball') {
        let teamHome = []
        const C = home.C ? home.C.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const PF = home.PF ? home.PF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const PG = home.PG ? home.PG.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const SF = home.SF ? home.SF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const SG = home.SG ? home.SG.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamHome = [...C, ...PF, ...PG, ...SF, ...SG]
        setTeamHome(teamHome)

        let teamAway = []
        const C2 = away.C ? away.C.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const PF2 = away.PF ? away.PF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const PG2 = away.PG ? away.PG.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const SF2 = away.SF ? away.SF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const SG2 = away.SG ? away.SG.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamAway = [...C2, ...PF2, ...PG2, ...SF2, ...SG2]
        setTeamAway(teamAway)
      } else if (match.params.sportsType === 'baseball') {
        let teamHome = []
        const P = home.P ? home.P.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const IF = home.IF ? home.IF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const CT = home.CT ? home.CT.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const OF = home.OF ? home.OF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamHome = [...P, ...IF, ...CT, ...OF]
        setTeamHome(teamHome)

        let teamAway = []
        const P2 = away.P ? away.P.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const IF2 = away.IF ? away.IF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const CT2 = away.CT ? away.CT.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const OF2 = away.OF ? away.OF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamAway = [...P2, ...IF2, ...CT2, ...OF2]
        setTeamAway(teamAway)
      }
    }
  }, [teamPlayerList, lineups])

  useEffect(() => {
    if (previousProps.data !== data) {
      if (data && data.dStartDate) {
        if ((new Date(data.dStartDate) > Date.now() + 86400000) || (new Date(data.dStartDate) < new Date(Date.now()))) {
          setTime(moment(data.dStartDate).format('lll'))
        } else {
          setIntervalRef(setInterval(() => {
            const duration = moment.duration(moment(data.dStartDate).diff(moment(new Date())))
            if ((duration?.seconds() < 0) || (duration?.minutes() < 0) || (duration?.hours() < 0)) {
              // setGoHome(true)
            } else {
              setTime(`${duration.hours()}h ${duration.minutes()}m  ${duration.seconds()}s left `)
            }
          }))
        }
      }
      return () => {
        clearInterval(intervalRef)
      }
    }
    return () => {
      previousProps.data = data
    }
  }, [data])

  useEffect(() => {
    if (goHome) {
      props.history.push(`/home/${match?.params?.sportsType}`)
    }
  }, [goHome])

  useEffect(() => {
    if (time && data?.dStartDate) {
      const duration = moment.duration(moment(data?.dStartDate).diff(moment(new Date())))
      if ((duration?.seconds() <= 0) && (duration?.minutes() <= 0) && (duration?.hours() <= 0)) {
        if (props.location.pathname.includes('/leagues/')) {
          // setLiveModal(true)
        } else if (props.location.pathname.includes('/leagues-detail/')) {
          if (backToSwitchTeam) {
            props.history.push({ pathname: goToBack, search: search || '', state: { tab: backTab || '3', switchTeamModal: true, currSwitchTeam: props.currentSwitchTeam } })
          } else if (backToLeagueSelectTeam || backToLeagueDetailsSelectTeam) {
            props.history.push({ pathname: goToBack, search: search || '', state: { tab: 1, selectTeamModal: true, leagueId: props.location.state.leagueId } })
          } else if (backToCreateContest) {
            props.history.push({ pathname: goToBack, search: search || '', state: { ...props.location.state, selectTeamModal: true } })
          } else if (backToJoinContest) {
            props.history.push({ pathname: goToBack, search: search || '', state: { selectTeamModal: true, joinContestCode: props.location.state.joinContestCode, verifiedId: props.location.state.verifiedId } })
          } else {
            props.history.push({ pathname: goToBack, search: location.search || '', state: { ...props.location.state, tab: backTab || '3' } })
          }
        }
      }
    }
  }, [time, data])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: timesUp,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <>
      {alert && alertMessage ? <Alert isOpen={alert} className='select-all' color="primary">{alertMessage}</Alert> : ''}
      <div className="league-header">
        <div className="d-flex align-items-center header-i justify-content-between">
          <div className="d-flex align-items-center">
            {
              (goToBack || props.location.pathname.includes('/private/'))
                ? <Fragment>
                  <Button
                    // tag={Link}
                    // to={{ pathname: goToBack, search: search || '', state: { tab: backTab || '1' } }}
                    onClick={() => {
                      if ((location?.pathname?.includes('/create-team/') || location?.pathname?.includes('/copy-team/')) && isSelectedPlayer.length !== 0 && editBackCheck && nextStep) {
                        setBackModal(true)
                      } else {
                        if (!nextStep && (location?.pathname?.includes('/create-team/') || location?.pathname?.includes('/copy-team/'))) {
                          setNextStep(true)
                        } else if (goToBack) {
                          if (backToSwitchTeam) {
                            props.history.push({ pathname: goToBack, search: search || '', state: { tab: backTab || '3', switchTeamModal: true, currSwitchTeam: props.currentSwitchTeam } })
                          } else if (backToLeagueSelectTeam || backToLeagueDetailsSelectTeam) {
                            props.history.push({ pathname: goToBack, search: search || '', state: { tab: 1, selectTeamModal: true, leagueId: props.location.state.leagueId } })
                          } else if (backToCreateContest) {
                            props.history.push({ pathname: goToBack, search: search || '', state: { ...props.location.state, selectTeamModal: true } })
                          } else if (backToJoinContest) {
                            props.history.push({ pathname: goToBack, search: search || '', state: { selectTeamModal: true, joinContestCode: props.location.state.joinContestCode, verifiedId: props.location.state.verifiedId } })
                          } else {
                            props.history.push({ pathname: goToBack, search: location.search || '', state: { ...props.location.state, tab: backTab || '3' } })
                          }
                        } else if (props.location.pathname.includes('/private/')) {
                          props.history.goBack()
                        } else {
                          setGoHome(true)
                        }
                      }
                    }
                    }
                    className="btn-link icon-left-arrow">
                  </Button>
                </Fragment>
                : <Fragment>
                  <Button onClick={() => {
                    if (location && location.state && location.state.referUrl) {
                      history.push(location.state.referUrl, { tab: location.state.tab })
                    // } else if (!nextStep && setNextStep) {
                    //   setNextStep && setNextStep(true)
                    } else if (location.pathname.includes('/matches/')) {
                      setGoHome(true)
                    } else {
                      history.goBack()
                    }
                  }} className="btn-link icon-left-arrow"></Button>
                </Fragment>
            }
            {/* <Button className='button-link bg-transparent py-2' tag={Link} to={match.path === '/leagues/:sportsType/:id/v1' ? `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}/v1` : `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`}><img src={homeIcon}></img></Button> */}
            <div>
              <h1>{Name ? `${editTeamTitle ? 'Edit Team' : 'Create Team'} ${Name}` : ((data && data.oHomeTeam.sShortName && data.oAwayTeam.sShortName && !location.pathname.includes('/matches/')) ? `${data.oHomeTeam.sShortName} vs ${data.oAwayTeam.sShortName}` : 'My Matches')}
              {/* {data?.bLineupsOut && data?.eStatus === 'U' && <Button className='btn-lineups-league' onClick={() => setLineups(true)}>
                <FormattedMessage id="Matches_LineupsOut" />
                <Fragment>
                  <img className="icon-info i-button ml-1" src={infoIconBlue} type="button" id={'info-icon'} />
                </Fragment>
              </Button>} */}
              </h1>
              {!completed
                ? data?.bLineupsOut && data?.eStatus === 'U'
                  ? <p style={{ color: '#20A5F9', textTransform: 'uppercase', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => setLineups(true)}><FormattedMessage id="Matches_LineupsOut" /></p>
                  : !location.pathname.includes('/matches/') && <p style={{ color: '#FF0000' }}>{time}</p>
                : data && data.eStatus && data.eStatus === 'L'
                  ? <p style={{ color: '#FF0000', textTransform: 'uppercase', fontWeight: 'bold' }}><FormattedMessage id="Matches_Live" /></p>
                  : data && data.eStatus === 'CMP'
                    ? <p style={{ color: '#1FAC1D', textTransform: 'uppercase', fontWeight: 'bold' }}><FormattedMessage id="Matches_Completed" /></p>
                    : data && data.eStatus === 'I' ? <p style={{ color: '#20A5F9', textTransform: 'uppercase', fontWeight: 'bold' }}><FormattedMessage id="Match_Review" /></p> : ''}
            </div>
          </div>
          <ul className="d-flex m-0 ht-link">
            {props.showLinks
              ? <Fragment>
                <li><Link className="icon-message" to="/chats" ></Link></li>
                <li>
                  <Link to='/notifications'>
                    <img src={notificationIcon} alt={<FormattedMessage id='Notification' />} width={20}></img>
                  </Link>
                </li>
              </Fragment>
              : ''
            }
            {/* { */}
              {/* props.showBalance && token && ( */}
                <>
                  <li className='mr-3' role='button' onClick={() => props.history.push({ pathname: `/how-to-play/${match.params.sportsType}`, search: '', state: { goBack: true } })}> <img src={howToPlayIcon}></img> </li>
                  <li className='mr-3' role='button'> <img src={pointsIcon} onClick={() => props.history.push({ pathname: '/point-system', search: `?activeSport=${match.params.sportsType}`, state: { ...props.location.state, goBack: true, allTeams, backToCreateTeam: true, matchId: match.params.id, sportsType: activeSport && activeSport[0]?.sKey, pathname: location.pathname } })}></img> </li>
                  <li className='mr-0' role='button' onClick={() => openWalletPost()}> <img src={walletIcon}></img> </li>
                </>
              {/* ) */}
            {/* } */}
            {data && data.sStreamUrl && !notShowing
              ? <Fragment>
                <li><img src={yotubeIcon} onClick={() => {
                  if (data.sStreamUrl.includes('https://www.youtube.com/')) {
                    setVideoStream(true)
                  } else {
                    setVideoStream(true)
                    setAlert(true)
                    setTimeout(() => {
                      setVideoStream(false)
                      setAlert(false)
                    }, 1000)
                    setAlertMessage('something went wrong.')
                  }
                }}></img></li>
              </Fragment>
              : ''
            }
          </ul>
        </div>
      </div>
      {VideoStream
        ? <div className="player-info videoStream">
          <div className="league-header u-header">
            <div className="d-flex align-items-center header-i">
              <button onClick={() => setVideoStream(false)} className="btn-link icon-left-arrow"></button>
              {/* <Button className='button-link bg-transparent py-2' tag={Link} to={match.path.includes('/v1') ? `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}/v1` : `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`}><img src={homeIcon}></img></Button> */}
              <div>
                <h1>{data && data.oHomeTeam.sShortName && data.oAwayTeam.sShortName ? `${data.oHomeTeam.sShortName} vs ${data.oAwayTeam.sShortName}` : ''} {data && data.eStatus === 'I' ? <FormattedMessage id="Match_IN_Review" /> : ''}</h1>
                {!completed ? <p>{time || (data && data.dStartDate ? moment(data && data.dStartDate).format('lll') : '')}</p> : data && data.eStatus && data.eStatus === 'L' ? <p className='text-uppercase'><FormattedMessage id="Matches_Live" /></p> : data && data.eStatus === 'CMP' && <p><FormattedMessage id="Matches_Completed" /></p>}
              </div>
            </div>
          </div>
          <div className='videoShowing'>
            <iframe
              src={data && data.sStreamUrl}
              frameBorder='0'
              allow='autoplay; encrypted-media'
              title='video'
            />
          </div>
        </div>
        : ''}
      {lineups
        ? <>
          <div className="s-team-bg" onClick={() => setLineups(false)}></div>
          <Card className='filter-card lineupsCard'>
            <CardHeader className='d-flex align-items-center justify-content-between'>
              <h2><FormattedMessage id="League_announced_Lineups" /></h2>
              <button onClick={() => setLineups(false)}><img src={CloseIcon} ></img></button>
            </CardHeader>
            <CardBody className='filter-box'>
              <Match {...props} data={data} key={1} upcoming onlyInsideFeild />
              {
                Array(maxValue(teamHome?.length, teamAway?.length)).fill().map((item, index) => {
                  const teamHomeName = teamHome[index]?.sName.split(' ')
                  const teamAwayName = teamAway[index]?.sName.split(' ')
                  return (
                    <Fragment key={index}>
                      <div className="compare-player bg-white">
                        <div className="p-c-box d-flex align-items-center justify-content-between">
                          {teamHome.length > 0
                            ? <div className={`p-box d-flex align-items-center ${uniquePlayerList?.length > 0 && uniquePlayerList.includes(teamHome[index]?._id) && !insideLeagueDetails ? 'active' : ''} ${uniquePlayerLeagueList?.length > 0 && uniquePlayerLeagueList.includes(teamHome[index]?._id) && insideLeagueDetails ? 'active' : ''}`}>
                            <div className="img">
                              {/* <img src={teamHome[index]?.sImage && url ? `${url}${teamHome[index]?.sImage}` : match && match.params && match.params.sportsType === 'cricket' ? cricketStaticImage(teamHome[index]?.eRole) : match && match.params && match.params.sportsType === 'football' ? footballStaticImage(teamHome[index]?.eRole) : ''} alt="" /> */}
                              <img src={teamHome[index] && teamHome[index].sImage ? `${url}${teamHome[index].sImage}` : PlayerImage} alt="" />
                            </div>
                            <div className="p-name">
                              <h3 className='p-0'>{teamHome[index]?.sName && `${teamHomeName && teamHomeName.length >= 2 ? teamHome[index]?.sName[0] : teamHomeName[0]} ${teamHomeName && teamHomeName.length === 2 ? teamHomeName[1] : teamHomeName && teamHomeName.length === 3 ? `${teamHomeName[2]}` : ''}`}</h3>
                              <p>{teamHome[index]?.eRole}</p>
                            </div>
                          </div>
                            : <div></div>}
                          {teamAway.length > 0
                            ? <div className={`p-box d-flex align-items-center ${uniquePlayerList?.length > 0 && uniquePlayerList.includes(teamAway[index]?._id) && !insideLeagueDetails ? 'active' : ''} ${uniquePlayerLeagueList?.length > 0 && uniquePlayerLeagueList.includes(teamAway[index]?._id) && insideLeagueDetails ? 'active' : ''}`}>
                            <div className="p-name text-right">
                              <h3 className='p-0'>{teamAway[index]?.sName && `${teamAwayName && teamAwayName.length >= 2 ? teamAway[index]?.sName[0] : teamAwayName[0]} ${teamAwayName && teamAwayName.length === 2 ? teamAwayName[1] : teamAwayName && teamAwayName.length === 3 ? `${teamAwayName[1]} ${teamAwayName[2]}` : ''}`}</h3>
                              <p>{teamAway[index]?.eRole}</p>
                            </div>
                            <div className="img">
                              {/* <img src={teamAway[index]?.sImage && url ? `${url}${teamAway[index]?.sImage}` : match && match.params && match.params.sportsType === 'cricket' ? cricketStaticImage(teamAway[index]?.eRole) : match && match.params && match.params.sportsType === 'football' ? footballStaticImage(teamAway[index]?.eRole) : ''} alt="" /> */}
                              <img src={teamAway[index] && teamAway[index].sImage ? `${url}${teamAway[index].sImage}` : PlayerImage} alt="" />
                            </div>
                          </div>
                            : <div></div>}
                        </div>
                      </div>
                    </Fragment>
                  )
                })
              }
              {!Array(maxValue(teamHome?.length, teamAway?.length)).fill().length &&
                <div className="Lineups-nodata">
                  <div className='text-center'>
                    <h2><FormattedMessage id="No_Data_Found" /></h2>
                    <p><FormattedMessage id="Please_Check_back" /></p>
                  </div>
                </div>
              }
            </CardBody>
            {/* <CardFooter className='bg-trnsparent'>
              <p><FormattedMessage id="League_Notes" /></p>
              <span className='spanColor'> <FormattedMessage id="League_Orange_Text" /></span>
            </CardFooter> */}
          </Card>
        </>
        : ''}
        {liveModal
          ? <>
          <div className="s-team-bg"></div>
          <div className="alertpopup-wrap">
            <div className="alertpopup">
              <h3>This match is live now</h3>
              <p className="mb-2">Please bear with us, enjoy the latest score in a moment.</p>
              <Lottie width={150} options={defaultOptions} isClickToPauseDisabled={true} />
              <Button className="" color='primary' onClick={() => setGoHome(true)}>Go Back</Button>
            </div>
          </div>
        </>
          : ''}
    </>
  )
}

LeagueHeader.propTypes = {
  data: PropTypes.shape({
    sName: PropTypes.string,
    bLineupsOut: PropTypes.bool,
    sStreamUrl: PropTypes.string,
    dStartDate: PropTypes.oneOfType([
      PropTypes.Date,
      PropTypes.string
    ]),
    eStatus: PropTypes.string,
    oHomeTeam: PropTypes.shape({
      iTeamId: PropTypes.string,
      sShortName: PropTypes.string
    }),
    oAwayTeam: PropTypes.shape({
      iTeamId: PropTypes.string,
      sShortName: PropTypes.string
    })
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      sportsType: PropTypes.string,
      id: PropTypes.string,
      id2: PropTypes.string
    }),
    path: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      referUrl: PropTypes.string,
      tab: PropTypes.number,
      currSwitchTeam: PropTypes.string,
      leagueId: PropTypes.string,
      joinContestCode: PropTypes.string,
      verifiedId: PropTypes.string
    }),
    search: PropTypes.string,
    pathname: PropTypes.string
  }),
  teamPlayerList: PropTypes.arrayOf(PropTypes.shape({
    length: PropTypes.number
  })),
  uniquePlayerList: PropTypes.array,
  uniquePlayerLeagueList: PropTypes.array,
  completed: PropTypes.bool,
  showLinks: PropTypes.bool,
  showBalance: PropTypes.bool,
  backTab: PropTypes.number,
  goToBack: PropTypes.string,
  setNextStep: PropTypes.func,
  onMatchPlayer: PropTypes.func,
  onUniquePlayer: PropTypes.func,
  onUniquePlayerLeague: PropTypes.func,
  insideLeagueDetails: PropTypes.bool,
  nextStep: PropTypes.string,
  setVideoStream: PropTypes.func,
  setPaymentSlide: PropTypes.func,
  VideoStream: PropTypes.bool,
  notShowing: PropTypes.bool,
  onGetMatchDetails: PropTypes.func,
  token: PropTypes.string,
  search: PropTypes.string,
  Name: PropTypes.string,
  setBackModal: PropTypes.func,
  isSelectedPlayer: PropTypes.array,
  editBackCheck: PropTypes.bool,
  setMainIndex: PropTypes.func,
  backToSwitchTeam: PropTypes.bool,
  currentSwitchTeam: PropTypes.object,
  editTeamTitle: PropTypes.string,
  backToLeagueSelectTeam: PropTypes.bool,
  backToLeagueDetailsSelectTeam: PropTypes.bool,
  backToCreateContest: PropTypes.bool,
  backToJoinContest: PropTypes.bool,
  allTeams: PropTypes.array
}

export default MatchDetails(LeagueHeader)
