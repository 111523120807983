import React, { useEffect, useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { CarouselControl, Carousel, CarouselItem } from 'reactstrap'
import { isUpperCase, allSportsRoles, handleImgError, shareTeamPost, pageViewPost } from '../../../helper'
import { Link } from 'react-router-dom'
// import { useQueryState } from 'react-router-use-location-state'
import Loading from '../../../component/Loading'
import PlayerLeagueInfo from '../components/PlayerLeagueInfo'
import PlayerInfo from '../components/PlayerInfo'
import PlayerImage from '../../../assests/images/PlayerImage.svg'
import TeamList from '../../../HOC/SportsLeagueList/TeamList'
import qs from 'query-string'
// import { useSelector } from 'react-redux'
import CaptainIcon from '../../../assests/images/captain-team-icon.svg'
import ViceCaptainIcon from '../../../assests/images/viceCaptain-team-icon.svg'
import closeIcon from '../../../assests/images/close-icon.svg'
import HomeTeam from '../../../assests/images/Team1.png'
import AwayTeam from '../../../assests/images/Team2.png'
import Edit from '../../../assests/images/my-team-edit.svg'
import ShareTeam from '../../../assests/images/my-team-share.svg'
import PointsIcon from '../../../assests/images/points-icon.svg'
import moment from 'moment'
// import TransparentLogo from '../../../assests/images/transparent_logo.svg'
const classNames = require('classnames')

function MyTeamsPreview (props) {
  const { playerRoles, url, token, match, history, teamList, teamPlayerList, teamPreview, userTeam, loading, getUserTeam, setLoading, matchDetails, location, getDreamTeamFunc, dreamTeam, shareTeamFun, getShareTeamFun, shareTeamResStatus, shareTeamRefferalCode, shareTeam, userData } = props
  const [allTeams, setAllTeams] = useState([])
  const [team, setTeam] = useState({})
  // const [viewTeam, setViewTeam] = useState([])
  const [totalCredit, setTotalCredit] = useState(0)
  const [totalScorePoints, setTotalScorePoints] = useState(0)
  const [teamView, setTeamView] = useState(0)
  // const [TeamName, setTeamName] = useState('')
  const [matchType, setMatchType] = useState('')
  const [animating, setAnimating] = useState(false)
  // const [userTeamId, setUserTeamId] = useState('')
  const [teamListCount, setTeamListCount] = useState([])

  const [playerId, setPlayerId] = useState('')
  const [playerInfo, setPlayerInfo] = useState(false)
  const [playerLeagueInfo, setPlayerLeagueInfo] = useState(false)

  const obj = qs.parse(location.search)
  // const activeSports = useSelector(state => state.activeSports.activeSports)
  // const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)
  const previousProps = useRef({
    userTeam, team, teamList, allTeams, shareTeamResStatus
  }).current
  const sportsType = match.params.sportsType

  useEffect(() => {
    pageViewPost('tm')
  }, [])

  useEffect(() => {
    if (props.location.pathname.includes('/dream-team-preview/') && match.params.matchID) {
      getDreamTeamFunc(match.params.matchID, token)
      setTeamView(0)
    } else if (obj.shareTeam === 'yes') {
      getShareTeamFun(match.params.userTeamID, obj.refUrl)
      setTeamView(0)
    } else {
      if (obj.index) {
      // setCurrentIndex(parseInt(obj.index))
        setTeamView(parseInt(obj.index))
      }
      if (match?.params?.sportsType) {
        const sport = match.params.sportsType
        isUpperCase(sport) && history.push(`/my-teams-preview/${sport.toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}`)
      }
      if (match?.params?.userTeamID && token) {
      // setUserTeamId(match.params.userTeamID)
        getUserTeam(match.params.userTeamID)
        setLoading(true)
      }
    // if (match && match.params && match.params.matchID && !matchPlayer) {
    //   getMatchPlayerList(match && match.params && match.params.matchID)
    // }
    }
  }, [token, match.params, obj.shareTeam])

  useEffect(() => {
    if (obj.shareTeam === 'yes' && shareTeam) {
      const arr = []
      arr.push(shareTeam)
      setAllTeams(arr)
    } else if (props.location.pathname.includes('/dream-team-preview') && dreamTeam) {
      const arr = []
      arr.push(dreamTeam)
      setAllTeams(arr)
    } else if (location?.state?.teamCreationPage || location?.state?.joinPrivateContest) {
      setAllTeams(location?.state?.allTeams)
    } else if (teamList && teamList.length !== 0) {
      if (teamList?.some(data => data._id === match.params.userTeamID)) {
        setAllTeams(teamList)
        setTeamView(teamList?.findIndex(data => data._id === match.params.userTeamID))
      } else {
        const arr = []
        arr.push(userTeam)
        setAllTeams(arr)
      }
    } else {
      const arr = []
      arr.push(userTeam)
      setAllTeams(arr)
    }
  }, [teamList, match.params, obj.shareTeam, location.state, dreamTeam, shareTeam])

  // useEffect(() => {
  //   if (dreamTeam && Object.keys(dreamTeam)?.length > 0 && props.location.pathname.includes('/dream-team-preview/')) {
  //     const allteams = []
  //     setAllTeams([...allteams, dreamTeam])
  //   }
  // }, [dreamTeam])

  // useEffect(() => {
  //   if (shareTeam && Object.keys(shareTeam)?.length > 0 && obj.shareTeam === 'yes') {
  //     const allteams = []
  //     setAllTeams([...allteams, shareTeam])
  //   }
  // }, [shareTeam])

  useEffect(() => {
    if (previousProps.shareTeamResStatus !== shareTeamResStatus) {
      if (shareTeamResStatus) {
        const startDate = moment(matchDetails?.dStartDate).format('X')
        shareTeamPost(matchDetails?._id, userTeam?._id, match?.params?.sportsType, startDate, matchDetails?.oHomeTeam?.sShortName, matchDetails?.oAwayTeam?.sShortName, shareTeamRefferalCode)
      }
    }

    return () => {
      previousProps.shareTeamResStatus = shareTeamResStatus
    }
  }, [shareTeamResStatus])

  const next = () => {
    if (animating) return
    const nextIndex = teamView === allTeams.length - 1 ? 0 : teamView + 1
    // setTeamView(nextIndex)
    // setCurrentIndex(nextIndex)
    // changeTeam(nextIndex)
    const userTeamId = allTeams[nextIndex]?._id
    props.history.replace({
      pathname: `/my-teams-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${userTeamId}`,
      search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined
        })}`
    })
  }

  const previous = () => {
    if (animating) return
    const nextIndex = teamView === 0 ? allTeams.length - 1 : teamView - 1
    // setTeamView(nextIndex)
    // setCurrentIndex(nextIndex)
    // changeTeam(nextIndex)
    const userTeamId = allTeams[nextIndex]?._id
    props.history.replace({
      pathname: `/my-teams-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${userTeamId}`,
      search: `?${qs.stringify({
          activeTab: obj?.activeTab || undefined,
          homePage: obj?.homePage ? 'yes' : undefined
        })}`
    })
  }

  useEffect(() => {
    if (allTeams && teamPlayerList?.length !== 0 && playerRoles) {
      let TotalCredit = 0
      let TotalScorePoints = 0
      if (allTeams && allTeams.length !== 0 && teamPlayerList) {
        const playerRole = playerRoles?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
        const players = Object.assign({}, ...playerRole?.map(key => ({ [key]: [] })))
        const teamList = Object.assign({}, ...teamPlayerList?.map(key => ({ [key?.oTeam?.sShortName]: [] })))
        allTeams?.length > 0 && allTeams[teamView]?.aPlayers?.length > 0 && allTeams[teamView]?.aPlayers?.map((Player) => {
          let PlayerDetails
          if (shareTeam && Object.keys(shareTeam).length > 0 && obj.shareTeam === 'yes') {
            PlayerDetails = teamPlayerList && teamPlayerList.length > 0 && teamPlayerList.find(player => player._id === (typeof Player === 'object' ? Player.iMatchPlayerId : Player))
          } else if (dreamTeam && Object.keys(dreamTeam).length > 0 && props.location.pathname.includes('/dream-team-preview/')) {
            PlayerDetails = teamPlayerList && teamPlayerList.length > 0 && teamPlayerList.find(player => player._id === (typeof Player === 'object' ? Player.iMatchPlayerId : Player))
          } else {
            PlayerDetails = teamPlayerList?.length > 0 && teamPlayerList.find(player => player._id === (typeof Player === 'object' ? Player.iMatchPlayerId : Player))
          }
          Object.entries(players).map(([key, value]) => {
            return (key === PlayerDetails?.eRole) && players[PlayerDetails?.eRole].push(PlayerDetails)
          })
          Object.entries(teamList).map(([key, value]) => {
            return (key === PlayerDetails?.oTeam?.sShortName) && teamList[PlayerDetails?.oTeam?.sShortName].push(PlayerDetails)
          })
          TotalCredit = TotalCredit + PlayerDetails?.nFantasyCredit
          setTotalCredit(TotalCredit)
          if (PlayerDetails && PlayerDetails.nScoredPoints) {
            if ((typeof Player === 'object' ? Player.iMatchPlayerId : Player) === allTeams[teamView].iCaptainId) {
              const newPoints = (Number(PlayerDetails.nScoredPoints) * 2)
              TotalScorePoints = TotalScorePoints + newPoints
            } else if ((typeof Player === 'object' ? Player.iMatchPlayerId : Player) === allTeams[teamView].iViceCaptainId) {
              const newPoints = (Number(PlayerDetails.nScoredPoints) * 1.5)
              TotalScorePoints = TotalScorePoints + newPoints
            } else { TotalScorePoints = TotalScorePoints + Number(PlayerDetails.nScoredPoints) }
          }
          setTotalScorePoints(TotalScorePoints)
          return players
        })
        const tempData = []
        Object.entries(players).map(([key, value]) => value && value.length > 0 && value.sort((a, b) => a.sName > b.sName ? 1 : -1).map(playerInfo => tempData.push(playerInfo)))
        setTeam(players)
        setTeamListCount(teamList)
      }
    }
    return () => {
      previousProps.allTeams = allTeams
    }
  }, [allTeams, userTeam, teamPlayerList, playerRoles, dreamTeam, shareTeam])

  // useEffect(() => {
  //   if (teamView >= 0) {
  //     let TotalCredits = 0
  //     let TotalScorePoints = 0

  //     if (allTeams && allTeams.length !== 0) {
  //       const playerRole = playerRoles?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
  //       const players = Object.assign(...playerRole?.map(key => ({ [key]: [] })))
  //       allTeams && allTeams.length !== 0 && allTeams.map((Player) => {
  //         const PlayerDetails = teamPlayerList && teamPlayerList.length !== 0 && teamPlayerList.find(player => player._id === Player)
  //         Object.entries(players).map(([key, value]) => {
  //           return key === PlayerDetails?.eRole && players[PlayerDetails?.eRole].push(PlayerDetails)
  //         })
  //         TotalCredits = TotalCredits + PlayerDetails?.nFantasyCredit
  //         setTotalCredit(TotalCredits)
  //         if (PlayerDetails && PlayerDetails.nScoredPoints) {
  //           if (Player === allTeams[teamView].iCaptainId) {
  //             const newPoints = (Number(PlayerDetails.nScoredPoints) * 2)
  //             TotalScorePoints = TotalScorePoints + newPoints
  //           } else if (Player === allTeams[teamView].iViceCaptainId) {
  //             const newPoints = (Number(PlayerDetails.nScoredPoints) * 1.5)
  //             TotalScorePoints = TotalScorePoints + newPoints
  //           } else { TotalScorePoints = TotalScorePoints + Number(PlayerDetails.nScoredPoints) }
  //         }
  //         setTotalScorePoints(TotalScorePoints)
  //         return TotalCredits
  //       })
  //       setTeam(players)
  //     }
  //   }
  // }, [teamView])

  function PlayerInfoFun (playerId) {
    setPlayerId(playerId)
    // if (location?.state?.teamCreationPage) {
    //   props.history.push({
    //     pathname: `/create-team/view-player-league-info/${match.params.sportsType}/${match.params.matchID}/${playerId}`,
    //     search: `?${qs.stringify({
    //       activeTab: obj?.activeTab || undefined,
    //       homePage: obj?.homePage ? 'yes' : undefined
    //     })}`,
    //     state: { teamCreationPage: true }
    //   })
    // } else if (matchDetails && matchDetails.eStatus === 'U') {
    //   props.history.push({
    //     pathname: `/view-player-leagueInfo/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${playerId}`,
    //     search: `?${qs.stringify({
    //       activeTab: obj?.activeTab || undefined,
    //       homePage: obj?.homePage ? 'yes' : undefined,
    //       playerLeagueInfo: 'y'
    //     })}`
    //   })
    // } else {
    //   props.history.push({
    //     pathname: `/view-player-Info/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}/${playerId}`,
    //     search: `?${qs.stringify({
    //       activeTab: obj?.activeTab || undefined,
    //       homePage: obj?.homePage ? 'yes' : undefined,
    //       userLeague: match?.params?.userLeagueID || undefined
    //     })}`
    //   })
    // }
    if (matchType === 'upcoming') {
      setPlayerLeagueInfo(true)
    } else {
      setPlayerInfo(true)
    }
  }

  useEffect(() => {
    if (matchDetails) {
      // matchDetails.oHomeTeam && matchDetails.oHomeTeam.sName && setTeamName(matchDetails.oHomeTeam.sName)
      matchDetails.eStatus === 'U' && setMatchType('upcoming')
    }
  }, [matchDetails])

  const slides = allTeams && allTeams.length !== 0 && allTeams?.map((item) => {
    return (
        <CarouselItem
          className="custom-tag"
          tag="div"
          key={item.id}
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
        >
          <div className="ground w-100">
            <Fragment>
                    {Object.entries(team).map(([key, value]) => {
                      return (
                        <div className="ground-c w-100" key={value._id || value.iMatchPlayerId}>
                          {value.length !== 0 &&
                          <h3>
                            {allSportsRoles(key)}
                            {/* {key === 'BATS' ? (<FormattedMessage id="Team_batsman" />) : key === 'WK' ? (<FormattedMessage id="Team_wk" />) : key === 'BWL' ? (<FormattedMessage id="Team_bowler" />) : key === 'ALLR' ? (<FormattedMessage id="Team_allRounder" />) : key} */}
                          </h3>
                          }
                          <div className={`player-list d-flex align-items-center justify-content-center w-100 ${value.length > 4 ? 'three-column' : ''}`}>
                            {
                              value && value.length !== 0 && value.map((playerInfo) => {
                                const playerName = playerInfo?.sName.split(' ')
                                const name = playerName.length === 1 ? playerName : `${playerInfo?.sName[0]} ${playerName[playerName.length - 1]}`
                                return (
                                  <div key={playerInfo._id || playerInfo.iMatchPlayerId} onClick={() => { PlayerInfoFun(playerInfo._id || playerInfo?.iMatchPlayerId) }} className="pbg">
                                    <div className="pg-img">
                                      <img src={playerInfo?.sImage && url ? `${url}${playerInfo?.sImage}` : PlayerImage} key={`inside${playerInfo?.eRole}`} alt="" />
                                      {/* <img src={playerInfo?.sImage && url ? `${url}${playerInfo?.sImage}` : match && match.params && match.params.sportsType === 'cricket' ? cricketStaticImage(playerInfo && playerInfo?.eRole) : match && match.params && match.params.sportsType === 'football' ? footballStaticImage(playerInfo && playerInfo?.eRole) : PlayerImage} key={`inside${playerInfo?.eRole}`} alt="" /> */}
                                      { !playerInfo?.bShow && matchDetails?.bLineupsOut && matchType === 'upcoming' &&
                                        <span className="unannounced-icon mr-1">U</span>
                                      }
                                      {
                                        (playerInfo?._id === (userTeam?.iCaptainId || allTeams[teamView]?.iCaptainId))
                                          ? <span className="tag"><img src={CaptainIcon} alt="CaptainIcon" /></span>
                                          : (playerInfo?._id === (userTeam?.iViceCaptainId || allTeams[teamView]?.iViceCaptainId))
                                              ? <span className="tag"><img src={ViceCaptainIcon} alt="ViceCaptainIcon" /></span>
                                              : ''
                                      }
                                    </div>
                                    {
                                      !teamPreview
                                        ? (
                                          <div className="team-pts-cr">{matchType === 'upcoming'
                                            ? playerInfo && playerInfo.nFantasyCredit > 0
                                              ? playerInfo.nFantasyCredit + ' Cr '
                                              : (<FormattedMessage id="Team_0CR" />)
                                            : (playerInfo?.iMatchPlayerId === allTeams[teamView]?.iCaptainId) || (playerInfo?._id === allTeams[teamView]?.iCaptainId)
                                                ? (Number(playerInfo.nScoredPoints) * 2) + ' Pts '
                                                : (playerInfo?.iMatchPlayerId === allTeams[teamView]?.iViceCaptainId) || (playerInfo?._id === allTeams[teamView]?.iViceCaptainId)
                                                    ? (Number(playerInfo.nScoredPoints) * 1.5) + ' Pts '
                                                    : parseFloat(Number((playerInfo.nScoredPoints)).toFixed(2)) + ' Pts '
                                              }
                                          </div>
                                          )
                                          // (
                                          // <span>{matchType === 'upcoming' ? playerInfo && playerInfo.nFantasyCredit ? playerInfo.nFantasyCredit + ' Cr ' : (<FormattedMessage id="Team_0CR" />) : playerInfo && playerInfo.nScoredPoints ? parseFloat(Number((playerInfo.nScoredPoints)).toFixed(2)) + ' Pts ' : (<FormattedMessage id="Team_0Pts" />)}</span>
                                          // )
                                        : (
                                          <div className="team-pts-cr">
                                            {
                                            playerInfo && playerInfo.nFantasyCredit > 0
                                              ? playerInfo.nFantasyCredit + ' Cr '
                                              : (<FormattedMessage id="Team_0CR" />)
                                            }
                                          </div>
                                          )
                                    }
                                    <p className="">
                                      {name}
                                    </p>
                                    {/* <p className={classNames({ backDarkBlue: TeamName === playerInfo.sTeamName, backWhitePlayer: TeamName !== playerInfo.sTeamName })}>
                                      {playerInfo?.sName && `${playerName && playerName.length >= 2 ? playerInfo?.sName[0] : playerName[0]} ${playerName && playerName.length === 2 ? playerName[1] : playerName && playerName.length === 3 ? `${playerName[2]}` : ''}`}
                                    </p> */}
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }
              </Fragment>
          </div>
        </CarouselItem>
    )
  })

  function shareTeamClick () {
    shareTeamFun(userTeam?._id)
  }

  // function changeTeam (index) {
  //   const playerRoles = playerRoles?.aPlayerRole?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
  //   const players = Object.assign(...playerRoles?.map(key => ({ [key]: [] })))
  //   allTeams[index].aPlayers && allTeams[index].aPlayers.length !== 0 && allTeams[index].aPlayers.map((Player) => {
  //     const PlayerDetails = teamPlayerList && teamPlayerList.length !== 0 && teamPlayerList.find(player => player._id === Player)
  //     Object.entries(players).map(([key, value]) => {
  //       return key === PlayerDetails?.eRole && players[PlayerDetails?.eRole].push(PlayerDetails)
  //     })
  //     return players
  //   })
  //   setTeam(players)
  // }
  return (
    <>
    {/* <div className="p-logo">
      <img src={TransparentLogo} alt={<FormattedMessage id='Transparent_Logo' />} />
    </div> */}
      {loading
        ? <Loading />
        : <Fragment>
            <div className={classNames('preview d-flex align-items-center justify-content-center', { 'p-football': sportsType === 'football', 'p-basketball': sportsType === 'basketball', 'p-baseball': sportsType === 'baseball', 'p-kabaddi': sportsType === 'kabaddi' })}>
              <div className="p-header d-flex align-items-center justify-content-between zIndex">
                <div className="match-detail">
                  <span className="match-icon">
                    <img src={matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sImage ? `${url}${matchDetails.oHomeTeam.sImage}` : HomeTeam} onError={(e) => handleImgError(e, HomeTeam)} alt="" />
                  </span>
                  <span>{teamListCount[matchDetails?.oHomeTeam?.sShortName]?.length}</span>
                  <span>:</span>
                  <span>{teamListCount[matchDetails?.oAwayTeam?.sShortName]?.length}</span>
                  <span className="match-icon">
                    <img src={matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sImage ? `${url}${matchDetails.oAwayTeam.sImage}` : AwayTeam} onError={(e) => handleImgError(e, AwayTeam)} alt="" />
                  </span>
                </div>
                <div className="d-flex align-items-center header-i">
                  <button onClick={() => {
                    if (obj?.toHome) {
                      props.history.push('/home/cricket')
                    } else if (location?.state?.joinPrivateContest) {
                      props.history.push({
                        pathname: `/create-team/${match.params.sportsType}/${match.params.matchID}/join/${props?.location?.state?.leagueId}/private/${props?.location?.state?.shareCode}`,
                        search: `?${qs.stringify({
                          activeTab: obj?.activeTab || undefined,
                          homePage: obj?.homePage ? 'yes' : undefined,
                          firstTime: true
                        })}`,
                        state: { ...location.state, allTeams, nextStep: props.location.state.nextStep }
                      })
                    } else if (location?.state?.joinLink) {
                      props.history.push({
                        pathname: `/create-team/${match.params.sportsType}/${match.params.matchID}/join/${location?.state?.leagueId}`,
                        search: `?${qs.stringify({
                          activeTab: obj?.activeTab || undefined,
                          homePage: obj?.homePage ? 'yes' : undefined,
                          firstTime: true
                        })}`,
                        state: { ...location.state, allTeams, nextStep: props.location.state.nextStep }
                      })
                    } else if (location?.state?.teamCreationPage) {
                      if (location?.state?.editTeam) {
                        props.history.push({
                          pathname: `/create-team/${match.params.sportsType}/${match.params.matchID}/${location?.state?.teamId}`,
                          search: `?${qs.stringify({
                          activeTab: obj?.activeTab || undefined,
                          homePage: obj?.homePage ? 'yes' : undefined,
                          firstTime: true
                        })}`,
                          state: { ...location.state, allTeams, nextStep: props.location.state.nextStep }
                        })
                      } else {
                        props.history.push({
                          pathname: `/create-team/${match.params.sportsType}/${match.params.matchID}`,
                          search: `?${qs.stringify({
                            activeTab: obj?.activeTab || undefined,
                            homePage: obj?.homePage ? 'yes' : undefined,
                            firstTime: true
                          })}`,
                          state: { ...location.state, allTeams, nextStep: props.location.state.nextStep }
                        })
                      }
                    } else if (matchDetails) {
                      if (matchDetails.eStatus === 'U') {
                        props.history.push({
                          pathname: `/leagues/${match.params.sportsType}/${match.params.matchID}`,
                          search: `?${qs.stringify({
                            activeTab: obj?.activeTab || undefined,
                            homePage: obj?.homePage ? 'yes' : undefined
                          })}`,
                          state: { ...location.state }
                        })
                      } else if (matchDetails.eStatus === 'L') {
                        props.history.push(`/liveleague/${match.params.matchID}/${match.params.sportsType}`)
                      } else if (matchDetails.eStatus === 'I' || matchDetails.eStatus === 'CMP') {
                        props.history.push({ pathname: `/completedleagues/participated/${match.params.matchID}/${match.params.sportsType}`, search: props.location.search })
                      }
                    }
                  }} className="bg-transparent">
                    <img src={closeIcon} alt="close-icon" className='img-fluid' />
                  </button>
                </div>
                {/* <div>
                  {
                    !(matchType === 'upcoming') && locationMatch.path !== '/completedleagues/participated/:id/:sportsType'
                      ? <button className="bg-transparent mr-3 icon-refresh"
                        onClick={(e) => refreshContent(e)}
                        ></button>
                      : ''
                  }
                </div> */}
              </div>
              <Fragment>
                <Carousel
                  className="w-100"
                  activeIndex={teamView}
                  slide={false}
                >
                  {slides}
                  {
                    allTeams && allTeams.length > 1 && (
                      <Fragment>
                        <div className="slider-control">
                          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </div>
                      </Fragment>
                    )
                  }
                </Carousel>
              </Fragment>
              {!location?.state?.teamCreationPage &&
              <div className="team-preview-footer">
                  <div className="team-preview-info">
                  {allTeams.length >= 1 &&
                    <p className="txt mb-0">{allTeams && allTeams.length > 0 && allTeams[teamView]?.sName}</p>
                  }
                  </div>
                {allTeams.length >= 1 &&
                  <div className="np-bottom">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                      {matchType !== 'upcoming' &&
                        <div className="preview-pts">
                          <FormattedMessage id="League_Points" />
                          <h3>{matchType !== 'upcoming' && totalScorePoints ? totalScorePoints : 0}</h3>
                        </div>
                      }
                        {matchType === 'upcoming' &&
                        <div className="preview-creditLeft">
                          <FormattedMessage id="Team_creditLeft" />
                          <h3>{!Number.isInteger(totalCredit) ? ((100 - totalCredit).toFixed(2)) : (100 - totalCredit)}</h3>
                        </div>
                        }
                      </div>
                      <div className="team-preview-action">
                        {allTeams[teamView]?.iUserId === userData?._id &&
                          <>
                            {matchType === 'upcoming' &&
                              <Link to={{
                                pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}`,
                                search: `?${qs.stringify({
                                      homePage: obj?.homePage ? 'yes' : undefined,
                                      firstTime: true
                                    })}`,
                                state: {
                                  backToTeamPreview: true,
                                  userTeamID: match?.params?.userTeamID,
                                  editTeam: true
                                }
                              }}>
                                <img src={Edit} alt="" width="24px" />
                              </Link>
                            }
                            <span role='button' className='mr-2' onClick={() => shareTeamClick()}>
                              <img src={ShareTeam} alt="" width="24px" />
                            </span>
                          </>
                        }
                        <Link to={{ pathname: '/point-system', search: `?activeSport=${match.params.sportsType}`, state: { goBack: true } }}>
                          <img className="pts-icon" src={PointsIcon} alt="" width="24px" />
                        </Link>
                      </div>
                      {/* {allTeams && allTeams.length > 1 && <p className="txt">{(teamView + 1) + '/' + allTeams?.length}</p>} */}
                    </div>
                  </div>
                }
              </div>
              }
            </div>
            {playerLeagueInfo
              ? <PlayerLeagueInfo {...props} onBackClick={() => setPlayerLeagueInfo(false)} pId={playerId} onPlayerInfoClick={() => setPlayerInfo(true)} isSeasonPoint={true} />
              : ''
            }
            {playerInfo
              ? <PlayerInfo {...props} pID={playerId} onBackClick={() => setPlayerInfo(false)} isSeasonPoint={true}></PlayerInfo>
              : ''
            }
          </Fragment>
      }
    </>
  )
}

MyTeamsPreview.propTypes = {
  data: PropTypes.shape({
    aPlayers: PropTypes.func,
    sName: PropTypes.string
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object,
    teamCreationPage: PropTypes.bool,
    allTeams: PropTypes.object,
    data: PropTypes.object,
    pathname: PropTypes.string
  }),
  token: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      matchID: PropTypes.string,
      sportsType: PropTypes.string,
      userTeamID: PropTypes.string,
      userLeagueID: PropTypes.string
    }),
    path: PropTypes.string
  }),
  url: PropTypes.string,
  onBackClick: PropTypes.func,
  userTeam: PropTypes.object,
  matchDetails: PropTypes.shape({
    _id: PropTypes.string,
    dStartDate: PropTypes.string,
    eStatus: PropTypes.string,
    bLineupsOut: PropTypes.bool,
    oHomeTeam: PropTypes.shape({
      sName: PropTypes.string,
      sImage: PropTypes.string,
      sShortName: PropTypes.string
    }),
    oAwayTeam: PropTypes.shape({
      sImage: PropTypes.string,
      sShortName: PropTypes.string
    })
  }),
  getMatchPlayerList: PropTypes.func,
  getUserTeam: PropTypes.func,
  teamPreview: PropTypes.bool,
  setLoading: PropTypes.bool,
  loading: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
    replace: PropTypes.func
  }),
  teamList: PropTypes.array,
  teamPlayerList: PropTypes.array,
  playerRoles: PropTypes.object,
  getDreamTeamFunc: PropTypes.func,
  dreamTeam: PropTypes.object,
  shareTeamFun: PropTypes.func,
  getShareTeamFun: PropTypes.func,
  shareTeamResStatus: PropTypes.bool,
  shareTeamRefferalCode: PropTypes.string,
  shareTeam: PropTypes.obj,
  userData: PropTypes.obj
}

export default TeamList(MyTeamsPreview)
