import React, { useState, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import LeagueHeader from '../components/LeagueHeader'
import Loading from '../../../component/Loading'
import qs from 'query-string'
const CreateTeamPage = lazy(() => import('./CreateTeam'))

function CreateTeam (props) {
  const { match, location } = props
  const [nextStep, setNextStep] = useState(true)
  const [VideoStream, setVideoStream] = useState(false)
  const obj = qs.parse(location.search)
  const [Name, setName] = useState('')
  const [backModal, setBackModal] = useState(false)
  const [isSelectedPlayer, setIsSelectedPlayer] = useState([])
  const [allTeams, setallTeams] = useState([])

  // Back button check
  const [editBackCheck, setEditBackCheck] = useState([])

  return (
    <>
      <LeagueHeader
        {...props}
        goToBack={
          props?.location?.state?.backToJoinContest
            ? `/join-contest/${match?.params?.sportsType}/${match?.params?.id}`
            : props?.location?.state?.backToCreateContest
              ? `/create-contest/${match?.params?.sportsType}/${match?.params?.id}/prize-breakups`
              : props?.location?.state?.backToLeagueSelectTeam
                ? `/leagues/${match?.params?.sportsType}/${match?.params?.id}`
                : props?.location?.state?.backToLeagueDetailsSelectTeam
                  ? `/leagues-detail/${match?.params?.sportsType}/${match?.params?.id}/${props?.location?.state?.leagueId}`
                  : props?.location?.state?.backToViewCricket
                    ? `/team-preview/${match?.params?.sportsType}/${match?.params?.id}/${props?.location?.state?.leagueId}/${props?.location?.state?.userTeamID}/${props?.location?.state?.teamView}`
                    : props?.location?.state?.backToTeamPreview
                      ? `/my-teams-preview/${match?.params?.sportsType}/${match?.params?.id}/${props?.location?.state?.userTeamID}`
                      : props?.location?.state?.backToSwitchTeam
                        ? `/leagues-detail/${match?.params?.sportsType}/${match?.params?.id}/${props?.location?.state?.leagueId}`
                        : match.path !== '/create-team/:sportsType/:id/join/:joinLeague/private/:sShareCode'
                          ? `/leagues/${match && match.params && match.params.sportsType}/${match && match.params && match.params.id}`
                          : ''
        }
        search={obj?.homePage ? 'homePage=yes' : ''}
        backToLeagueSelectTeam={props?.location?.state?.backToLeagueSelectTeam}
        backToLeagueDetailsSelectTeam={props?.location?.state?.backToLeagueDetailsSelectTeam}
        backToSwitchTeam={props?.location?.state?.backToSwitchTeam}
        currentSwitchTeam={props?.location?.state?.currSwitchTeam}
        backToTeamPreview={props?.location?.state?.backToTeamPreview}
        backToViewCricket={props?.location?.state?.backToViewCricket}
        backToCreateContest={props?.location?.state?.backToCreateContest}
        backToJoinContest={props?.location?.state?.backToJoinContest}
        backTab={props?.location?.state?.activeTab}
        editTeamTitle={props?.location?.state?.editTeam}
        nextStep={nextStep}
        setVideoStream={setVideoStream}
        VideoStream={VideoStream}
        setNextStep={setNextStep}
        notShowing
        Name={Name}
        backModal={backModal}
        setBackModal={setBackModal}
        isSelectedPlayer={isSelectedPlayer}
        editBackCheck={editBackCheck}
        allTeams={allTeams}
        />
      <Suspense fallback={<Loading />}>
        <CreateTeamPage
          {...props}
          nextStep={nextStep}
          goToBack={
            props?.location?.state?.backToJoinContest
              ? `/join-contest/${match?.params?.sportsType}/${match?.params?.id}`
              : props?.location?.state?.backToCreateContest
                ? `/create-contest/${match?.params?.sportsType}/${match?.params?.id}/prize-breakups`
                : props?.location?.state?.backToLeagueSelectTeam
                  ? `/leagues/${match?.params?.sportsType}/${match?.params?.id}`
                  : props?.location?.state?.backToLeagueDetailsSelectTeam
                    ? `/leagues-detail/${match?.params?.sportsType}/${match?.params?.id}/${props?.location?.state?.leagueId}`
                    : props?.location?.state?.backToViewCricket
                      ? `/team-preview/${match?.params?.sportsType}/${match?.params?.id}/${props?.location?.state?.leagueId}/${props?.location?.state?.userTeamID}/${props?.location?.state?.teamView}`
                      : props?.location?.state?.backToTeamPreview
                        ? `/my-teams-preview/${match?.params?.sportsType}/${match?.params?.id}/${props?.location?.state?.userTeamID}`
                        : props?.location?.state?.backToSwitchTeam
                          ? `/leagues-detail/${match?.params?.sportsType}/${match?.params?.id}/${props?.location?.state?.leagueId}`
                          : match.path !== '/create-team/:sportsType/:id/join/:joinLeague/private/:sShareCode'
                            ? `/leagues/${match && match.params && match.params.sportsType}/${match && match.params && match.params.id}`
                            : ''
          }
          backToLeagueSelectTeam={props?.location?.state?.backToLeagueSelectTeam}
          backToLeagueDetailsSelectTeam={props?.location?.state?.backToLeagueDetailsSelectTeam}
          backToSwitchTeam={props?.location?.state?.backToSwitchTeam}
          currentSwitchTeam={props?.location?.state?.currSwitchTeam}
          backToTeamPreview={props?.location?.state?.backToTeamPreview}
          backToViewCricket={props?.location?.state?.backToViewCricket}
          backToCreateContest={props?.location?.state?.backToCreateContest}
          backToJoinContest={props?.location?.state?.backToJoinContest}
          search={obj?.homePage ? 'homePage=yes' : ''}
          backTab={props?.location?.state?.activeTab}
          setNextStep={setNextStep}
          Name={Name}
          setName={setName}
          backModal={backModal}
          setBackModal={setBackModal}
          setIsSelectedPlayer={setIsSelectedPlayer}
          setEditBackCheck={setEditBackCheck}
          setallTeams={setallTeams}
        />
      </Suspense>
    </>
  )
}
CreateTeam.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sportsType: PropTypes.string
    }),
    path: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  })
}
export default CreateTeam
