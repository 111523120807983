import React, { Fragment, useEffect, useRef } from 'react'
import { NavLink } from 'react-router-dom'
// import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
// import Logo from '../../../assests/images/fansportiz_header.svg'
// import ProfileIcon from '../../../assests/images/profile-icon.png'
import PowerplayIcon from '../../../assests/images/Powerplay 11.svg'
import Cricket from '../../../assests/images/cricket.svg'
import Kabaddi from '../../../assests/images/kabaddi.svg'
import BasketBall from '../../../assests/images/Basketball.svg'
import BaseBall from '../../../assests/images/baseball.svg'
import Football from '../../../assests/images/football.svg'
import walletIcon from '../../../assests/images/ic_wallet.svg'
import howToPlayIcon from '../../../assests/images/HowToPlayButton.svg'
import pointsIcon from '../../../assests/images/points-icon.svg'
// import notificationIcon from '../../../assests/images/notificationIcon.svg'
import UserNotification from '../../../HOC/User/UserNotification'
import { Button } from 'reactstrap'
import { closeWebApp, openWalletPost } from '../../../helper'

function HomeHeader (props) {
  const { sportsRequired, token, activeSports, onGetActiveSports, setMainIndex, match, active, isPublic } = props

  const previousProps = useRef({
    match
  }).current

  const isHome = props?.location?.pathname?.includes('/home/')

  useEffect(() => {
    (!activeSports || activeSports.length === 0) && token && sportsRequired && onGetActiveSports()
  }, [token])

  useEffect(() => {
    activeSports && activeSports.length !== 0 && activeSports.map((data, index) => {
      const name = data?.sKey.toLowerCase()
      match && match.params && match.params.sportsType === name && setMainIndex && setMainIndex(index + 1)
      return null
    })
  }, [activeSports])

  useEffect(() => {
    if (previousProps.match !== match) {
      if (match && match.params) {
        activeSports && activeSports.length !== 0 && activeSports.map((data, index) => {
          const name = data?.sKey.toLowerCase()
          match?.params?.sportsType === name && setMainIndex && setMainIndex(index + 1)
          return null
        })
      }
    }
    return () => {
      previousProps.match = match
    }
  }, [match && match.params && match.params.sportsType])

  function pathFunc (data) {
    if (isPublic) {
      return props?.home ? `/home/${data?.sKey.toLowerCase()}/v1` : `/matches/${data?.sKey.toLowerCase()}/v1`
    } else {
      return props?.home ? `/home/${data?.sKey.toLowerCase()}` : `/matches/${data?.sKey.toLowerCase()}`
    }
  }

  return (
    <div className="home-header">
      {isHome &&
      <div className="header-i d-flex align-items-center justify-content-between">
        {/* <span style={{ minWidth: '68px' }}></span> */}
        {/* <img src={ProfileIcon} alt={<FormattedMessage id='Fansportiz' />} width='32'/> */}
        <div className="d-flex align-items-center">
          <Fragment>
            <Button
              // tag={Link}
              // to={{ pathname: goToBack, search: search || '', state: { tab: backTab || '1' } }}
              onClick={() => {
                closeWebApp()
              }
              }
              className="btn-link icon-left-arrow">
            </Button>
          </Fragment>
           <img src={PowerplayIcon} width='30' style={{ marginRight: '5px' }} ></img>
           <h3>PowerPlay11 </h3>
        </div>
        {/* {token && */}
        {!isPublic
          ? <ul className="d-flex m-0 ht-link align-items-center">
          {/* <li>
            <Link to='/notifications'>
              <img src={notificationIcon} alt={<FormattedMessage id='Notifications' />} width={20}></img>
              {nCount && nCount.nUnreadCount > 0 ? <span className="count">{nCount && nCount.nUnreadCount}</span> : '' }
            </Link>
          </li> */}
          <>
            <li className='mr-3 mt-1 mb-1 ml-0' role='button' onClick={() => props.history.push({ pathname: `/how-to-play/${match.params.sportsType}`, search: '', state: { goBack: true } })}> <img src={howToPlayIcon} width='28'></img> </li>
            <li className='mr-3' role='button'> <img src={pointsIcon} onClick={() => props.history.push({ pathname: '/point-system', search: `?activeSport=${match.params.sportsType}`, state: { goBack: true } })}></img> </li>
            <li className='mr-0 mt-1 mb-1 ml-0' role='button' onClick={() => openWalletPost()}> <img src={walletIcon} width='28'></img> </li>
          </>
          {/* {
            props.showBalance && (
              <li className='mr-2 mt-1 mb-1 ml-2' > <img src={walletIcon} width='28' onClick={() => setPaymentSlide(false)}></img> </li>
            )
          } */}
        </ul>
          : <span style={{ minWidth: '68px', minHeight: '36px' }}></span>
        }
        {/* } */}
      </div>
      }
      {!isHome &&
      <div className="d-flex align-items-center header-i justify-content-between">
        <div className="d-flex align-items-center">
        <Fragment>
          <Button
            // tag={Link}
            // to={{ pathname: goToBack, search: search || '', state: { tab: backTab || '1' } }}
            onClick={() => {
              props.history.push(`/home/${match?.params?.sportsType}`)
            }
            }
            className="btn-link icon-left-arrow">
          </Button>
        </Fragment>
          {/* <Button className='button-link bg-transparent py-2' tag={Link} to={match.path === '/leagues/:sportsType/:id/v1' ? `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}/v1` : `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`}><img src={homeIcon}></img></Button> */}
          <div>
            <h1 className="mb-0">My Matches</h1>
          </div>
        </div>
        <ul className="d-flex m-0 ht-link">
          {/* {props.showLinks
            ? <Fragment>
              <li><Link className="icon-message" to="/chats" ></Link></li>
              <li>
                <Link to='/notifications'>
                  <img src={notificationIcon} alt={<FormattedMessage id='Notification' />} width={20}></img>
                </Link>
              </li>
            </Fragment>
            : ''
          } */}
          {
            props.showBalance && token && (
              <>
                {/* <li className='mr-2 mt-1' role='button'> <img src={pointsIcon} onClick={() => history.push({ pathname: '/point-system', search: '', state: { goBack: true } })}></img> </li> */}
                <li className='mr-2 mt-1' role='button'> <img src={walletIcon} onClick={() => openWalletPost()}></img> </li>
              </>
            )
          }
          {/* {data && data.sStreamUrl && !notShowing
            ? <Fragment>
              <li><img src={yotubeIcon} onClick={() => {
                if (data.sStreamUrl.includes('https://www.youtube.com/')) {
                  setVideoStream(true)
                } else {
                  setVideoStream(true)
                  setAlert(true)
                  setTimeout(() => {
                    setVideoStream(false)
                    setAlert(false)
                  }, 1000)
                  setAlertMessage('something went wrong.')
                }
              }}></img></li>
            </Fragment>
            : ''
          } */}
        </ul>
      </div>
      }
      <div className='bg-white'>
        {!active && activeSports && activeSports.length >= 2
          ? <ul className={`d-flex match-links sports m-0 fixHeight ${activeSports && activeSports.length <= 2 ? 'centeritem' : ''}`}>
            {
              activeSports && activeSports.length > 0 &&
                activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => {
                  return (
                  <li key={data.sKey}>
                    <NavLink activeClassName='active' to={pathFunc(data)} >
                      <img
                        src={ data.sKey === 'CRICKET' ? Cricket : data.sKey === 'FOOTBALL' ? Football : data.sKey === 'KABADDI' ? Kabaddi : data.sKey === 'BASKETBALL' ? BasketBall : data.sKey === 'BASEBALL' ? BaseBall : Cricket}
                        alt={data.sName} />
                      <div
                        className='sportsText'>
                        {data.sName.charAt(0).toUpperCase()}{data.sName.slice(1).toLowerCase()}
                      </div>
                    </NavLink>
                  </li>
                  )
                })
            }
          </ul>
          : ''
        }
      </div>
    </div>
  )
}

HomeHeader.propTypes = {
  active: PropTypes.bool,
  sportsRequired: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sportsType: PropTypes.string
    }),
    path: PropTypes.string,
    url: PropTypes.string
  }),
  home: PropTypes.bool,
  GetCount: PropTypes.func,
  setMainIndex: PropTypes.func,
  setPaymentSlide: PropTypes.func,
  showBalance: PropTypes.bool,
  onGetActiveSports: PropTypes.func,
  nCount: PropTypes.shape({
    nUnreadCount: PropTypes.number
  }),
  activeSports: PropTypes.array,
  token: PropTypes.string,
  isPublic: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object
}

export default UserNotification(HomeHeader)
