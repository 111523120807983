/* eslint-disable no-unneeded-ternary */
import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
// import { useSelector } from 'react-redux'
// import homeIcon from '../../../assests/images/homeIconWhite.svg'
import howToPlayIcon from '../../../assests/images/HowToPlay-Icon.svg'
import editIcon from '../../../assests/images/edit.svg'
// import walletIcon from '../../../assests/images/ic_wallet.svg'
import CloseIcon from '../../../assests/images/close-icon.svg'
// import { openWalletPost } from '../../../helper'

function UserHeader (props) {
  const { location, backURL, setActiveTab, activeTab, history, createContest, editable, title, setEditable, shareContest, subTitle } = props
  // const activeSports = useSelector(state => state.activeSports.activeSports)
  // const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  function backClick () {
    if (activeTab === false) {
      setActiveTab(true)
    } else {
      if (backURL) {
        history.push(backURL,
          createContest ? { ContestName: props?.location?.state?.ContestName, ContestPrize: props?.location?.state?.ContestPrize, ContestSize: props?.location?.state?.ContestSize, multipleTeam: props?.location?.state?.multipleTeam, poolPrice: props?.location?.state?.poolPrice, entryFee: props?.location?.state?.entryFee, contestType: props?.location?.state?.contestType } : location?.state
        )
      } else if (location.state?.backToCreateTeam) {
        history.push({
          pathname: location.state.pathname,
          search: props.location.search,
          state: {
            ...props?.location?.state,
            allTeams: props?.location?.state?.allTeams
          }
        })
      } else {
        history.goBack({}, location.state)
      }
    }
  }
  return (
    <div className="league-header u-header">
      <div className="header-i d-flex align-items-center justify-content-between">
        <div className='d-flex align-items-center'>
          {shareContest
            ? <button onClick={backClick} className="close-btn"><img src={CloseIcon} /></button>
            : <button onClick={backClick} className="btn-link icon-left-arrow"></button>
          }
          {/* <Link className='btn-link bg-transparent' to={match.path.includes('/v1') ? `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}/v1` : `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`}><img src={homeIcon} /></Link> */}
          <div>
            <h1>{props.title}</h1>
            {shareContest && <p>{subTitle}</p>}
          </div>
        </div>
        {editable && title === 'Bank Details' && <ul className="d-flex m-0 ht-link">
          <li role="button" onClick={() => setEditable(false)}>
            {/* <button onClick={() => setEditable(false)}> */}
            <img src={editIcon}></img>
            {/* </button> */}
          </li>
        </ul>}
        <ul className="d-flex m-0 ht-link">
          {/* <li className='mr-0' onClick={() => openWalletPost()}> <img src={walletIcon} alt=""></img> </li> */}
          { props.match?.path.includes('/how-to-play') ? <li className='mr-0'> <img src={howToPlayIcon} width='28'></img> </li> : '' }
        </ul>
      </div>
    </div>
  )
}

UserHeader.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  backURL: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }),
  activeTab: PropTypes.bool,
  setActiveTab: PropTypes.func,
  location: PropTypes.object,
  createContest: PropTypes.bool,
  match: PropTypes.object,
  editable: PropTypes.bool,
  setEditable: PropTypes.func,
  shareContest: PropTypes.bool,
  subTitle: PropTypes.string
}

export default UserHeader
