/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Nav, NavItem, NavLink, TabContent, Table } from 'reactstrap'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useQueryState } from 'react-router-use-location-state'
import { FormattedMessage } from 'react-intl'
import qs from 'query-string'
import { clickEventPost, isUpperCase, pageViewPost } from '../../../helper'
import MyUpcomingMatch from '../components/MyUpcomingMatch'
import UserHome from '../../../HOC/User/UserHome'
import Slider from '../components/Slider'

function Matches (props) {
  const { location, mainIndex, match, history, paymentSlide, setPaymentSlide, currencyLogo, userInfo, onGetUserProfile } = props
  const [activeTab, setActiveTab] = useState('1')
  const [swipeDirection, setSwipeDirection] = useState(null)
  const [swipeStartX, setSwipeStartX] = useState(null)
  const [activeState, setActiveState] = useQueryState('matchType', 'UP')
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  const handleTouchStart = (event) => {
    setSwipeStartX(event.touches[0].clientX)
    console.log('setSwipeStartX(event.touches[0].clientX): ', setSwipeStartX(event.touches[0].clientX))
  }
  const handleTouchMove = (event) => {
    const currentX = event.touches[0].clientX
    const diffX = currentX - swipeStartX

    if (Math.abs(diffX) > 50) {
      setSwipeDirection(diffX > 0 ? 'right' : 'left')
    }
  }
  const handleTouchEnd = () => {
    if (swipeDirection === 'right') {
      const prevTab = parseInt(activeTab) - 1
      if (prevTab > 0) {
        toggle(prevTab.toString())
      }
    } else if (swipeDirection === 'left') {
      const nextTab = parseInt(activeTab) + 1
      if (nextTab <= 3) {
        toggle(nextTab.toString())
      }
    }

    setSwipeDirection(null)
  }
  useEffect(() => { // handle the response
    if (location && location.state && location.state.tab) {
      toggle(`${location.state.tab}`)
    }
    const obj = qs.parse(location.search)
    if (obj?.matchType) {
      const active = obj.matchType === 'UP' ? '1' : obj.matchType === 'L' ? '2' : obj.matchType === 'CMP' ? '3' : '1'
      setActiveState(obj.matchType)
      toggle(active)
    }
    pageViewPost('upg')
  }, [])
  useEffect(() => { // handle the response
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      isUpperCase(sport) && history.push(`/matches/${sport.toLowerCase()}`)
    }
  }, [match.params.sportsType])

  useEffect(() => {
    if (paymentSlide) {
      onGetUserProfile()
    }
  }, [paymentSlide])

  return (
    <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}> {/* was fragment before to implement the swipe effect changed it into div */}
      <div className='bg-white live-tabs-home-wrap'>
        <Nav className="live-tabs-home justify-content-around">
          <NavItem className="text-center">
            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => {
              toggle('1')
              setActiveState('UP')
              clickEventPost('mm_u')
              pageViewPost('upg')
            }}><FormattedMessage id="Matches_Upcoming" /></NavLink>
          </NavItem>
          <NavItem className="text-center">
            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => {
              toggle('2')
              setActiveState('L')
              clickEventPost('mm_l')
              pageViewPost('ong')
            }}><FormattedMessage id="Matches_Live" /></NavLink>
          </NavItem>
          <NavItem className="text-center">
            <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => {
              toggle('3')
              setActiveState('CMP')
              clickEventPost('mm_c')
              pageViewPost('pst')
            }}><FormattedMessage id="Matches_Completed" /></NavLink>
          </NavItem>
        </Nav>
      </div>
      <div className="home-container">
        <TabContent activeTab={activeTab}>
          <Fragment>
            <Slider />
            <MyUpcomingMatch {...props} mainIndex={mainIndex} subIndex={parseInt(activeTab)} />
          </Fragment>
        </TabContent>
      </div>

      {paymentSlide
        ? <>
            <div className="s-team-bg" onClick={() => setPaymentSlide(false)}></div>
            <Card className='filter-card'>
              <CardHeader className='d-flex align-items-center justify-content-between'>
                <button onClick={() => { setPaymentSlide(false) }}><FormattedMessage id='Wallet_Details' /></button>
                <button className='red-close-btn' onClick={() => setPaymentSlide(false)}><FormattedMessage id='League_Close' /></button>
              </CardHeader>
              <CardBody className='payment-box'>

              <Table className="m-0 bg-white payment">
                <thead>
                  <tr className='text-center'> <th colSpan='2'><FormattedMessage id="PROFILE_TOTAL_BALANCE" /></th> </tr>
                  <tr className='text-center'> <th colSpan='2'>{currencyLogo}{userInfo && userInfo.nCurrentTotalBalance ? userInfo && userInfo.nCurrentTotalBalance : 0} </th> </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><FormattedMessage id="PROFILE_DEP_BALANCE" /></td>
                    <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentDepositBalance ? userInfo && userInfo.nCurrentDepositBalance : 0}</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="PROFILE_WIN_BALANCE" /></td>
                    <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentWinningBalance ? userInfo && userInfo.nCurrentWinningBalance : 0}</td>
                  </tr>
                  <tr>
                    <td><FormattedMessage id="PROFILE_CASH_BONUS" /></td>
                    <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentBonus ? userInfo && userInfo.nCurrentBonus : 0}</td>
                  </tr>
                </tbody>
              </Table>
              </CardBody>
              <CardFooter className='border-0 bg-white p-0'>
                <Button color='primary-two' onClick={() => props.history.push('/deposit')} className='w-100'><FormattedMessage id="PROFILE_ADD_CASH" /></Button>
              </CardFooter>
            </Card>
          </>
        : ''
        }
    </div>
  )
}

Matches.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.object
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      activeTab: PropTypes.number,
      tab: PropTypes.string
    }),
    search: PropTypes.string
  }),
  pathName: PropTypes.string,
  history: PropTypes.object,
  mainIndex: PropTypes.number,
  paymentSlide: PropTypes.bool,
  setPaymentSlide: PropTypes.func,
  currencyLogo: PropTypes.string,
  userInfo: PropTypes.object,
  onGetUserProfile: PropTypes.func
}
export default UserHome(Matches)
