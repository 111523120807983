import ALLR from '../src/assests/images/ALLR.jpg'
import BATS from '../src/assests/images/BATS.jpg'
import BWL from '../src/assests/images/BWL.jpg'
import WK from '../src/assests/images/WK.jpg'
import DEF from '../src/assests/images/DEF.jpg'
import FWD from '../src/assests/images/FWD.jpg'
import GK from '../src/assests/images/GK.jpg'
import MID from '../src/assests/images/MID.jpg'
import PlayerImage from '../src/assests/images/PlayerImage.png'

const errMsg = 'Server is unavailable.'

export function verifyEmail (value) {
  const emailRex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (emailRex.test(value)) {
    return true
  }
  return false
}

export function verifyPassword (value) {
  const passwordRex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
  if (passwordRex.test(value)) {
    return true
  }
  return false
}

export function withoutSpace (value) {
  const SpacelessRex = /\s/g
  if (SpacelessRex.test(value)) {
    return true
  }
  return false
}

export function verifySpecialCharacter (value) {
  // const regex = /^[0-9a-zA-Z]+$/
  const regex = /^\d*[a-zA-Z][a-zA-Z0-9]*$/
  if (regex.test(value)) {
    return true
  }
  return false
}

export function verifySpecCharWithSpace (value) {
  const regex = /^[A-Za-z0-9 ]+$/
  if (regex.test(value)) {
    return true
  }
  return false
}

export function verifyOnlyAlphabets (value) {
  const regex = /^[A-Za-z ]+$/
  if (regex.test(value)) {
    return true
  }
  return false
}

export function verifyLength (value, length) {
  if (value.length >= length) {
    return true
  }
  return false
}

export function verifyMobileNumber (value) {
  const mobRex = /^[0-9]{10}$/
  if (mobRex.test(value)) {
    return true
  }
  return false
}

export function maxValue (value1, value2) {
  if (value1 > value2) {
    return value1
  } else {
    return value2
  }
}

export function isNumber (value) {
  const numRex = /^[0-9\b]+$/
  if (numRex.test(value)) {
    return true
  }
  return false
}

export function isPositive (value) {
  if (value > 0) {
    return true
  }
  return false
}

export function isUpperCase (value) {
  if (value === value.toUpperCase()) {
    return true
  }
  return false
}

export function panCardNumber (value) {
  const panRex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/
  if (!panRex.test(value)) {
    return true
  }
  return false
}

export function ifscCode (value) {
  const ifscRex = /^[A-Z]{4}0[A-Z0-9]{6}$/
  if (!ifscRex.test(value)) {
    return true
  }
  return false
}

export function validPinCode (value) {
  const ifscRex = /^[1-9]{1}[0-9]{5}$/
  if (!ifscRex.test(value)) {
    return true
  }
  return false
}

export function cricketStaticImage (Role) {
  if (Role === 'WK') {
    return WK
  } else if (Role === 'ALLR') {
    return ALLR
  } else if (Role === 'BATS') {
    return BATS
  } else if (Role === 'BWL') {
    return BWL
  } else {
    return PlayerImage
  }
}

export function footballStaticImage (Role) {
  if (Role === 'MID') {
    return MID
  } else if (Role === 'GK') {
    return GK
  } else if (Role === 'FWD') {
    return FWD
  } else if (Role === 'DEF') {
    return DEF
  } else {
    return PlayerImage
  }
}

export function allSportsRoles (Role) {
  // cricket sports
  if (Role === 'WK') {
    return 'Wicket-keepers'
  }
  if (Role === 'ALLR') {
    return 'All Rounders'
  }
  if (Role === 'BATS') {
    return 'Batters'
  }
  if (Role === 'BWL') {
    return 'Bowlers'
  }
  // Football sports
  if (Role === 'GK') {
    return 'Goal Keeper'
  }
  if (Role === 'DEF') {
    return 'Defenders'
  }
  if (Role === 'MID') {
    return 'Mid Fielders'
  }
  if (Role === 'FWD') {
    return 'Forwards'
  }
  // Basketball sports
  if (Role === 'C') {
    return 'Center'
  }
  if (Role === 'PF') {
    return 'Power Forward'
  }
  if (Role === 'PG') {
    return 'Point Guard'
  }
  if (Role === 'SF') {
    return 'Small Forward'
  }
  if (Role === 'SG') {
    return 'Shooting Guard'
  }
  // Baseball sports
  if (Role === 'P') {
    return 'Pitcher'
  }
  if (Role === 'IF') {
    return 'Infielders'
  }
  if (Role === 'CT') {
    return 'Catcher'
  }
  if (Role === 'OF') {
    return 'Outfielders'
  }
  // Kabaddi sports
  if (Role === 'RAID') {
    return 'Raiders'
  }
  // if (Role === 'DEF') {
  //   return 'Defenders'
  // }
  // if (Role === 'ALLR') {
  //   return 'All Rounders'
  // }
}

export function allSportsRolesSingular (Role) {
  // cricket sports
  if (Role === 'WK') {
    return 'Wicket-keeper'
  }
  if (Role === 'ALLR') {
    return 'All Rounder'
  }
  if (Role === 'BATS') {
    return 'Batter'
  }
  if (Role === 'BWL') {
    return 'Bowler'
  }
  // Football sports
  if (Role === 'GK') {
    return 'Goal Keeper'
  }
  if (Role === 'DEF') {
    return 'Defender'
  }
  if (Role === 'MID') {
    return 'Mid Fielder'
  }
  if (Role === 'FWD') {
    return 'Forward'
  }
  // Basketball sports
  if (Role === 'C') {
    return 'Center'
  }
  if (Role === 'PF') {
    return 'Power Forward'
  }
  if (Role === 'PG') {
    return 'Point Guard'
  }
  if (Role === 'SF') {
    return 'Small Forward'
  }
  if (Role === 'SG') {
    return 'Shooting Guard'
  }
  // Baseball sports
  if (Role === 'P') {
    return 'Pitcher'
  }
  if (Role === 'IF') {
    return 'Infielder'
  }
  if (Role === 'CT') {
    return 'Catcher'
  }
  if (Role === 'OF') {
    return 'Outfielder'
  }
  // Kabaddi sports
  if (Role === 'RAID') {
    return 'Raider'
  }
  // if (Role === 'DEF') {
  //   return 'Defenders'
  // }
  // if (Role === 'ALLR') {
  //   return 'All Rounders'
  // }
}

export function handleInputValue (value) {
  const phoneRegex = /^\(?([0-9]{5})\)?[-. ]?([0-9]{5})$/
  return (
    value.replace(phoneRegex, '$1 $2')
  )
}

export function isImageValid (url) {
  const request = new XMLHttpRequest()
  request.open('GET', url, true)
  request.send()
  request.onload = function () {
    if (request.status === 200) {
      return true
    } else {
      return false
    }
  }
}

export function catchError (type, error) {
  return {
    type,
    payload: {
      resStatus: false,
      resMessage: error && error.response ? error.response.data.message : errMsg
    }
  }
}

export function catchBlankData (type) {
  return {
    type,
    payload: {
      resStatus: false,
      data: []
    }
  }
}

export function catchBlankDataObj (type) {
  return {
    type,
    payload: {
      resStatus: false,
      data: {}
    }
  }
}

export function handleImgError (e, teamImg) {
  e.target.src = teamImg
}

export function currencyConvert (number) {
  const currency = new Intl.NumberFormat('en-IN', { currency: 'INR' }).format(number)
  return currency
}

// NOTE: Post Messages
export function shareTeamPost (matchId, teamId, sportType, time, homeTeam, awayTeam, rCode) {
  const obj = {
    key: 'fantasyShareTeam',
    value: {
      code: '5501',
      link: `code=5501&mId=${matchId}&tId=${teamId}&spType=${sportType}&rCode=${rCode}`,
      additionalData: {
        matchStartTime: time,
        team1Name: homeTeam,
        team2Name: awayTeam
      }
    }
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function shareContestPost (matchId, invitecode, sportType, contestName, entryFee, nMax, time, homeTeam, awayTeam) {
  const obj = {
    key: 'fantasyShareContest',
    value: {
      code: '5502',
      link: `code=5502&mId=${matchId}&ccode=${invitecode}&spType=${sportType}`,
      additionalData: {
        contestName: contestName,
        entryFee: entryFee,
        maximumParticipants: nMax,
        matchStartTime: time,
        team1Name: homeTeam,
        team2Name: awayTeam
      }
    }
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function lowCrownPost () {
  const obj = {
    key: 'fantasyLowCrown',
    value: {
      code: '5503'
    }
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function openWalletPost () {
  const obj = {
    key: 'fantasyOpenWallet',
    value: {
      code: '5504'
    }
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function sliderLink (link) {
  const obj = {
    key: 'fantasyJioGamesLink',
    value: {
      code: '5506',
      link: link
    }
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function loginRequiredPost () {
  const obj = {
    key: 'fantasyLoginRequired',
    value: null
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function sessionExpiredPost () {
  const obj = {
    key: 'fantasySessionExpired',
    value: null
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function sessionVerifiedPost () {
  const obj = {
    key: 'fantasySessionVerified',
    value: null
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function subscribeToFCMTopicPost (key) {
  const obj = {
    key: 'subscribeToFCMTopic',
    value: {
      key
    }
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function clickEventPost (bid, gid, cid, c1, sid) {
  const obj = {
    key: 'fantasyAnalytics',
    value: {
      key: 'ClickEvent',
      value: {}
    }
  }

  if (bid) {
    obj.value.value = {
      ...obj.value.value,
      bid
    }
  }

  if (gid) {
    obj.value.value = {
      ...obj.value.value,
      gid
    }
  }

  if (cid) {
    obj.value.value = {
      ...obj.value.value,
      cid
    }
  }

  if (c1) {
    obj.value.value = {
      ...obj.value.value,
      c1
    }
  }

  if (sid) {
    obj.value.value = {
      ...obj.value.value,
      sid
    }
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function pageViewPost (pid, gid) {
  const obj = {
    key: 'fantasyAnalytics',
    value: {
      key: 'PageView',
      value: {}
    }
  }

  if (pid) {
    obj.value.value = {
      ...obj.value.value,
      pid
    }
  }

  if (gid) {
    obj.value.value = {
      ...obj.value.value,
      gid
    }
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function participationPost (data) {
  const obj = {
    key: 'fantasyAnalytics',
    value: {
      key: 'prtc',
      value: { ...data, ts: `${Math.floor(Date.now() / 1000)}` }
    }
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function teamPost (data) {
  const obj = {
    key: 'fantasyAnalytics',
    value: {
      key: 'fteam',
      value: { ...data, ts: `${Math.floor(Date.now() / 1000)}` }
    }
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function closeWebApp () {
  const obj = {
    key: 'exit',
    value: null
  }

  console.log(JSON.stringify(obj))

  window?.DroidHandler?.postMessage(JSON.stringify(obj))
}

export function getAdsUniqueId () {
  const id = 'jiofantasy' + Math.floor(Math.random() * 1000)
  return id
}

export function getAdsConfig () {
  const adspotkeyRewarded = '9wyr2at6'
  const adspotkeyBillBoard = 'c5gvir4d'
  const dataSource = 'fantasy.jiogames.com'
  return { adspotkeyRewarded, adspotkeyBillBoard, dataSource }
}
