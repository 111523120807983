import React from 'react'
import MyTeamsPreview from './MyTeamsPreview'

function MyTeamPreview (props) {
  return (
    <>
      <MyTeamsPreview {...props} />
    </>
  )
}
export default MyTeamPreview
