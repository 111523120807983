import React from 'react'
import TeamPreview from './ViewCricket'

function TeamPreviewIndex (props) {
  return (
    <>
      <TeamPreview {...props} />
    </>
  )
}

export default TeamPreviewIndex
