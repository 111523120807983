import React from 'react'
import ViewPlayerInfo from './ViewPlayerInfo'

function PlayerInfoIndex (props) {
  return (
    <>
      <ViewPlayerInfo {...props} isSeasonPoint/>
    </>
  )
}
export default PlayerInfoIndex
