import React, { useEffect, useState, Fragment, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import HomeTeam from '../../../assests/images/Team1.png'
import AwayTeam from '../../../assests/images/Team2.png'
// import CrownUtils from '../../../assests/images/crown-utils.svg'
import SkeletonUpcoming from '../../../component/SkeletonUpcoming'
import { handleImgError } from '../../../helper'
import YouWonReturn from './YouWonReturn'

function Scorecard (props) {
  const { matchDetails, loadingScorecard, url, matchSport } = props

  // const [teamsArr, setTeamsArr] = useState([])
  const [myMatch, setMyMatch] = useState({})

  // const currencyLogo = useSelector(state => state.settings.currencyLogo)
  const matchList = useSelector(state => state.match.matchList)

  const previousProps = useRef({
    matchDetails, myMatch
  }).current

  useEffect(() => {
    if (matchDetails) {
      // const teams = matchDetails && matchDetails.sName && matchDetails.sName.split(' ')
      // setTeamsArr(teams)
    }
    return () => {
      previousProps.matchDetails = matchDetails
    }
  }, [matchDetails])

  useEffect(() => {
    if (matchList && matchDetails) {
      const match = matchList.find(match => match.iMatchId === matchDetails._id)
      setMyMatch(match)
    } else {
      setMyMatch(JSON.parse(localStorage.getItem('currMatch')) || matchDetails)
    }
  }, [matchList, matchDetails])

  return (
    <>
      {loadingScorecard
        ? <SkeletonUpcoming scoredData/>
        : <Fragment>
          {(matchSport && matchSport === 'CRICKET')
            ? <div className="scorecard-cricket">
                <div className="match-box">
                  <div className="match-i">
                      <div className="m-name justify-content-center">
                        Scoreboard
                        {/* {data.sSponsoredText ? <p>{data.sSponsoredText}</p> : ''} */}
                      </div>
                      <div className="team-wrap">
                        <div className="team-icon d-flex align-items-end">
                          <div className="team-img">
                            <img src={matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sImage ? `${url}${matchDetails.oHomeTeam.sImage}` : HomeTeam} onError={(e) => handleImgError(e, HomeTeam)} alt={<FormattedMessage id="Footer_Home" />} />
                          </div>
                          <div className="team-detail">
                            <h3>{matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sName ? matchDetails.oHomeTeam.sShortName : matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sName ? matchDetails.oAwayTeam.sShortName : matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sShortName}</h3>
                          </div>
                        </div>

                        <div className="team-icon d-flex align-items-end">
                          <div className="team-detail text-right">
                            <h3>{matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sName ? matchDetails.oAwayTeam.sShortName : matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sName ? matchDetails.oHomeTeam.sShortName : matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sShortName}</h3>

                          </div>
                          <div className="team-img">
                            <img src={matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sImage ? `${url}${matchDetails.oAwayTeam.sImage}` : AwayTeam} onError={(e) => handleImgError(e, AwayTeam)} alt={<FormattedMessage id="Footer_Home" />} />
                          </div>
                        </div>
                      </div>
                      <div className="scorecard-detail">
                        <div className="scorecard-text">
                          {matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.nScore
                            ? <div className='score'>{matchDetails.oHomeTeam.nScore}</div>
                            : <FormattedMessage id="Team_ScoreData" />
                          }
                        </div>
                        <div className="team-info">
                          {/* {myMatch?.nWinnings
                            ? <div className="team-info">
                              <FormattedMessage id='You_won' /> {currencyLogo}{myMatch?.nWinnings}
                            </div>
                            : null
                          } */}
                          {
                            (matchDetails?.eStatus === 'L' || myMatch?.eStatus === 'L') &&
                              <div className='status-text status-text-live '><FormattedMessage id='Matches_Live' /></div>
                          }
                          {
                            (matchDetails?.eStatus === 'CMP' || myMatch?.eStatus === 'CMP') &&
                              <div className='status-text status-text-completed'>
                                <YouWonReturn data={myMatch} />
                              </div>
                          }
                          {
                            (matchDetails?.eStatus === 'I' || myMatch?.eStatus === 'I') &&
                              <div className='status-text status-text-inreview'><FormattedMessage id='Match_Review' /></div>
                          }
                          {
                            (matchDetails?.eStatus === 'CNCL' || myMatch?.eStatus === 'CNCL') &&
                              <div className='status-text status-text-abandoned'><FormattedMessage id='Match_Abandoned' /></div>
                          }
                          {/* <div className='match-overs'>
                            <p>20 Overs</p>
                          </div> */}
                        </div>
                        <div className="scorecard-text text-right">
                            {matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.nScore
                              ? <div className='score'>{matchDetails.oAwayTeam.nScore}</div>
                              : <FormattedMessage id="Team_ScoreData" />
                            }
                        </div>
                      </div>
                      {(matchDetails?.eStatus === 'I' || matchDetails?.eStatus === 'CMP') && matchDetails.sWinning &&
                        <div className="footer-m d-flex align-items-center  justify-content-center">
                          <p>{matchDetails.sWinning}</p>
                        </div>
                      }
                      {matchDetails?.eStatus === 'L' && matchDetails.iTossWinnerId &&
                        <div className="footer-m d-flex align-items-center  justify-content-center">
                          <p>{`${matchDetails.iTossWinnerId === matchDetails.oHomeTeam.iTeamId ? matchDetails.oHomeTeam.sName : matchDetails.oAwayTeam.sName} won the toss & elected to ${matchDetails?.eTossWinnerAction === 'BAT' ? 'bat' : 'bowl'}`}</p>
                        </div>
                      }
                      {/* <div className="footer-m d-flex align-items-center  justify-content-center">
                        <p>
                          {(matchDetails?.eStatus === 'I' || matchDetails?.eStatus === 'CMP') &&
                            matchDetails.sWinning
                          }
                          {matchDetails?.eStatus === 'L' ? matchDetails.sStatusNote : ''}
                        </p>
                      </div> */}
                  </div>
                </div>
                {/* <h4><FormattedMessage id="Player_scoreCard" /></h4> */}
                {/* <div className="scorecard-item">
                  <div className="t-img"><img src={matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sImage ? `${url}${matchDetails.oHomeTeam.sImage}` : HomeTeam} alt={<FormattedMessage id="Footer_Home" />} /></div>
                  <p>
                    <span className="t-name"><b> {teamsArr && teamsArr.length && teamsArr[0]} </b> </span>
                    <span> {matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.nScore ? matchDetails.oHomeTeam.nScore : <FormattedMessage id="Team_ScoreData" />} </span>
                  </p>
                </div>
                <div className="scorecard-item">
                  <div className="t-img"><img src={matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sImage ? `${url}${matchDetails.oAwayTeam.sImage}` : AwayTeam} alt={<FormattedMessage id="Footer_Away" />} /></div>
                  <p>
                    <span className="t-name"><b> {teamsArr && teamsArr.length && teamsArr[2]} </b> </span>
                    <span> {matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.nScore ? matchDetails.oAwayTeam.nScore : <FormattedMessage id="Team_ScoreData" />} </span>
                  </p>
                </div>
                <div className="w-txt">{matchDetails && matchDetails.sWinning} </div> */}
              </div>
            : <>
              <div className="scorecard-cricket">
                <div className="match-box">
                  <div className="match-i">
                      <div className="scorecard-wrapper">
                      <div className="m-name-2 justify-content-center">
                        <p>Scoreboard</p>
                        {/* {data.sSponsoredText ? <p>{data.sSponsoredText}</p> : ''} */}
                      </div>
                      </div>
                      <div className="team-wrap">
                        <div className="team-icon d-flex flex-column align-items-center">
                          <div className="team-img">
                            <img src={matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sImage ? `${url}${matchDetails.oHomeTeam.sImage}` : HomeTeam} onError={(e) => handleImgError(e, HomeTeam)} alt={<FormattedMessage id="Footer_Home" />} />
                          </div>
                          <div className="team-detail m-0">
                            <h3>{matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sName ? matchDetails.oHomeTeam.sShortName : matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sName ? matchDetails.oAwayTeam.sShortName : matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sShortName}</h3>
                          </div>
                        </div>

                      <div className="scorecard-detail-2">
                        <div className="scorecard-text mr-3">
                          {matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.nScore
                            ? <div className='score'>{matchDetails.oHomeTeam.nScore}</div>
                            : <FormattedMessage id="Team_ScoreData" />
                          }
                        </div>
                        -
                        <div className="scorecard-text text-right ml-3">
                            {matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.nScore
                              ? <div className='score'>{matchDetails.oAwayTeam.nScore}</div>
                              : <FormattedMessage id="Team_ScoreData" />
                            }
                        </div>
                      </div>

                        <div className="team-icon d-flex flex-column align-items-center">
                          <div className="team-img">
                            <img src={matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sImage ? `${url}${matchDetails.oAwayTeam.sImage}` : AwayTeam} onError={(e) => handleImgError(e, AwayTeam)} alt={<FormattedMessage id="Footer_Home" />} />
                          </div>
                          <div className="team-detail text-right m-0">
                            <h3>{matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sName ? matchDetails.oAwayTeam.sShortName : matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sName ? matchDetails.oHomeTeam.sShortName : matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sShortName}</h3>
                          </div>
                        </div>
                      </div>
                      {(matchDetails?.eStatus === 'I' || matchDetails?.eStatus === 'CMP') && matchDetails.sWinning &&
                        <div className="footer-m d-flex align-items-center  justify-content-center">
                          <p>{matchDetails.sWinning}</p>
                        </div>
                      }
                      {matchDetails?.eStatus === 'L' && matchDetails.iTossWinnerId &&
                        <div className="footer-m d-flex align-items-center  justify-content-center">
                          <p>{`${matchDetails.iTossWinnerId === matchDetails.oHomeTeam.iTeamId ? matchDetails.oHomeTeam.sName : matchDetails.oAwayTeam.sName} won the toss & elected to ${matchDetails?.eTossWinnerAction === 'BAT' ? 'bat' : 'bowl'}`}</p>
                        </div>
                      }
                      {/* <div className="footer-m d-flex align-items-center  justify-content-center">
                        <p>
                          {(matchDetails?.eStatus === 'I' || matchDetails?.eStatus === 'CMP') &&
                            matchDetails.sWinning
                          }
                          {matchDetails?.eStatus === 'L' ? matchDetails.sStatusNote : ''}
                        </p>
                      </div> */}
                  </div>
                </div>
              </div>
            </>
          }
        </Fragment>
      }
    </>
  )
}

Scorecard.propTypes = {
  matchDetails: PropTypes.shape({
    oHomeTeam: PropTypes.shape({
      nScore: PropTypes.Number,
      sImage: PropTypes.string,
      sName: PropTypes.string,
      sShortName: PropTypes.string,
      iTeamId: PropTypes.string
    }),
    oAwayTeam: PropTypes.shape({
      nScore: PropTypes.Number,
      sImage: PropTypes.string,
      sName: PropTypes.string,
      sShortName: PropTypes.string,
      iTeamId: PropTypes.string
    }),
    sWinning: PropTypes.string,
    sName: PropTypes.string,
    sStatusNote: PropTypes.string,
    eStatus: PropTypes.string,
    nWinnings: PropTypes.number,
    iTossWinnerId: PropTypes.string,
    eTossWinnerAction: PropTypes.string,
    _id: PropTypes.string
  }),
  loadingScorecard: PropTypes.bool,
  url: PropTypes.string,
  matchSport: PropTypes.string
}
export default Scorecard
