/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
import React, { Fragment, useState, useEffect, useRef, lazy, Suspense } from 'react'
import { Link } from 'react-router-dom'
import MultiRangeSlider from 'multi-range-slider-react'
import {
  Button, Card, CardBody, CardFooter, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane, Alert, Table
} from 'reactstrap'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useQueryState } from 'react-router-use-location-state'
import { FormattedMessage } from 'react-intl'
import qs from 'query-string'
import CreateContestIcon from '../../../assests/images/create_contest_icon.svg'
import JoinContestIcon from '../../../assests/images/join_contest_icon.svg'
import IconSettings from '../../../assests/images/icon-settings.svg'
import CrownUtils from '../../../assests/images/crown-utils.svg'
import closeIcon from '../../../assests/images/close-icon.svg'
import SuccessIcon from '../../../assests/images/success-icon.svg'

import Loading from '../../../component/Loading'
import { clickEventPost, isUpperCase, pageViewPost } from '../../../helper'
import LeaguesList from '../../../HOC/SportsLeagueList/LeaguesList'
import { viewMatchTips } from '../../../Analytics.js'
import Slider from '../components/Slider'
import AutoPick from '../components/AutoPick/AutoPick'
import AutoPickAnimation from '../components/AutoPick/AutoPickAnimation'
import TeamBottomSection from '../components/common/TeamBottomSection'

const classNames = require('classnames')
const MyContact = lazy(() => import('../components/MyContast'))
const LeagueContest = lazy(() => import('../../Home/components/LeagueContest'))
const MyAllTeams = lazy(() => import('../components/MyAllTeams'))

function LeaguesPage (props) {
  const {
    activeTab,
    getMyContestsList,
    getMyTeamList,
    leagueList,
    message,
    modalMessage,
    toggle,
    filterSlide,
    setFilterSlide,
    sortSlide,
    setSortSlide,
    Sort,
    Filter,
    setFilter,
    setSort,
    match,
    listed,
    Filterd, FilterdEntry, FilterdNoOfTeam, FilterdPrizePool, FilterdCategory,
    setFilterdEntry,
    setFilterdNoOfTeam,
    sorted,
    type,
    ApplyFilter,
    changeType,
    close,
    loading,
    amountData,
    teamList,
    matchDetails,
    location,
    matchTipsDetails,
    VideoStream,
    paymentSlide,
    setPaymentSlide,
    FilterEntry,
    setFilterEntry,
    FilterNoOfTeam,
    setFilterNoOfTeam,
    FilterPrizePool,
    setFilterPrizePool,
    FilterCategory,
    setFilterCategory,
    onGetUserProfile,
    userInfo,
    currencyLogo,
    history,
    list,
    contestJoinListCount,
    teamListCount,
    getTeamPlayerList,
    setModalMessage,
    autoPickAnimation,
    setAutoPickAnimation,
    fetchAutoPickData
  } = props
  const [filterData, setFilterData] = useState([])
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [activeTabs, setActiveState] = useQueryState('activeTab', '1')
  const [filteredPrizePool, setFilteredPrizePool] = useState(0)
  const obj = qs.parse(location.search)
  const previousProps = useRef({
    amountData
  }).current
  let active = 1

  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(0)

  const [minValueTwo, setMinValueTwo] = useState(1)
  const [maxValueTwo, setMaxValueTwo] = useState(1)

  const [reset, setReset] = useState(false)

  useEffect(() => { // handle the response
    if (location && location.state && location.state.tab && !location.state.selectTeamModal) {
      toggle(parseInt(location.state.tab))
      setActiveState(location.state.tab)
    }
    if (location && location.state && location.state.message) {
      if (location.state.message) {
        setAlert(true)
        setAlertMessage(location.state.message)
        setTimeout(() => {
          setAlert(false)
        }, 2000)
      }
    }
    if (obj) {
      if (obj.activeTab) {
        active = obj.activeTab
        setActiveState(parseInt(active))
        toggle(parseInt(active))
      }
    }
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      isUpperCase(sport) && history.push({ pathname: `/leagues/${sport.toLowerCase()}/${match.params.id}`, state: { ...props.location.state } })
    }
    !userInfo && onGetUserProfile()
  }, [])

  useEffect(() => {
    if (minValue === 0 && maxValue === 0) {
      setReset(false)
    }
  }, [minValue, maxValue])

  useEffect(() => {
    if (minValueTwo === 1 && maxValueTwo === 1) {
      setReset(false)
    }
  }, [minValueTwo, maxValueTwo])

  function handleRefresh () {
    leagueList()
  }

  useEffect(() => {
    getTeamPlayerList(matchDetails?._id)
  }, [matchDetails])

  useEffect(() => { // set the response
    if (previousProps.amountData !== amountData) {
      if (amountData && amountData?.oValue?.nAmount > 0) {
        // props.history.push({
        //   pathname: '/deposit',
        //   state: {
        //     amountData: amountData?.oValue,
        //     message: 'Insufficient Balance'
        //   }
        // })
        // setAlertMessage('Insufficient Crown')
        // setAlert(true)
      }
    }
    return () => {
      previousProps.amountData = amountData
    }
  }, [amountData])

  useEffect(() => {
    if (paymentSlide) {
      !userInfo && onGetUserProfile()
    }
  }, [paymentSlide])
  function myContestList () {
    getMyContestsList()
  }

  function myTeamList () {
    getMyTeamList()
  }

  function resetFilter () {
    setFilterEntry([])
    setFilterNoOfTeam([])
    setFilterPrizePool([])
    setFilter([])
    setFilterCategory([])
    setFilterdEntry([])
    setFilterdNoOfTeam([])
    setFilterNoOfTeam([])
    setMinValue(0)
    setMaxValue(0)
    setMinValueTwo(1)
    setMaxValueTwo(1)
    setReset(true)
    // ApplyFilter(Filter, FilterEntry, FilterNoOfTeam, FilterPrizePool)
    ApplyFilter(false, Filter, FilterEntry, FilterNoOfTeam, [], [])
  }

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setModalMessage(false)
      }, 2000)
    }
  }, [message])

  useEffect(() => {
    if (list) {
      const filtered = Math.max(...list.map(o => o.nTotalPayout))
      setFilteredPrizePool(filtered)
    }
  }, [list])

  const onClickAutoPick = () => {
    const { _id: matchID } = matchDetails
    setAutoPickAnimation(true)
    fetchAutoPickData({ matchID })
  }

  return (
    <>
      {alert && alertMessage && alertMessage.length ? <Alert isOpen={alert} color={props?.teamResStatus && alertMessage === 'Contest joined successfully' ? 'success' : 'primary'}>{props?.teamResStatus && alertMessage === 'Contest joined successfully' && <img className="mr-1" src={SuccessIcon} alt="SuccessIcon" />}{alertMessage}</Alert> : ''}
      {autoPickAnimation && <AutoPickAnimation/>}
      {
        modalMessage && message
          ? message.toLowerCase() === 'match player does not exist'
            ? activeTab === 3
              ? <Fragment>
                <Alert isOpen={modalMessage} color='primary'>{message}</Alert>
              </Fragment>
              : ''
            : <Fragment>
              <Alert isOpen={modalMessage} color={props?.teamResStatus ? message.toLowerCase() === 'match player does not exist' ? 'primary' : 'success' : 'primary'}>{props?.teamResStatus && message.toLowerCase() !== 'match player does not exist' && <img className="mr-1" src={SuccessIcon} alt="SuccessIcon" />} {message}</Alert>
            </Fragment>
          : ''
      }
      <Fragment >
        <Nav className={`live-tabs justify-content-around ${matchDetails && matchDetails.sFantasyPost && 'four-tabs'}`}>
          <NavItem className='text-center'>
            <NavLink className={classnames({ active: activeTab === 1 })} onClick={() => {
              toggle(1)
              setActiveState(1)
            }}><FormattedMessage id="League_contest" /></NavLink>
          </NavItem>
          <NavItem className='text-center'>
            <NavLink className={classnames({ active: activeTab === 2 })} onClick={() => {
              toggle(2)
              setActiveState(2)
              pageViewPost('mct')
            }}><FormattedMessage id="League_myContest" />{contestJoinListCount ? ` (${contestJoinListCount})` : ''}</NavLink>
          </NavItem>
          <NavItem className='text-center'>
            <NavLink className={classnames({ active: activeTab === 3 })} onClick={() => {
              toggle(3)
              setActiveState(3)
              pageViewPost('myt')
            }}><FormattedMessage id="League_myTeams" />{teamListCount ? ` (${teamListCount})` : ''}</NavLink>
          </NavItem>
          {
            matchDetails && matchDetails.sFantasyPost && (
              <Fragment>
                <NavItem className='text-center'>
                  <NavLink className={classnames({ active: activeTab === 4 })} onClick={() => {
                    if (matchDetails && matchDetails.sName && matchDetails._id && location.pathname) {
                      viewMatchTips(matchDetails.sName, matchDetails._id, location.pathname)
                    } else {
                      matchDetails && matchDetails.sName && matchDetails._id && viewMatchTips(matchDetails.sName, matchDetails._id, '')
                    }
                    toggle(4)
                    setActiveState(4)
                  }}><FormattedMessage id="League_matchTips" /></NavLink>
                </NavItem>
              </Fragment>
            )
          }
        </Nav>
        {
          ((list?.length > 0) || (listed?.length > 0)) && activeTab === 1 && (
            <div className='sort-view'>
              <p className='sort_by_text'><FormattedMessage id="League_sortBy" /></p>
              <div className='new-sort-box d-flex align-items-center justify-content-between'>
                <div className={`d-flex justify-content-center filter ${sorted === 'Popularity' ? 'actived' : ''}`} onClick={() => {
                  changeType('Popularity')
                  clickEventPost('cl_ac_sb')
                }}>
                  <Button className={`${sorted === 'Popularity' ? 'selected' : ''}`}><FormattedMessage id="League_popularity" /></Button>
                </div>
                <div className={`d-flex justify-content-center filter ${sorted === 'Prize-Pool' ? 'actived' : ''}`} onClick={() => {
                  changeType('Prize-Pool')
                  clickEventPost('cl_ac_sb')
                }}>
                  <Button className={`${sorted === 'Prize-Pool' ? 'selected' : ''}`}><FormattedMessage id="League_prizePoll" /></Button>
                  {
                    sorted === 'Prize-Pool' && <i className={classNames('asc-dsc', { 'icon-up-arrow': !type, 'icon-down-arrow': type })}> </i>
                  }
                </div>
                <div className={`d-flex justify-content-center filter ${sorted === 'Spots' ? 'actived' : ''}`} onClick={() => {
                  changeType('Spots')
                  clickEventPost('cl_ac_sb')
                }}>
                  <Button className={`${sorted === 'Spots' ? 'selected' : ''}`} ><FormattedMessage id="League_spots" /></Button>
                  {
                    sorted === 'Spots' && <i className={classNames('asc-dsc', { 'icon-up-arrow': !type, 'icon-down-arrow': type })}> </i>
                  }
                </div>
                <div className={`d-flex justify-content-center filter ${sorted === 'Winner' ? 'actived' : ''}`} onClick={() => {
                  changeType('Winner')
                  clickEventPost('cl_ac_sb')
                }}>
                  <Button className={`${sorted === 'Winner' ? 'selected' : ''}`}><FormattedMessage id="League_winners" /></Button>
                  {
                    sorted === 'Winner' && <i className={classNames('asc-dsc', { 'icon-up-arrow': !type, 'icon-down-arrow': type })}> </i>
                  }
                </div>
                <div className={`d-flex justify-content-center filter ${sorted === 'Entry' ? 'actived' : ''}`} onClick={() => {
                  changeType('Entry')
                  clickEventPost('cl_ac_sb')
                }}>
                  <Button className={`${sorted === 'Entry' ? 'selected' : ''}`}><FormattedMessage id="League_entry" /></Button>
                  {
                    sorted === 'Entry' && <i className={classNames('asc-dsc', { 'icon-up-arrow': !type, 'icon-down-arrow': type })}> </i>
                  }
                </div>
              </div>
            </div>
          )
        }
        <div className='league-container' onClick={() => {
          if (filterSlide) setFilterSlide(false)
          if (sortSlide) setSortSlide(false)
        }}>
          {
            !VideoStream &&
            <TabContent activeTab={activeTab}>
              {activeTab === 1 && <TabPane tabId={1}>
                <div className='d-flex contest-btn align-items-center justify-content-between'>
                  <div className='contest-btn-header'>
                    <h2>Your contest your rules!</h2>
                    <h3>Create your own contest and invite friends</h3>
                  </div>
                  <div className='contest-btn-bottom'>
                    <Link to={{
                      pathname: `/join-contest/${(match.params.sportsType).toLowerCase()}/${match.params.id}`,
                      search: `?${qs.stringify({
                        homePage: obj?.homePage ? 'yes' : undefined
                      })}`
                    }} className='d-flex align-items-center f-btn justify-content-between'> <img src={JoinContestIcon} alt={<FormattedMessage id='Contast_JoinLeeague' />} /><FormattedMessage id="League_enterCode" /></Link>
                    <Link to={{
                      pathname: `/create-contest/${(match.params.sportsType).toLowerCase()}/${match.params.id}`,
                      search: `?${qs.stringify({
                        homePage: obj?.homePage ? 'yes' : undefined,
                        firstTeam: true
                      })}`
                    }} className='d-flex align-items-center f-btn justify-content-between'><img src={CreateContestIcon} alt={<FormattedMessage id='Team_CreatePrivateLeague' />} /><FormattedMessage id="League_createContest" /></Link>
                  </div>
                </div>
                {/* <div className='hidden-feild'> */}
                {/* </div> */}
                {/* <div className='sort-box d-flex align-items-end justify-content-between pt-0'>
                  <div className='sort-i position-relative'>
                    <label className='d-block'><FormattedMessage id="Common_sort" /> </label>
                    <button onClick={() => { setSortSlide(true) }} className='w-100 d-flex align-items-center justify-content-between f-btn'><i className='icon-caret-down'></i>{sorted}
                    </button>
                    {
                      !(sorted === 'Popularity')
                        ? <button className={classNames('asc-dsc', { 'icon-up-arrow': type, 'icon-down-arrow': !type })} onClick={changeType}> </button>
                        : ''
                    }
                  </div>
                  <button onClick={() => { setFilterSlide(true) }} className='d-flex align-items-center justify-content-between f-btn'><i className='icon-sound-mixer'></i>{Filterd && Filterd.length !== 0 ? Filterd && Filterd.join(', ') : <FormattedMessage id="League_Select_Filter" />}</button>
                </div> */}
                <Slider />
                <Suspense fallback={<Loading />}>
                  <LeagueContest tab={1} matchType='upcoming' resetFilter={resetFilter} handleRefreshFun={handleRefresh} setFilterData={setFilterData} listed={listed} {...props} sortBy={sorted} FilterdEntry={FilterdEntry} FilterdNoOfTeam={FilterdNoOfTeam} FilterdPrizePool={FilterdPrizePool} FilterdCategory={FilterdCategory} Filterd={Filterd} />
                </Suspense>
              </TabPane>}
              {activeTab === 2 && <TabPane tabId={2}>
                <Slider />
                <Suspense fallback={<Loading />}>
                  <MyContact {...props} tab={2} matchType='upcoming' myContestList={myContestList} setTab={() => {
                    setActiveState(1)
                    toggle(1)
                  }
                  } />
                </Suspense>
              </TabPane>}
              {activeTab === 3 && <TabPane tabId={3}>
                <div className="my-teamtabs">
                  <Suspense fallback={<Loading />}>
                    <MyAllTeams {...props} match={match} onLinesUp={matchDetails && matchDetails.bLineupsOut} loadingList={loading} myTeamList={myTeamList} matchType='upcoming' params={match && match.params.id} onPreviewTeam notCalling />
                  </Suspense>
                </div>
                  {
                    teamList && teamList.length > 0 && (
                      <>
                        <TeamBottomSection
                          onClickAutoPick={onClickAutoPick}
                          clickEventPost={clickEventPost}
                          match={match}
                          location={location}
                          activeTab={activeTab}
                        />
                      </>
                    )
                  }
              </TabPane>}
              <TabPane tabId={4}>
                {loading && <Loading />}
                <div className="cms backWhite">
                  <h1> {matchTipsDetails && matchTipsDetails.sTitle} </h1>
                  {
                    matchTipsDetails && matchTipsDetails.sContent && (
                      <div className="offer-d-txt" dangerouslySetInnerHTML={{ __html: matchTipsDetails && matchTipsDetails.sContent && matchTipsDetails.sContent }}>
                      </div>
                    )
                  }
                </div>
              </TabPane>
            </TabContent>
          }
        </div>
        {filterSlide
          ? <>
            <div className="s-team-bg" onClick={() => setFilterSlide(false)}></div>
            <Card className={classNames('filter-card small-screen-filter', { show: filterSlide })}>
              <CardHeader className='d-flex align-items-center justify-content-between filter-header-sticky'>
                <button>
                  {/* <i onClick={() => {
                  setFilterSlide(false)
                  ApplyFilter(Filter, FilterEntry, FilterNoOfTeam, FilterPrizePool)
                }} className='icon-left'></i>
                <FormattedMessage id="League_filterBy" /> */}
                  <FormattedMessage id="Player_filter" />
                </button>
                <button className='red-clear-btn' onClick={() => {
                  setFilterEntry([])
                  setFilterNoOfTeam([])
                  setFilterPrizePool([])
                  setFilter([])
                  setFilterCategory([])
                  setFilterSlide(false)
                  // ApplyFilter(Filter, FilterEntry, FilterNoOfTeam, FilterPrizePool)
                }}>
                  {/* <FormattedMessage id="League_clear_all" /> */}
                  <img src={closeIcon} alt="close-icon" className='img-fluid' />
                </button>
              </CardHeader>
              <div className='popup-content pb-3'>
                <CardBody className='filter-box'>
                  <div className="filter-range">
                    <div className="filter-range-title d-flex justify-content-between">
                      <h3><FormattedMessage id="League_Entry_Fee" /></h3>
                      {(minValue !== 0 || maxValue !== 0) && <h3><img src={CrownUtils} /> {minValue} - <img src={CrownUtils} /> {maxValue}</h3>}
                    </div>
                    <MultiRangeSlider
                      min={0}
                      max={1000}
                      step={1}
                      stepOnly={true}
                      minValue={minValue}
                      maxValue={maxValue}
                      onChange={({ minValue, maxValue }) => {
                        if (!reset) {
                          setMinValue(minValue)
                          setMaxValue(maxValue)
                        }
                      }}
                    />
                  </div>
                  <div className="filter-range">
                    <div className="filter-range-title d-flex justify-content-between">
                      <h3><FormattedMessage id="League_no_of_teams" /></h3>
                      {(minValueTwo !== 1 || maxValueTwo !== 1) && <h3>{minValueTwo} - {maxValueTwo}</h3>}
                    </div>
                    <MultiRangeSlider
                      min={1}
                      max={50}
                      step={1}
                      stepOnly={true}
                      minValue={minValueTwo}
                      maxValue={maxValueTwo}
                      onChange={({ minValue, maxValue }) => {
                        if (!reset) {
                          setMinValueTwo(minValue)
                          setMaxValueTwo(maxValue)
                        }
                      }}
                    />
                  </div>
                  {/* <ul className='m-0 d-flex align-item-center flex-wrap'>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterEntry.includes('1-50')} />
                      <label htmlFor='FilterData' onClick={() => FilterEntry.includes('1-50') ? setFilterEntry(FilterEntry.filter(fData => fData !== '1-50')) : setFilterEntry([...FilterEntry, '1-50'])}>{`${currencyLogo}1 - ${currencyLogo}50`}</label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterEntry.includes('51-100')} />
                      <label htmlFor='FilterData' onClick={() => FilterEntry.includes('51-100') ? setFilterEntry(FilterEntry.filter(fData => fData !== '51-100')) : setFilterEntry([...FilterEntry, '51-100'])}>{`${currencyLogo}51 - ${currencyLogo}100`}</label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterEntry.includes('101-1000')} />
                      <label htmlFor='FilterData' onClick={() => FilterEntry.includes('101-1000') ? setFilterEntry(FilterEntry.filter(fData => fData !== '101-1000')) : setFilterEntry([...FilterEntry, '101-1000'])}>{`${currencyLogo}101 - ${currencyLogo}1000`}</label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterEntry.includes('1001-above')} />
                      <label htmlFor='FilterData' onClick={() => FilterEntry.includes('1001-above') ? setFilterEntry(FilterEntry.filter(fData => fData !== '1001-above')) : setFilterEntry([...FilterEntry, '1001-above'])}>{`${currencyLogo}1001 & above`}</label>
                    </li>
                  </ul> */}
                </CardBody>
                {/* <CardBody className='filter-box'>
                  <h3><FormattedMessage id="League_no_of_teams" /></h3>
                  <ul className='m-0 d-flex align-item-center flex-wrap'>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterNoOfTeam.includes('2')} />
                      <label htmlFor='FilterData' onClick={() => FilterNoOfTeam.includes('2') ? setFilterNoOfTeam(FilterNoOfTeam.filter(fData => fData !== '2')) : setFilterNoOfTeam([...FilterNoOfTeam, '2'])}>{'2'}</label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterNoOfTeam.includes('3-10')} />
                      <label htmlFor='FilterData' onClick={() => FilterNoOfTeam.includes('3-10') ? setFilterNoOfTeam(FilterNoOfTeam.filter(fData => fData !== '3-10')) : setFilterNoOfTeam([...FilterNoOfTeam, '3-10'])}>{'3 - 10'}</label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterNoOfTeam.includes('11-100')} />
                      <label htmlFor='FilterData' onClick={() => FilterNoOfTeam.includes('11-100') ? setFilterNoOfTeam(FilterNoOfTeam.filter(fData => fData !== '11-100')) : setFilterNoOfTeam([...FilterNoOfTeam, '11-100'])}>{'11 - 100'}</label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterNoOfTeam.includes('101-1000')} />
                      <label htmlFor='FilterData' onClick={() => FilterNoOfTeam.includes('101-1000') ? setFilterNoOfTeam(FilterNoOfTeam.filter(fData => fData !== '101-1000')) : setFilterNoOfTeam([...FilterNoOfTeam, '101-1000'])}>{'101 - 1000'}</label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterNoOfTeam.includes('1001-above')} />
                      <label htmlFor='FilterData' onClick={() => FilterNoOfTeam.includes('1001-above') ? setFilterNoOfTeam(FilterNoOfTeam.filter(fData => fData !== '1001-above')) : setFilterNoOfTeam([...FilterNoOfTeam, '1001-above'])}>{'1001 - above'}</label>
                    </li>
                  </ul>
                </CardBody> */}
                <CardBody className='filter-box'>
                  <h3><FormattedMessage id="League_prize_pool" /></h3>
                  <ul className='m-0 d-flex align-item-center flex-wrap'>
                    <ul className='m-0 d-flex align-item-center flex-wrap'>
                      {(filteredPrizePool >= 10000 || filteredPrizePool >= 1) &&
                        <li>
                          <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterPrizePool.includes('1-10000')} />
                          <label htmlFor='FilterData' onClick={() => FilterPrizePool.includes('1-10000') ? setFilterPrizePool(FilterPrizePool.filter(fData => fData !== '1-10000')) : setFilterPrizePool([...FilterPrizePool, '1-10000'])}>
                            <img src={CrownUtils} /> 1 to <img src={CrownUtils} /> 10,000
                          </label>
                        </li>
                      }
                      {filteredPrizePool >= 100000 &&
                        <li>
                          <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterPrizePool.includes('10000-100000')} />
                          <label htmlFor='FilterData' onClick={() => FilterPrizePool.includes('10000-100000') ? setFilterPrizePool(FilterPrizePool.filter(fData => fData !== '10000-100000')) : setFilterPrizePool([...FilterPrizePool, '10000-100000'])}>
                            <img src={CrownUtils} /> 10,000 to <img src={CrownUtils} /> 1 Lakh
                          </label>
                        </li>
                      }
                      {filteredPrizePool >= 1000000 &&
                        <li>
                          <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterPrizePool.includes('100000-1000000')} />
                          <label htmlFor='FilterData' onClick={() => FilterPrizePool.includes('100000-1000000') ? setFilterPrizePool(FilterPrizePool.filter(fData => fData !== '100000-1000000')) : setFilterPrizePool([...FilterPrizePool, '100000-1000000'])}>
                            <img src={CrownUtils} /> 1 Lakh to <img src={CrownUtils} /> 10 Lakh
                          </label>
                        </li>
                      }
                      {filteredPrizePool >= 2500000 &&
                        <li>
                          <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterPrizePool.includes('1000000-2500000')} />
                          <label htmlFor='FilterData' onClick={() => FilterPrizePool.includes('1000000-2500000') ? setFilterPrizePool(FilterPrizePool.filter(fData => fData !== '1000000-2500000')) : setFilterPrizePool([...FilterPrizePool, '1000000-2500000'])}>
                            <img src={CrownUtils} /> 10 Lakh to <img src={CrownUtils} /> 25 Lakh
                          </label>
                        </li>
                      }
                      {filteredPrizePool > 2500000 &&
                        <li>
                          <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterPrizePool.includes('2500000')} />
                          <label htmlFor='FilterData' onClick={() => FilterPrizePool.includes('2500000') ? setFilterPrizePool(FilterPrizePool.filter(fData => fData !== '2500000')) : setFilterPrizePool([...FilterPrizePool, '2500000'])}>
                            <img src={CrownUtils} /> 25 Lakh & above
                          </label>
                        </li>
                      }
                    </ul>
                  </ul>
                </CardBody>
                <CardBody className='filter-box'>
                  <h3><FormattedMessage id="League_contest_type" /></h3>
                  <ul className='m-0 d-flex align-item-center flex-wrap'>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterCategory.includes('SingleEntry')} />
                      <label htmlFor='FilterData' onClick={() => FilterCategory.includes('SingleEntry') ? setFilterCategory(FilterCategory.filter(fData => fData !== 'SingleEntry')) : setFilterCategory([...FilterCategory, 'SingleEntry'])}><FormattedMessage id='League_single_entry' /></label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterCategory.includes('MultiEntry')} />
                      <label htmlFor='FilterData' onClick={() => FilterCategory.includes('MultiEntry') ? setFilterCategory(FilterCategory.filter(fData => fData !== 'MultiEntry')) : setFilterCategory([...FilterCategory, 'MultiEntry'])}><FormattedMessage id='League_Multi_Entry' /></label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterCategory.includes('SingleWinner')} />
                      <label htmlFor='FilterData' onClick={() => FilterCategory.includes('SingleWinner') ? setFilterCategory(FilterCategory.filter(fData => fData !== 'SingleWinner')) : setFilterCategory([...FilterCategory, 'SingleWinner'])}><FormattedMessage id='League_Single_Winner' /></label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterCategory.includes('MultiWinner')} />
                      <label htmlFor='FilterData' onClick={() => FilterCategory.includes('MultiWinner') ? setFilterCategory(FilterCategory.filter(fData => fData !== 'MultiWinner')) : setFilterCategory([...FilterCategory, 'MultiWinner'])}><FormattedMessage id='League_Multi_Winner' /></label>
                    </li>
                    <li>
                      <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={FilterCategory.includes('Guranteed')} />
                      <label htmlFor='FilterData' onClick={() => FilterCategory.includes('Guranteed') ? setFilterCategory(FilterCategory.filter(fData => fData !== 'Guranteed')) : setFilterCategory([...FilterCategory, 'Guranteed'])}><FormattedMessage id='League_Guaranteed' /></label>
                    </li>
                  </ul>
                </CardBody>
                <CardBody className='filter-box'>
                  <h3> <FormattedMessage id="League_league_type" /> </h3>
                  <ul className='m-0 d-flex align-item-center flex-wrap'>
                    {
                      filterData && filterData.length !== 0
                        ? filterData.map(data => {
                          return (
                            <li key={data._id}>
                              <input type='checkbox' id='FilterData' name='filter' className='d-none' checked={Filter.includes(data)} />
                              <label htmlFor='FilterData' onClick={() => Filter.includes(data) ? setFilter(Filter.filter(fData => fData !== data)) : setFilter([...Filter, data])}>{data}</label>
                            </li>
                          )
                        })
                        : <h3> <FormattedMessage id="No_Filter" /> </h3>
                    }
                  </ul>
                </CardBody>
              </div>
              <CardFooter className='btn-bottom'>
                <div className='row'>
                  <div className='col-6 pr-2'>
                    <Button color='border' className='w-100' onClick={() => { resetFilter() }}><FormattedMessage id="Reset_btn_txt" /></Button>
                  </div>
                  <div className='col-6 pl-2'>
                    <Button color='primary' className='w-100' onClick={() => {
                      if (minValue !== 0 || maxValue !== 0 || minValueTwo !== 1 || maxValueTwo !== 1) {
                        ApplyFilter(true, Filter, FilterEntry, FilterNoOfTeam, FilterPrizePool, FilterCategory, minValue, maxValue, minValueTwo, maxValueTwo)
                      } else {
                        ApplyFilter(true, Filter, FilterEntry, FilterNoOfTeam, FilterPrizePool, FilterCategory)
                      }
                    }}><FormattedMessage id="User_Apply" /></Button>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </>
          : ''
        }
        {paymentSlide
          ? <>
            <div className="s-team-bg" onClick={() => setPaymentSlide(false)}></div>
            <Card className={classNames('filter-card', { show: filterSlide })}>
              <CardHeader className='d-flex align-items-center justify-content-between'>
                <button onClick={() => { setPaymentSlide(false) }}><FormattedMessage id='Wallet_Details' /></button>
                <button className='red-close-btn' onClick={() => setPaymentSlide(false)}><FormattedMessage id='League_Close' /></button>
              </CardHeader>
              <CardBody className='payment-box'>

                <Table className="m-0 bg-white payment">
                  <thead>
                    <tr className='text-center'> <th colSpan='2'><FormattedMessage id="PROFILE_TOTAL_BALANCE" /></th> </tr>
                    <tr className='text-center'> <th colSpan='2'>{currencyLogo}{userInfo && userInfo.nCurrentTotalBalance} </th> </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><FormattedMessage id="PROFILE_DEP_BALANCE" /></td>
                      <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentDepositBalance}</td>
                    </tr>
                    <tr>
                      <td><FormattedMessage id="PROFILE_WIN_BALANCE" /></td>
                      <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentWinningBalance}</td>
                    </tr>
                    <tr>
                      <td><FormattedMessage id="PROFILE_CASH_BONUS" /></td>
                      <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentBonus}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className='p-0 border-0 bg-white'>
                <Button color='primary-two' onClick={() => props.history.push('/deposit')} className='w-100'><FormattedMessage id="PROFILE_ADD_CASH" /></Button>
              </CardFooter>
            </Card>
          </>
          : ''
        }
        {sortSlide
          ? <>
            <div className="s-team-bg" onClick={() => setSortSlide(false)}></div>
            <Card className={classNames('filter-card', { show: sortSlide })}>
              <CardHeader className='d-flex align-items-center justify-content-between'>
                <button onClick={() => {
                  setSortSlide(false)
                  setSort(Sort)
                }}><i className='icon-left'></i><FormattedMessage id="League_sortBy" /></button>
              </CardHeader>
              <CardBody>
                <ul className='m-0 d-flex align-item-center flex-wrap'>
                  <li>
                    <input type='radio' id='Popularity' name='sort' className='d-none' defaultChecked={Sort === 'Popularity'} />
                    <label htmlFor='Popularity' value='Popularity' onClick={() => setSort('Popularity')}><FormattedMessage id="League_popularity" /><i className='icon-checked'></i></label>
                  </li>
                  <li>
                    <input type='radio' id='Spots' name='sort' className='d-none' defaultChecked={Sort === 'Spots'} />
                    <label htmlFor='Spots' value='Spots' onClick={() => setSort('Spots')}><FormattedMessage id="League_spots" /><i className='icon-checked'></i></label>
                  </li>
                  <li>
                    <input type='radio' id='Winner' name='sort' className='d-none' defaultChecked={Sort === 'Winner'} />
                    <label htmlFor='Winner' value='Winner' onClick={() => setSort('Winner')}><FormattedMessage id="League_winners" /><i className='icon-checked'></i></label>
                  </li>
                  <li>
                    <input type='radio' id='Entry' name='sort' className='d-none' defaultChecked={Sort === 'Entry'} />
                    <label htmlFor='Entry' value='Entry' onClick={() => setSort('Entry')}><FormattedMessage id="League_entry" /><i className='icon-checked'></i></label>
                  </li>
                  <li>
                    <input type='radio' id='PrizePool' name='sort' className='d-none' defaultChecked={Sort === 'Prize-Pool'} />
                    <label htmlFor='PrizePool' value='Prize Pool' onClick={() => setSort('Prize-Pool')}><FormattedMessage id="League_prizePoll" /><i className='icon-checked'></i></label>
                  </li>
                </ul>
              </CardBody>
              <CardFooter className='p-0 border-0 bg-trnsparent'>
                <Button color='primary' onClick={() => close(Sort)} className='w-100'><FormattedMessage id="League_applyNow" /></Button>
              </CardFooter>
            </Card>
          </>
          : ''
        }
        {
          activeTab === 1 && (
            <button onClick={() => {
              setFilterSlide(true)

              setFilter(Filterd)
              setFilterEntry(FilterdEntry)
              setFilterNoOfTeam(FilterdNoOfTeam)
              setFilterPrizePool(FilterdPrizePool)
              setFilterCategory(FilterdCategory)
            }} className='bottomRight-btn'><img src={IconSettings} />{(FilterEntry.length !== 0 || FilterNoOfTeam.length !== 0 || FilterPrizePool.length !== 0 || FilterCategory.length !== 0) && <div className='active'></div>}</button>
          )
        }
      </Fragment>
    </>
  )
}
LeaguesPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sportsType: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      activeTab: PropTypes.number,
      tab: PropTypes.number,
      message: PropTypes.string,
      selectTeamModal: PropTypes.bool
    }),
    search: PropTypes.string,
    pathname: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  resStatus: PropTypes.bool,
  resMessage: PropTypes.string,
  leagueList: PropTypes.func,
  list: PropTypes.array,
  listed: PropTypes.array,
  Filterd: PropTypes.array,
  FilterdEntry: PropTypes.array,
  FilterdNoOfTeam: PropTypes.array,
  FilterdPrizePool: PropTypes.array,
  FilterdCategory: PropTypes.array,
  sorted: PropTypes.string,
  Sort: PropTypes.string,
  Filter: PropTypes.array,
  sortSlide: PropTypes.bool,
  loading: PropTypes.bool,
  filterSlide: PropTypes.bool,
  setFilterSlide: PropTypes.func,
  onGetUserProfile: PropTypes.func,
  userInfo: PropTypes.object,
  FilterEntry: PropTypes.array,
  setFilterEntry: PropTypes.func,
  FilterNoOfTeam: PropTypes.array,
  setFilterNoOfTeam: PropTypes.func,
  FilterPrizePool: PropTypes.array,
  setFilterPrizePool: PropTypes.func,
  FilterCategory: PropTypes.array,
  setFilterCategory: PropTypes.func,
  setSortSlide: PropTypes.func,
  setPaymentSlide: PropTypes.func,
  setSort: PropTypes.func,
  type: PropTypes.bool,
  ApplyFilter: PropTypes.func,
  changeType: PropTypes.func,
  close: PropTypes.func,
  activeTab: PropTypes.number,
  message: PropTypes.string,
  modalMessage: PropTypes.bool,
  paymentSlide: PropTypes.bool,
  toggle: PropTypes.func,
  setFilter: PropTypes.func,
  getMyTeamList: PropTypes.func,
  getMyContestsList: PropTypes.func,
  amountData: PropTypes.shape({
    oValue: PropTypes.shape({
      nAmount: PropTypes.number
    })
  }),
  matchDetails: PropTypes.shape({
    bLineupsOut: PropTypes.bool,
    sFantasyPost: PropTypes.string,
    _id: PropTypes.string,
    sName: PropTypes.string
  }),
  matchTipsDetails: PropTypes.shape({
    sTitle: PropTypes.string,
    sContent: PropTypes.string
  }),
  teamList: PropTypes.array,
  VideoStream: PropTypes.bool,
  currencyLogo: PropTypes.string,
  contestJoinListCount: PropTypes.number,
  teamListCount: PropTypes.number,
  setFilterdEntry: PropTypes.func,
  setFilterdNoOfTeam: PropTypes.func,
  teamResStatus: PropTypes.bool,
  getTeamPlayerList: PropTypes.func,
  setModalMessage: PropTypes.func,
  fetchAutoPickData: PropTypes.func,
  autoPickAnimation: PropTypes.bool,
  setAutoPickAnimation: PropTypes.func
}
export default LeaguesList(LeaguesPage)
