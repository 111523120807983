import React from 'react'
import PropTypes from 'prop-types'
import UserHeader from '../../User/components/UserHeader'
import JoinContestPage from './JoinContest'
import { FormattedMessage } from 'react-intl'
import qs from 'query-string'

function JoinContest (props) {
  const { match, location } = props
  const obj = qs.parse(location.search)

  return (
    <>
      <UserHeader {...props} title={<FormattedMessage id='Join_contest' />} backURL={obj?.toHome === 'yes' ? '/home/cricket' : obj?.homePage ? `/leagues/${match.params.sportsType}/${match.params.id}?homePage=yes` : `/leagues/${match.params.sportsType}/${match.params.id}`}/>
      <JoinContestPage {...props}/>
    </>
  )
}

JoinContest.propTypes = {
  match: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  })
}

export default JoinContest
