/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment, useRef, Suspense } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Card, CardBody, CardFooter, CardHeader, Alert, Form, FormGroup, Input, Label, Table } from 'reactstrap'
import { verifyLength, isUpperCase, currencyConvert, lowCrownPost, pageViewPost } from '../../../helper'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Loading from '../../../component/Loading'
import MyTeam from '../components/MyTeam'
import { joinPrivateContest, joinTeam } from '../../../Analytics.js'
import PrivateJoinContest from '../../../HOC/SportsLeagueList/PrivateJoinContest'
import qs from 'query-string'
import close from '../../../assests/images/close.svg'
import walletIcon from '../../../assests/images/ic_wallet.svg'
import CrownUtils from '../../../assests/images/crown-utils.svg'
import classNames from 'classnames'
function JoinContestPage (props) {
  const {
    resMessage,
    verifyContestDetails,
    verifyingContest,
    isTeamList,
    match,
    joiningContest,
    modalOpen,
    joinedContest,
    sucessFullyJoin,
    updatedTeamList,
    loading,
    amountData,
    verifiedId,
    location,
    onMyJoinList,
    teamPlayerList,
    onFetchMatchPlayer,
    token,
    currencyLogo,
    onGetUserProfile,
    teamList,
    userInfo,
    setVerifiedId
  } = props
  const [alert, setAlert] = useState(false)
  const [code, setCode] = useState('')
  const [sportType, setSportType] = useState('')
  const [matchId, setMatchId] = useState('')
  const [errCode, setErrCode] = useState('')
  const [userTeams, setUserTeams] = useState([])
  const [modalMessage, setModalMessage] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)
  const [modalMessage2, setModalMessage2] = useState(false)
  const [totalPay, setTotalPay] = useState(0)
  const [noOfJoin, setNoOfJoin] = useState('')
  const [alertMessage, setAlertMessage] = useState('')
  const [updatedFilterData, setUpdatedFilterData] = useState([])
  const [topData, setTopData] = useState([])
  const [bottomData, setBottomData] = useState([])
  const mainTeamList = useSelector(state => state.team.teamList)
  const previousProps = useRef({ verifyContestDetails, updatedTeamList, joinedContest, sucessFullyJoin, amountData }).current

  useEffect(() => {
    const obj = qs.parse(location.search)
    if (match && match.params && match.params.id) {
      setMatchId(match && match.params && match.params.id)
    } else {
      if (obj.matchId) {
        setMatchId(obj.matchId)
      }
    }
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      setSportType(sport)
    } else {
      if (obj.sportsType) {
        const sport = obj.sportsType
        setSportType(sport)
      }
    }
    if (props?.location?.state?.selectTeamModal) {
      setModalMessage(true)
    }
    if (props?.location?.state?.joinContestCode) {
      setCode(props?.location?.state?.joinContestCode)
    }
    if (props?.location?.state?.verifiedId) {
      setVerifiedId(props?.location?.state?.verifiedId)
    }
    pageViewPost('pct_jn')
  }, [])
  useEffect(() => {
    const obj = qs.parse(location.search)
    if (obj) {
      if (obj.code) {
        setCode(obj.code)
      }
      if (obj.matchId && obj.sportsType) {
        const sport = obj.sportsType
        setSportType(sport)
        setMatchId(obj.matchId)
        props.history.push(`/join-contest/${sport.toLowerCase()}/${obj.matchId}?code=${code}`)
      }
    }
    if (match && match.params && match.params.id && (teamPlayerList?.length === 0 || !teamPlayerList)) {
      setMatchId(match && match.params && match.params.id)
      onFetchMatchPlayer(match && match.params && match.params.id)
    }
    if (obj && obj.matchId && (teamPlayerList?.length === 0 || !teamPlayerList)) {
      onFetchMatchPlayer(obj && obj.matchId)
    }
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      setSportType(sport)
      isUpperCase(sport) && props.history.push(`/join-contest/${sport.toLowerCase()}/${match.params.id}`)
    }
    onGetUserProfile()
  }, [token, match.params.id])

  useEffect(() => {
    if (modalMessage) {
      onMyJoinList(matchId)
    }
  }, [modalMessage, matchId])

  useEffect(() => {
    if (selectedAll) {
      let updatedSelectedTeam = []
      if (mainTeamList && mainTeamList.length > 0) {
        updatedSelectedTeam = mainTeamList.map(data => data._id)
        setUserTeams(updatedSelectedTeam)
      }
    } else {
      setUserTeams([])
    }
  }, [selectedAll])

  useEffect(() => {
    if (previousProps.sucessFullyJoin !== sucessFullyJoin) {
      if (sucessFullyJoin) {
        props.history.push(`/leagues/${(sportType).toLowerCase()}/${matchId}?activeTab=2`, { message: resMessage })
      }
    }
    return () => {
      previousProps.sucessFullyJoin = sucessFullyJoin
    }
  }, [sucessFullyJoin])

  useEffect(() => {
    if (previousProps.verifyContestDetails !== verifyContestDetails) {
      if (verifyContestDetails && verifyContestDetails._id) {
        if (isTeamList) {
          setModalMessage(true)
        } else {
          props.history.push({
            pathname: `/create-team/${(sportType).toLowerCase()}/${matchId}/join/${verifyContestDetails._id}/private/${verifyContestDetails.sShareCode}`,
            state: {
              joinPrivateContest: true,
              leagueId: verifyContestDetails?._id,
              shareCode: verifyContestDetails?.sShareCode,
              backToJoinContest: props.location.pathname.includes('/join-contest/'),
              joinContestCode: code,
              verifiedId: verifiedId,
              selectTeamModal: true,
              joinTeamCreation: true,
              isJoinContest: true
            }
          })
        }
      }
    }
    return () => {
      previousProps.verifyContestDetails = verifyContestDetails
    }
  }, [verifyContestDetails])
  useEffect(() => {
    if (previousProps.amountData !== amountData) {
      if (amountData && amountData?.oValue?.nAmount > 0) {
        props.history.push({
          pathname: '/deposit',
          state: {
            amountData: amountData?.oValue,
            message: 'Insufficient Balance',
            joinData: {
              userTeams, verifiedId, code
            }
          }
        })
      }
    }
    return () => {
      previousProps.amountData = amountData
    }
  }, [amountData])

  useEffect(() => {
    if (previousProps.updatedTeamList !== updatedTeamList) {
      if (updatedTeamList !== null && updatedTeamList.length === 0 && verifyContestDetails?._id && match?.params?.id && code) {
        props.history.push({
          pathname: `/create-team/${(sportType).toLowerCase()}/${matchId}/join/${verifiedId}/private/${verifyContestDetails.sShareCode}`,
          state: {
            joinPrivateContest: true,
            leagueId: verifyContestDetails?._id,
            shareCode: verifyContestDetails?.sShareCode,
            backToJoinContest: props.location.pathname.includes('/join-contest/'),
            joinContestCode: code,
            verifiedId: verifiedId,
            selectTeamModal: true,
            joinTeamCreation: true,
            isJoinContest: true
          }
        })
      }
    }
    return () => {
      previousProps.updatedTeamList = updatedTeamList
    }
  }, [updatedTeamList])

  useEffect(() => {
    if (teamList && updatedTeamList) {
      if (updatedTeamList.length !== 0) {
        const bottomData = []

        teamList && teamList.length !== 0 && teamList.sort((a, b) => a.sName > b.sName ? 1 : -1).map((data, index) => {
          updatedTeamList?.map((item) => {
            if (item._id === data._id) {
              // setSwitchTeamBottomData((prev) => [...prev, data])
              bottomData.push(data)
            }
            return item
          })
          return data
        })

        const unique1 = teamList.filter((o) => updatedTeamList.indexOf(o) === -1)
        const unique2 = updatedTeamList.filter((o) => teamList.indexOf(o) === -1)

        const unique = unique1.concat(unique2)

        setTopData(unique)
        setBottomData(bottomData)
      } else {
        setTopData([])
        setBottomData(teamList)
      }
    }
  }, [updatedTeamList, teamList])

  // useEffect(() => {
  //   if (contestJoinList && data && teamList) {
  //     const contestData = insideleagueDetailsPage
  //       ? joinDetails
  //       : contestJoinList && contestJoinList.length !== 0 && contestJoinList.find(contest => contest && contest.iMatchLeagueId === data._id)
  //     const FilterTeam = contestData && contestData.aUserTeams && contestData.aUserTeams.length > 0
  //       ? teamList && teamList.length !== 0 && teamList.filter(team => contestData && contestData.aUserTeams && !contestData.aUserTeams.includes(team._id))
  //       : teamList
  //     if (contestData) {
  //       setUpdatedTeamList(FilterTeam)
  //     }
  //   }
  // }, [contestJoinList, data, teamList, joinDetails])

  useEffect(() => {
    if (bottomData?.length > 0) {
      setUserTeams([bottomData[0]?._id])
    }
  }, [bottomData])

  function handleChangeJoin (event, type) { // set the value
    switch (type) {
      case 'Code':
        if (verifyLength(event.target.value, 1)) {
          setErrCode('')
        } else {
          if (!verifyLength(event.target.value, 1)) {
            setErrCode(<FormattedMessage id="JOIN_ERR" />)
          }
        }
        setCode(event.target.value)
        break
      default:
        break
    }
  }

  function JoinContest () { // join contest
    setModalMessage2(false)
    if (userTeams && userTeams.length > 0 && verifyLength(code, 1) && verifiedId) {
      joiningContest(userTeams, verifiedId, code)
      callJoinContestEvent()
      callJoinTeamEvent()
      setModalMessage(false)
    } else {
      if (!verifyLength(code, 1)) {
        setErrCode(<FormattedMessage id="JOIN_ERR" />)
      }
    }
  }

  function checkTeamJoin (e) {
    e.preventDefault()
    if (verifyLength(code, 1) && match && match.params && matchId) {
      verifyingContest(matchId, code)
      onMyJoinList(matchId)
    }
  }

  function callJoinContestEvent () {
    if (code && match && match.params && matchId && location.pathname) {
      joinPrivateContest(code, matchId, location.pathname)
    } else {
      code && match && match.params && matchId && joinPrivateContest(code, matchId, '')
    }
  }

  function callJoinTeamEvent () {
    if (userTeams && match && match.params && matchId && location.pathname) {
      joinTeam(userTeams, matchId, location.pathname)
    } else {
      userTeams && match && match.params && matchId && joinTeam(userTeams, matchId, '')
    }
  }

  useEffect(() => {
    if (userInfo !== previousProps.userInfo) {
      if (userInfo && userInfo.nCurrentTotalBalance) {
        const value = verifyContestDetails && updatedFilterData && ((verifyContestDetails.nPrice * updatedFilterData.length) - (userInfo && userInfo.nCurrentTotalBalance))
        setTotalPay(value > 0 ? value.toFixed(2) : 0)
      }
    }
    return () => {
      previousProps.userInfo = userInfo
    }
  }, [userInfo])

  useEffect(() => {
    if (verifyContestDetails) {
      setNoOfJoin(verifyContestDetails.bPrivateLeague && verifyContestDetails.bMultipleEntry ? verifyContestDetails.nMax - verifyContestDetails.nJoined : verifyContestDetails.nTeamJoinLimit)
    }
  }, [verifyContestDetails])

  function clearCode () {
    setCode('')
  }

  function copyCode () {
    navigator.clipboard.readText().then((e) => setCode(e))
  }

  return (
    <>
      {alert && alertMessage ? <Alert isOpen={alert} className='select-all' color="primary">{alertMessage}</Alert> : ''}
      {loading && <Loading />}
      {
        modalOpen && resMessage
          ? <Fragment>
            <Alert isOpen={modalOpen} color="primary">{resMessage}</Alert>
          </Fragment>
          : ''
      }
      <div className="user-container join-contest bg-white no-footer">
        <Form>
          <p className="m-msg mt-0 mb-3"><FormattedMessage id="Contest_inviteCodetoJoinContest" /></p>
          <FormGroup>
            <Input
              type="text"
              autoComplete='off'
              maxLength={10}
              id="InviteCode"
              onChange={(e) => { handleChangeJoin(e, 'Code') }}
              value={code}
              placeholder='Enter invite code…'
              // className={classNames({ 'hash-contain': code, error: errCode }) }
            />
            <Button
              type='button'
              className='formButton'
              color='primary'
              onClick={code ? clearCode : copyCode}
            >
              {code ? <FormattedMessage id='League_Clear' /> : <FormattedMessage id='Join_Paste' />}
            </Button>
            {/* <Label className="label m-0" for="InviteCode"><FormattedMessage id="Contest_inviteCode" /></Label> */}
          </FormGroup>
          <p className="error-text mt-1">{errCode}</p>
          <div className="btn-bottom text-center">
            <Button
              type="submit"
              disabled={!code || errCode}
              color="primary"
              className="w-100 d-block"
              onClick={(e) => checkTeamJoin(e)}
            >
              <FormattedMessage id="Contest_joinContest" />
            </Button>
          </div>
        </Form>
        {
          modalMessage
            ? <>
              <div className="s-team-bg" onClick={() => {
                setModalMessage(false)
                setSelectedAll(false)
              }}></div>
              <Card className="filter-card select-team switch-team-popup">
                <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                  <h2 onClick={() => {
                    setModalMessage(false)
                    setSelectedAll(false)
                  }}> <span className='icon-left-arrow mr-2'></span> <FormattedMessage id="Team_SelectTeam" /></h2>
                  {/* <button><FormattedMessage id="Team_SelectTeam" /></button>
                  <button onClick={() => {
                    setModalMessage(false)
                    setSelectedAll(false)
                  }} ><img src={close}></img></button> */}
                </CardHeader>
                <CardBody className="p-0">
                  <div className="popup-content max-height100">
                    {/* <div className="two-button border-0 bg-white m-0 d-flex justify-content-between card-footer">

                      <Button type="submit" className='create-contest-button' onClick={() => props.history.push({
                        pathname: `/create-contest/${(match.params.sportsType).toLowerCase()}/${match.params.id}`,
                        search: `?${qs.stringify({
                          homePage: obj?.homePage ? 'yes' : undefined
                        })}`
                      })}>
                        <img src={createContest} width={20} className='mr-2' alt={<FormattedMessage id='Team_CreatePrivateLeague' />}></img>
                        <FormattedMessage id="Team_CreatePrivateLeague" />
                      </Button>
                    </div> */}
                    {topData && topData.length !== 0 &&
                      <div className='already-join'>
                        <h2><FormattedMessage id="Already_Joined" /></h2>
                            {topData.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                              return (
                                <Suspense key={data1._id} fallback={<Loading />}>
                                  <div className='switch-team-wrap'>
                                  <MyTeam {...props}
                                    join
                                    upcoming
                                    disabledRadio={updatedTeamList?.find((item) => item._id === data1._id)?._id}
                                    disabledRadioFlag={updatedTeamList?.length !== 0}
                                    teamId={data1._id}
                                    // leagueData={data}
                                    noOfJoin={noOfJoin}
                                    userTeams={userTeams}
                                    selectModal={modalMessage}
                                    code={code}
                                    verifiedId={verifiedId}
                                    setUserTeams={(Id) => {
                                      // setUserTeams(Id)
                                      // setUserTeamId(Id)
                                      // setSelectedAll(false)
                                    }}
                                    params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={teamList} UserTeamChoice teamPlayerList={teamPlayerList} />
                                    </div>
                                </Suspense>
                              )
                            })}
                      </div>
                    }
                    {bottomData.length !== 0 &&
                    <div className="chooseteam-to-join">
                      <FormattedMessage id="Select_from_your_existing_teams" />
                    </div>}

                    {/* {data && data.bMultipleEntry && bottomData.length !== 0 && ((UpdatedTeamList && UpdatedTeamList.length !== 1) && (teamList && teamList.length !== 1)) && <div className='SelectAll Radio-MyTeam d-flex align-items-center'>
                        <input type="radio" id='name' name="gender" onClick={() => setSelectedAll(!selectedAll)}
                            checked={selectedAll}
                        />
                        <label htmlFor='name'><FormattedMessage id="Team_SelectAll" /> </label>
                      </div>} */}
                      {bottomData && bottomData.length !== 0 &&
                      bottomData.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                        return (
                          <Suspense key={data1._id} fallback={<Loading />}>
                            <div className='switch-team-wrap'>
                            <MyTeam {...props}
                              join
                              upcoming
                              disabledRadio={updatedTeamList?.find((item) => item._id === data1._id)?._id}
                              disabledRadioFlag={updatedTeamList?.length !== 0}
                              teamId={data1._id}
                              // leagueData={data}
                              noOfJoin={noOfJoin}
                              userTeams={userTeams}
                              selectModal={modalMessage}
                              code={code}
                              verifiedId={verifiedId}
                              setUserTeams={(Id) => {
                                setUserTeams(Id)
                                // setUserTeamId(Id)
                                setSelectedAll(false)
                              }}
                              params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={teamList} UserTeamChoice teamPlayerList={teamPlayerList} />
                              </div>
                          </Suspense>
                        )
                      })}
                      {/* {
                        teamList && teamList.length !== 0 &&
                          teamList.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                            return (
                              <Suspense key={data1._id} fallback={<Loading />}>
                                <div className='switch-team-wrap'>
                                <MyTeam {...props}
                                  join
                                  upcoming
                                  disabledRadio={UpdatedTeamList?.find((item) => item._id === data1._id)?._id}
                                  disabledRadioFlag={UpdatedTeamList?.length !== 0}
                                  teamId={data1._id}
                                  leagueData={data}
                                  noOfJoin={noOfJoin}
                                  userTeams={userTeams}
                                  setUserTeams={(Id) => {
                                    setUserTeams(Id)
                                    setUserTeamId(Id)
                                    setSelectedAll(false)
                                  }}
                                  params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={teamList} UserTeamChoice teamPlayerList={teamPlayerList} />
                                  </div>
                              </Suspense>
                            )
                          })
                      } */}
                  </div>
                </CardBody>
                {userInfo?.nCurrentCrown < (userTeams?.length ? (verifyContestDetails?.nCrownUtil * userTeams?.length) : verifyContestDetails?.nCrownUtil) &&
                  <div className="lowbalance">
                    <div className="lowbalance-text">
                        <img src={walletIcon} alt="" />
                        <div>
                          <FormattedMessage id="Low_crowns_balance" />
                          <span className="need-crown">{currencyConvert((verifyContestDetails?.nCrownUtil * userTeams?.length) - userInfo?.nCurrentCrown)}</span>
                          <FormattedMessage id="Low_crowns_balance_more" />
                        </div>
                    </div>
                    <div className="balance-btn btn btn-primary" onClick={() => lowCrownPost()}>
                        <img src={CrownUtils} className="mr-1" alt="" />
                        <FormattedMessage id="Earn_More" />
                    </div>
                  </div>
                }
                <CardFooter className='btn-bottom border-0 m-0 position-relative'>
                  <div className="row">
                    <div className="col-6 pr-2">
                      <Button type="submit" color="border" className='w-100' onClick={() => props.history.push({
                        pathname: `/create-team/${(sportType).toLowerCase()}/${matchId}/join/${verifiedId}/private/${verifyContestDetails?.sShareCode}`,
                        state: {
                          joinPrivateContest: true,
                          leagueId: verifyContestDetails?._id,
                          shareCode: verifyContestDetails?.sShareCode,
                          backToJoinContest: props.location.pathname.includes('/join-contest/'),
                          joinContestCode: code,
                          verifiedId: verifiedId,
                          selectTeamModal: true,
                          joinTeamCreation: true,
                          isJoinContest: true
                        }
                      })}><FormattedMessage id="Team_CreateTeam" /></Button>
                    </div>
                    <div className="col-6 pl-2">
                      <Button type="submit" color='primary' className='w-100'
                        disabled={(userTeams && userTeams.length === 0) || (userInfo?.nCurrentCrown < (userTeams?.length ? (verifyContestDetails?.nCrownUtil * userTeams?.length) : verifyContestDetails?.nCrownUtil))}
                        onClick={() => {
                          if ((verifyContestDetails && userTeams) && userTeams.length > (verifyContestDetails.nMax - verifyContestDetails.nJoined)) {
                            setAlert(true)
                            setAlertMessage(<p><FormattedMessage id="Team_Title1" /> {verifyContestDetails.nMax - verifyContestDetails.nJoined || '-'} <FormattedMessage id="Team_Title2" /></p>)
                            setTimeout(() => {
                              setAlertMessage('')
                              setAlert(false)
                            }, 2000)
                            setUserTeams([])
                          } else if (updatedTeamList?.length >= userTeams.length) {
                            const filterData = updatedTeamList.filter(data => userTeams.includes(data._id))
                            // onGetUserProfile()
                            setUpdatedFilterData(filterData)
                            // setModalMessage2(true)
                            JoinContest()
                            setModalMessage(false)
                          } else {
                            setAlert(true)
                            setAlertMessage(<p><FormattedMessage id="Team_Title1" /> {noOfJoin || '-'} <FormattedMessage id="Team_Title2" /></p>)
                            setTimeout(() => {
                              setAlertMessage('')
                              setAlert(false)
                            }, 2000)
                            setUserTeams([])
                          }
                        }}
                      >
                        <FormattedMessage id="League_join" />
                        {' '}
                        {(verifyContestDetails?.nCrownUtil || verifyContestDetails?.nPrice) && !props?.location?.state?.editTeam && !props?.location?.state?.withoutCurrency
                          ? verifyContestDetails?.nCrownUtil && verifyContestDetails?.nCrownUtil !== 0
                            ? <><img src={CrownUtils} width="12" style={{ marginTop: '-2px' }}/> {currencyConvert(verifyContestDetails?.nCrownUtil * userTeams.length)}</>
                            : <>{verifyContestDetails?.nPrice
                              ? `${currencyLogo}${currencyConvert(verifyContestDetails?.nPrice * userTeams.length)}`
                              : <><img src={CrownUtils} width="12" style={{ marginTop: '-2px' }}/> {currencyConvert(verifyContestDetails?.nPrice * userTeams.length)}</>
                        }</>
                          : ''
                    }
                      </Button>

                    </div>
                  </div>
                  {/* <Button type="submit" color='border' className="w-100" onClick={() => props.history.push(`/create-team/${(sportType).toLowerCase()}/${matchId}/join/${verifiedId}/private/${verifyContestDetails.sShareCode}`)}><FormattedMessage id="Team_CreateTeam" /></Button> */}
                </CardFooter>
              </Card>
            </>
            : modalMessage2
              ? <>
              {loading && <Loading />}
              <div className="s-team-bg" onClick={() => setModalMessage2(false)}></div>
              <Card className="filter-card select-team promo-card">
                <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                  <button><FormattedMessage id="League_Payment" /></button>
                  <button onClick={() => { setModalMessage2(false) }} ><img src={close}></img></button>
                </CardHeader>
                <CardBody className="p-0 teamXShawing">
                  <div className='teamJoin'>
                    {
                      updatedFilterData && updatedFilterData.length && (
                        <h3>
                          {updatedFilterData.length} <FormattedMessage id="League_Teams_Selected" />
                        </h3>
                      )
                    }
                  </div>
                  <div className='selectedTeamList'>
                    {
                      updatedFilterData && updatedFilterData.length !== 0
                        ? updatedFilterData.sort((a, b) => a?.sTeamName?.toString().localeCompare(b?.sTeamName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                          return (
                            <Suspense key={data1._id} fallback={<Loading />}>
                              <MyTeam {...props} upcoming
                                noOfJoin={noOfJoin}
                                params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={updatedFilterData} UserTeamChoice viewOnly code={code} />
                            </Suspense>
                          )
                        })
                        : ''
                    }
                  </div>
                  <Table className="m-0 bg-white promocode">
                    <thead>
                      <tr>
                        <th><FormattedMessage id="League_Total_Entry" /></th>
                        <th className='rightAlign'>{currencyLogo}{verifyContestDetails && updatedFilterData && verifyContestDetails.nPrice * updatedFilterData.length} ( {verifyContestDetails && updatedFilterData && `${verifyContestDetails.nPrice} X ${updatedFilterData.length}`} )</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><FormattedMessage id="League_In_Wallet" /></td>
                        <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentTotalBalance ? userInfo.nCurrentTotalBalance : 0}</td>
                      </tr>
                    </tbody>
                    {
                      totalPay && currencyLogo
                        ? (
                        <tfoot>
                          <tr>
                            <td><h1><FormattedMessage id="League_To_Pay" /></h1></td>
                            <td className='rightAlign'>{currencyLogo}
                              {totalPay}</td>
                          </tr>
                        </tfoot>
                          )
                        : ''
                    }
                  </Table>
                </CardBody>
                <CardFooter className='p-0 border-0 bg-trnsparent m-0 d-flex justify-content-between'>
                  <Button type="submit" color='primary' className="w-100"
                    disabled={userTeams && userTeams.length === 0}
                    onClick={() => JoinContest(userTeams)}>
                    {
                      totalPay > 0
                        ? <FormattedMessage id="User_AddMoney" />
                        : <FormattedMessage id="League_join" />
                    }
                  </Button>
                </CardFooter>
              </Card>
            </>
              : ''
        }
      </div>
    </>
  )
}

JoinContestPage.propTypes = {
  isTeamList: PropTypes.bool,
  loading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.object,
    url: PropTypes.string
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  amountData: PropTypes.shape({
    oValue: PropTypes.shape({
      nAmount: PropTypes.number
    })
  }),
  verifyingContest: PropTypes.func,
  joiningContest: PropTypes.func,
  resMessage: PropTypes.string,
  onFetchMatchPlayer: PropTypes.func,
  modalOpen: PropTypes.bool,
  joinedContest: PropTypes.bool,
  sucessFullyJoin: PropTypes.bool,
  verifyContestDetails: PropTypes.object,
  modalMessage: PropTypes.string,
  token: PropTypes.string,
  verifiedId: PropTypes.string,
  updatedTeamList: PropTypes.array,
  teamList: PropTypes.array,
  onMyJoinList: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  }),
  teamPlayerList: PropTypes.shape({
    length: PropTypes.number
  }),
  currencyLogo: PropTypes.string,
  userInfo: PropTypes.object,
  onGetUserProfile: PropTypes.func,
  setVerifiedId: PropTypes.func
}

export default PrivateJoinContest(JoinContestPage)
