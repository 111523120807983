/* eslint-disable react/jsx-key */
import React, { useEffect, useState, Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import {
  Button, Card, CardBody, CardFooter, CardHeader, CarouselControl, Carousel, CarouselItem
} from 'reactstrap'
import {
  TelegramShareButton, LinkedinShareButton, LinkedinIcon, TelegramIcon, WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon, FacebookMessengerShareButton, FacebookMessengerIcon, TwitterShareButton, TwitterIcon
} from 'react-share'
import { Link } from 'react-router-dom'
import config from '../../../config'
import PlayerImage from '../../../assests/images/PlayerImage.svg'
import TeamList from '../../../HOC/SportsLeagueList/TeamList'
import { allSportsRoles, handleImgError, pageViewPost, shareTeamPost } from '../../../helper'
import Loading from '../../../component/Loading'
import TeamPlayerInfo from '../TeamPlayerInfo/index'
import TeamPlayerLeagueInfo from '../TeamPlayerLeagueInfo/index'
// import { cricketStaticImage, footballStaticImage, allSportsRoles } from '../../../helper'
import qs from 'query-string'
import close from '../../../assests/images/close.svg'
import closeIcon from '../../../assests/images/close-icon.svg'
import CaptainIcon from '../../../assests/images/captain-team-icon.svg'
import ViceCaptainIcon from '../../../assests/images/viceCaptain-team-icon.svg'
import HomeTeam from '../../../assests/images/Team1.png'
import AwayTeam from '../../../assests/images/Team2.png'
import Edit from '../../../assests/images/my-team-edit.svg'
import ShareTeamIcon from '../../../assests/images/my-team-share.svg'
import PointsIcon from '../../../assests/images/points-icon.svg'
import moment from 'moment'

const classNames = require('classnames')

function PreviewCricket (props) {
  const { location, allLeaderBoardList, myTeamsLeaderBoardList, getMyTeamLeaderBoardListFunc, myAllTeamPagination, url, match, index, sportsType, getMatchPlayerList, loading, teamPreview, getUserTeam, userTeam, matchDetails, playerRoles, teamPlayerList, shareTeamFun, shareTeamResStatus, shareTeamRefferalCode, token } = props
  const [allTeams, setAllTeams] = useState([])
  const [team, setTeams] = useState([])
  const [AllLeaderBoardList, setAllLeaderBoardList] = useState([])
  const [matchType, setMatchType] = useState('')
  const [ShareTeam, setShareTeam] = useState(false)
  const [teamView, setTeamView] = useState(index || 0)
  // const [viewTeam, setViewTeam] = useState([])
  const [teamDetails, setTeamDetails] = useState([])
  const [totalCredit, setTotalCredit] = useState(0)
  // const [totalScorePoints, setTotalScorePoints] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [value] = useState(0)
  const [teamListCount, setTeamListCount] = useState([])

  const [playerId, setPlayerId] = useState('')
  const [playerInfo, setPlayerInfo] = useState(false)
  const [playerLeagueInfo, setPlayerLeagueInfo] = useState(false)

  const limit = location?.state?.allUserLeagues || 0
  const obj = qs.parse(props.location.search)
  const previousProps = useRef({ userTeam, team, allTeams, allLeaderBoardList, myTeamsLeaderBoardList }).current

  // useEffect(() => {
  //   if (myTeamPreViewUpcoming && userTeamId) {
  //     getUserTeam(userTeamId)
  //     setLoading(true)
  //   } else {
  //     changeTeam(teamView)
  //   }
  // }, [myTeamPreViewUpcoming, userTeamId])

  useEffect(() => {
    pageViewPost('tm')
  }, [])

  useEffect(() => {
    if (token && match && match.params && match.params.userLeagueID) {
      myAllTeamPagination(limit, value, match.params.userLeagueID)
      getMyTeamLeaderBoardListFunc(match.params.userLeagueID)
    }
    if (token && match && match.params && match.params.matchID) {
      getMatchPlayerList(match.params.matchID)
    }
  }, [token])

  useEffect(() => {
    if (token && match && match.params && match.params.userTeamID) {
      getUserTeam(match.params.userTeamID)
    }
    if (token && match && match.params && match.params.index) {
      setTeamView(parseInt(match.params.index))
    }
  }, [match.params, token])

  useEffect(() => {
    if (previousProps.allLeaderBoardList !== allLeaderBoardList) {
      if (allLeaderBoardList && allLeaderBoardList.length !== 0 && myTeamsLeaderBoardList) {
        const myTeams = myTeamsLeaderBoardList.map(data => data._id)
        const allUsers = allLeaderBoardList?.filter(data => !myTeams.includes(data._id))
        setAllLeaderBoardList(AllLeaderBoardList => [...AllLeaderBoardList, ...allUsers])
      }
    }
    return () => {
      previousProps.allLeaderBoardList = allLeaderBoardList
      previousProps.myTeamsLeaderBoardList = myTeamsLeaderBoardList
    }
  }, [allLeaderBoardList, myTeamsLeaderBoardList])

  useEffect(() => {
    if (matchType === 'U' && myTeamsLeaderBoardList && myTeamsLeaderBoardList.length !== 0) {
      const allUserLeagues = [...myTeamsLeaderBoardList.sort((a, b) => b.nTotalPoints - a.nTotalPoints)]
      setAllTeams(allUserLeagues)
    } else if (matchType !== 'U' && myTeamsLeaderBoardList && myTeamsLeaderBoardList.length !== 0 && AllLeaderBoardList && AllLeaderBoardList.length !== 0) {
      const allUserLeagues = [...myTeamsLeaderBoardList.sort((a, b) => b.nTotalPoints - a.nTotalPoints), ...AllLeaderBoardList.sort((a, b) => b.nTotalPoints - a.nTotalPoints)]
      setAllTeams(allUserLeagues)
    }
  }, [AllLeaderBoardList, myTeamsLeaderBoardList, matchType])

  useEffect(() => { // handle the response
    if (previousProps.value !== value) {
      if (value) {
        myAllTeamPagination(limit, value, match.params.userLeagueID)
        getMyTeamLeaderBoardListFunc(match.params.userLeagueID)
      }
    }
    return () => {
      previousProps.value = value
    }
  }, [value])

  useEffect(() => {
    if (shareTeamResStatus) {
      const startDate = moment(matchDetails?.dStartDate).format('X')
      shareTeamPost(matchDetails?._id, userTeam?._id, match?.params?.sportsType, startDate, matchDetails?.oHomeTeam?.sShortName, matchDetails?.oAwayTeam?.sShortName, shareTeamRefferalCode)
    }
  }, [shareTeamResStatus])

  // useEffect(() => {
  //   const Team = []
  //   if (matchPlayer && matchPlayer.length > 0 && userTeam) {
  //     matchPlayer.forEach(player => {
  //       if (userTeam && userTeam.aPlayers?.length > 0 && userTeam.aPlayers.some(player2 => player._id === player2.iMatchPlayerId)) {
  //         Team.push(player)
  //       }
  //     })
  //   }
  //   setViewTeam(Team)
  // }, [matchPlayer, userTeam])

  const next = () => {
    if (animating) return
    const nextIndex = teamView === allTeams.length - 1 ? 0 : teamView + 1
    // setTeamView(parseInt(nextIndex))
    // changeTeam(nextIndex)
    // if (nextIndex > limit) {
    //   setValue(value => value + limit)
    // }
    const userTeamId = allTeams[nextIndex]?.iUserTeamId
    props.history.replace({
      pathname: `/team-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userLeagueID}/${userTeamId}/${nextIndex}`,
      search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined
        })}`
    })
  }

  const previous = () => {
    if (animating) return
    const nextIndex = teamView === 0 ? allTeams.length - 1 : teamView - 1
    // setTeamView(parseInt(nextIndex))
    // changeTeam(nextIndex)
    const userTeamId = allTeams[nextIndex]?.iUserTeamId
    props.history.replace({
      pathname: `/team-preview/${match.params.sportsType.toLowerCase()}/${match.params.matchID}/${match.params.userLeagueID}/${userTeamId}/${nextIndex}`,
      search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined
        })}`
    })
  }

  useEffect(() => {
    if (teamPlayerList) {
      let TotalCredits = 0
      let TotalScorePoints = 0
      if (userTeam && userTeam.length !== 0) {
        const playerRole = playerRoles?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
        const players = Object.assign({}, ...playerRole?.map(key => ({ [key]: [] })))
        const teamList = Object.assign({}, ...teamPlayerList?.map(key => ({ [key?.oTeam?.sShortName]: [] })))
        userTeam?.aPlayers?.map((Player) => {
          const PlayerDetails = teamPlayerList?.length > 0 && teamPlayerList?.find(player => player._id === Player.iMatchPlayerId)
          Object.entries(players).map(([key, value]) => {
            return (key === PlayerDetails?.eRole) && players[PlayerDetails?.eRole].push(PlayerDetails)
          })
          Object.entries(teamList).map(([key, value]) => {
            return (key === PlayerDetails?.oTeam?.sShortName) && teamList[PlayerDetails?.oTeam?.sShortName].push(PlayerDetails)
          })
          TotalCredits = TotalCredits + Player.nFantasyCredit
          setTotalCredit(TotalCredits)
          if (Player && Player.iMatchPlayerId && userTeam.iCaptainId ? Player.iMatchPlayerId === userTeam.iCaptainId : Player === userTeam.iCaptainId) {
            const newPoints = (Number(Player.nScoredPoints) * 2)
            TotalScorePoints = TotalScorePoints + newPoints
          } else if (Player && Player.iMatchPlayerId && userTeam.iViceCaptainId ? Player.iMatchPlayerId === userTeam.iViceCaptainId : Player === userTeam.iViceCaptainId) {
            const newPoints = (Number(Player.nScoredPoints) * 1.5)
            TotalScorePoints = TotalScorePoints + newPoints
          } else { TotalScorePoints = TotalScorePoints + Number(Player.nScoredPoints) }
          // setTotalScorePoints(TotalScorePoints)
          return players
        })
        setTeamDetails(userTeam)
        // if (matchType !== 'U') {
        const tempData = []
        // eslint-disable-next-line array-callback-return
        Object.entries(players).map(([key, value]) => {
          value && value.length > 0 && value.sort((a, b) => a.sName > b.sName ? 1 : -1).map(playerInfo => tempData.push(playerInfo))
        })
        // }
        setTeams(players)
        setTeamListCount(teamList)
      }
    }
    return () => {
      previousProps.userTeam = userTeam
    }
  }, [allTeams, userTeam, teamPlayerList, playerRoles])

  useEffect(() => {
    if (matchDetails) {
      // matchDetails.oHomeTeam && matchDetails.oHomeTeam.sName && setTeamName(matchDetails.oHomeTeam.sName)
      setMatchType(matchDetails.eStatus)
    }
  }, [matchDetails])

  function PlayerInfoFun (playerId) {
    setPlayerId(playerId)
    // if (matchType === 'U') {
    //   props.history.push({
    //     pathname: `/view-player-leagueInfo/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match.params.userTeamID}/${playerId}`,
    //     search: `?${qs.stringify({
    //         index: match.params.index || undefined,
    //         userLeague: match.params.userLeagueID,
    //         homePage: obj?.homePage ? 'yes' : undefined,
    //         playerLeagueInfo: 'y'
    //       })}`
    //   })
    // } else {
    //   props.history.push({
    //     pathname: `/view-player-Info/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}/${playerId}`,
    //     search: `?${qs.stringify({
    //         index: match.params.index || undefined,
    //         userLeague: match.params.userLeagueID,
    //         homePage: obj?.homePage ? 'yes' : undefined
    //       })}`
    //   })
    // }

    if (matchType === 'U') {
      setPlayerLeagueInfo(true)
    } else {
      setPlayerInfo(true)
    }
  }

  function shareTeamClick () {
    shareTeamFun(userTeam?._id)
  }

  const slides = allTeams && allTeams.length !== 0 && allTeams.map((item, index) => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={index}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <div className="ground w-100">
          {
            Object.entries(team).map(([key, value]) => {
              return (
                <div className="ground-c w-100" key={value._id || value.iMatchPlayerId}>
                  <h3>
                    {allSportsRoles(key)}
                    {/* {key === 'BATS' ? (<FormattedMessage id="Team_batsman" />) : key === 'WK' ? (<FormattedMessage id="Team_wk" />) : key === 'BWL' ? (<FormattedMessage id="Team_bowler" />) : key === 'ALLR' ? (<FormattedMessage id="Team_allRounder" />) : key} */}
                  </h3>
                  <div className={`player-list d-flex align-items-center justify-content-center w-100 ${value.length > 4 ? 'three-column' : ''}`}>
                    {
                      value && value.length !== 0 && value.map((playerInfo) => {
                        const playerName = playerInfo?.sName.split(' ')
                        const name = playerName.length === 1 ? playerName : `${playerInfo?.sName[0]} ${playerName[playerName.length - 1]}`
                        return (
                          <div key={playerInfo._id || playerInfo.iMatchPlayerId} onClick={() => { PlayerInfoFun(playerInfo._id || playerInfo?.iMatchPlayerId) }} className="pbg">
                            <div className="pg-img">
                              <img src={playerInfo?.sImage && url ? `${url}${playerInfo?.sImage}` : PlayerImage} key={`inside${playerInfo._id}`} alt="Kohli" />

                              { !playerInfo?.bShow && matchDetails?.bLineupsOut && matchType === 'U' &&
                                <span className="unannounced-icon mr-1">U</span>
                              }
                              {
                                (playerInfo.iMatchPlayerId === userTeam.iCaptainId) || (playerInfo?._id === userTeam.iCaptainId)
                                  ? <span className="tag"><img src={CaptainIcon} alt="CaptainIcon" /></span>
                                  : (playerInfo.iMatchPlayerId === userTeam.iViceCaptainId) || (playerInfo?._id === userTeam.iViceCaptainId)
                                      ? <span className="tag"><img src={ViceCaptainIcon} alt="ViceCaptainIcon" /></span>
                                      : ''
                              }
                            </div>
                            {
                              !teamPreview
                                ? (
                                  <div className="team-pts-cr">{matchType === 'U'
                                    ? playerInfo && playerInfo.nFantasyCredit > 0
                                      ? playerInfo.nFantasyCredit + ' Cr '
                                      : (<FormattedMessage id="Team_0CR" />)
                                    : (playerInfo?._id === allTeams[teamView]?.iCaptainId) || (playerInfo?._id === teamDetails?.iCaptainId)
                                        ? (Number(playerInfo.nScoredPoints) * 2) + ' Pts '
                                        : (playerInfo?._id === allTeams[teamView]?.iViceCaptainId) || (playerInfo?._id === teamDetails?.iViceCaptainId)
                                            ? (Number(playerInfo.nScoredPoints) * 1.5) + ' Pts '
                                            : parseFloat(Number((playerInfo.nScoredPoints)).toFixed(2)) + ' Pts '
                                      }
                                  </div>
                                  )
                                : (
                                  <div className="team-pts-cr">
                                    {
                                    playerInfo && playerInfo.nFantasyCredit > 0
                                      ? playerInfo.nFantasyCredit + ' Cr '
                                      : (<FormattedMessage id="Team_0CR" />)
                                    }
                                  </div>
                                  )
                            }
                            <p className="">
                              {name}
                            </p>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </CarouselItem>)
  })

  return (
    <>
      {loading && <Loading />}
      <div className={classNames('preview d-flex align-items-center justify-content-center', { 'p-football': sportsType === 'football', 'p-basketball': sportsType === 'basketball', 'p-baseball': sportsType === 'baseball', 'p-kabaddi': sportsType === 'kabaddi' })}>
        <div className="p-header d-flex align-items-center justify-content-between zIndex">
          <div className="match-detail">
            <span className="match-icon">
              <img src={matchDetails && matchDetails.oHomeTeam && matchDetails.oHomeTeam.sImage ? `${url}${matchDetails.oHomeTeam.sImage}` : HomeTeam} onError={(e) => handleImgError(e, HomeTeam)} alt="" />
            </span>
            <span>{teamListCount[matchDetails?.oHomeTeam?.sShortName]?.length}</span>
            <span>:</span>
            <span>{teamListCount[matchDetails?.oAwayTeam?.sShortName]?.length}</span>
            <span className="match-icon">
              <img src={matchDetails && matchDetails.oAwayTeam && matchDetails.oAwayTeam.sImage ? `${url}${matchDetails.oAwayTeam.sImage}` : AwayTeam} onError={(e) => handleImgError(e, AwayTeam)} alt="" />
            </span>
          </div>
          <div>
            <button onClick={() => {
              if (matchDetails?.eStatus === 'U') {
                if (obj?.homePage) {
                  props.history.push(`/leagues-detail/${match.params.sportsType}/${match.params.matchID}/${match.params.userLeagueID}?homePage=yes`)
                } else {
                  props.history.push(`/leagues-detail/${match.params.sportsType}/${match.params.matchID}/${match.params.userLeagueID}`)
                }
              } else if (matchDetails?.eStatus === 'L' || matchDetails?.eStatus === 'I' || matchDetails?.eStatus === 'CMP') {
                props.history.push(`/leagues-details/${match.params.sportsType}/${match.params.matchID}/${match.params.userLeagueID}/participated`)
              }
            }} className="bg-transparent">
              <img src={closeIcon} alt="close-icon" className='img-fluid' />
            </button>

            {/* {
              !(matchType === 'U') && !completed
                ? <button className="bg-transparent mr-3 icon-refresh" onClick={(e) => refreshContent(e)}></button>
                : ''
            } */}
          </div>
        </div>
        <Fragment>
          <Carousel
            className="w-100"
            activeIndex={parseInt(teamView)}
            slide={false}
          >
            {slides}
            {
              allTeams && allTeams.length >= 2 && (
                <Fragment>
                  <div className="slider-control">
                    <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                    <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                  </div>
                </Fragment>
              )
            }
          </Carousel>
        </Fragment>
        {allTeams && allTeams?.length > 0 && teamView >= 0 &&
          <div className="team-preview-footer">
            <div className="team-preview-info">
                <p className="txt">{allTeams[teamView]?.sUserName} ({allTeams && teamView >= 0 && allTeams[teamView] && (allTeams[teamView]?.sTeamName || allTeams[teamView].sName)})</p>
            </div>
            <div className="np-bottom" >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                {matchType !== 'U' &&
                <>
                  <div className="preview-pts">
                    <FormattedMessage id="League_Points" />
                    {/* <h3>{matchType !== 'U' ? (userTeam?.nTotalPoints || 0) : 0}</h3> */}
                    <h3>{matchType !== 'U' ? (allLeaderBoardList?.find((item) => item.iUserTeamId === userTeam?._id)?.nTotalPoints || 0) : 0}</h3>
                  </div>
                  <div className="preview-creditLeft">
                      <FormattedMessage id="League_Rank" />
                      <h3>#{(allTeams.length && allTeams[teamView].nRank) || 0}</h3>
                    </div>
                    </>
                }
                  {matchType === 'U' &&
                    <div className="preview-creditLeft">
                      <FormattedMessage id="Team_creditLeft" />
                      <h3>{!Number.isInteger(totalCredit) ? ((100 - totalCredit).toFixed(2)) : (100 - totalCredit)}</h3>
                    </div>
                  }
                </div>
                <div className="team-preview-action">
                  {matchType === 'U' &&
                    <Link to={{
                      pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.matchID}/${match?.params?.userTeamID}`,
                      search: `?${qs.stringify({
                                  homePage: obj?.homePage ? 'yes' : undefined,
                                  firstTime: true
                                })}`,
                      state: {
                        backToViewCricket: true,
                        userTeamID: match?.params?.userTeamID,
                        editTeam: true,
                        leagueId: match?.params?.userLeagueID,
                        teamView: teamView
                      }
                    }}>
                      <img src={Edit} alt="" width="24px" />
                    </Link>
                  }
                  <span className='mr-2' role='button' onClick={() => shareTeamClick()}>
                    <img src={ShareTeamIcon} alt="" width="24px" />
                  </span>
                  <Link to={{ pathname: '/point-system', search: '', state: { goBack: true } }}>
                    <img className="pts-icon" src={PointsIcon} alt="" width="24px" />
                  </Link>
                </div>
                {/* {matchType === 'U'
                  ? <p className="txt">{(parseInt(teamView) + 1) + '/' + myTeamsLeaderBoardList?.length}</p>
                  : <p className="txt">{(parseInt(teamView) + 1) + '/' + allTeams?.length}</p>}
                <p className="txt">{matchType === 'U' ? <FormattedMessage id="Common_TotalCredits" /> : <FormattedMessage id="Common_TotalPoints" />}: {matchType === 'U' ? totalCredit : totalScorePoints}</p> */}
                {/* <p className="txt">{matchType === 'upcoming' ? <FormattedMessage id="Common_TotalCredits" /> : <FormattedMessage id="Common_TotalPoints" />}: {matchType === 'upcoming' ? totalCredit : allTeams && teamView >= 0 && allTeams[teamView] && allTeams[teamView].nTotalPoints ? allTeams && teamView >= 0 && allTeams[teamView] && allTeams[teamView].nTotalPoints : <FormattedMessage id="Team_0" />}</p> */}
              </div>
            </div>
          </div>
        }
        {
          ShareTeam
            ? <>
              <div className="s-team-bg" onClick={() => setShareTeam(false)}></div>
              <Card className="filter-card select-team promo-card">
                <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                  <button><FormattedMessage id="Team_ShareTeam" /></button>
                  <button onClick={() => { setShareTeam(false) }} ><img src={close}></img></button>
                </CardHeader>
                <CardBody className="p-0">
                  <FacebookShareButton
                    url={config.facebook}
                    quote={<FormattedMessage id="Static_Facebook" />}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={40} round />
                  </FacebookShareButton>
                  <FacebookMessengerShareButton
                    url={config.facebook}
                    appId={config.facebookAppID}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookMessengerIcon size={40} round />
                  </FacebookMessengerShareButton>
                  <TwitterShareButton
                    url={config.elevenWicket}
                    title={<FormattedMessage id="Static_Twitter" />}
                    className="Demo__some-network__share-button"
                  >
                    <TwitterIcon size={40} round />
                  </TwitterShareButton>
                  <TelegramShareButton
                    url={config.elevenWicket}
                    title={<FormattedMessage id="Static_Telegram" />}
                    className="Demo__some-network__share-button"
                  >
                    <TelegramIcon size={40} round />
                  </TelegramShareButton>
                  <WhatsappShareButton
                    url={config.elevenWicket}
                    title={<FormattedMessage id="Static_Title_Text" />}
                    separator=":"
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={40} round />
                  </WhatsappShareButton>
                  <LinkedinShareButton url={config.elevenWicket} className="Demo__some-network__share-button">
                    <LinkedinIcon size={40} round />
                  </LinkedinShareButton>
                </CardBody>
                <CardFooter className='p-0 border-0 bg-trnsparent m-0 d-flex justify-content-between'>
                  <Button type="submit" color='primary' className="w-100"><FormattedMessage id="League_join" /></Button>
                </CardFooter>
              </Card>
            </>
            : ''
        }
      </div>
      {playerLeagueInfo
        ? <TeamPlayerLeagueInfo {...props} pId={playerId} matchID={match.params.matchID} userTeamID={match.params.userTeamID} onBackClick={() => setPlayerLeagueInfo(false)} onPlayerInfoClick={() => setPlayerInfo(true)} isSeasonPoint={true} />
        : ''
      }
      {playerInfo
        ? <TeamPlayerInfo {...props} pId={playerId} matchID={match.params.matchID} userTeamID={match.params.userTeamID} onBackClick={() => setPlayerInfo(false)}></TeamPlayerInfo>
        : ''
      }
    </>
  )
}
PreviewCricket.propTypes = {
  data: PropTypes.shape({
    aPlayers: PropTypes.func,
    sTeamName: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      sportsType: PropTypes.string,
      matchID: PropTypes.string,
      userLeagueID: PropTypes.string,
      userTeamID: PropTypes.string,
      index: PropTypes.string
    })
  }),
  allTeams: PropTypes.shape([{
    iCaptainId: PropTypes.string,
    iViceCaptainId: PropTypes.string,
    sTeamName: PropTypes.string,
    nTotalPoints: PropTypes.Number,
    aPlayers: PropTypes.shape([{
      eRole: PropTypes.string
    }])
  }]),
  index: PropTypes.number,
  teamView: PropTypes.number,
  onBackClick: PropTypes.func,
  token: PropTypes.string,
  userTeamId: PropTypes.string,
  sportsType: PropTypes.string,
  userTeam: PropTypes.object,
  matchDetails: PropTypes.object,
  onPlayerInfoClick: PropTypes.func,
  refreshContent: PropTypes.func,
  getMatchPlayerList: PropTypes.func,
  getUserTeam: PropTypes.func,
  teamPreview: PropTypes.bool,
  setLoading: PropTypes.bool,
  url: PropTypes.string,
  loading: PropTypes.bool,
  myTeamPreViewUpcoming: PropTypes.bool,
  matchPlayer: PropTypes.array,
  teamPlayerList: PropTypes.array,
  userName: PropTypes.string,
  isPlayerInfo: PropTypes.bool,
  playerId: PropTypes.string,
  setPlayerId: PropTypes.func,
  setPlayerInfo: PropTypes.func,
  setPlayers: PropTypes.func,
  setUserTeamId: PropTypes.func,
  history: PropTypes.object,
  allLeaderBoardList: PropTypes.object,
  myTeamsLeaderBoardList: PropTypes.object,
  getMyTeamLeaderBoardListFunc: PropTypes.func,
  myAllTeamPagination: PropTypes.func,
  location: PropTypes.object,
  playerRoles: PropTypes.object,
  shareTeamFun: PropTypes.func,
  shareTeamResStatus: PropTypes.bool,
  shareTeamRefferalCode: PropTypes.string
}
export default TeamList(PreviewCricket)
