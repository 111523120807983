import React, { Fragment } from 'react'
import Match from './Match'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Lottie from 'react-lottie'
import noContestJoined from '../../../assests/animations/no-contest-joined.json'
// import ReactPullToRefresh from 'react-pull-to-refresh'
// import PullToRefresh from 'react-simple-pull-to-refresh'
import SkeletonUpcoming from '../../../component/SkeletonUpcoming'
import SportsLeagueList from '../../../HOC/SportsLeagueList/SportsLeagueList'
import PropTypes from 'prop-types'

function MyUpcomingMatch (props) {
  const {
    list, loading,
    // FetchingList, mainIndex,
    subIndex
  } = props
  // function handleRefresh () {
  //   FetchingList(mainIndex, subIndex)
  // }
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noContestJoined,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
  // <PullToRefresh
  //   onRefresh={handleRefresh}
  //   pullDownThreshold={15}
  //   refreshingContent={true}
  // >
      <div>
        {
          loading
            ? <SkeletonUpcoming numberOfColumns={5} />
            : (
              <Fragment>
                {list && list.length !== 0 && subIndex !== 1
                  ? list.sort(function (a, b) {
                    return new Date(b.dStartDate) - new Date(a.dStartDate)
                  }).map((data, i) => {
                    return (
                    <Match {...props} data={data} key={i} live={subIndex === 2} completed={subIndex === 3 && data.eStatus !== 'CNCL'} cancel={subIndex === 3 && data.eStatus === 'CNCL'} />
                    )
                  })
                  : list.map((data, i) => {
                    return (
                    <Match {...props} data={data} key={i} live={subIndex === 2} completed={subIndex === 3 && data.eStatus !== 'CNCL'} cancel={subIndex === 3 && data.eStatus === 'CNCL'} />
                    )
                  })}
                {
                  list && !list.length && (
                  // <div className="text-center">
                  //   <h3 className='mt-5'>
                  //     <FormattedMessage id="Home_No_Match" />
                  //   </h3>
                  // </div>
                  <div className="no-team d-flex align-items-center justify-content-center fixing-width">
                    <div className="no-team-inside">
                      {/* <i className="icon-trophy"></i> */}
                      <Lottie width={174} options={defaultOptions} isClickToPauseDisabled={true} />
                      <h6 className="m-4">
                        <FormattedMessage id="No_joined_upcoming_contests" />
                      </h6>
                      <Link to={`/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`} className="btn btn-primary w-100"><FormattedMessage id="View_upcoming_matches" /> </Link>
                    </div>
                  </div>
                  )
                }
              </Fragment>
              )
        }
      </div>
  //  </PullToRefresh>
  )
}

MyUpcomingMatch.propTypes = {
  list: PropTypes.array,
  points: PropTypes.string,
  teamDetails: PropTypes.object,
  mainIndex: PropTypes.number,
  subIndex: PropTypes.number,
  loading: PropTypes.bool,
  FetchingList: PropTypes.func
}

export default SportsLeagueList(MyUpcomingMatch, 'cricket', 'Upcoming')
