import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  Button,
  // PopoverBody, UncontrolledPopover,
  Alert,
  // CardFooter,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import SkeletonUpcoming from '../../../component/SkeletonUpcoming'
import HomeTeam from '../../../assests/images/Team1.png'
import AwayTeam from '../../../assests/images/Team2.png'
import Ball from '../../../assests/images/Ball.png'
import CrownUtils from '../../../assests/images/crown-utils.svg'
// import Dollar from '../../../assests/images/dollar.svg'
import Bat from '../../../assests/images/Bat.png'
// import Trophy from '../../../assests/images/trophy.svg'
import contest from '../../../assests/images/contest-match-icon.svg'
import teams from '../../../assests/images/team-match-icon.svg'
import megaContest from '../../../assests/images/mega-contest-home-logo.png'
// import jioBellIcon from '../../../assests/images/jio-bell-icon.svg'
import lineupsOutInfoIcon from '../../../assests/images/lineupsout-info-icon.svg'
import CloseIcon from '../../../assests/images/close-icon.svg'
import { viewMatch, viewLiveMatch } from '../../../Analytics.js'
// import infoIcon from '../../../assests/images/info-icon-gray.svg'
import home from '../../../assests/images/homeIconWhite.svg'
import PlayerImage from '../../../assests/images/PlayerImage.svg'
import Lottie from 'react-lottie'
import timesUp from '../../../assests/animations/times-up.json'

import { useSelector, useDispatch } from 'react-redux'
import qs from 'query-string'
import { getMyTeamPlayerList } from '../../../redux/actions/team'
import { clickEventPost, currencyConvert, handleImgError, loginRequiredPost, maxValue } from '../../../helper'
import YouWonReturn from './YouWonReturn'
const classNames = require('classnames')

function Match (props) {
  const { data, loading, url, subIndex, match, location, noRedirect, onlyInsideFeild } = props
  const [time, setTime] = useState('')
  // const [dataMatch, setDataMatch] = useState({})
  const [intervalRef, setIntervalRef] = useState(null)
  const [VideoStream, setVideoStream] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  // const [popupMsg, setPopupMsg] = useState('')
  // const [popupOpen, setPopupOpen] = useState(false)
  const [lineups, setLineups] = useState(false)
  const [teamHome, setTeamHome] = useState([])
  const [teamAway, setTeamAway] = useState([])
  const [matchClick, setMatchClick] = useState(false)
  const [timeUpModal, setTimeUpModal] = useState(false)
  const [shouldShortTeamNameMarquee, setShouldShortTeamNameMarquee] = useState(false)
  const [shouldHomeFullTeamNameMarquee, setShouldHomeFullTeamNameMarquee] = useState(false)
  const [shouldAwayFullTeamNameMarquee, setShouldAwayFullTeamNameMarquee] = useState(false)
  const shortTeamNameRef = useRef(null)
  const fullHomeTeamNameRef = useRef(null)
  const fullAwayTeamNameRef = useRef(null)
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const currencyLogo = useSelector(state => state.settings.currencyLogo)
  const teamPlayerList = useSelector(state => state.team.teamPlayerList)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  const dispatch = useDispatch()

  useEffect(() => {
    if (lineups) {
      dispatch(getMyTeamPlayerList(data._id))
    }
  }, [lineups])

  useEffect(() => {
    if (teamPlayerList?.length > 0 && lineups) {
      let home = {}
      let away = {}
      teamPlayerList.map(player => {
        if ((player?.oTeam?.iTeamId === data?.oHomeTeam?.iTeamId) && player.bShow) {
          // home.push(player)
          home = {
            ...home,
            [player.eRole]: Object.keys(home).includes(player.eRole) ? [...home[player.eRole], player] : [player]
          }
        } else if ((player?.oTeam?.iTeamId === data?.oAwayTeam?.iTeamId) && player.bShow) {
          away = {
            ...away,
            [player.eRole]: Object.keys(away).includes(player.eRole) ? [...away[player.eRole], player] : [player]
          }
        }
        return player
      })

      if (match.params.sportsType === 'cricket') {
        let teamHome = []
        const WK = home.WK ? home.WK.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const ALLR = home.ALLR ? home.ALLR.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const BATS = home.BATS ? home.BATS.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const BWL = home.BWL ? home.BWL.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamHome = [...WK, ...ALLR, ...BATS, ...BWL]
        setTeamHome(teamHome)

        let teamAway = []
        const WK2 = away.WK ? away.WK.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const ALLR2 = away.ALLR ? away.ALLR.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const BATS2 = away.BATS ? away.BATS.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const BWL2 = away.BWL ? away.BWL.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamAway = [...WK2, ...ALLR2, ...BATS2, ...BWL2]
        setTeamAway(teamAway)
      } else if (match.params.sportsType === 'football') {
        let teamHome = []
        const GK = home.GK ? home.GK.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const DEF = home.DEF ? home.DEF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const MID = home.MID ? home.MID.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const FWD = home.FWD ? home.FWD.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamHome = [...GK, ...DEF, ...MID, ...FWD]
        setTeamHome(teamHome)

        let teamAway = []
        const GK2 = away.GK ? away.GK.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const DEF2 = away.DEF ? away.DEF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const MID2 = away.MID ? away.MID.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const FWD2 = away.FWD ? away.FWD.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamAway = [...GK2, ...DEF2, ...MID2, ...FWD2]
        setTeamAway(teamAway)
      } else if (match.params.sportsType === 'basketball') {
        let teamHome = []
        const C = home.C ? home.C.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const PF = home.PF ? home.PF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const PG = home.PG ? home.PG.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const SF = home.SF ? home.SF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const SG = home.SG ? home.SG.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamHome = [...C, ...PF, ...PG, ...SF, ...SG]
        setTeamHome(teamHome)

        let teamAway = []
        const C2 = away.C ? away.C.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const PF2 = away.PF ? away.PF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const PG2 = away.PG ? away.PG.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const SF2 = away.SF ? away.SF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const SG2 = away.SG ? away.SG.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamAway = [...C2, ...PF2, ...PG2, ...SF2, ...SG2]
        setTeamAway(teamAway)
      } else if (match.params.sportsType === 'baseball') {
        let teamHome = []
        const P = home.P ? home.P.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const IF = home.IF ? home.IF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const CT = home.CT ? home.CT.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const OF = home.OF ? home.OF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamHome = [...P, ...IF, ...CT, ...OF]
        setTeamHome(teamHome)

        let teamAway = []
        const P2 = away.P ? away.P.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const IF2 = away.IF ? away.IF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const CT2 = away.CT ? away.CT.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const OF2 = away.OF ? away.OF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamAway = [...P2, ...IF2, ...CT2, ...OF2]
        setTeamAway(teamAway)
      } else if (match.params.sportsType === 'kabaddi') {
        let teamHome = []
        const DEF = home.DEF ? home.DEF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const ALLR = home.ALLR ? home.ALLR.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const RAID = home.RAID ? home.RAID.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamHome = [...DEF, ...ALLR, ...RAID]
        setTeamHome(teamHome)

        let teamAway = []
        const DEF2 = away.DEF ? away.DEF.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const ALLR2 = away.ALLR ? away.ALLR.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        const RAID2 = away.RAID ? away.RAID.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })) : []
        teamAway = [...DEF2, ...ALLR2, ...RAID2]
        setTeamAway(teamAway)
      }
    }
  }, [teamPlayerList, lineups])

  useEffect(() => {
    // if (myMatches) {
    //   if (data?._id && matchDetailsList?.length > 0) {
    //     const filterData = matchDetailsList.filter(match => match._id === data.iMatchId)
    //     setDataMatch(filterData[0])
    //     if (filterData?.length > 0 && filterData[0].dStartDate) {
    //       if ((new Date(filterData[0].dStartDate) > Date.now() + 86400000) || (new Date(filterData[0].dStartDate) < new Date(Date.now()))) {
    //         setTime(moment(filterData[0].dStartDate).format('lll'))
    //       } else {
    //         setIntervalRef(setInterval(() => {
    //           const duration = moment.duration(moment(filterData[0].dStartDate).diff(moment(new Date())))
    //           setTime(`${duration.hours()}h ${duration.minutes()}m  ${duration.seconds()}s left `)
    //         }, 1000))
    //       }
    //     }
    //   }
    //   return () => {
    //     clearInterval(intervalRef)
    //   }
    // } else {
    if (data && data.dStartDate) {
      if ((new Date(data.dStartDate) > Date.now() + 86400000) || (new Date(data.dStartDate) < new Date(Date.now()))) {
        setTime(moment(data.dStartDate).format('MMM Do, h:mm A'))
      } else {
        setIntervalRef(setInterval(() => {
          if (data && (data.eStatus === 'U') && moment(data.dStartDate) < moment(new Date())) {
            setTime(moment(data.dStartDate).format('MMM Do, h:mm A'))
          } else {
            const duration = moment.duration(moment(data.dStartDate).diff(moment(new Date())))
            if (props.lineupsOutMatch) {
              setTime(`${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s left`)
            } else {
              setTime(`${duration.hours()}h : ${duration.minutes()}m :  ${duration.seconds()}s `)
            }
          }
        }, 0))
      }
    }
    return () => {
      clearInterval(intervalRef)
    }
    // }
  }, [])

  useEffect(() => {
    if (matchClick && data && (data.eStatus === 'U') && moment(data.dStartDate) < moment(new Date())) {
      setTimeUpModal(true)
    }
  }, [matchClick])

  // to check the overflow in shortname and fullname of the team
  useEffect(() => {
    if (shortTeamNameRef.current) {
      const isOverflowing = shortTeamNameRef.current.scrollWidth > shortTeamNameRef.current.clientWidth
      setShouldShortTeamNameMarquee(isOverflowing)
    }
    if (fullHomeTeamNameRef.current) {
      const isOverflowing = fullHomeTeamNameRef.current.scrollWidth > fullHomeTeamNameRef.current.clientWidth
      setShouldHomeFullTeamNameMarquee(isOverflowing)
    }
    if (fullAwayTeamNameRef.current) {
      const isOverflowing = fullAwayTeamNameRef.current.scrollWidth > fullAwayTeamNameRef.current.clientWidth
      setShouldAwayFullTeamNameMarquee(isOverflowing)
    }
  }, [])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: timesUp,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  function callViewEvent () {
    // if (myMatches) {
    //   if (dataMatch && dataMatch.eStatus === 'L') {
    //     if (dataMatch && dataMatch.sName && dataMatch._id && location.pathname) {
    //       viewLiveMatch(dataMatch.sName, dataMatch._id, location.pathname)
    //     } else {
    //       dataMatch && dataMatch.sName && dataMatch._id && viewLiveMatch(dataMatch.sName, dataMatch._id, '')
    //     }
    //   } else {
    //     if (dataMatch && dataMatch.sName && dataMatch._id && location.pathname) {
    //       viewMatch(dataMatch.sName, dataMatch._id, location.pathname)
    //     } else {
    //       dataMatch && dataMatch.sName && dataMatch._id && viewMatch(dataMatch.sName, dataMatch._id, '')
    //     }
    //   }
    // } else {
    if (data && data.eStatus === 'L') {
      if (data && data.sName && data._id && location.pathname) {
        viewLiveMatch(data.sName, data._id, location.pathname)
      } else {
        data && data.sName && data._id && viewLiveMatch(data.sName, data._id, '')
      }
    } else {
      if (data && data.sName && data._id && location.pathname) {
        viewMatch(data.sName, data._id, location.pathname)
      } else {
        data && data.sName && data._id && viewMatch(data.sName, data._id, '')
      }
    }
    // }
  }

  return (
    <>
      {alert && alertMessage ? <Alert isOpen={alert} color="primary">{alertMessage}</Alert> : ''}
      {/* {popupOpen && popupMsg ? <Alert isOpen={popupOpen} color="primary">{popupMsg}</Alert> : ''} */}
      <div className={classNames('match-box', { disabled: (data && data.bDisabled) })} onClick={() => {
        localStorage.setItem('currMatch', JSON.stringify(data))
        if (props?.match?.path === '/home/:sportsType/v1') {
          // setPopupOpen(true)
          // setTimeout(() => {
          //   setPopupOpen(false)
          // }, 3000)
          // setPopupMsg('Session Expired')
          loginRequiredPost()
        } else if (data && data.bDisabled) {
          setAlert(true)
          setTimeout(() => {
            setAlert(false)
          }, 3000)
          setAlertMessage(<FormattedMessage id="Match_Disable_Msg" />)
        } else if (data && (data.eStatus === 'U') && moment(data.dStartDate) < moment(new Date())) {
          // setAlert(true)
          // setTimeout(() => {
          //   setAlert(false)
          // }, 3000)
          // setAlertMessage(<FormattedMessage id="Match_Live_Msg" />)
          setMatchClick(true)
        } else {
          callViewEvent()
        }
        if (props?.match?.path === '/home/:sportsType' && data.eStatus === 'U') {
          clickEventPost('f_hm_um')
        }
      }}>
        {
          (loading)
            ? <SkeletonUpcoming numberOfColumns={7} />
            : <>
              <Fragment>
                {
                  // myMatches
                  //   ? <Fragment>
                  //     {
                  //       onlyInsideFeild
                  //         ? (
                  //           <Fragment>
                  //             <Link className="match-i">
                  //               <div className="d-flex align-items-center justify-content-between only">
                  //                 <div className="team d-flex align-items-center">
                  //                   <div className="t-img"><img src={dataMatch && dataMatch.oHomeTeam && dataMatch.oHomeTeam.sImage ? `${url}${dataMatch.oHomeTeam.sImage}` : HomeTeam} alt={<FormattedMessage id="Footer_Home" />} /></div>
                  //                   <div className="name">
                  //                     <h3>{dataMatch && dataMatch.oHomeTeam && dataMatch.oHomeTeam.sShortName ? dataMatch.oHomeTeam.sShortName : dataMatch?.oHomeTeam?.sName && dataMatch.oHomeTeam.sName.substr(0, 3)}</h3>
                  //                     <div className="d-flex">
                  //                       {dataMatch && dataMatch.iTossWinnerId && dataMatch.iTossWinnerId === dataMatch.oHomeTeam.iTeamId
                  //                         ? dataMatch.eTossWinnerAction === 'BAT'
                  //                           ? <Fragment>
                  //                             <img src={Dollar} alt="dollar" width="18px" />
                  //                             <img src={Bat} alt="Bat" width="18px" />
                  //                           </Fragment>
                  //                           : <Fragment>
                  //                             <img src={Dollar} alt="dollar" width="18px" />
                  //                             <img src={Ball} alt="Bat" width="18px" />
                  //                           </Fragment>
                  //                         : dataMatch && dataMatch.iTossWinnerId && dataMatch.oAwayTeam && dataMatch.iTossWinnerId === dataMatch.oAwayTeam.iTeamId
                  //                           ? dataMatch.eTossWinnerAction === 'BAT'
                  //                             ? <img src={Ball} alt="Ball" width="18px" />
                  //                             : <img src={Bat} alt="Bat" width="18px" />
                  //                           : ''
                  //                       }
                  //                     </div>
                  //                   </div>
                  //                 </div>
                  //                 <div className="time">{time} </div>
                  //                 <div className="team d-flex align-items-center">
                  //                   <div className="name">
                  //                     <h3>{dataMatch && dataMatch.oAwayTeam && dataMatch.oAwayTeam.sShortName ? dataMatch.oAwayTeam.sShortName : dataMatch?.oAwayTeam?.sName.substr(0, 3)}</h3>
                  //                     <div className="d-flex justify-content-end">
                  //                       {dataMatch && dataMatch.iTossWinnerId && dataMatch.iTossWinnerId === dataMatch.oAwayTeam.iTeamId
                  //                         ? dataMatch.eTossWinnerAction === 'BAT'
                  //                           ? <Fragment>
                  //                             <img src={Dollar} alt="dollar" width="18px" />
                  //                             <img src={Bat} alt="Bat" width="18px" />
                  //                           </Fragment>
                  //                           : <Fragment>
                  //                             <img src={Dollar} alt="dollar" width="18px" />
                  //                             <img src={Ball} alt="Bat" width="18px" />
                  //                           </Fragment>
                  //                         : dataMatch && dataMatch.iTossWinnerId && dataMatch.oHomeTeam && dataMatch.iTossWinnerId === dataMatch.oHomeTeam.iTeamId
                  //                           ? dataMatch.eTossWinnerAction === 'BAT'
                  //                             ? <img src={Ball} alt="Bat" width="18px" />
                  //                             : <img src={Bat} alt="Bat" width="18px" />
                  //                           : ''
                  //                       }
                  //                     </div>
                  //                     {props.icons
                  //                       ? <div className="d-flex justify-content-end">
                  //                         <img src={Ball} alt="Ball" width="18px" />
                  //                       </div>
                  //                       : ''
                  //                     }
                  //                   </div>
                  //                   <div className="t-img"><img src={dataMatch && dataMatch.oAwayTeam && dataMatch.oAwayTeam.sImage ? `${url}${dataMatch.oAwayTeam.sImage}` : AwayTeam} alt={<FormattedMessage id="Footer_Away" />} /></div>
                  //                 </div>
                  //               </div>
                  //             </Link>
                  //           </Fragment>
                  //           )
                  //         : (
                  //           <Fragment>
                  //             {
                  //               dataMatch && dataMatch.sInfo && (
                  //                 <Fragment>
                  //                   <Button className="icon-info i-button" type="button" id={`p${dataMatch._id}`}></Button>
                  //                   <UncontrolledPopover trigger="legacy" placement="bottom" target={`p${dataMatch._id}`}>
                  //                     <PopoverBody>{dataMatch && dataMatch.sInfo}</PopoverBody>
                  //                   </UncontrolledPopover>
                  //                 </Fragment>
                  //               )
                  //             }
                  //             <Link style={noRedirect ? { pointerEvents: 'none' } : null} to={props.live ? { pathname: `/liveleague/${data.iMatchId}/${dataMatch?.eCategory?.toLowerCase()}`, state: { tab: subIndex, referUrl: `/matches/${match && match.params && match.params.sportsType}` } } : props.upcoming ? { pathname: `/leagues/${dataMatch?.eCategory?.toLowerCase()}/${dataMatch._id}`, state: { tab: 1, referUrl: `/home/${match && match.params && match.params.sportsType}` } } : props.completed ? { pathname: `/completedleagues/participated/${data.iMatchId}/${dataMatch?.eCategory?.toLowerCase()}`, state: { tab: subIndex, referUrl: `/matches/${match && match.params && match.params.sportsType}` } } : { pathname: `/leagues/${dataMatch?.eCategory?.toLowerCase()}/${data.iMatchId}`, state: { tab: subIndex, referUrl: `/matches/${match && match.params && match.params.sportsType}` } }} className="match-i">
                  //               <div className="m-name">
                  //                 <strong>{dataMatch && dataMatch.sSeasonName ? dataMatch.sSeasonName : ' '}</strong>
                  //                 {dataMatch?.sSponsoredText ? <p>{dataMatch.sSponsoredText}</p> : ''}
                  //               </div>
                  //               <div className="d-flex align-items-center justify-content-between">
                  //                 <div className="team d-flex align-items-center">
                  //                   <div className="t-img"><img src={dataMatch && dataMatch.oHomeTeam && dataMatch.oHomeTeam.sImage ? `${url}${dataMatch.oHomeTeam.sImage}` : HomeTeam} alt={<FormattedMessage id="Footer_Home" />} /></div>
                  //                   <div className="name">
                  //                     <h3>{dataMatch && dataMatch.oHomeTeam && dataMatch.oHomeTeam.sShortName ? dataMatch.oHomeTeam.sShortName : dataMatch?.oHomeTeam?.sName && dataMatch.oHomeTeam.sName.substr(0, 3)}</h3>
                  //                     <div className="d-flex">
                  //                       {dataMatch && dataMatch.iTossWinnerId && dataMatch.iTossWinnerId === dataMatch.oHomeTeam.iTeamId
                  //                         ? dataMatch.eTossWinnerAction === 'BAT'
                  //                           ? <Fragment>
                  //                             <img src={Dollar} alt="dollar" width="18px" />
                  //                             <img src={Bat} alt="Bat" width="18px" />
                  //                           </Fragment>
                  //                           : <Fragment>
                  //                             <img src={Dollar} alt="dollar" width="18px" />
                  //                             <img src={Ball} alt="Bat" width="18px" />
                  //                           </Fragment>
                  //                         : dataMatch && dataMatch.iTossWinnerId && dataMatch.oAwayTeam && dataMatch.iTossWinnerId === dataMatch.oAwayTeam.iTeamId
                  //                           ? dataMatch.eTossWinnerAction === 'BAT'
                  //                             ? <img src={Ball} alt="Ball" width="18px" />
                  //                             : <img src={Bat} alt="Bat" width="18px" />
                  //                           : ''
                  //                       }
                  //                     </div>
                  //                   </div>
                  //                 </div>
                  //                 <div className="time">{time} </div>
                  //                 <div className="team d-flex align-items-center">
                  //                   <div className="name">
                  //                     <h3>{dataMatch && dataMatch.oAwayTeam && dataMatch.oAwayTeam.sShortName ? dataMatch.oAwayTeam.sShortName : dataMatch?.oAwayTeam?.sName.substr(0, 3)}</h3>
                  //                     <div className="d-flex justify-content-end">
                  //                       {dataMatch && dataMatch.iTossWinnerId && dataMatch.iTossWinnerId === dataMatch.oAwayTeam.iTeamId
                  //                         ? dataMatch.eTossWinnerAction === 'BAT'
                  //                           ? <Fragment>
                  //                             <img src={Dollar} alt="dollar" width="18px" />
                  //                             <img src={Bat} alt="Bat" width="18px" />
                  //                           </Fragment>
                  //                           : <Fragment>
                  //                             <img src={Dollar} alt="dollar" width="18px" />
                  //                             <img src={Ball} alt="Bat" width="18px" />
                  //                           </Fragment>
                  //                         : dataMatch && dataMatch.iTossWinnerId && dataMatch.oHomeTeam && dataMatch.iTossWinnerId === dataMatch.oHomeTeam.iTeamId
                  //                           ? dataMatch.eTossWinnerAction === 'BAT'
                  //                             ? <img src={Ball} alt="Bat" width="18px" />
                  //                             : <img src={Bat} alt="Bat" width="18px" />
                  //                           : ''
                  //                       }
                  //                     </div>
                  //                     {props.icons
                  //                       ? <div className="d-flex justify-content-end">
                  //                         <img src={Ball} alt="Ball" width="18px" />
                  //                       </div>
                  //                       : ''
                  //                     }
                  //                   </div>
                  //                   <div className="t-img"><img src={dataMatch && dataMatch.oAwayTeam && dataMatch.oAwayTeam.sImage ? `${url}${dataMatch.oAwayTeam.sImage}` : AwayTeam} alt={<FormattedMessage id="Footer_Away" />} /></div>
                  //                 </div>
                  //               </div>
                  //               <div className={
                  //                 `footer-m d-flex align-items-center ${((dataMatch?.nTeams && dataMatch?.bLineupsOut && dataMatch?.eStatus === 'U') || dataMatch?.nWinnings) ? 'justify-content-between' : ''} ${(dataMatch?.nTeams && dataMatch?.bLineupsOut && dataMatch?.eStatus !== 'U') ? 'justify-content-center' : ''}${(dataMatch?.nTeams && !dataMatch?.bLineupsOut) ? 'justify-content-center' : ''} ${(!dataMatch?.nTeams && dataMatch?.bLineupsOut) ? 'justify-content-center' : ''}
                  //                 `}>
                  //                 {dataMatch?.nTeams
                  //                   ? <ul className="d-flex align-items-center m-0">
                  //                     <li><i className="icon-group"></i>{`${dataMatch.nTeams} `}<FormattedMessage id="League_Team" /></li>
                  //                     <li><i className="icon-security-star-symbol"></i>{`${dataMatch.nJoinedLeague}  `}<FormattedMessage id="League_CONTEST" /></li>
                  //                   </ul>
                  //                   : ''
                  //                 }
                  //                 {dataMatch && dataMatch?.bLineupsOut && dataMatch?.eStatus === 'U' && <Button color="success" ><FormattedMessage id="Matches_LineupsOut" /></Button>}
                  //                 {
                  //                   dataMatch && dataMatch?.nWinnings && (dataMatch?.eStatus === 'CMP' || dataMatch?.eStatus === 'I')
                  //                     ? <b className="text-success">{dataMatch?.nWinnings && (<Fragment> <FormattedMessage id="WON" /> {parseFloat(dataMatch?.nWinnings.toFixed(2))} </Fragment>)}</b>
                  //                     : ''
                  //                 }
                  //               </div>
                  //               {
                  //                 dataMatch && dataMatch.bWinningZone && dataMatch.eStatus === 'L' &&
                  //                 <div className="bg-white d-flex align-items-center justify-content-center">
                  //                   <Badge color="info" className='winning-zone' pill>
                  //                     <img src={Trophy}></img>
                  //                     <span className='mt-2 winning-text'>
                  //                       <FormattedMessage id="Match_Winning_Zone" />
                  //                     </span>
                  //                   </Badge>
                  //                 </div>
                  //               }
                  //             </Link>
                  //           </Fragment>
                  //           )
                  //     }
                  //   </Fragment>
                  //   :
                  <Fragment>
                    {
                      onlyInsideFeild
                        ? (
                          <Fragment>
                            <div className="match-i">
                              <div className="d-flex align-items-center justify-content-between only">
                                <div className="team d-flex align-items-center">
                                  <div className="t-img"><img src={data && data.oHomeTeam && data.oHomeTeam.sImage ? `${url}${data.oHomeTeam.sImage}` : HomeTeam} onError={(e) => handleImgError(e, HomeTeam)} alt={<FormattedMessage id="Footer_Home" />} /></div>
                                  <div className="name">
                                    <h3>{data && data.oHomeTeam && data.oHomeTeam.sShortName ? data.oHomeTeam.sShortName : data?.oHomeTeam?.sName && data.oHomeTeam.sName.substr(0, 3)}</h3>
                                    <div className="d-flex">
                                      {data && data.iTossWinnerId && data.iTossWinnerId === data.oHomeTeam.iTeamId
                                        ? data.eTossWinnerAction === 'BAT'
                                          ? <Fragment>
                                            {/* <img src={Dollar} alt={<FormattedMessage id='Dollar' />} width="18px" /> */}
                                            <img src={Bat} alt={<FormattedMessage id='Bat' />} width="18px" />
                                          </Fragment>
                                          : <Fragment>
                                            {/* <img src={Dollar} alt={<FormattedMessage id='Dollar' />} width="18px" /> */}
                                            <img src={Ball} alt={<FormattedMessage id='Bat' />} width="18px" />
                                          </Fragment>
                                        : data && data.iTossWinnerId && data.oAwayTeam && data.iTossWinnerId === data.oAwayTeam.iTeamId
                                          ? data.eTossWinnerAction === 'BAT'
                                            ? <img src={Ball} alt={<FormattedMessage id='Ball' />} width="18px" />
                                            : <img src={Bat} alt={<FormattedMessage id='Bat' />} width="18px" />
                                          : ''
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className="time">{time} </div>
                                <div className="team d-flex align-items-center">
                                  <div className="name">
                                    <h3>{data && data.oAwayTeam && data.oAwayTeam.sShortName ? data.oAwayTeam.sShortName : data?.oAwayTeam?.sName.substr(0, 3)}</h3>
                                    <div className="d-flex justify-content-end">
                                      {data && data.iTossWinnerId && data.iTossWinnerId === data.oAwayTeam.iTeamId
                                        ? data.eTossWinnerAction === 'BAT'
                                          ? <Fragment>
                                            {/* <img src={Dollar} alt={<FormattedMessage id='Dollar' />} width="18px" /> */}
                                            <img src={Bat} alt={<FormattedMessage id='Bat' />} width="18px" />
                                          </Fragment>
                                          : <Fragment>
                                            {/* <img src={Dollar} alt={<FormattedMessage id='Dollar' />} width="18px" /> */}
                                            <img src={Ball} alt={<FormattedMessage id='Bat' />} width="18px" />
                                          </Fragment>
                                        : data && data.iTossWinnerId && data.oHomeTeam && data.iTossWinnerId === data.oHomeTeam.iTeamId
                                          ? data.eTossWinnerAction === 'BAT'
                                            ? <img src={Ball} alt={<FormattedMessage id='Bat' />} width="18px" />
                                            : <img src={Bat} alt={<FormattedMessage id='Bat' />} width="18px" />
                                          : ''
                                      }
                                    </div>
                                    {props.icons
                                      ? <div className="d-flex justify-content-end">
                                        <img src={Ball} alt={<FormattedMessage id='Ball' />} width="18px" />
                                      </div>
                                      : ''
                                    }
                                  </div>
                                  <div className="t-img"><img src={data && data.oAwayTeam && data.oAwayTeam.sImage ? `${url}${data.oAwayTeam.sImage}` : AwayTeam} onError={(e) => handleImgError(e, AwayTeam)} alt={<FormattedMessage id="Footer_Away" />} /></div>
                                </div>
                              </div>
                              {
                                props.liveStream && (
                                  <div className={
                                    `footer-m d-flex align-items-center ${((data.nTeams && data.bLineupsOut && data.eStatus === 'U') || (data.nWinnings || data.nBonusWin || data.aExtraWin?.length >= 1)) ? 'justify-content-between' : ''} ${(props.liveStream) ? 'justify-content-center' : ''} ${(data.nTeams && data.bLineupsOut && data.eStatus !== 'U') ? 'justify-content-center' : ''}${(data.nTeams && !data.bLineupsOut) ? 'justify-content-center' : ''} ${(!data.nTeams && data.bLineupsOut) ? 'justify-content-center' : ''}
                                      `}>
                                    {data && data.sStreamUrl && props.liveStream && !props.completed && <Button className="live-stream-btn-live" onClick={() => {
                                      if (props.liveStream) {
                                        if (data.sStreamUrl.includes('https://www.youtube.com/')) {
                                          setVideoStream(true)
                                        } else {
                                          setVideoStream(true)
                                          setAlert(true)
                                          setTimeout(() => {
                                            setVideoStream(false)
                                            setAlert(false)
                                          }, 1000)
                                          setAlertMessage('something went wrong.')
                                        }
                                      }
                                    }}><FormattedMessage id="Matches_Watch_Live" /></Button>}
                                    {data && data.sStreamUrl && props.liveStream && props.completed && <Button className='live-stream-btn-cmp' onClick={() => {
                                      if (props.liveStream) {
                                        if (data.sStreamUrl.includes('https://www.youtube.com/')) {
                                          setVideoStream(true)
                                        } else {
                                          setVideoStream(true)
                                          setAlert(true)
                                          setTimeout(() => {
                                            setVideoStream(false)
                                            setAlert(false)
                                          }, 1000)
                                          setAlertMessage('something went wrong.')
                                        }
                                      }
                                    }}><FormattedMessage id="Matches_Watch_Now" /></Button>}
                                  </div>
                                )
                              }
                            </div>
                          </Fragment>)
                        : (<Fragment>
                            {/* {
                                data && data.sInfo && (
                                  <Fragment>
                                    <img src={infoIcon} id={`p${data._id}`} className="match-info"/>
                                    <UncontrolledPopover trigger="legacy" placement="bottom" target={`p${data._id}`}>
                                      <PopoverBody>{data && data.sInfo}</PopoverBody>
                                    </UncontrolledPopover>
                                  </Fragment>
                                )
                              } */}
                            <div className='match-i'>

                              <Link style={(noRedirect || (data && data.bDisabled)) || props?.match?.path === '/home/:sportsType/v1' || (data && (data.eStatus === 'U') && moment(data.dStartDate) < moment(new Date())) ? { pointerEvents: 'none' } : null}
                                to={(props?.match?.path === '/home/:sportsType/v1' || props?.match?.path === '/matches/:sportsType/v1')
                                  ? {
                                      pathname: `/leagues/${match && match.params && match.params.sportsType}/${data._id}/v1`,
                                      state: { data }
                                    }
                                  : props.live
                                    ? { pathname: `/liveleague/${data.iMatchId}/${data?.eCategory?.toLowerCase()}`, state: { tab: subIndex, referUrl: `/matches/${match && match.params && match.params.sportsType}`, isHome: props?.noOpenUpcoming } }
                                    : props.upcoming
                                      ? {
                                          pathname: `/leagues/${data?.eCategory?.toLowerCase()}/${data._id}`,
                                          search: `?${qs.stringify({
                                          homePage: (match?.path.includes('/home') && 'yes') || undefined
                                        })}`,
                                          state: { tab: 1, referUrl: `/home/${match && match.params && match.params.sportsType}`, isHome: props?.noOpenUpcoming }
                                        }
                                      : props.completed
                                        ? { pathname: `/completedleagues/participated/${data.iMatchId}/${data?.eCategory?.toLowerCase()}`, state: { tab: subIndex, referUrl: `/matches/${match && match.params && match.params.sportsType}`, isHome: props?.noOpenUpcoming } }
                                        : !props.cancel ? { pathname: `/leagues/${data?.eCategory?.toLowerCase()}/${data.iMatchId}`, state: { tab: subIndex, referUrl: `/matches/${match && match.params && match.params.sportsType}` } } : '#'}
                                className="match-link">
                              </Link>
                              <div className="m-name">
                                <strong>{data && data.sSeasonName ? data.sSeasonName : ' '}</strong>
                                {/* {data.sSponsoredText ? <p>{data.sSponsoredText}</p> : ''} */}
                                {/* <img src={jioBellIcon} /> */}
                              </div>
                              <div className="middle-team-box after-line" style={{ marginBottom: '0' }}>
                                {/* {data && data.iTossWinnerId !== null && <hr />} */}
                                {data && data.iTossWinnerId !== null}

                                <div className="team d-flex align-items-center">
                                  <div className="t-img">
                                    <img
                                      src={data && data.oHomeTeam && data.oHomeTeam.sImage ? `${url}${data.oHomeTeam.sImage}` : HomeTeam}
                                      onError={(e) => handleImgError(e, HomeTeam)}
                                      alt={<FormattedMessage id="Footer_Home" />}
                                    />
                                  </div>
                                  <div className="name">
                                    <h3 ref={shortTeamNameRef} className={shouldShortTeamNameMarquee ? 'marquee-text' : ''}>{data && data.oHomeTeam && data.oHomeTeam.sShortName ? data.oHomeTeam.sShortName : ''}</h3>
                                    <div className="name-img d-flex">
                                      {data && data.iTossWinnerId &&
                                        <>
                                          {data && data.iTossWinnerId && data.iTossWinnerId === data.oHomeTeam.iTeamId
                                            ? data.eTossWinnerAction === 'BAT'
                                              ? <Fragment>
                                                {/* <img src={Dollar} alt={<FormattedMessage id='Dollar' />} width="18px" /> */}
                                                <img src={Bat} alt={<FormattedMessage id='Bat' />} width="18px" />
                                              </Fragment>
                                              : <Fragment>
                                                {/* <img src={Dollar} alt={<FormattedMessage id='Dollar' />} width="18px" /> */}
                                                <img src={Ball} alt={<FormattedMessage id='Bat' />} width="18px" />
                                              </Fragment>
                                            : data && data.iTossWinnerId && data.oAwayTeam && data.iTossWinnerId === data.oAwayTeam.iTeamId
                                              ? data.eTossWinnerAction === 'BAT'
                                                ? <img src={Ball} alt={<FormattedMessage id='Ball' />} width="18px" />
                                                : <img src={Bat} alt={<FormattedMessage id='Bat' />} width="18px" />
                                              : ''
                                          }
                                        </>
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div className='time-middle-match'>
                                  {
                                    data.eStatus === 'U' &&
                                    <div className={(new Date(data.dStartDate) > Date.now() + 86400000) || (new Date(data.dStartDate) < new Date(Date.now())) ? 'fixTime' : 'time'}>{time} </div>
                                  }
                                  {
                                    data.eStatus === 'L' &&
                                    <div className='status-text status-text-live'><FormattedMessage id='Matches_Live' /></div>
                                  }
                                  {
                                    data.eStatus === 'CMP' &&
                                    <div className='status-text status-text-completed'>
                                      <YouWonReturn data={data} />
                                    </div>
                                  }
                                  {
                                    data.eStatus === 'I' &&
                                    <div className='status-text status-text-inreview'><FormattedMessage id='Match_Review' /></div>
                                  }
                                  {
                                    data.eStatus === 'CNCL' &&
                                    <div className='status-text status-text-abandoned'><FormattedMessage id='Match_Abandoned' /></div>
                                  }
                                </div>
                                <div className="team d-flex align-items-center">
                                  <div className="name">
                                    <h3 ref={shortTeamNameRef} className={shouldShortTeamNameMarquee ? 'marquee-text text-right' : 'text-right'} >{data.oAwayTeam.sShortName}</h3>
                                    <div className="d-flex justify-content-end name-img">
                                      {data && data.iTossWinnerId &&
                                        <>
                                          {data && data.iTossWinnerId && data.iTossWinnerId === data.oAwayTeam.iTeamId
                                            ? data.eTossWinnerAction === 'BAT'
                                              ? <Fragment>
                                                {/* <img src={Dollar} alt={<FormattedMessage id='Dollar' />} width="18px" /> */}
                                                <img src={Bat} alt={<FormattedMessage id='Bat' />} width="18px" />
                                              </Fragment>
                                              : <Fragment>
                                                {/* <img src={Dollar} alt={<FormattedMessage id='Dollar' />} width="18px" /> */}
                                                <img src={Ball} alt={<FormattedMessage id='Bat' />} width="18px" />
                                              </Fragment>
                                            : data && data.iTossWinnerId && data.oHomeTeam && data.iTossWinnerId === data.oHomeTeam.iTeamId
                                              ? data.eTossWinnerAction === 'BAT'
                                                ? <img src={Ball} alt={<FormattedMessage id='Ball' />} width="18px" />
                                                : <img src={Bat} alt={<FormattedMessage id='Bat' />} width="18px" />
                                              : ''
                                          }
                                        </>
                                      }
                                    </div>
                                    {props.icons
                                      ? <div className="d-flex justify-content-end">
                                        <img src={Ball} alt={<FormattedMessage id='Ball' />} width="18px" />
                                      </div>
                                      : ''
                                    }
                                  </div>
                                  <div className="t-img"><img src={data && data.oAwayTeam && data.oAwayTeam.sImage ? `${url}${data.oAwayTeam.sImage}` : AwayTeam} onError={(e) => handleImgError(e, AwayTeam)} /></div>
                                </div>
                              </div>
                              {/* Full name of the team */}
                              <div className='m-name middle-team-box' style={{ margin: '0' }}>
                                <div className='team d-flex align-items-centre'>
                                  <div className='fullname'>
                                    <h3 ref={fullHomeTeamNameRef} className={shouldHomeFullTeamNameMarquee || shouldAwayFullTeamNameMarquee ? 'marquee-fullname' : ''}>{data.oHomeTeam.sName}</h3>
                                  </div>
                                </div>
                                <div className='team-middle-match' style={{ padding: '2px 58px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', width: '120px', flexShrink: '0' }}></div>
                                <div className='team d-flex align-items-centre'>
                                  <div className='fullname'>
                                    <h3 ref={fullAwayTeamNameRef} className={shouldAwayFullTeamNameMarquee || shouldHomeFullTeamNameMarquee ? 'marquee-fullname' : ''}>{data.oAwayTeam.sName}</h3>
                                  </div>
                                </div>
                              </div>
                              {data && data.bLineupsOut && data.eStatus === 'U' &&
                                <div className='d-flex justify-content-center lineups-container' onClick={() => setLineups(true)}>
                                  <p className='lineups-btn lineups-blue d-flex align-items-center'>
                                    <FormattedMessage id="Matches_LineupsOut" />
                                    <img className='ml-1' width="14px" src={lineupsOutInfoIcon} />
                                  </p>
                                </div>
                              }
                              {data && (data.eStatus === 'CMP' || data.eStatus === 'CNCL') &&
                                <div className='d-flex justify-content-center lineups-container'>
                                  <p className='date-time-info date-time-gray d-flex align-items-center'>
                                    {(data && data.dStartDate ? moment(data && data.dStartDate).format('Do MMM, h:mmA') : '')}
                                  </p>
                                </div>
                              }
                              {data && !data.bLineupsOut && data.eStatus === 'U' && time && (!(new Date(data.dStartDate) > Date.now() + 86400000) || (new Date(data.dStartDate) < new Date(Date.now()))) && <p className='lineups-btn time-remaining-lineups'><FormattedMessage id="Time_remaining" /></p>}
                              {(data?.nTeams || (data?.nLeagueTotalPayout !== 0 && data?.nLeagueTotalPayout)) &&
                                <div className={
                                  `footer-m d-flex align-items-center ${((data.nTeams && data.bLineupsOut && data.eStatus === 'U') || (data.eStatus === 'CMP' && (data.nWinnings || data.nBonusWin || data.aExtraWin?.length))) ? 'justify-content-between' : ''} ${(data.nTeams && data.bLineupsOut && data.eStatus !== 'U') ? 'justify-content-center' : ''}${(data.nTeams && !data.bLineupsOut) ? 'justify-content-center' : ''} ${(!data.nTeams && data.bLineupsOut) ? 'justify-content-center' : ''}
                                  `}>
                                  {data.nTeams
                                    ? <div className='d-flex flex-column w-100'>
                                      <hr className='m-0 footer-hr' />
                                      <ul className="d-flex align-items-center justify-content-between mt-2 mb-2">
                                        <li><img src={teams} /><span className='pl-1'>{`${data.nTeams} `}<FormattedMessage id="League_Team" /></span></li>
                                        <li><span>{`${data.nJoinedLeague ? data.nJoinedLeague : '0'}  `}</span><FormattedMessage id="League_CONTEST" /><img className='pl-1' src={contest} /></li>
                                      </ul>
                                    </div>
                                    : ''
                                  }
                                  {data?.iGrandLeagueId
                                    ? <div className='d-flex flex-column w-100'>
                                      <hr className='m-0 footer-hr' />
                                      <ul className="d-flex align-items-center justify-content-between mt-2 mb-2">
                                        <li><img width={20} src={data?.sGrandLeagueImage ? `${url}${data?.sGrandLeagueImage}` : megaContest} /><span className='pl-1'>{data?.sGrandLeagueName} - {data?.eGrandPayoutType ? <img src={CrownUtils} /> : currencyLogo} {currencyConvert(data?.nLeagueTotalPayout)}</span></li>
                                      </ul>
                                    </div>
                                    : ''
                                  }
                                  {/* NOTE: Winning Text */}
                                  {/* {
                                      data && (data.eStatus === 'CMP')
                                        ? <b className="text-success">
                                            {
                                              data && data.nWinnings >= 1 && !data.nBonusWin >= 1 && data.aExtraWin?.length === 0
                                                ? (<Fragment> <FormattedMessage id="WON" /> {parseFloat(data.nWinnings.toFixed(2))} </Fragment>)
                                                : data && !data.nWinnings >= 1 && data.nBonusWin >= 1 && data.aExtraWin?.length === 0
                                                  ? (<Fragment> <FormattedMessage id="WON" /> {parseFloat(data.nBonusWin.toFixed(2))} Bonus </Fragment>)
                                                  : data && !data.nWinnings >= 1 && !data.nBonusWin >= 1 && data.aExtraWin?.length === 1
                                                    ? (<Fragment> <FormattedMessage id="ONLY_WON" /> {data.aExtraWin[0]?.sInfo} </Fragment>)
                                                    : data && !data.nWinnings >= 1 && !data.nBonusWin >= 1 && data.aExtraWin?.length >= 2
                                                      ? (<Fragment> <FormattedMessage id="ONLY_WON_Gadget" /> </Fragment>)
                                                      : data && !data.nWinnings >= 1 && !data.nBonusWin >= 1 && data.aExtraWin?.length === 0
                                                        ? ''
                                                        : (<Fragment> <FormattedMessage id="WON_Multiple_Prizes" /> </Fragment>)
                                            }
                                          </b>
                                        : ''
                                    } */}
                                </div>
                              }
                              {
                                // data && data.bWinningZone && data.eStatus === 'L' &&
                                // <div className="bg-white d-flex align-items-center justify-content-center">
                                //   <Badge color="info" className='winning-zone' pill>
                                //     <img src={Trophy}></img>
                                //     <span className='mt-2 winning-text'>
                                //       <FormattedMessage id="Match_Winning_Zone" />
                                //     </span>
                                //   </Badge>
                                // </div>
                              }
                              {data && data.sStreamUrl && props.liveStream && !props.completed && <Button className="live-stream-btn-live" onClick={() => {
                                if (props.liveStream) {
                                  if (data.sStreamUrl.includes('https://www.youtube.com/')) {
                                    setVideoStream(true)
                                  } else {
                                    setVideoStream(true)
                                    setAlert(true)
                                    setTimeout(() => {
                                      setVideoStream(false)
                                      setAlert(false)
                                    }, 1000)
                                    setAlertMessage('something went wrong.')
                                  }
                                }
                              }}><FormattedMessage id="Matches_Watch_Live" /></Button>}
                              {data && data.sStreamUrl && props.liveStream && props.completed && <Button className='live-stream-btn-cmp' onClick={() => {
                                if (props.liveStream) {
                                  if (data.sStreamUrl.includes('https://www.youtube.com/')) {
                                    setVideoStream(true)
                                  } else {
                                    setVideoStream(true)
                                    setAlert(true)
                                    setTimeout(() => {
                                      setVideoStream(false)
                                      setAlert(false)
                                    }, 1000)
                                    setAlertMessage('something went wrong.')
                                  }
                                }
                              }}><FormattedMessage id="Matches_Watch_Now" /></Button>}

                            </div>
                          </Fragment>)}
                  </Fragment>
                }
              </Fragment>
            </>
        }
      </div>
      {timeUpModal
        ? <>
          <div className="s-team-bg"></div>
          <div className="alertpopup-wrap">
            <div className="alertpopup">
              <h3>Time up! Contest has started</h3>
              <p className="mb-2">Don’t worry, you can participate in contests from upcoming matches!</p>
              <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
              <Button className="" color='primary' onClick={() => props.location.pathname.includes('/home/') ? props.history.go(0) : props.history.push(`/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`)}>View Upcoming Matches</Button>
            </div>
          </div>
        </>
        : ''}
      {VideoStream
        ? <div className="player-info videoStream">
          <div className="league-header u-header">
            <div className="d-flex align-items-center header-i">
              <button onClick={(e) => {
                e.preventDefault()
                setVideoStream(false)
              }} className="btn-link icon-left-arrow"></button>
              <Button className='button-link bg-transparent py-2' tag={Link} to={`/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`}><img src={home} /></Button>
              <div>
                <h1>{data && data.sName ? data.sName : ''} {data && data.eStatus === 'I' ? <FormattedMessage id="Match_IN_Review" /> : ''}</h1>
                {!props.completed ? <p>{time || (data && data.dStartDate ? moment(data && data.dStartDate).format('MMMM Do YYYY, h:mm:ss a') : '')}</p> : data && data.eStatus && data.eStatus === 'L' ? <p><FormattedMessage id="Matches_Live" /></p> : data && data.eStatus === 'CMP' && <p><FormattedMessage id="Matches_Completed" /></p>}
              </div>
            </div>
          </div>
          <div className='videoShowing'>
            <iframe
              src={data && data.sStreamUrl}
              frameBorder='0'
              allow='autoplay; encrypted-media'
              title='video'
            />
          </div>
        </div>
        : ''}
      {lineups
        ? <>
          <div className="s-team-bg" onClick={() => setLineups(false)}></div>
          <Card className='filter-card lineupsCard'>
            <CardHeader className='d-flex align-items-center justify-content-between'>
              <h2><FormattedMessage id="League_announced_Lineups" /></h2>
              <button onClick={() => setLineups(false)}><img src={CloseIcon} ></img></button>
            </CardHeader>
            <CardBody className='filter-box'>
              <Match {...props} data={data} key={1} upcoming onlyInsideFeild lineupsOutMatch />
              {
                Array(maxValue(teamHome?.length, teamAway?.length)).fill().map((item, index) => {
                  const teamHomeName = teamHome[index]?.sName.split(' ')
                  const teamAwayName = teamAway[index]?.sName.split(' ')
                  return (
                    <Fragment key={index}>
                      <div className="compare-player bg-white">
                        <div className="p-c-box d-flex align-items-center justify-content-between">
                          {teamHome.length > 0
                            ? <div className={'p-box d-flex align-items-center'}>
                              <div className="img">
                                {/* <img src={teamHome[index]?.sImage && url ? `${url}${teamHome[index]?.sImage}` : match && match.params && match.params.sportsType === 'cricket' ? cricketStaticImage(teamHome[index]?.eRole) : match && match.params && match.params.sportsType === 'football' ? footballStaticImage(teamHome[index]?.eRole) : ''} alt="" /> */}
                                <img src={teamHome[index] && teamHome[index].sImage ? `${url}${teamHome[index].sImage}` : PlayerImage} alt="" />
                              </div>
                              <div className="p-name">
                                <h3 className='p-0'>{teamHome[index]?.sName && `${teamHomeName && teamHomeName.length >= 2 ? teamHome[index]?.sName[0] : teamHomeName[0]} ${teamHomeName && teamHomeName.length === 2 ? teamHomeName[1] : teamHomeName && teamHomeName.length === 3 ? `${teamHomeName[2]}` : ''}`}</h3>
                                <p>{teamHome[index]?.eRole}</p>
                              </div>
                            </div>
                            : <div></div>}
                          {teamAway.length > 0
                            ? <div className={'p-box d-flex align-items-center'}>
                              <div className="p-name text-right">
                                <h3 className='p-0'>{teamAway[index]?.sName && `${teamAwayName && teamAwayName.length >= 2 ? teamAway[index]?.sName[0] : teamAwayName[0]} ${teamAwayName && teamAwayName.length === 2 ? teamAwayName[1] : teamAwayName && teamAwayName.length === 3 ? `${teamAwayName[1]} ${teamAwayName[2]}` : ''}`}</h3>
                                <p>{teamAway[index]?.eRole}</p>
                              </div>
                              <div className="img">
                                {/* <img src={teamAway[index]?.sImage && url ? `${url}${teamAway[index]?.sImage}` : match && match.params && match.params.sportsType === 'cricket' ? cricketStaticImage(teamAway[index]?.eRole) : match && match.params && match.params.sportsType === 'football' ? footballStaticImage(teamAway[index]?.eRole) : ''} alt="" /> */}
                                <img src={teamAway[index] && teamAway[index].sImage ? `${url}${teamAway[index].sImage}` : PlayerImage} alt="" />
                              </div>
                            </div>
                            : <div></div>}
                        </div>
                      </div>
                    </Fragment>
                  )
                })
              }
              {!Array(maxValue(teamHome?.length, teamAway?.length)).fill().length &&
                <div className="Lineups-nodata">
                  <div className='text-center'>
                    <h2><FormattedMessage id="No_Data_Found" /></h2>
                    <p><FormattedMessage id="Please_Check_back" /></p>
                  </div>
                </div>
              }
            </CardBody>
            {/* <CardFooter className='bg-trnsparent'>
              <p><FormattedMessage id="League_Notes" /></p>
              <span className='spanColor'> <FormattedMessage id="League_Orange_Text" /></span>
            </CardFooter> */}
          </Card>
        </>
        : ''}
    </>
  )
}

Match.propTypes = {
  data: PropTypes.shape({
    length: PropTypes.number,
    bDisabled: PropTypes.bool,
    sAwayTeamShortName: PropTypes.string,
    map: PropTypes.func,
    sInfo: PropTypes.string,
    sSeasonName: PropTypes.string,
    eCategory: PropTypes.string,
    sSponsoredText: PropTypes.string,
    sName: PropTypes.string,
    oHomeTeam: PropTypes.object,
    oAwayTeam: PropTypes.object,
    sHomeTeamShortName: PropTypes.string,
    dStartDate: PropTypes.string,
    iTossWinnerId: PropTypes.string,
    sStreamUrl: PropTypes.string,
    eTossWinnerAction: PropTypes.string,
    nTeams: PropTypes.number,
    bLineupsOut: PropTypes.bool,
    nJoinedLeague: PropTypes.number,
    nBonusWin: PropTypes.number,
    nCrownWin: PropTypes.number,
    aExtraWin: PropTypes.array,
    _id: PropTypes.string,
    iMatchId: PropTypes.string,
    eStatus: PropTypes.string,
    nWinnings: PropTypes.number,
    bWinningZone: PropTypes.bool,
    nLeagueTotalPayout: PropTypes.number,
    iGrandLeagueId: PropTypes.string,
    sGrandLeagueName: PropTypes.string,
    sGrandLeagueImage: PropTypes.string,
    eGrandPayoutType: PropTypes.string
  }),
  match: PropTypes.object,
  icons: PropTypes.bool,
  completed: PropTypes.bool,
  loading: PropTypes.bool,
  upcoming: PropTypes.bool,
  liveStream: PropTypes.bool,
  noRedirect: PropTypes.bool,
  onlyInsideFeild: PropTypes.bool,
  live: PropTypes.bool,
  url: PropTypes.string,
  matchDetailsList: PropTypes.array,
  subIndex: PropTypes.number,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  history: PropTypes.shape({
    go: PropTypes.func,
    push: PropTypes.func
  }),
  lineupsOutMatch: PropTypes.bool,
  noOpenUpcoming: PropTypes.bool,
  cancel: PropTypes.bool
}

export default Match
