import React, { Fragment, useState, useEffect } from 'react'
import PlayerDetails from '../../../HOC/SportsLeagueList/PlayerDetails'
import Loading from '../../../component/SkeletonTable'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
// import { cricketStaticImage, footballStaticImage } from '../../../helper'
import PlayerImage from '../../../../src/assests/images/PlayerImage.svg'
import CloseIcon from '../../../assests/images/close-icon.svg'
import HomeTeam from '../../../assests/images/Team1.png'
import AwayTeam from '../../../assests/images/Team2.png'
import { allSportsRolesSingular, handleImgError, pageViewPost } from '../../../helper'

function PlayerInfo (props) {
  const { playerData, pointBreakUp, nScoredPoints, loading, url, playerList, matchDetails, playerScorePoints, playerSeasonNames, pID } = props
  const [myPlayer, setMyPlayer] = useState()

  useEffect(() => {
    if (pID) {
      playerScorePoints(pID)
      playerSeasonNames(pID)
      pageViewPost('pl_if', pID)
    }
  }, [pID])

  useEffect(() => {
    if (playerList && playerData) {
      const player = playerList.find((p) => p._id === playerData._id)
      setMyPlayer(player)
    }
  }, [playerList, playerData])

  return (
    <>
      {loading && <Loading series Lines={7}/>}
      <div className="s-team-bg" onClick={() => props.onBackClick()}></div>
      <div className="filter-card player-info p-league-info pb-0">
        {/* <div className="league-header u-header">
          <div className="d-flex align-items-center header-i">
            <button onClick={() => props.onBackClick()} className="btn-link icon-left-arrow"></button>
            <div>
              <h1 className="text-uppercase">{playerData && playerData.sName}</h1>
            </div>
          </div>
        </div> */}
        <div className='card-header d-flex align-items-center justify-content-between m-0'>
          <h2><FormattedMessage id="League_playerStats" /></h2>
          <button onClick={() => props.onBackClick()} ><img src={CloseIcon}></img></button>
        </div>
        <div className='popup-content'>
          <div className="container-inside">
             <div className='player-info-card'>
              <div className='player-detail'>
                <div className='player-img'>
                  <img className='h-100 v-100 fullBorderRadius' src={playerData?.sImage && url ? `${url}${playerData?.sImage}` : PlayerImage} alt="player" />
                </div>
                <div className="player-data">
                  <h2>{playerData && playerData.sName}</h2>
                  <div className='player-stat'>
                    <div className='d-flex'>
                      <p><FormattedMessage id="Player_points" /> <b>{nScoredPoints || <FormattedMessage id="Team_0" />}</b></p>
                      <p><FormattedMessage id="Player_credits" /> <b>{playerData && playerData.nFantasyCredit ? playerData.nFantasyCredit : <FormattedMessage id="Team_0" />}</b></p>
                      <p><FormattedMessage id="Player_selectedBy" /> <b>{playerData && playerData.nSetBy ? playerData.nSetBy : <FormattedMessage id="Team_0" />}<FormattedMessage id="Common_perc" /></b></p>
                    </div>
                    <div className='d-flex'>
                      <p><FormattedMessage id="Player_Captain_by" /> <b>{playerData && playerData.nCaptainBy ? playerData.nCaptainBy : <FormattedMessage id="Team_0" />}<FormattedMessage id="Common_perc" /></b></p>
                      <p><FormattedMessage id="Player_Vice_Captain_by" /> <b>{playerData && playerData.nViceCaptainBy ? playerData.nViceCaptainBy : <FormattedMessage id="Team_0" />}<FormattedMessage id="Common_perc" /></b></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='player-bats-info'>
                <div className='county-img mr-2'>
                  <img src={myPlayer && myPlayer?.oTeam?.sImage && url ? `${url}${myPlayer?.oTeam?.sImage}` : matchDetails?.oHomeTeam?.sShortName === myPlayer?.oTeam?.sShortName ? HomeTeam : AwayTeam} onError={(e) => handleImgError(e, (matchDetails?.oHomeTeam?.sShortName === myPlayer?.oTeam?.sShortName ? HomeTeam : AwayTeam))} alt="img" />
                </div>
                <span className='player-role'>{allSportsRolesSingular(playerData?.eRole)} {playerData && playerData.aPlayingStyle.map((item, index) => {
                  return (
                        <span key={index}>
                          , {item}
                        </span>
                  )
                })
                }</span>
              </div>
            </div>
            {/* <div className="player-detail d-flex align-items-center">
              <div className="p-i">
                <img className='h-100 w-100 borderRadius' src={match && match.params && match.params.sportsType === 'cricket' ? cricketStaticImage(playerData && playerData.eRole && playerData.eRole) : match && match.params && match.params.sportsType === 'football' ? footballStaticImage(playerData && playerData.eRole && playerData.eRole) : PlayerImage} alt="" />
              </div>
              <div className="player-d-i d-flex flex-wrap">
                <div className="item">
                  <p><FormattedMessage id="Player_selectedBy" /></p>
                  <b>{playerData && playerData.nSetBy ? playerData.nSetBy : <FormattedMessage id="Team_0" />}<FormattedMessage id="Common_perc" /></b>
                </div>
                <div className="item text-center">
                  <p><FormattedMessage id="Player_points" /></p>
                  <b>{nScoredPoints || <FormattedMessage id="Team_0" />}</b>
                </div>
                <div className="item text-right">
                  <p><FormattedMessage id="Player_credits" /></p>
                  <b>{playerData && playerData.nFantasyCredit ? playerData.nFantasyCredit : <FormattedMessage id="Team_0" />}</b>
                </div>
                <div className="item">
                  <p><FormattedMessage id="Player_c" /></p>
                  <b>{playerData && playerData.nCaptainBy ? playerData.nCaptainBy : <FormattedMessage id="Team_0" />}<FormattedMessage id="Common_perc" /></b>
                </div>
                <div className="item text-center">
                  <p><FormattedMessage id="Player_vc" /></p>
                  <b>{playerData && playerData.nViceCaptainBy ? playerData.nViceCaptainBy : <FormattedMessage id="Team_0" />}<FormattedMessage id="Common_perc" /></b>
                </div>
              </div>
            </div> */}

            <Table className="player-d-t price-table table-radius bg-white mt-3 table">
              <thead>
                <tr>
                  <th><FormattedMessage id="Player_event" /></th>
                  <th><FormattedMessage id="Player_action" /></th>
                  <th><FormattedMessage id="Player_points" /></th>
                </tr>
              </thead>
              <tbody>
                {
                  loading
                    ? <Loading series={true} Lines={7}/>
                    : pointBreakUp && pointBreakUp.length && pointBreakUp.length !== 0
                      ? pointBreakUp.map(points => {
                        return (
                          <tr key={points._id}>
                            <td>{points && points.sName && points.sName}</td>
                            <td>{points && points.nPoint && points.nScoredPoints ? parseFloat(Number((points.nScoredPoints / points.nPoint)).toFixed(2)) : '-'}</td>
                            <td>{points && points.nScoredPoints && points.nScoredPoints} </td>
                          </tr>
                        )
                      })
                      : <Fragment>
                        <tr>
                          <td colSpan='3'>
                            <FormattedMessage id="NO_PointBreakup_Avail" />
                          </td>
                        </tr>
                      </Fragment>
                }
                <tr>
                        <td colSpan="2"><FormattedMessage id="Common_Total" /></td>
                        <td className='text-center'>{nScoredPoints}</td>
                      </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}
PlayerInfo.propTypes = {
  onBackClick: PropTypes.func,
  playerData: PropTypes.shape({
    _id: PropTypes.string,
    nViceCaptainBy: PropTypes.number,
    nCaptainBy: PropTypes.number,
    nFantasyCredit: PropTypes.number,
    nSetBy: PropTypes.number,
    eRole: PropTypes.string,
    sName: PropTypes.string,
    sImage: PropTypes.string,
    aPlayingStyle: PropTypes.array
  }),
  match: PropTypes.object,
  pointBreakUp: PropTypes.array,
  nScoredPoints: PropTypes.number,
  loading: PropTypes.bool,
  url: PropTypes.string,
  playerList: PropTypes.array,
  matchDetails: PropTypes.object,
  playerScorePoints: PropTypes.func,
  playerSeasonNames: PropTypes.func,
  pID: PropTypes.string
}
export default PlayerDetails(PlayerInfo)
