import React, { Fragment } from 'react'
import { Card } from 'reactstrap'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

function SkeletonPlayerLeagueInfo (props) {
  const { length } = props
  return (
    <Fragment>
      {
        Array(length || 2)
          .fill()
          .map((index) => (
            <Card key={index} className='leagues-card ml-0 mr-0'>
              <div className="fantasy-stats-cards mb-0">
                <div className="card-title d-flex align-items-center">
                  <Skeleton height={16} width={180} />
                </div>
                <div className="player-bats-info-three d-flex flex-wrap">
                  <div className="player-bats-info-three-inner text-center">
                    <Skeleton height={16} width={100} />
                    <Skeleton height={20} width={60} />
                  </div>
                  <div className="player-bats-info-three-inner text-center">
                    <Skeleton height={16} width={100} />
                    <Skeleton height={20} width={60} />
                  </div>
                  <div className="player-bats-info-three-inner text-center">
                    <Skeleton height={16} width={100} />
                    <Skeleton height={20} width={60} />
                  </div>
                  <div className="player-bats-info-three-inner text-center">
                    <Skeleton height={16} width={100} />
                    <Skeleton height={20} width={60} />
                  </div>
                  <div className="player-bats-info-three-inner text-center">
                    <Skeleton height={16} width={100} />
                    <Skeleton height={20} width={60} />
                  </div>
                  <div className="player-bats-info-three-inner text-center">
                    <Skeleton height={16} width={100} />
                    <Skeleton height={20} width={60} />
                  </div>
                </div>
              </div>
            </Card>
          ))
      }
    </Fragment>

  )
}

SkeletonPlayerLeagueInfo.propTypes = {
  length: PropTypes.number
}

export default SkeletonPlayerLeagueInfo
