import React from 'react'
import PlayerLeagueInfo from './ViewPlayerLeagueInfo'
import PropTypes from 'prop-types'

function PlayerLeagueInfoIndex (props) {
  return (
    <>
      <PlayerLeagueInfo {...props} pId={props.pId} isSeasonPoint />
    </>
  )
}

PlayerLeagueInfoIndex.propTypes = {
  pId: PropTypes.string
}

export default PlayerLeagueInfoIndex
