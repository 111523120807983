import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { Table, Card } from 'reactstrap'

function SkeletonPlayerStatus (props) {
  const { teams } = props
  return (
    <Fragment>
      {
        teams
          ? <Fragment>
        {Array(teams)
          .fill()
          .map((item, index) => (
            <Card key={index} className='mt-1 ml-2 mr-2 leagues-card'>
              <div className="match-box mr-3" key={index}>
                <div className='d-flex justify-content-between'>
                  <Skeleton height={20} width={100} />
                  <Skeleton height={20} width={100} />
                </div>
                <div className='team-p d-flex align-items-center'>
                  <div width={75} className='mr-5 player'>
                    <div className='img'>
                      <Skeleton circle={true} height={80} width={80} className='mt-2 ml-4'/>
                    </div>
                    <Skeleton height={20} width={80} className='ml-4 mb-3'/>
                  </div>
                  <div width={75} className='mr-5 player'>
                    <div className='img'>
                      <Skeleton circle={true} height={80} width={80} className='mt-2 ml-4'/>
                    </div>
                    <Skeleton height={20} width={80} className='ml-4 mb-3'/>
                  </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <Skeleton height={20} width={40} className='ml-4'/>
                  <Skeleton height={20} width={40} />
                  <Skeleton height={20} width={40} />
                  <Skeleton height={20} width={40} className='mr-4'/>
                </div>
              </div>
            </Card>
          ))}
        </Fragment>
          : <Fragment>
          {
            Array(1)
              .fill()
              .map((item, index) => (
                <Table key={index} className='bg-white player-list player-stats-table m-0'>
                    <thead >
                      <tr>
                        <th><Skeleton height={20} width={50} /></th>
                        <th><Skeleton height={20} width={30} /></th>
                        <th><Skeleton height={20} width={30} /></th>
                        <th><Skeleton height={20} width={30} /></th>
                        <th><Skeleton height={20} width={30} /></th>
                      </tr>
                    </thead>
                    { Array(props.numberOfColumns)
                      .fill()
                      .map((ind) => {
                        return (
                          <tr key={ind}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="l-img">
                                  <Skeleton circle={true} className="border-0" height={20} width={20} />
                                </div>
                                <div className='ml-2'>
                                  <h4 className="p-name"><Skeleton height={10} width={80} /></h4>
                                  <p className="c-name"><Skeleton height={10} width={30} /><span className="role ml-2"><Skeleton height={10} width={30} /></span></p>
                                </div>
                              </div>
                            </td>
                            <td><Skeleton height={10} width={30} /></td>
                            <td><Skeleton height={10} width={30} /></td>
                            <td><Skeleton height={10} width={30} /></td>
                            <td><Skeleton height={10} width={30} /></td>
                          </tr>
                        )
                      })}
                </Table>
              ))
          }
        </Fragment>
      }
    </Fragment>
  )
}

SkeletonPlayerStatus.propTypes = {
  numberOfColumns: PropTypes.number,
  teams: PropTypes.bool
}

export default SkeletonPlayerStatus
