import React from 'react'
import { Link } from 'react-router-dom'
import { Row } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

function NotFound (props) {
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <Row>
        <div className='notFound'>
          <h1><span className='span'><FormattedMessage id="NotFound_404" /></span><br></br><h1><FormattedMessage id="NotFound_Page" /></h1></h1>
          <br></br>
          <Link to={`/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`}>
            <FormattedMessage id="NotFound_GoHome" />
          </Link>
        </div>
      </Row>
    </div>
  )
}

export default NotFound
