/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect, useRef, lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import Diamond from '../../../assests/images/diamond.svg'
// import DiamondDisable from '../../../assests/images/diamond_disable.svg'
import Multi from '../../../assests/images/multi-entry.svg'
import Single from '../../../assests/images/single-entry.svg'
// import MultiDisable from '../../../assests/images/multi_disable.svg'
import Copy from '../../../assests/images/League-status.svg'
import CopyDisable from '../../../assests/images/League-status-disabled.svg'
import CopyGuaranteed from '../../../assests/images/League-status-guaranteed.svg'
import Loyalty from '../../../assests/images/ic_Loyaly_colored.svg'
import CrownUtils from '../../../assests/images/crown-utils.svg'
// import LoyaltyDisable from '../../../assests/images/ic_Loyaly_colored_disable.svg'
import Trophy from '../../../assests/images/trophy.svg'
import Medal from '../../../assests/images/medal.svg'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter, CardHeader, Badge, Table, Input, Label, FormGroup, Alert, UncontrolledTooltip } from 'reactstrap'
import offerIcon from '../../../assests/images/cashback.svg'
import JoinContest from '../../../HOC/SportsLeagueList/JoinContest'
import Loading from '../../../component/Loading'
import PromocodeLoading from '../../../component/PromocodeLoading'
import { viewContest, joinTeam } from '../../../Analytics.js'
import createteam from '../../../assests/images/createTeamWhite.svg'
import createContest from '../../../assests/images/createContestWhite.svg'
import close from '../../../assests/images/close.svg'
import rightGreenArrow from '../../../assests/images/right-green-arrow.svg'
import ProfileIcon from '../../../assests/images/profile.png'
import qs from 'query-string'
import SkeletonTable from '../../../component/SkeletonTable'
import { currencyConvert } from '../../../helper'
const MyTeam = lazy(() => import('./MyTeam'))
const classNames = require('classnames')
const userData = JSON.parse(localStorage.getItem('userData'))

function CreateContestLeague (props) {
  const {
    data,
    joinContest,
    match,
    teamList,
    contestJoinList,
    participated,
    toggleMessage,
    modalMessage,
    applyPromocodeData,
    onGetUserProfile,
    setModalMessage,
    joined,
    applyPromo,
    userInfo,
    loading,
    matchID,
    tab,
    firstPrize,
    firstRankType,
    getMyTeamList,
    matchType,
    activeTab,
    location,
    appliedPromocode,
    onGetPromocodeList,
    matchPromoCodeList,
    promocodeLoading,
    currencyLogo,
    showInformation,
    amountData,
    MatchLeagueId,
    joinDetails,
    insideleagueDetailsPage,
    setLoading,
    url
  } = props
  const [noOfJoin, setNoOfJoin] = useState('')
  const [userTeams, setUserTeams] = useState([])
  const [promoData, setPromoData] = useState('')
  const [alert, setAlert] = useState(false)
  const [applied, setApplied] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [finalPromocode, setFinalPromocode] = useState('')
  const [PromoCodes, setPromoCodes] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)
  const [userTeamId, setUserTeamId] = useState('')
  const [updatedFilterData, setUpdatedFilterData] = useState([])
  const [IsJoinLeague, setIsJoinLeague] = useState(false)
  const [modalMessage2, setModalMessage2] = useState(false)
  const [totalPay, setTotalPay] = useState(0)
  const [totalPayFinal, setTotalPayFinal] = useState(0)
  const [UpdatedTeamList, setUpdatedTeamList] = useState([])
  const [constestFill, setConstestFill] = useState('green')
  const matchDetails = useSelector(state => state.match.matchDetails)
  const previousProps = useRef({
    teamList, userInfo, applyPromocodeData, amountData
  }).current
  const obj = qs.parse(location.search)

  useEffect(() => {
    setSelectedAll(false)
  }, [])

  useEffect(() => {
    if (((100 * data?.nJoined) / data?.nMax) >= 70 && ((100 * data?.nJoined) / data?.nMax) < 100) {
      setConstestFill('yellow')
    } else if (((100 * data?.nJoined) / data?.nMax) === 100) {
      setConstestFill('red')
    } else {
      setConstestFill('green')
    }
  }, [data])

  useEffect(() => {
    if (teamList !== previousProps.teamList && IsJoinLeague) {
      if (IsJoinLeague && teamList && teamList.length !== 0) {
        data && data._id && joint(data._id)
      }
    }
    if (teamList?.length > 0) {
      setUserTeams([teamList[0]?._id])
    }
    return () => {
      previousProps.teamList = teamList
    }
  }, [teamList])

  useEffect(() => {
    if (IsJoinLeague) {
      if (!teamList || (teamList && teamList.length > 0 && data && data.iMatchId && teamList[0].iMatchId !== data.iMatchId)) {
        getMyTeamList()
      } else {
        data && data._id && joint(data._id)
      }
    }
  }, [IsJoinLeague])

  useEffect(() => {
    if (userInfo !== previousProps.userInfo) {
      if (userInfo) {
        const promocodeData = applyPromocodeData && applyPromocodeData.nDiscount ? applyPromocodeData.nDiscount : 0
        const updatedFilterDataFinal = updatedFilterData?.length === 0 ? 1 : updatedFilterData?.length
        const value = data && updatedFilterData && ((data.nPrice * updatedFilterDataFinal) - promocodeData - (userInfo && userInfo.nCurrentTotalBalance))
        setTotalPay(value > 0 ? value.toFixed(2) : 0)
        const value2 = (data?.nPrice * updatedFilterDataFinal) - promocodeData
        setTotalPayFinal(value2)
        setLoading(false)
      }
    }
    return () => {
      previousProps.userInfo = userInfo
    }
  }, [userInfo])

  useEffect(() => {
    if (selectedAll) {
      let updatedSelectedTeam = []
      if (UpdatedTeamList && UpdatedTeamList.length > 0) {
        updatedSelectedTeam = UpdatedTeamList.map(data => data._id)
        setUserTeams(updatedSelectedTeam)
      } else if (teamList && teamList.length > 0) {
        updatedSelectedTeam = teamList.map(data => data._id)
        setUserTeams(updatedSelectedTeam)
      }
    } else {
      if (userTeamId) {
        setUserTeams(userTeams.filter((item) => item.id !== userTeamId))
      } else {
        setUserTeams([])
      }
    }
  }, [selectedAll, userTeamId])

  useEffect(() => {
    if (applyPromocodeData !== previousProps.applyPromocodeData) {
      if ((applyPromocodeData && applyPromocodeData.nDiscount && appliedPromocode) || !applyPromocodeData) {
        setFinalPromocode(applyPromocodeData && applyPromocodeData.sCode)
        const promocodeData = applyPromocodeData && applyPromocodeData.nDiscount ? applyPromocodeData.nDiscount : 0
        const updatedFilterDataFinal = updatedFilterData?.length === 0 ? 1 : updatedFilterData?.length
        const value = data && updatedFilterData && ((data.nPrice * updatedFilterDataFinal) - promocodeData - (userInfo && userInfo.nCurrentTotalBalance))
        setTotalPay(value > 0 ? value.toFixed(2) : 0)
        const value2 = (data?.nPrice * updatedFilterDataFinal) - promocodeData
        setTotalPayFinal(value2)
      }
    }
    return () => {
      previousProps.applyPromocodeData = applyPromocodeData
    }
  }, [applyPromocodeData])

  useEffect(() => {
    if (previousProps.appliedPromocode !== appliedPromocode) { // handle the loader
      setApplied(appliedPromocode)
    }
    return () => {
      previousProps.appliedPromocode = appliedPromocode
    }
  }, [appliedPromocode])

  function joint (ID) {
    const contestData = insideleagueDetailsPage
      ? joinDetails
      : contestJoinList && contestJoinList.length !== 0 && contestJoinList.find(contest => contest && contest.iMatchLeagueId === data._id)
    if (contestData) {
      const FilterTeam = contestData && contestData.aUserTeams && contestData.aUserTeams.length > 0
        ? teamList && teamList.length !== 0 && teamList.filter(team => contestData && contestData.aUserTeams && !contestData.aUserTeams.includes(team._id))
        : teamList
      if (FilterTeam && FilterTeam.length !== 0) {
        setUpdatedTeamList(FilterTeam)
        toggleMessage()
        setNoOfJoin(data.bPrivateLeague && data.bMultipleEntry ? data.nMax - data.nJoined : contestData && contestData.nJoinedCount ? (data.nTeamJoinLimit - contestData.nJoinedCount) : data.nTeamJoinLimit)
      } else {
        if (data && data.bPrivateLeague && data.sShareCode) {
          props.history.push({
            pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}/private/${data.sShareCode}`,
            search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            firstTime: true
          })}`,
            state: { activeTab: activeTab }
          })
        } else {
          props.history.push({
            pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}`,
            search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            firstTime: true
          })}`,
            state: { activeTab: activeTab }
          })
        }
      }
    } else {
      if (teamList && teamList.length !== 0) {
        toggleMessage()
        setNoOfJoin(data.bPrivateLeague && data.bMultipleEntry ? data.nMax - data.nJoined : data.nTeamJoinLimit)
      } else {
        if (data && data.bPrivateLeague && data.sShareCode) {
          props.history.push({
            pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}/private/${data.sShareCode}`,
            search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            firstTime: true
          })}`,
            state: { activeTab: activeTab }
          })
        } else {
          props.history.push({
            pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}`,
            search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            firstTime: true
          })}`,
            state: { activeTab: activeTab }
          })
        }
      }
    }
    setIsJoinLeague(false)
  }
  function LeagueJoin (userTeams) {
    setModalMessage2(false)
    if (totalPay > 0) {
      props.history.push({
        pathname: '/deposit',
        state: {
          amountData: { nAmount: Number(totalPay) },
          message: 'Insufficient Balance'
        }
      })
    } else {
      applied && finalPromocode ? joinContest(userTeams, finalPromocode) : joinContest(userTeams, '')
      callJoinTeamEvent()
    }
  }

  const applePromoCode = (promo) => {
    if (data && data._id && updatedFilterData && updatedFilterData.length > 0 && promo) {
      applyPromo({ iMatchLeagueId: data._id, nTeamCount: updatedFilterData.length, sPromo: promo })
      setPromoCodes(false)
      setPromoData('')
      setModalMessage2(true)
      setFinalPromocode('')
    }
  }
  function callViewContestEvent () {
    if (data && data.sName && data._id && location.pathname) {
      viewContest(data.sName, data._id, location.pathname)
    } else {
      data && data.sName && data._id && viewContest(data.sName, data._id, '')
    }
    if (PromoCodes) setPromoCodes(false)
  }

  function callJoinTeamEvent () {
    if (userTeams && match && match.params && match.params.id && location.pathname) {
      joinTeam(userTeams, match.params.id, location.pathname)
    } else {
      userTeams && match && match.params && match.params.id && joinTeam(userTeams, match.params.id, '')
    }
  }

  function getPromocodeList () {
    setPromoCodes(true)
    setModalMessage(false)
    setModalMessage2(false)
    data && data._id && onGetPromocodeList(data._id)
  }

  function clearPromo () {
    setTotalPayFinal(updatedFilterData?.length > 0 ? data?.nPrice * updatedFilterData?.length : data?.nPrice)
    setApplied(false)
    setFinalPromocode('')
  }

  return (
    <>
      {loading && <Loading />}
      {alert && alertMessage ? <Alert isOpen={alert} className='select-all' color="primary">{alertMessage}</Alert> : ''}
      <Fragment>
        <div className="card border-0 bg-transparent" onClick={() => callViewContestEvent()}>
          <CardBody className={data && data.bPrivateLeague ? 'bg-white rounded-0' : 'bg-white'}>
              {/* <ul className="m-0 d-flex align-items-center flex-wrap leagues-card-container">
                <li className='d-flex flex-column align-items-between'>
                  <p><FormattedMessage id="League_prizePool" /></p>
                  <h1 className='m-0'>{currencyLogo} {data && data.nTotalPayout && data.nTotalPayout}</h1>
                </li>
                <li className='d-flex flex-column'>
                  {data && data.nLoyaltyPoint !== 0 && data.nLoyaltyPoint && <p>{data.nLoyaltyPoint} Loyalty Points</p>}
                  <div className='d-flex justify-content-end align-items-center'>
                    <p><FormattedMessage id='League_Entry' /></p>
                    <button onClick={() => setIsJoinLeague(true)} disabled={participated || (Object.keys(joined).length > 0 && data && data.bPrivateLeague && !data.bMultipleEntry) || (joined && data && !data.bPrivateLeague && (joined.nJoinedCount === data.nTeamJoinLimit)) || (data && data.nJoined && data.nMax && !data.bUnlimitedJoin && data.nJoined === data.nMax) || (data && joinDetails && !data.bPrivateLeague && (joinDetails.nJoinedCount === data.nTeamJoinLimit))}>{data?.nCrownUtil && data?.nCrownUtil !== 0 ? <><img src={CrownUtils} /> {data?.nCrownUtil}</> : <>{currencyLogo}{data?.nPrice}</>}</button>
                  </div>
                </li>
              </ul> */}
              <div className="private-info d-flex h-w justify-content-between text-center">
                <div className="hw-b">
                  <p><FormattedMessage id="Contest_contestPrize" /></p>
                  <h4>{data?.ePayoutType === 'R' ? currencyLogo : <img src={CrownUtils} width="14px" style={{ marginTop: '-1px' }}/>} {data?.nTotalPayout}</h4>
                </div>
                <div className="hw-b text-center">
                  <p><FormattedMessage id="League_spots" /></p>
                  <h4>{data?.nMax}</h4>
                </div>
                <div className="hw-b text-right">
                  <p><FormattedMessage id="Contest_entryFee" /></p>
                  <h4>{data?.ePayoutType === 'R' ? currencyLogo : <img src={CrownUtils} width="14px" style={{ marginTop: '-1px' }} />} {data?.ePayoutType === 'R' ? currencyConvert(data?.nPrice) : currencyConvert(data?.nCrownUtil)}</h4>
                </div>
              </div>
              {
                data && data.nJoined >= 0
                  ? <Fragment>
                    <div className='leagues-card-container'>
                      {(data && data.bUnlimitedJoin)
                        ? <div className="ul-p-bar"></div>
                        : <div className="p-bar"><span style={{ width: data && data.nMax >= 0 && data.nJoined >= 0 && `${((100 * data.nJoined) / data.nMax)}%`, background: constestFill === 'yellow' ? '#F7AB20' : constestFill === 'red' ? '#FF0000' : '#1CA959' }}></span></div>}
                    </div>
                    <div className="t-info d-flex align-items-center justify-content-between leagues-card-container">
                      <span>
                      {
                          constestFill === 'green'
                            ? (data?.nJoined > data.nMax) && (!data && data.bUnlimitedJoin)
                                ? (
                              <Fragment>
                                0 <FormattedMessage id="League_left" />
                              </Fragment>
                                  )
                                : (
                              <Fragment>
                                {data && data.bUnlimitedJoin ? 'ထ ' : amountData && amountData.sKey === 'SUCCESS' && data._id === MatchLeagueId ? (currencyConvert(data.nMax - (amountData?.oValue?.nJoined))) : data && data.nJoined >= 0 && data.nMax >= 0 && (data.nMax - data.nJoined) >= 0 ? currencyConvert(data.nMax - data.nJoined) : 0} <FormattedMessage id="League_left" />
                              </Fragment>
                                  )
                            : constestFill === 'yellow'
                              ? 'Filling Fast'
                              : constestFill === 'red'
                                ? <span className="text-red">Contest Full</span>
                                : ''
                        }
                      </span>
                      <span className='blackColor'>
                        {
                          (data?.nJoined > data.nMax)
                            ? (
                            <Fragment>
                              {data && data.bUnlimitedJoin ? data?.nJoined + '/ထ' : amountData && amountData.sKey === 'SUCCESS' && data._id === MatchLeagueId ? amountData?.oValue?.nJoined : data && data.nJoined === 0 ? 0 : data.nJoined} {(<span className='blackColor'><FormattedMessage id="League_spots" /></span>)}
                            </Fragment>
                              )
                            : (
                            <Fragment>
                              {data && data.bUnlimitedJoin ? 'ထ' : data && (data.nMax > data.nJoined) ? data.nMax : data.nJoined} {(<span className='blackColor'><FormattedMessage id="League_spots" /></span>)}
                            </Fragment>
                              )
                        }
                      </span>
                    </div>
                  </Fragment>
                  : <Fragment>
                    <div className="p-bar"><span style={{ width: data && data.nMax >= 0 && data.nJoined >= 0 ? ((100 * data.nJoined) / data.nMax) : '' }}> </span> </div>
                    <div className="t-info d-flex align-items-center justify-content-between">
                      <span> <FormattedMessage id="League_left" /> </span>
                      <span> <FormattedMessage id="League_teams" /> </span>
                    </div>
                  </Fragment>
              }
            <CardFooter className="leagues-footer">
              <div className='footer-item' id={`winPrize-${data?._id}`}>
                <img src={Medal} />
                <h4>{firstRankType === 'CW' ? <><img src={CrownUtils} /></> : <>{firstRankType !== 'E' && firstRankType !== 'CW' && currencyLogo}</>} {firstRankType !== 'E' ? parseFloat(Number((firstPrize)).toFixed(2)) : firstPrize} {firstRankType === 'B' && 'Bonus'} </h4>
              </div>
              <UncontrolledTooltip placement="bottom" autohide={false} target={`winPrize-${data?._id}`}>
                1<sup>st</sup> Prize =
                {firstRankType === 'CW' ? <><img src={CrownUtils} width="11px" className='ml-1' style={{ marginTop: '-2px' }} /></> : <>{firstRankType !== 'E' && firstRankType !== 'CW' && currencyLogo}</>} {firstRankType !== 'E' ? parseFloat(Number((firstPrize)).toFixed(2)) : firstPrize} {firstRankType === 'B' && 'Bonus'}
              </UncontrolledTooltip>

              <div className='footer-item' id={`winCount-${data?._id}`}>
                <img src={Trophy} />
                <h4>{data && data.nMax && data.nWinnersCount ? (parseInt((data.nWinnersCount || 0) / data.nMax * 100)) : 0}<FormattedMessage id="Common_perc" /></h4>
              </div>
              <UncontrolledTooltip placement="bottom" autohide={false} target={`winCount-${data?._id}`}>
                <span className='mr-1'>
                  {data && data.nMax && data.nWinnersCount ? (parseInt((data.nWinnersCount || 0) / data.nMax * 100)) : 0}<FormattedMessage id="Common_perc" />
                </span>
                <FormattedMessage id="Participate_Win_Prize" />
              </UncontrolledTooltip>

              <div className='footer-item' id={`teamEntry-${data?._id}`}>
              {(data && data.nTeamJoinLimit && data.nTeamJoinLimit === 1 && !data.bMultipleEntry)
                ? <>
                  <img src={Single} />
                  <h4><FormattedMessage id="Single-entry" /></h4>
                </>
                : <>
                  <img src={Multi} />
                  <h4><FormattedMessage id="Multi-upto" /> {data?.bPrivateLeague ? (data?.nMax) : data?.nTeamJoinLimit} <FormattedMessage id="Multi-teams" /></h4>
                </>
               }
              </div>
              <UncontrolledTooltip placement="bottom" autohide={false} target={`teamEntry-${data?._id}`}>
                <span className='mr-1'>
                  Max&nbsp;
                  { data?.bPrivateLeague ? (data?.nMax) : data?.nTeamJoinLimit}
                </span>
                <FormattedMessage id="Team_allowed_contest" />
              </UncontrolledTooltip>
              {(data && data.nBonusUtil !== 0 && (!data.bPrivateLeague)) &&
                <div className='footer-item'>
                  <img src={Diamond} />
                  <h4>{(data && data.nBonusUtil && data.nBonusUtil !== 0 && !data.bPrivateLeague) && data.nBonusUtil}<FormattedMessage id="Common_perc" /></h4>
                </div>
              }
              <div className="footer-item" id={`Guaranteed-${data?._id}`}>
                {data && data.bConfirmLeague ? <img src={CopyGuaranteed} alt={<FormattedMessage id='Copy' />} /> : data && (!data.bConfirmLeague) && (data.nJoined >= data.nMin) ? <img src={Copy} alt={<FormattedMessage id='Copy' />} /> : <img src={CopyDisable} alt={<FormattedMessage id='Copy' />} />}
              </div>
              <UncontrolledTooltip placement="bottom" autohide={false} target={`Guaranteed-${data?._id}`}>
                {data && data.bConfirmLeague ? <FormattedMessage id='Guaranteed_contest' /> : data && (!data.bConfirmLeague) && (data.nJoined >= data.nMin) ? <FormattedMessage id='Confirmed_contest' /> : <FormattedMessage id='UnConfirmed_contest' />}
              </UncontrolledTooltip>
            </CardFooter>
            {/* <CardFooter className="bg-white d-flex align-items-center justify-content-between">
                <div className="f-w">
                  <Fragment>
                    {data && data.nBonusUtil && (!data.bPrivateLeague) ? <img src={Diamond} alt={<FormattedMessage id='Diamond' />} /> : <img src={DiamondDisable} alt={<FormattedMessage id='Diamond_Disable' />}></img>}
                    {data && data.bMultipleEntry ? <img src={Multi} alt={<FormattedMessage id='Multi' />} /> : <img src={MultiDisable} alt={<FormattedMessage id='Multi_Disable' />}></img>}
                    {data && data.bConfirmLeague && (!data.bPrivateLeague) ? <img src={Copy} alt={<FormattedMessage id='Copy' />} /> : <img src={CopyDisable} alt={<FormattedMessage id='Copy_Disable' />}></img>}
                    {data && data.nLoyaltyPoint && (!data.bPrivateLeague) ? <img src={Loyalty} alt={<FormattedMessage id='Loyalty' />} /> : <img src={LoyaltyDisable} alt={<FormattedMessage id='Loyalty_Disable' />}></img>}
                  </Fragment>
                </div>
              {
                data && data.nBonusUtil
                  ? <div className="f-w text-center"><FormattedMessage id="League_bonus" /> : <b> {data && data.nBonusUtil ? data.nBonusUtil : <FormattedMessage id="Team_0" />} <FormattedMessage id="Common_perc" /></b></div>
                  : ''
              }
              <div className="f-w text-right"><FormattedMessage id="League_entry" />
                <Button color="primary" onClick={() => setIsJoinLeague(true)} disabled={participated || (Object.keys(joined).length > 0 && data && data.bPrivateLeague && !data.bMultipleEntry) || (joined && data && !data.bPrivateLeague && (joined.nJoinedCount === data.nTeamJoinLimit)) || (data && data.nJoined && data.nMax && !data.bUnlimitedJoin && data.nJoined === data.nMax) || (data && joinDetails && !data.bPrivateLeague && (joinDetails.nJoinedCount === data.nTeamJoinLimit))}>{currencyLogo} {data && data.nPrice ? data.nPrice : <FormattedMessage id="Team_0" />}</Button>
              </div>
            </CardFooter> */}
            {/* {
              data && data.nCashbackAmount && data.nMinCashbackTeam
                ? <div className="bg-white d-flex">
                <Badge className='cashback' color="info" pill >
                  <img className='img' src={offerIcon}></img>
                  <span>
                    {currencyLogo}  {data && data.nCashbackAmount} {data && data.eCashbackType === 'B' ? <FormattedMessage id="League_bonus" /> : <FormattedMessage id="Cashback_On" /> }
                    {
                      data && data.nMinCashbackTeam >= 1
                        ? <Fragment>
                        { data && ` ${data.nMinCashbackTeam}` } { data && data.nMinCashbackTeam > 1 ? <FormattedMessage id="League_Entries" /> : <FormattedMessage id="League_Entry" />}
                      </Fragment>
                        : ' '
                    }
                  </span>
                </Badge>
              </div>
                : ''
            } */}
            {/* {
              showInformation && (
                <>
                  <hr className='league-hr' />
                  <div className="mt-footer ft-12 d-flex align-items-center justify-content-around">
                    <span className='gray'>
                        <img src={Diamond} alt={<FormattedMessage id='Diamond' />} />
                        <FormattedMessage id="League_bonus" />
                    </span>
                    <span className='gray'>
                        <img src={Multi} alt={<FormattedMessage id='Multi' />} />
                        <FormattedMessage id="League_multi_entry" />
                    </span>
                    <span className='gray'>
                        <img src={Copy} alt={<FormattedMessage id='Copy' />} />
                        <FormattedMessage id="League_Confirmed" />
                    </span>
                    <span className='gray'>
                        <img src={Loyalty} alt={<FormattedMessage id='Loyalty' />} />
                        <FormattedMessage id="League_LoyaltyPoints" />
                    </span>
                  </div>
                </>
              )
            } */}
          </CardBody>
          {data && data.userJoined && (matchDetails?.eStatus === 'L' || matchDetails?.eStatus === 'I' || matchDetails?.eStatus === 'CMP') &&
          <Table
              className="bg-white
                  player-list
                  price-table
                  participated-playerlist
                  team-com-on
                  table-radius
                  mt-2
                  "
            >
              {
                loading
                  ? (
                    <SkeletonTable Lines={3} />
                    )
                  : (
                    <Fragment>
                      <thead>
                        <tr>
                          <td><FormattedMessage id="Team_AllTeam" />
                            {/* ({(AllLeaderBoardList && myTeamsLeaderBoardList && AllLeaderBoardList.length + myTeamsLeaderBoardList.length)}) */}
                            &nbsp;({data && data.userJoined?.length})
                          </td>
                          <td className="player_points"><FormattedMessage id="Player_points" /></td>
                          <td><FormattedMessage id="League_Rank" /></td>
                        </tr>
                      </thead>
                      <tbody>
                      {data?.userJoined?.sort((a, b) => a.nRank - b.nRank).map((team, index) => {
                        return (
                          index <= 2
                            ? (
                              <tr key={index}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="l-img cursor-default">
                                      <img src={userData && userData.sProPic ? url + userData.sProPic : ProfileIcon} alt="" />
                                    </div>
                                    <div>
                                      <span className="p-name cursor-default">{team.sTeamName ? team.sTeamName : ''}</span>
                                        {
                                              team.bTeamWinningZone && (
                                                <Fragment>
                                                    <div className='greenText green-color'>
                                                      <img src={Trophy} className='mr-1'></img>
                                                      <span className='mt-2 winning-tex won-txt'>
                                                        <FormattedMessage id="Match_Winning_Zone" />
                                                      </span>
                                                    </div>
                                                </Fragment>
                                              )
                                            }
                                            {matchDetails?.eStatus === 'CMP' &&
                                            <Fragment>
                                              {team && team.nPrice >= 1 && !team.nBonusWin >= 1 && team.aExtraWin?.length === 0
                                                ? (<p className='greenText won-txt'><FormattedMessage id="You_won"/> {currencyLogo} {parseFloat(team.nPrice.toFixed(2))}</p>)
                                                : team && !team.nPrice >= 1 && team.nBonusWin >= 1 && team.aExtraWin?.length === 0
                                                  ? (<p className='greenText won-txt'><FormattedMessage id="You_won"/> {parseFloat(team.nBonusWin.toFixed(2))} Bonus </p>)
                                                  : team && !team.nPrice >= 1 && !team.nBonusWin >= 1 && team.aExtraWin?.length === 1
                                                    ? (<p className='greenText won-txt'><FormattedMessage id="You_won"/> {team.aExtraWin[0]?.sInfo} </p>)
                                                    : team && !team.nPrice >= 1 && !team.nBonusWin >= 1 && team.aExtraWin?.length >= 2
                                                      ? (<p className='greenText won-txt'> <FormattedMessage id="ONLY_WON_Gadget"/> </p>)
                                                      : team && !team.nPrice >= 1 && !team.nBonusWin >= 1 && team.aExtraWin?.length === 0
                                                        ? ''
                                                        : (<p className='greenText won-txt'><FormattedMessage id="WON_Multiple_Prizes"/> </p>)
                                            }
                                            </Fragment>}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {team.nTotalPoints > 0 ? team.nTotalPoints : '0'}
                                </td>
                                <td>#{team.nRank ? team.nRank : 0}</td>
                              </tr>
                              )
                            : index === 3 && (
                                  <tr key={index}>
                                    <td colSpan={3}>
                                      <Link to={!participated
                                        ? {
                                            pathname: `/leagues-detail/${(match.params.sportsType).toLowerCase()}/${matchID}/${data && data._id}`,
                                            search: `?${qs.stringify({
                                      homePage: obj?.homePage ? 'yes' : undefined
                                    })}`,
                                            state: { tab: tab, referUrl: `/leagues/${(match.params.sportsType).toLowerCase()}/${matchID}`, teamList }
                                          }
                                        : `/leagues-details/${(match.params.sportsType).toLowerCase()}/${match && match.params.id}/${data && data._id}/participated`}>
                                        <div className={'mt-footer footerPrizeBreakup d-flex align-items-center justify-content-around'}>
                                          <span>
                                            + {data.userJoined?.length - 3} more
                                          </span>
                                        </div>
                                      </Link>
                                    </td>
                                  </tr>
                            )
                        )
                      })
                      }
                      </tbody>
                    </Fragment>
                    )
              }
            </Table>
          }
        </div>
        {
          modalMessage
            ? <>
              <div className="s-team-bg" onClick={() => {
                setModalMessage(false)
                setSelectedAll(false)
              }}></div>
              <Card className="filter-card select-team promo-card">
                <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                  <button><FormattedMessage id="Team_SelectTeam" /></button>
                  <button onClick={() => {
                    setSelectedAll(false)
                    setModalMessage(false)
                  }}><img src={close}></img></button>
                </CardHeader>
                <CardBody className="p-0">
                <div className="two-button border-0 bg-white m-0 d-flex justify-content-between card-footer">
                  <Button type="submit" className='create-team-button' onClick={() => props.history.push({
                    pathname: data && data.bPrivateLeague && data.sShareCode ? `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${data._id}/private/${data.sShareCode}` : `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${data._id}`,
                    search: `?${qs.stringify({
                    homePage: obj?.homePage ? 'yes' : undefined,
                    firstTime: true
                  })}`,
                    state: { activeTab: activeTab }
                  })}>
                    <img src={createteam} width={20} className='mr-2' alt={<FormattedMessage id='Team_CreateTeam' />}></img>
                    <FormattedMessage id="Team_CreateTeam" /></Button>
                  <Button type="submit" className='create-contest-button' onClick={() => props.history.push({
                    pathname: `/create-contest/${(match.params.sportsType).toLowerCase()}/${match.params.id}`,
                    search: `?${qs.stringify({
                      homePage: obj?.homePage ? 'yes' : undefined,
                      firstTime: true
                    })}`
                  })}>
                    <img src={createContest} width={20} className='mr-2' alt={<FormattedMessage id='Team_CreatePrivateLeague' />}></img>
                    <FormattedMessage id="Team_CreatePrivateLeague" />
                  </Button>
                </div>
                  {data && data.bMultipleEntry && ((UpdatedTeamList && UpdatedTeamList.length !== 1) && (teamList && teamList.length !== 1)) && <div className='SelectAll d-flex align-items-center'>
                    <input type="radio" id='name' name="gender" onClick={() => setSelectedAll(!selectedAll)}
                        checked={selectedAll}
                    />
                    <label htmlFor='name'><FormattedMessage id="Team_SelectAll" /> </label>
                  </div>}
                  {
                    teamList && teamList.length !== 0 &&
                      teamList.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                        return (
                          <Suspense key={data1._id} fallback={<Loading />}>
                            <div className='switch-team-wrap'>
                            <MyTeam {...props}
                              join
                              upcoming
                              disabledRadio={UpdatedTeamList?.find((item) => item._id === data1._id)?._id}
                              disabledRadioFlag={UpdatedTeamList?.length !== 0}
                              teamId={data1._id}
                              leagueData={data}
                              noOfJoin={noOfJoin}
                              userTeams={userTeams}
                              setUserTeams={(Id) => {
                                setUserTeams(Id)
                                setUserTeamId(Id)
                                setSelectedAll(false)
                              }}
                              params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={teamList} UserTeamChoice />
                              </div>
                          </Suspense>
                        )
                      })
                  }
                </CardBody>
                <CardFooter className='p-0 border-0 bg-trnsparent m-0 d-flex justify-content-between'>
                  <Button type="submit" color='primary-two' className="w-100"
                    disabled={userTeams && userTeams.length === 0}
                    onClick={() => {
                      if (userTeams.length > (data.nMax - data.nJoined) && (!data.bUnlimitedJoin)) {
                        setAlert(true)
                        setAlertMessage(<p><FormattedMessage id="Team_Title1" /> {data.nMax - data.nJoined || '-'} <FormattedMessage id="Team_Title2" /></p>)
                        setTimeout(() => {
                          setAlertMessage('')
                          setAlert(false)
                        }, 2000)
                      } else if (noOfJoin >= userTeams.length) {
                        const filterData = teamList.filter(data => userTeams.includes(data._id))
                        onGetUserProfile()
                        setUpdatedFilterData(filterData)
                        setModalMessage2(true)
                        setModalMessage(false)
                        setFinalPromocode('')
                      } else {
                        setAlert(true)
                        setAlertMessage(<p><FormattedMessage id="Team_Title1" /> {noOfJoin || '-'} <FormattedMessage id="Team_Title2" /></p>)
                        setTimeout(() => {
                          setAlertMessage('')
                          setAlert(false)
                        }, 2000)
                      }
                      setSelectedAll(false)
                    // }}><FormattedMessage id="League_join" /> (<FormattedMessage id='Pay'/> <FormattedMessage id='Team_Rupee'/>0)</Button>
                    }}><FormattedMessage id='other_Next' /> </Button>
                </CardFooter>
              </Card>
            </>
            : modalMessage2 && totalPayFinal >= 0
              ? <>
                {loading && <Loading />}
                <div className="s-team-bg" onClick={() => setModalMessage2(false)}></div>
                <Card className="filter-card select-team promo-card">
                  <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                    <button><FormattedMessage id="League_Payment" /></button>
                    <button onClick={() => {
                      setModalMessage2(false)
                      setModalMessage(true)
                    }}><img src={close}></img></button>
                  </CardHeader>
                  <CardBody className="p-0 teamXShawing">
                    <div className='teamJoin'>
                      {
                        updatedFilterData && updatedFilterData.length && (
                          <h3>
                            {updatedFilterData.length} <FormattedMessage id="League_Teams_Selected" />
                          </h3>
                        )
                      }
                    </div>
                    <div className='selectedTeamList'>
                      {
                        updatedFilterData && updatedFilterData.length !== 0
                          ? updatedFilterData.sort((a, b) => a?.sTeamName?.toString().localeCompare(b?.sTeamName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                            return (
                              <Suspense key={data1._id} fallback={<Loading />}>
                                <MyTeam {...props} upcoming leagueData={data}
                                  noOfJoin={noOfJoin}
                                  params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={updatedFilterData} UserTeamChoice viewOnly />
                              </Suspense>
                            )
                          })
                          : ''
                      }
                    </div>
                    <Table className="m-0 bg-white promocode">
                      <thead>
                        <tr>
                          <th><FormattedMessage id="League_Total_Entry" /></th>
                          <th className='rightAlign'>{currencyLogo}{data && updatedFilterData && data.nPrice * updatedFilterData.length} ( {data && updatedFilterData && `${data.nPrice} X ${updatedFilterData.length}`} )</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(!data.bPrivateLeague) && <Fragment>
                        <tr>
                          <td colSpan='2' className='green'>
                            {finalPromocode
                              ? <div className='d-flex align-items-center'>
                              <h3 className='promocode-text m-0'>{finalPromocode} <FormattedMessage id='Promo_Applied' /></h3>
                              <Button onClick={clearPromo} color='link' className="ml-2"><img src={close} /></Button>
                            </div>
                              : <Button onClick={() => getPromocodeList()} className='p-0 d-flex align-items-center' color='link'>
                              <h3 className='promocode-text m-0'><FormattedMessage id="League_Apply_Promocode" /></h3>
                              <img src={rightGreenArrow} className='ml-1' />
                            </Button>}
                          </td>
                        </tr>
                        {/* <tr>
                          <td colSpan='2' className='green'>
                            <FormGroup className="c-input mt-2 mb-0">
                              <Input onClick={() => getPromocodeList()} type="text" className={classNames({ 'hash-contain': finalPromocode }) } id="Promocode" value={finalPromocode} autoComplete='off' required onChange={(e) => setPromoData(e.target.value)} />
                              <Label className="no-change label m-0" for="Promocode"><FormattedMessage id="User_Promocode" /></Label>
                              {applied && finalPromocode
                                ? <button className="i-icon" onClick={clearPromo}>Remove</button>
                                : <button className="i-icon" onClick={() => applePromoCode(promoData)}><FormattedMessage id="User_Apply" /></button>}
                            </FormGroup>
                          </td>
                        </tr> */}
                       {finalPromocode && <tr>
                          <td><FormattedMessage id="League_Discount" /></td>
                          <td className='rightAlign'>{currencyLogo}{applyPromocodeData && applyPromocodeData.nDiscount ? applyPromocodeData.nDiscount : '0'}</td>
                        </tr>}
                        </Fragment>}
                        <tr>
                          <td><FormattedMessage id="League_In_Wallet" /></td>
                          <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentTotalBalance ? userInfo.nCurrentTotalBalance : 0}</td>
                        </tr>
                      </tbody>
                      {
                        totalPay && currencyLogo
                          ? (
                          <tfoot>
                            <tr>
                              <td><h1><FormattedMessage id="League_To_Pay" /></h1></td>
                              <td className='rightAlign'>{currencyLogo}
                                {totalPay}</td>
                            </tr>
                          </tfoot>
                            )
                          : ''
                      }
                    </Table>
                  </CardBody>
                  <CardFooter className='p-0 border-0 bg-trnsparent m-0 d-flex justify-content-between'>
                    <Button type="submit" color='primary-two' className="w-100"
                      disabled={userTeams && userTeams.length === 0}
                      onClick={() => LeagueJoin(userTeams)}>
                      {
                        totalPay > 0
                          ? <FormattedMessage id="User_AddMoney" />
                          : <><FormattedMessage id="League_join" /> (<FormattedMessage id='Pay'/> {' ' + currencyLogo}{totalPayFinal})</>
                      }
                    </Button>
                  </CardFooter>
                </Card>
              </>
              : ''
        }
        {PromoCodes
          ? <>
            <div className="s-team-bg" onClick={() => setPromoCodes(false)}></div>
            <Card className="filter-card show select-team promo-card">
              <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                <button><FormattedMessage id="League_Promocode" /></button>
                <button onClick={() => {
                  setPromoCodes(false)
                  setModalMessage2(true)
                }}><img src={close}></img></button>
              </CardHeader>
              <CardBody className='p-10'>
                {promocodeLoading && <PromocodeLoading />}
                <div className="p-title-2"><FormattedMessage id="User_promocodeForU" /></div>
                {matchPromoCodeList !== {} && matchPromoCodeList && matchPromoCodeList.length > 0
                  ? matchPromoCodeList.map(matchPromo => {
                    return (
                      <div key={matchPromo._id} className="d-flex align-items-center justify-content-between promo-box">
                        <div>
                          <b>{matchPromo.sCode}</b>
                          <p>{matchPromo.sInfo}</p>
                        </div>
                        <Button color="white" onClick={() => applePromoCode(matchPromo.sCode)}><FormattedMessage id="User_Apply" /></Button>
                      </div>
                    )
                  })
                  : (
                    <Fragment>
                      <center>
                        <h2> <FormattedMessage id="Blank_Promocode_List" /> </h2>
                      </center>
                    </Fragment>
                    )
                }
              </CardBody>
              <CardFooter className='p-0 border-0 bg-transparent m-0 d-flex justify-content-between'>
              </CardFooter>
            </Card>
          </>
          : ''
        }
      </Fragment>
    </>
  )
}

CreateContestLeague.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sportsType: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }),
  data: PropTypes.shape({
    aLeaguePrize: PropTypes.array,
    nCrownUtil: PropTypes.number,
    userJoined: PropTypes.shape([{
      nRank: PropTypes.number,
      nPrice: PropTypes.number,
      nTotalPoints: PropTypes.number,
      bTeamWinningZone: PropTypes.bool,
      sTeamName: PropTypes.string
    }]),
    sName: PropTypes.string,
    eMatchStatus: PropTypes.string,
    bPoolPrize: PropTypes.bool,
    nBonusUtil: PropTypes.number,
    nWinnersCount: PropTypes.number,
    nMax: PropTypes.number,
    nMin: PropTypes.number,
    nPrice: PropTypes.number,
    nLoyaltyPoint: PropTypes.number,
    nJoined: PropTypes.number,
    nTotalPayout: PropTypes.number,
    nTeamJoinLimit: PropTypes.number,
    _id: PropTypes.string,
    bConfirmLeague: PropTypes.bool,
    bMultipleEntry: PropTypes.bool,
    nJoinedCount: PropTypes.bool,
    bPrivateLeague: PropTypes.bool,
    iMatchId: PropTypes.string,
    sShareCode: PropTypes.string,
    eCashbackType: PropTypes.string,
    bUnlimitedJoin: PropTypes.bool,
    nCashbackAmount: PropTypes.number,
    nMinCashbackTeam: PropTypes.number,
    nDeductPercent: PropTypes.number,
    ePayoutType: PropTypes.string
  }),
  applyPromocodeData: PropTypes.shape({
    nDiscount: PropTypes.string,
    sCode: PropTypes.string
  }),
  userInfo: PropTypes.shape({
    nCurrentTotalBalance: PropTypes.number
  }),
  home: PropTypes.bool,
  joinContest: PropTypes.func,
  firstPrize: PropTypes.string,
  firstRankType: PropTypes.string,
  lastPrize: PropTypes.string,
  MatchLeagueId: PropTypes.string,
  amountData: PropTypes.object,
  contestList: PropTypes.object,
  participated: PropTypes.bool,
  teamList: PropTypes.shape([{
    iMatchId: PropTypes.string
  }]),
  matchType: PropTypes.string,
  activeTab: PropTypes.string,
  contestJoinList: PropTypes.array,
  joinDetails: PropTypes.object,
  insideleagueDetailsPage: PropTypes.bool,
  toggleMessage: PropTypes.func,
  applyPromo: PropTypes.func,
  onGetUserProfile: PropTypes.func,
  modalMessage: PropTypes.string,
  setModalMessage: PropTypes.func,
  joined: PropTypes.bool,
  getMyTeamList: PropTypes.func,
  onGetPromocodeList: PropTypes.func,
  loading: PropTypes.bool,
  promocodeLoading: PropTypes.bool,
  appliedPromocode: PropTypes.bool,
  upComing: PropTypes.bool,
  showInformation: PropTypes.bool,
  matchID: PropTypes.string,
  currencyLogo: PropTypes.string,
  matchPromoCodeList: PropTypes.shape([{
    sCode: PropTypes.string,
    sInfo: PropTypes.string,
    _id: PropTypes.string
  }]),
  tab: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }),
  homePage: PropTypes.string,
  setLoading: PropTypes.func,
  url: PropTypes.string
}

export default JoinContest(CreateContestLeague)
