import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyTeamList, switchTeam, getMyJoinDetails, getMyTeamPlayerList, getMyJoinList } from '../../redux/actions/team'
import { getMatchDetails } from '../../redux/actions/match'
import { getMatchLeagueDetails } from '../../redux/actions/league'
import { getMyTeamLeaderBoardList, getAllTeamLeaderBoardList } from '../../redux/actions/leaderBoard'
import { GetUserProfile } from '../../redux/actions/profile'
import PropTypes from 'prop-types'
import { participationPost } from '../../helper'

export const ContestDetails = (Component) => {
  const MyComponent = (props) => {
    const { match } = props
    const dispatch = useDispatch()
    const token = useSelector(state => state.auth.token)
    const matchLeagueDetails = useSelector(state => state.league.matchLeagueDetails)
    const contestJoinList = useSelector(state => state.team.contestJoinList)
    const resStatus = useSelector(state => state.team.resStatus)
    const resMessage = useSelector(state => state.team.resMessage)
    const myTeamsLeaderBoardList = useSelector(state => state.leaderBoard.myTeamsLeaderBoardList)
    const allLeaderBoardList = useSelector(state => state.leaderBoard.allLeaderBoardList)
    const resLeaderboardStatus = useSelector(state => state.leaderBoard.resStatus)
    const nPutTime = useSelector(state => state.leaderBoard.nPutTime)
    const matchDetails = useSelector(state => state.match.matchDetails)
    const teamList = useSelector(state => state.team.teamList)
    const joinDetails = useSelector(state => state.team.joinDetails)
    const switchTeamSuccess = useSelector(state => state.team.switchTeamSuccess)
    const userInfo = useSelector(state => state.profile.userInfo || state.auth.userData)
    const teamPlayerList = useSelector(state => state.team.teamPlayerList)
    const currencyLogo = useSelector(state => state.settings.currencyLogo)
    const resjoinMessage = useSelector(state => state.league.resjoinMessage)
    const resJoinLeagueStatus = useSelector(state => state.league.resStatus)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const [activeTab, setActiveTab] = useState(1)

    const previousProps = useRef({
      matchLeagueDetails,
      contestJoinList,
      resLeaderboardStatus,
      myTeamsLeaderBoardList,
      allLeaderBoardList,
      teamList,
      switchTeamSuccess,
      resjoinMessage,
      getUrlLink,
      activeTab,
      joinDetails
    }).current
    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [leaderboardLoading, setLeaderboardLoading] = useState(false)
    const [switchTeamLoader] = useState(false)
    const [switchTeamModal, setSwitchTeamModal] = useState(false)
    const [callTeamApi, setCallTeamApi] = useState(false)
    const [allleaderboard, setAllLeaderboard] = useState([])
    const [SwitchTeamList, setSwitchTeamList] = useState([])
    const [message, setMessage] = useState('')
    const [limit] = useState(50)
    const [offset, setOffset] = useState(0)
    const matchId = match && match.params && match.params.id
    function onTabChange (tab) {
      setActiveTab(tab)
    }

    useEffect(() => { // handle the data and onload apis calling
      if (getUrlLink && token) {
        setUrl(getUrlLink)
      }
      if (match && match.params && match.params.id2 && token) {
        leagueDetailsApicall()
        if (activeTab === 2 && token) {
          const leagueId = match && match.params && match.params.id2
          if (leagueId) {
            dispatch(getMyTeamLeaderBoardList(leagueId, token))
            dispatch(getAllTeamLeaderBoardList(limit, offset, leagueId, token, nPutTime))
            myJoinleagueApicall()
            setLeaderboardLoading(true)
          }
        }
      }
      if ((!matchDetails || (matchDetails && matchDetails._id && match.params.id !== matchDetails._id)) && token) {
        dispatch(getMatchDetails(match.params.id, match.params.sportsType, token))
      }
      if (!teamPlayerList && token && match && match.params && match.params.id) {
        dispatch(getMyTeamPlayerList(match.params.id, token))
      }
      token && getTeamList()
      token && getLeagueList()
    }, [token])

    useEffect(() => {
      if (previousProps.getUrlLink !== getUrlLink) {
        if (getUrlLink && getUrlLink.length !== 0) {
          setUrl(getUrlLink)
        }
      }
      return () => {
        previousProps.getUrlLink = getUrlLink
      }
    }, [getUrlLink])

    useEffect(() => {
      if (previousProps.joinDetails !== joinDetails) {
        if (joinDetails && joinDetails.iMatchLeagueId) {
          setLoading(false)
        }
      }
      return () => {
        previousProps.joinDetails = joinDetails
      }
    }, [joinDetails])

    function leagueDetailsApicall () {
      const leagueId = match && match.params && match.params.id2
      if (leagueId && token) {
        dispatch(getMatchLeagueDetails(leagueId, token))
        setLoading(true)
      }
    }

    function getTeamList () {
      if (match && match.params && match.params.id && token) {
        dispatch(getMyTeamList(match.params.id, token))
        setLeaderboardLoading(true)
      }
    }

    function myJoinleagueApicall () {
      const matchLeagueId = match && match.params && match.params.id2
      if (matchLeagueId && token) {
        dispatch(getMyJoinDetails(matchLeagueId, token))
      }
    }
    // when user joins the league
    useEffect(() => {
      if (previousProps.resjoinMessage !== resjoinMessage) {
        if (resjoinMessage) {
          if (resJoinLeagueStatus) {
            leagueDetailsApicall()
            setLoading(true)
          } else {
            setLoading(false)
          }
          if (resStatus) {
            onGetmyTeamsList()
            myAllTeamPagination(50, 0)
            setAllLeaderboard([])
          }
        }
      }
      return () => {
        previousProps.resjoinMessage = resjoinMessage
      }
    }, [resjoinMessage, resStatus])

    useEffect(() => {
      if (previousProps.activeTab !== activeTab) { // handle the response
        if (activeTab === 2 && token) {
          const leagueId = match && match.params && match.params.id2
          if (leagueId) {
            dispatch(getMyTeamLeaderBoardList(leagueId, token))
            dispatch(getAllTeamLeaderBoardList(limit, offset, leagueId, token, nPutTime))
            setLeaderboardLoading(true)
          }
          if (!matchLeagueDetails || (matchLeagueDetails && matchLeagueDetails._id && matchLeagueDetails._id !== match.params.id2)) {
            leagueDetailsApicall()
          }
        }
        if (activeTab) {
          setAllLeaderboard([])
          setOffset(0)
        }
      }
      return () => {
        previousProps.activeTab = activeTab
      }
    }, [activeTab])

    useEffect(() => {
      if (matchLeagueDetails !== previousProps.matchLeagueDetails) { // handle the response
        if (resStatus !== null) {
          myJoinleagueApicall()
        }
      }
      return () => {
        previousProps.matchLeagueDetails = matchLeagueDetails
      }
    }, [matchLeagueDetails])

    useEffect(() => {
      if (contestJoinList !== previousProps.contestJoinList) { // handle the response
        if (resStatus !== null) {
          if (callTeamApi && token) {
            const matchId = match && match.params && match.params.id
            dispatch(getMyTeamList(matchId, token))
          } else {
            setLoading(false)
          }
        }
      }
      return () => {
        previousProps.contestJoinList = contestJoinList
      }
    }, [contestJoinList])

    useEffect(() => {
      if (myTeamsLeaderBoardList !== previousProps.myTeamsLeaderBoardList) { // my Teans leaderboard list set
        if (resLeaderboardStatus !== null) {
          if (!resLeaderboardStatus) {
            setLeaderboardLoading(false)
          }
        }
      }
      return () => {
        previousProps.myTeamsLeaderBoardList = myTeamsLeaderBoardList
      }
    }, [myTeamsLeaderBoardList])

    useEffect(() => {
      if (allLeaderBoardList !== previousProps.allLeaderBoardList && userInfo) { // All Leaderboard list data set
        if (resLeaderboardStatus !== null) {
          if (allLeaderBoardList && allLeaderBoardList.length > 0) {
            const data = userInfo && (allLeaderBoardList.filter((data) => data.iUserId !== userInfo._id) || [])
            setAllLeaderboard(AllLeaderBoardList => [...AllLeaderBoardList, ...data])
          }
          setLeaderboardLoading(false)
        }
      }
      return () => {
        previousProps.allLeaderBoardList = allLeaderBoardList
      }
    }, [allLeaderBoardList, userInfo])

    useEffect(() => {
      if (teamList !== previousProps.teamList) { // switch team list data set
        if (resStatus !== null) {
          if (resStatus) {
            if (teamList && teamList.length !== 0) {
              const leagueId = match && match.params && match.params.id2
              const joinContest = contestJoinList && contestJoinList.length !== 0 && contestJoinList.find(joinContest => joinContest.iMatchLeagueId === leagueId)
              if (joinContest && teamList && teamList.length !== 0) {
                const switchTeamList = joinContest.aUserTeams && teamList.filter(team => !joinContest.aUserTeams.includes(team._id))
                setSwitchTeamList(switchTeamList)
              }
            }
          }
          setLeaderboardLoading(false)
          setLoading(false)
        }
      }
      return () => {
        previousProps.teamList = teamList
      }
    }, [teamList])

    useEffect(() => { // switch team Sucessfully time call related apis
      if (switchTeamSuccess !== previousProps.switchTeamSuccess) {
        if (resStatus !== null) {
          if (switchTeamSuccess && token) {
            setAllLeaderboard([])
            const leagueId = match && match.params && match.params.id2
            dispatch(getMyTeamLeaderBoardList(leagueId, token))
            dispatch(getAllTeamLeaderBoardList(limit, offset, leagueId, token, nPutTime))
            myJoinleagueApicall()
            setLeaderboardLoading(true)
          }
        }
        setSwitchTeamModal(false)
        setMessage(resMessage)
      }
      return () => {
        previousProps.switchTeamSuccess = switchTeamSuccess
      }
    }, [switchTeamSuccess])

    // when offset change
    useEffect(() => {
      if (offset) {
        myAllTeamPagination(limit, offset)
      }
    }, [offset])
    function onClickSwitchTeam () {
      setCallTeamApi(true)
      myJoinleagueApicall()
    }

    function onSwitchTeam (userLeagueId, selectedTeam) {
      if (token) {
        setLeaderboardLoading(true)
        dispatch(switchTeam(userLeagueId, selectedTeam, token))
        const obj = {
          ac: 'swt',
          ctid: matchLeagueDetails._id,
          ctnm: matchLeagueDetails.sName,
          mtnm: matchDetails.sName,
          mid: matchLeagueDetails.iMatchId,
          ntm: [userLeagueId].length,
          tmid: [userLeagueId],
          sportType: matchDetails.eCategory
        }
        participationPost(obj)
      }
    }

    function myAllTeamPagination (limit, offset) {
      if (token) {
        const leagueId = match && match.params && match.params.id2
        dispatch(getAllTeamLeaderBoardList(limit, offset, leagueId, token, nPutTime))
        // setLeaderboardLoading(true)
      }
    }

    function onGetmyTeamsList () {
      if (token) {
        const leagueId = match && match.params && match.params.id2
        dispatch(getMyTeamLeaderBoardList(leagueId, token))
        setLeaderboardLoading(true)
      }
    }

    function getLeagueList () {
      if (match?.params?.id && token) {
        dispatch(getMyJoinList(match?.params?.id, token))
        setLoading(true)
      }
    }

    function onGetUserProfile () {
      token && dispatch(GetUserProfile(token))
    }
    function onFetchMatchPlayer (id) {
      token && dispatch(getMyTeamPlayerList(id, token))
    }
    return (
      <Component
        {...props}
        activeTab={activeTab}
        onTabChange={onTabChange}
        loading={loading}
        matchLeagueDetails={matchLeagueDetails}
        contestJoinList={contestJoinList}
        leaderboardLoading={leaderboardLoading}
        myTeamsLeaderBoardList={myTeamsLeaderBoardList}
        allLeaderBoardList={allleaderboard}
        switchTeam={onClickSwitchTeam}
        SwitchTeamList={SwitchTeamList}
        switchTeamModal={switchTeamModal}
        setSwitchTeamModal={setSwitchTeamModal}
        matchId={matchId}
        switchTeamLoader={switchTeamLoader}
        onSwitchTeam={onSwitchTeam}
        message={message}
        getMyTeamList={getTeamList}
        myAllTeamPagination={myAllTeamPagination}
        teamList={teamList}
        leagueDetailsApicall={leagueDetailsApicall}
        onGetmyTeamsList={onGetmyTeamsList}
        resjoinMessage={resjoinMessage}
        resStatus={resStatus}
        url={url}
        switchTeamSuccess={switchTeamSuccess}
        otherTeamJoinList={allLeaderBoardList}
        setOffset={setOffset}
        userInfo={userInfo}
        onGetUserProfile={onGetUserProfile}
        offset={offset}
        teamPlayerList={teamPlayerList}
        onFetchMatchPlayer={onFetchMatchPlayer}
        currencyLogo={currencyLogo}
        joinDetails={joinDetails}
        getLeagueList={getLeagueList}
      />
    )
  }
  MyComponent.propTypes = {
    match: PropTypes.object
  }
  MyComponent.displayName = MyComponent
  return MyComponent
}

export default ContestDetails
