import axios from '../../axios/instanceAxios'
import {
  TEAM_LIST,
  CLEAR_TEAM_LIST,
  CLEAR_USER_TEAM,
  CLEAR_CONTEST_LIST,
  CLEAR_TEAM_PLAYER_LIST,
  CLEAR_CONTEST_JOIN_LIST,
  USER_TEAM,
  CONTEST_LIST,
  CLEAR_EDIT_TEAM,
  CONTEST_JOIN_LIST,
  TEAM_PLAYER_LIST,
  SWITCH_USER_TEAM,
  CREATE_TEAM,
  EDIT_TEAM,
  CLEAR_CREATE_TEAM,
  CLEAR_TEAM_MESSAGE,
  USER_COMPARE_TEAM,
  CLEAR_PRIVATE_LEAGUE_VALIDATION,
  PRIVATE_LEAGUE_VALIDATION,
  CLEAR_JOIN_DETAILS,
  JOIN_DETAILS,
  DREAM_TEAM,
  SHARE_TEAM,
  CLEAR_SHARE_TEAM,
  GET_SHARE_TEAM,
  CLEAR_AUTO_PICK_LIST_PLAYER
} from '../constants'
import { catchBlankData, catchError, participationPost, teamPost } from '../../helper'
import { SessionID } from '../../localStorage/localStorage'
import { joinLeague } from './league'

const errMsg = 'Server is unavailable.'

// get the my team list for that particular match
export const getMyTeamList = (ID, token) => (dispatch) => {
  dispatch({ type: CLEAR_TEAM_LIST })
  axios.get(`/user-team/teams/${ID}/v3`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: TEAM_LIST,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(TEAM_LIST))
  })
}

export const getDreamTeam = (ID, token) => async (dispatch) => {
  dispatch({ type: CLEAR_TEAM_LIST })
  await axios.get(`/dream-team/${ID}/v1`, { headers: { Authorization: token } }).then((response) => {
    dispatch({
      type: DREAM_TEAM,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(DREAM_TEAM))
  })
}

export const getShareTeam = (ID, refId, token) => async (dispatch) => {
  dispatch({ type: CLEAR_TEAM_LIST })
  await axios.post('/shared-team/v1', { uuid: refId, iUserTeamId: ID }, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: GET_SHARE_TEAM,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(GET_SHARE_TEAM))
  })
}

export const shareYourTeam = (ID, token) => async (dispatch) => {
  dispatch({ type: CLEAR_SHARE_TEAM })
  await axios.post('/user-team/share/v1', { iTeamId: ID }, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: SHARE_TEAM,
      payload: {
        refferralCode: response.data.response.sReferralCode,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(SHARE_TEAM))
  })
}

// validation
export const privateLeagueValidationList = (Type, token) => (dispatch) => {
  dispatch({ type: CLEAR_TEAM_LIST })
  dispatch({ type: CLEAR_PRIVATE_LEAGUE_VALIDATION })
  axios.get(`/setting/${Type}/v2`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: PRIVATE_LEAGUE_VALIDATION,
      payload: {
        privateLeagueValidation: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(PRIVATE_LEAGUE_VALIDATION))
  })
}

// list of the user team
export const getUserTeam = (ID, token) => (dispatch) => {
  dispatch({ type: CLEAR_USER_TEAM })
  axios.get(`/user-team/team-player-leaderboard/${ID}/v2`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: USER_TEAM,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(USER_TEAM))
  })
}

// get the compare user team
export const getCompareUserTeam = (ID, token) => (dispatch) => {
  dispatch({ type: CLEAR_USER_TEAM })
  axios.get(`/user-team/team-player-leaderboard/${ID}/v3`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: USER_COMPARE_TEAM,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(USER_COMPARE_TEAM))
  })
}

// get the validation join contest list
export const getMyContestList = (ID, token) => (dispatch) => {
  dispatch({ type: CLEAR_CONTEST_LIST })
  axios.get(`/user-league/join-list/${ID}/v3?eSource=JG`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: CONTEST_LIST,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(CONTEST_LIST))
  })
}

// get joined contest list
export const getMyJoinList = (ID, token) => (dispatch) => {
  dispatch({ type: CLEAR_CONTEST_JOIN_LIST })
  axios.get(`/user-league/join/${ID}/v1`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: CONTEST_JOIN_LIST,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(CONTEST_JOIN_LIST))
  })
}

export const getMyJoinDetails = (ID, token) => (dispatch) => {
  dispatch({ type: CLEAR_JOIN_DETAILS })
  axios.get(`/user-league/join-details/${ID}/v1`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: JOIN_DETAILS,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(JOIN_DETAILS))
  })
}

// get match player list
export const getMyTeamPlayerList = (ID, token) => (dispatch) => {
  dispatch({ type: CLEAR_TEAM_PLAYER_LIST })
  axios.get(`/match-player/${ID}/v2`).then((response) => {
    dispatch({
      type: TEAM_PLAYER_LIST,
      payload: {
        matchPlayerMatchId: ID,
        matchPlayer: response.data.data.matchPlayer,
        aPlayerRole: response.data.data.aPlayerRole,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: TEAM_PLAYER_LIST,
      payload: {
        resMessage: error && error.response && error.response.data && error.response.data.message ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

// Switch team
export const switchTeam = (iUserLeagueId, iUserTeamId, token) => (dispatch) => {
  dispatch(dispatch({
    type: SWITCH_USER_TEAM,
    payload: {
      resMessage: '',
      resStatus: null,
      switchTeamSuccess: null
    }
  }))
  axios.put(`/user-league/switch-team/${iUserLeagueId}/v1`, { iUserTeamId }, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: SWITCH_USER_TEAM,
      payload: {
        resMessage: response.data.message,
        resStatus: true,
        switchTeamSuccess: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: SWITCH_USER_TEAM,
      payload: {
        switchTeamSuccess: false,
        resStatus: false,
        resMessage: error && error.response ? error.response.data.message : errMsg
      }
    })
  })
}

// create team
export const createTeam = (matchID, captionId, viceCaptionId, SelectedPlayer, name, token, matchDetails, teamPlayerList) => (dispatch) => {
  dispatch({ type: CLEAR_TEAM_MESSAGE })
  dispatch({ type: CLEAR_CREATE_TEAM })
  axios.post('/user-team/v3', { iMatchId: matchID, iCaptainId: captionId, iViceCaptainId: viceCaptionId, aPlayers: SelectedPlayer, sName: name }, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({ type: CLEAR_AUTO_PICK_LIST_PLAYER })
    dispatch({
      type: CREATE_TEAM,
      payload: {
        createTeamData: response.data.data,
        resMessage: response.data.message,
        resStatus: !!response.data.data,
        isCreateTeam: !!response.data.data
      }
    })
    const cpname = teamPlayerList.find((item) => item._id === response.data.data.iCaptainId)
    const vcname = teamPlayerList.find((item) => item._id === response.data.data.iViceCaptainId)
    const obj = {
      tmid: response.data.data._id,
      mky: matchDetails.sKey,
      p1: teamPlayerList[0].sName,
      p2: teamPlayerList[1].sName,
      p3: teamPlayerList[2].sName,
      p4: teamPlayerList[3].sName,
      p5: teamPlayerList[4].sName,
      p6: teamPlayerList[5].sName,
      p7: teamPlayerList[6].sName,
      p8: teamPlayerList[7].sName,
      p9: teamPlayerList[8].sName,
      p10: teamPlayerList[9].sName,
      p11: teamPlayerList[10].sName,
      cp: cpname.sName,
      vc: vcname.sName,
      mtnm: matchDetails.sName,
      mid: matchDetails._id,
      mdt: matchDetails.dStartDate,
      snm: matchDetails.sSeasonName,
      fmt: matchDetails.eFormat,
      sportType: matchDetails.eCategory
    }
    teamPost(obj)
  }).catch((error) => {
    dispatch({
      type: CREATE_TEAM,
      payload: {
        resMessage: error.response.data.message,
        resStatus: false,
        isCreateTeam: false
      }
    })
  })
}

// create team and join contest
export const createTeamJoinLeague = (matchID, captionId, viceCaptionId, SelectedPlayer, iMatchLeagueId, bPrivateLeague, sShareCode, Name, sPromoCode, sportsType, token, matchLeagueDetails, matchDetails) => (dispatch) => {
  dispatch({ type: CLEAR_TEAM_MESSAGE })
  axios.post('/user-team/v3', { iMatchId: matchID, iCaptainId: captionId, iViceCaptainId: viceCaptionId, aPlayers: SelectedPlayer, sName: Name }, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    if (response.status === 200) {
      dispatch({
        type: CREATE_TEAM,
        payload: {
          resMessage: response.data.message,
          resStatus: true,
          createAndJoin: true
        }
      })
      dispatch(joinLeague(iMatchLeagueId, response.data.data._id, bPrivateLeague, token, sShareCode, sPromoCode, sportsType))
      const obj = {
        ac: 'jn',
        ctid: matchLeagueDetails._id,
        ctnm: matchLeagueDetails.sName,
        mtnm: matchDetails.sName,
        mid: matchLeagueDetails.iMatchId,
        ntm: [response.data.data._id].length,
        tmid: [response.data.data._id],
        sportType: matchDetails.eCategory
      }
      participationPost(obj)
    } else {
      dispatch({
        type: CREATE_TEAM,
        payload: {
          resMessage: response.data.message,
          resStatus: false
        }
      })
    }
  }).catch((error) => {
    dispatch(catchError(CREATE_TEAM, error))
  })
}

// Edit team
export const editTeam = (matchID, teamId, captionId, viceCaptionId, SelectedPlayer, Name, token, matchDetails, teamPlayerList) => (dispatch) => {
  dispatch({ type: CLEAR_TEAM_MESSAGE })
  dispatch({ type: CLEAR_EDIT_TEAM })
  axios.put(`/user-team/${teamId}/v3`, { iMatchId: matchID, iCaptainId: captionId, iViceCaptainId: viceCaptionId, aPlayers: SelectedPlayer, sName: Name }, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: EDIT_TEAM,
      payload: {
        resMessage: response.data.message,
        resStatus: true,
        isEditTeam: true
      }
    })
    const cpname = teamPlayerList.find((item) => item._id === response.data.data.iCaptainId)
    const vcname = teamPlayerList.find((item) => item._id === response.data.data.iViceCaptainId)
    const obj = {
      tmid: response.data.data._id,
      mky: matchDetails.sKey,
      p1: teamPlayerList[0].sName,
      p2: teamPlayerList[1].sName,
      p3: teamPlayerList[2].sName,
      p4: teamPlayerList[3].sName,
      p5: teamPlayerList[4].sName,
      p6: teamPlayerList[5].sName,
      p7: teamPlayerList[6].sName,
      p8: teamPlayerList[7].sName,
      p9: teamPlayerList[8].sName,
      p10: teamPlayerList[9].sName,
      p11: teamPlayerList[10].sName,
      cp: cpname.sName,
      vc: vcname.sName,
      mtnm: matchDetails.sName,
      mid: matchDetails._id,
      mdt: matchDetails.dStartDate,
      snm: matchDetails.sSeasonName,
      fmt: matchDetails.eFormat,
      sportType: matchDetails.eCategory
    }
    teamPost(obj)
  }).catch((error) => {
    dispatch({
      type: EDIT_TEAM,
      payload: {
        resMessage: error.response.data.message,
        resStatus: false,
        isEditTeam: false
      }
    })
  })
}
