/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import { useQueryState } from 'react-router-use-location-state'
import LeagueHeader from '../components/LeagueHeader'
import LeaguesCompletedPage from './LeaguesCompleted'
function LeaguesCompleted (props) {
  const { location, match } = props
  const [activeState, setActiveState] = useQueryState('activeState', '1')
  const [VideoStream, setVideoStream] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  useEffect(() => {
    const obj = qs.parse(location.search)
    if (obj) {
      if (obj.activeState) {
        const active = obj.activeState
        setActiveState(active)
        toggle(active)
      }
    }
  }, [])
  return (
    <>
      <LeagueHeader
        {...props}
        completed
        setVideoStream={setVideoStream}
        VideoStream={VideoStream}
        goToBack={props?.location?.state?.isHome ? `/home/${match.params.sportsType}` : `/matches/${match.params.sportsType}`}
        backTab={match.path === '/liveleague/:id/:sportsType' ? '2' : match.path === '/completedleagues/participated/:id/:sportsType' ? '3' : '1'}
        />
      <LeaguesCompletedPage {...props} activeTab={activeTab} toggle={toggle} setActiveState={setActiveState}/>
    </>
  )
}

LeaguesCompleted.propTypes = {
  match: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  })
}

export default LeaguesCompleted
