import React, { Fragment } from 'react'
import { Card, CardBody } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import Lottie from 'react-lottie'
import autoPickLottie from '../../../../assests/animations/autopick.json'

function AutoPickAnimation (props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: autoPickLottie,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <Fragment>
      <div className="s-team-bg"></div>
      <Card className="auto-pick-animation">
        <CardBody className="auto-pick-animation-body p-10">
          <div className="card-body-container">
            <div className="card-body-container-message">
              <FormattedMessage id="AUTO_PICK_LOADING_MESSAGE" />
              <Lottie options={defaultOptions} />
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  )
}

export default AutoPickAnimation
