import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetHomeBanner, getMatchDetails, getBannerStatics, myRecentMatchList } from '../../redux/actions/match'
import { getMatchLeagueDetails } from '../../redux/actions/league'
import { GetUserProfile, onGetStreamButton } from '../../redux/actions/profile'
import { useHistory, useParams } from 'react-router-dom'

export const UserHome = (Component) => {
  const MyComponent = (props) => {
    const dispatch = useDispatch()
    const [pathName] = useState('')
    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(true)
    const [bannerImg, setBannerImg] = useState([])
    const token = useSelector(state => state.auth.token)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const resStatus = useSelector(state => state.match.resStatus)
    const resMessage = useSelector(state => state.match.resMessage)
    const bannerData = useSelector(state => state.match.bannerData)
    const currencyLogo = useSelector(state => state.settings.currencyLogo)
    const matchDetails = useSelector(state => state.match.matchDetails)
    const upcomingList = useSelector(state => state.match.recentMatchList)
    const userInfo = useSelector(state => state.profile.userInfo || state.auth.userData) || localStorage.getItem('userData')
    const isShowingStreamButton = useSelector(state => state.profile.isShowingStreamButton)
    const matchLeagueDetails = useSelector(state => state.league.matchLeagueDetails)
    const activeSports = useSelector(state => state.activeSports.activeSports)
    const previousProps = useRef({ bannerData, getUrlLink, resMessage, resStatus, matchLeagueDetails, userInfo }).current

    const history = useHistory()
    const params = useParams()

    useEffect(() => {
      if (token) { // call initialize
        if (history.location.pathname.includes('/matches/')) {
          dispatch(GetHomeBanner('MM', params?.sportsType))
        } else {
          dispatch(GetHomeBanner('H', params?.sportsType))
        }
        dispatch(onGetStreamButton())
        dispatch(myRecentMatchList(params?.sportsType, token))
      }
      if (getUrlLink) {
        setUrl(getUrlLink)
      }
    }, [params?.sportsType, token])

    useEffect(() => { // Handle response
      if (previousProps.getUrlLink !== getUrlLink) {
        if (getUrlLink) {
          setUrl(getUrlLink)
        }
      }
      return () => {
        previousProps.getUrlLink = getUrlLink
      }
    }, [getUrlLink])

    useEffect(() => { // Handle loader
      if (previousProps.resMessage !== resMessage) {
        if (!resStatus) {
          setLoading(false)
        }
      }
      return () => {
        previousProps.resMessage = resMessage
      }
    }, [resMessage, resStatus])

    useEffect(() => {
      if (previousProps.bannerData !== bannerData) {
        if (bannerData) { // Handle the response
          const items = []
          if (bannerData.length > 0) {
            bannerData.sort((a, b) => a.nPosition - b.nPosition).map((data) => {
              items.push({ src: data.sImage, eCategory: data.eCategory, eScreen: data.eScreen, iMatchId: data.iMatchId, iMatchLeagueId: data.iMatchLeagueId, key: data._id, sLink: data.sLink, eType: data.eType, sDescription: data.sDescription, bShowDescription: data.bShowDescription })
              return items
            })
          }
          setBannerImg(items)
        }
      }
      return () => {
        previousProps.bannerData = bannerData
      }
    }, [bannerData])

    useEffect(() => { // Handle loader
      if (userInfo && userInfo._id) {
        setLoading(false)
      }
    }, [userInfo])

    function onGetMatchDetails (ID) {
      if (ID && token) {
        dispatch(getMatchDetails(ID, '', token))
        // setLoading(true)
      }
    }

    function onGetLeagueDetails (ID) {
      if (ID && token) {
        dispatch(getMatchLeagueDetails(ID, token))
        // setLoading(true)
      }
    }

    function onBannerStatictics (ID) {
      if (ID && token) {
        dispatch(getBannerStatics(ID, token))
        // setLoading(true)
      }
    }

    function onGetUserProfile () {
      token && dispatch(GetUserProfile(token))
      // setLoading(true)
    }

    return (
      <Component
        {...props}
        myUpcomingList={upcomingList}
        resStatus={resStatus}
        resMessage={resMessage}
        activeSports={activeSports}
        bannerImg={bannerImg}
        token={token}
        loading={loading}
        matchDetails={matchDetails}
        onGetMatchDetails={onGetMatchDetails}
        onGetLeagueDetails={onGetLeagueDetails}
        onBannerStatictics={onBannerStatictics}
        matchLeagueDetails={matchLeagueDetails}
        userInfo={userInfo}
        currencyLogo={currencyLogo}
        onGetUserProfile={onGetUserProfile}
        url={url}
        isShowingStreamButton={isShowingStreamButton}
        pathName={pathName} />
    )
  }
  MyComponent.displayName = MyComponent
  return MyComponent
}
export default UserHome
