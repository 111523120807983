/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect, useRef, lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import Diamond from '../../../assests/images/diamond.svg'
// import DiamondDisable from '../../../assests/images/diamond_disable.svg'
import Multi from '../../../assests/images/multi-entry.svg'
import Single from '../../../assests/images/single-entry.svg'
// import MultiDisable from '../../../assests/images/multi_disable.svg'
import Copy from '../../../assests/images/League-status.svg'
import CopyDisable from '../../../assests/images/League-status-disabled.svg'
import CopyGuaranteed from '../../../assests/images/League-status-guaranteed.svg'
import Loyalty from '../../../assests/images/ic_Loyaly_colored.svg'
import CrownUtils from '../../../assests/images/crown-utils.svg'
import Voucher from '../../../assests/images/voucher.svg'
// import LoyaltyDisable from '../../../assests/images/ic_Loyaly_colored_disable.svg'
import Trophy from '../../../assests/images/trophy.svg'
import Medal from '../../../assests/images/medal.svg'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter, CardHeader, Badge, Table, Input, Label, FormGroup, Alert, UncontrolledTooltip } from 'reactstrap'
import offerIcon from '../../../assests/images/cashback.svg'
import JoinContest from '../../../HOC/SportsLeagueList/JoinContest'
import Loading from '../../../component/Loading'
import PromocodeLoading from '../../../component/PromocodeLoading'
import { viewContest, joinTeam } from '../../../Analytics.js'
import createteam from '../../../assests/images/createTeamWhite.svg'
import createContest from '../../../assests/images/createContestWhite.svg'
import close from '../../../assests/images/close.svg'
import rightGreenArrow from '../../../assests/images/right-green-arrow.svg'
import ProfileIcon from '../../../assests/images/profile.png'
import qs from 'query-string'
import SkeletonTable from '../../../component/SkeletonTable'
import walletIcon from '../../../assests/images/ic_wallet.svg'
import howToPlayIcon from '../../../assests/images/HowToPlayButton.svg'
import { clickEventPost, currencyConvert, getAdsConfig, getAdsUniqueId, handleImgError, lowCrownPost, openWalletPost, pageViewPost } from '../../../helper'

import ShowRewardAds from './ShowAds'
// import { uniqueId } from 'lodash'

const MyTeam = lazy(() => import('./MyTeam'))
const classNames = require('classnames')
const userData = JSON.parse(localStorage.getItem('userData'))

function League (props) {
  const {
    data,
    joinContest,
    match,
    teamList,
    contestJoinList,
    participated,
    toggleMessage,
    modalMessage,
    applyPromocodeData,
    onGetUserProfile,
    setModalMessage,
    joined,
    applyPromo,
    userInfo,
    loading,
    matchID,
    tab,
    firstPrize,
    firstRankType,
    getMyTeamList,
    getLeagueList,
    matchType,
    activeTab,
    location,
    appliedPromocode,
    onGetPromocodeList,
    matchPromoCodeList,
    promocodeLoading,
    currencyLogo,
    showInformation,
    amountData,
    MatchLeagueId,
    joinDetails,
    insideleagueDetailsPage,
    setLoading,
    url,
    teamPlayerList,
    getTeamPlayerList
  } = props
  const [noOfJoin, setNoOfJoin] = useState('')
  const [userTeams, setUserTeams] = useState([])
  const [promoData, setPromoData] = useState('')
  const [alert, setAlert] = useState(false)
  const [applied, setApplied] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [finalPromocode, setFinalPromocode] = useState('')
  const [PromoCodes, setPromoCodes] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)
  const [userTeamId, setUserTeamId] = useState('')
  const [updatedFilterData, setUpdatedFilterData] = useState([])
  const [IsJoinLeague, setIsJoinLeague] = useState(false)
  const [modalMessage2, setModalMessage2] = useState(false)
  const [totalPay, setTotalPay] = useState(0)
  const [totalPayFinal, setTotalPayFinal] = useState(0)
  const [UpdatedTeamList, setUpdatedTeamList] = useState([])
  const [topData, setTopData] = useState([])
  const [bottomData, setBottomData] = useState([])
  const [constestFill, setConstestFill] = useState('green')
  const [showAds, setShowAds] = useState(false)
  const [isAdsViewed, setIsAdsViewed] = useState(false)
  const [adsFailedCount, setAdsFailedCount] = useState(0)

  const adId = getAdsUniqueId()
  const { adspotkeyRewarded, dataSource } = getAdsConfig()

  const matchDetails = useSelector(state => state.match.matchDetails)
  const previousProps = useRef({
    teamList, userInfo, applyPromocodeData, amountData, data
  }).current
  const obj = qs.parse(location.search)

  // const uuId = uniqueId()

  useEffect(() => {
    setSelectedAll(false)
    if (props?.location?.state?.selectTeamModal && data?.iLeagueId === props?.location?.state?.leagueId) {
      setModalMessage(true)
      onGetUserProfile()
    }
    // getTeamPlayerList()
  }, [])

  useEffect(() => {
    if (alertMessage && alert) {
      setTimeout(() => setAlert(false), 2000)
    }
  }, [alertMessage, alert])

  useEffect(() => {
    if (teamList && UpdatedTeamList) {
      if (UpdatedTeamList.length !== 0) {
        const bottomData = []

        teamList && teamList?.length !== 0 && teamList?.sort((a, b) => a.sName > b.sName ? 1 : -1).map((data, index) => {
          UpdatedTeamList?.map((item) => {
            if (item._id === data._id) {
            // setSwitchTeamBottomData((prev) => [...prev, data])
              bottomData.push(data)
            }
            return item
          })
          return data
        })

        const unique1 = teamList?.filter((o) => UpdatedTeamList?.indexOf(o) === -1)
        const unique2 = UpdatedTeamList?.filter((o) => teamList?.indexOf(o) === -1)

        const unique = unique1.concat(unique2)

        setTopData(unique)
        setBottomData(bottomData)
      } else {
        setTopData([])
        setBottomData(teamList)
      }
    }
  }, [UpdatedTeamList, teamList])

  useEffect(() => {
    if (bottomData?.length > 0) {
      setUserTeams([bottomData[0]?._id])
    }
  }, [bottomData])

  // useEffect(() => {
  //   if (modalMessage) {
  //   }
  // }, [modalMessage])

  useEffect(() => {
    if (teamList !== previousProps.teamList && IsJoinLeague) {
      if (IsJoinLeague && teamList && teamList.length !== 0) {
        data && data._id && joint(data._id)
      }
    }
    return () => {
      previousProps.teamList = teamList
    }
  }, [teamList])

  useEffect(() => {
    if (previousProps.data !== data) {
      if (data?.iMatchId && props?.location?.state?.selectTeamModal && data?.iLeagueId === props?.location?.state?.leagueId) {
        getLeagueList()
      }
    }
    if (data?.eMatchStatus === 'U') {
      if (((100 * data?.nJoined) / data?.nMax) >= 70 && ((100 * data?.nJoined) / data?.nMax) < 100) {
        setConstestFill('yellow')
      } else if (((100 * data?.nJoined) / data?.nMax) === 100) {
        setConstestFill('red')
      } else {
        setConstestFill('green')
      }
    } else {
      setConstestFill('green')
    }

    return () => {
      previousProps.data = data
    }
  }, [data])

  useEffect(() => {
    if (IsJoinLeague) {
      if (!teamList || (teamList && teamList.length > 0 && data && data.iMatchId && teamList[0].iMatchId !== data.iMatchId)) {
        getMyTeamList()
      } else {
        data && data._id && joint(data._id)
      }
    }
  }, [IsJoinLeague])

  useEffect(() => {
    if (userInfo !== previousProps.userInfo) {
      if (userInfo) {
        const promocodeData = applyPromocodeData && applyPromocodeData.nDiscount ? applyPromocodeData.nDiscount : 0
        const updatedFilterDataFinal = updatedFilterData?.length === 0 ? 1 : updatedFilterData?.length
        const value = data && updatedFilterData && ((data.nPrice * updatedFilterDataFinal) - promocodeData - (userInfo && userInfo.nCurrentTotalBalance))
        setTotalPay(value > 0 ? value.toFixed(2) : 0)
        const value2 = (data?.nPrice * updatedFilterDataFinal) - promocodeData
        setTotalPayFinal(value2)
        setLoading(false)
      }
    }
    return () => {
      previousProps.userInfo = userInfo
    }
  }, [userInfo])

  useEffect(() => {
    if (selectedAll) {
      let updatedSelectedTeam = []
      if (UpdatedTeamList && UpdatedTeamList.length > 0) {
        updatedSelectedTeam = UpdatedTeamList.map(data => data._id)
        setUserTeams(updatedSelectedTeam)
      } else if (teamList && teamList.length > 0) {
        updatedSelectedTeam = teamList.map(data => data._id)
        setUserTeams(updatedSelectedTeam)
      }
    } else {
      if (userTeamId.length !== 0 && userTeamId.length === userTeams.length) {
        setUserTeams(userTeams.filter((item) => item.id !== userTeamId))
      } else {
        setUserTeams([])
      }
    }
  }, [selectedAll, userTeamId])

  useEffect(() => {
    if (applyPromocodeData !== previousProps.applyPromocodeData) {
      if ((applyPromocodeData && applyPromocodeData.nDiscount && appliedPromocode) || !applyPromocodeData) {
        setFinalPromocode(applyPromocodeData && applyPromocodeData.sCode)
        const promocodeData = applyPromocodeData && applyPromocodeData.nDiscount ? applyPromocodeData.nDiscount : 0
        const updatedFilterDataFinal = updatedFilterData?.length === 0 ? 1 : updatedFilterData?.length
        const value = data && updatedFilterData && ((data.nPrice * updatedFilterDataFinal) - promocodeData - (userInfo && userInfo.nCurrentTotalBalance))
        setTotalPay(value > 0 ? value.toFixed(2) : 0)
        const value2 = (data?.nPrice * updatedFilterDataFinal) - promocodeData
        setTotalPayFinal(value2)
      }
    }
    return () => {
      previousProps.applyPromocodeData = applyPromocodeData
    }
  }, [applyPromocodeData])

  useEffect(() => {
    if (previousProps.appliedPromocode !== appliedPromocode) { // handle the loader
      setApplied(appliedPromocode)
    }
    return () => {
      previousProps.appliedPromocode = appliedPromocode
    }
  }, [appliedPromocode])

  useEffect(() => {
    if (data) {
      setNoOfJoin(data.bPrivateLeague && data.bMultipleEntry ? data.nMax - data.nJoined : data.nTeamJoinLimit)
    }
  }, [data])

  useEffect(() => {
    if (contestJoinList && data && teamList) {
      const contestData = insideleagueDetailsPage
        ? joinDetails
        : contestJoinList && contestJoinList.length !== 0 && contestJoinList.find(contest => contest && contest.iMatchLeagueId === data._id)
      const FilterTeam = contestData && contestData.aUserTeams && contestData.aUserTeams.length > 0
        ? teamList && teamList.length !== 0 && teamList.filter(team => contestData && contestData.aUserTeams && !contestData.aUserTeams.includes(team._id))
        : teamList
      if (contestData) {
        setUpdatedTeamList(FilterTeam)
      }
    }
  }, [contestJoinList, data, teamList, joinDetails])

  function joint (ID) {
    const contestData = insideleagueDetailsPage
      ? joinDetails
      : contestJoinList && contestJoinList.length !== 0 && contestJoinList.find(contest => contest && contest.iMatchLeagueId === data._id)
    if (contestData && teamList && teamList.length !== 0) {
      const FilterTeam = contestData && contestData.aUserTeams && contestData.aUserTeams.length > 0
        ? teamList && teamList.length !== 0 && teamList.filter(team => contestData && contestData.aUserTeams && !contestData.aUserTeams.includes(team._id))
        : teamList
      if (FilterTeam && FilterTeam.length !== 0) {
        setUpdatedTeamList(FilterTeam)
        toggleMessage()
        pageViewPost('jct')
        setNoOfJoin(data.bPrivateLeague && data.bMultipleEntry ? data.nMax - data.nJoined : contestData && contestData.nJoinedCount ? (data.nTeamJoinLimit - contestData.nJoinedCount) : data.nTeamJoinLimit)
      } else {
        if (data && data.bPrivateLeague && data.sShareCode) {
          props.history.push({
            pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}/private/${data.sShareCode}`,
            search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            firstTime: true
          })}`,
            state: {
              activeTab: activeTab,
              leagueId: data?._id,
              joinPrivateContest: true,
              shareCode: data?.sShareCode
            }
          })
        } else {
          props.history.push({
            pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}`,
            search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            firstTime: true
          })}`,
            state: {
              activeTab: activeTab,
              leagueId: data?._id
            }
          })
        }
      }
    } else {
      if (teamList && teamList.length !== 0) {
        toggleMessage()
        pageViewPost('jct')
        setNoOfJoin(data.bPrivateLeague && data.bMultipleEntry ? data.nMax - data.nJoined : data.nTeamJoinLimit)
      } else {
        if (data && data.bPrivateLeague && data.sShareCode) {
          props.history.replace({
            pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}/private/${data.sShareCode}`,
            search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            firstTime: true

          })}`,
            state: {
              activeTab: activeTab,
              leagueId: data?._id,
              joinPrivateContest: true,
              shareCode: data?.sShareCode,
              firstTime: true
            }
          })
        } else {
          props.history.replace({
            pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${ID}`,
            search: `?${qs.stringify({
            homePage: obj?.homePage ? 'yes' : undefined,
            firstTime: true
          })}`,
            state: {
              activeTab: activeTab,
              leagueId: data?._id,
              firstTime: true
            }
          })
        }
      }
    }
    setIsJoinLeague(false)
  }

  const AddTeams = () => {
    const { bJoinByAd } = data
    if (bJoinByAd) {
      setShowAds(true)
    } else {
      LeagueJoin(userTeams)
    }
  }

  useEffect(() => {
    if (isAdsViewed) {
      LeagueJoin(userTeams)
    }
  }, [isAdsViewed])

  function LeagueJoin (userTeams) {
    setModalMessage2(false)
    if (totalPay > 0) {
      props.history.push({
        pathname: '/deposit',
        state: {
          amountData: { nAmount: Number(totalPay) },
          message: 'Insufficient Balance'
        }
      })
    } else {
      applied && finalPromocode ? joinContest(userTeams, finalPromocode) : joinContest(userTeams, '')
      callJoinTeamEvent()
    }
  }

  const applePromoCode = (promo) => {
    if (data && data._id && updatedFilterData && updatedFilterData.length > 0 && promo) {
      applyPromo({ iMatchLeagueId: data._id, nTeamCount: updatedFilterData.length, sPromo: promo })
      setPromoCodes(false)
      setPromoData('')
      setModalMessage2(true)
      setFinalPromocode('')
    }
  }
  function callViewContestEvent () {
    if (data && data.sName && data._id && location.pathname) {
      viewContest(data.sName, data._id, location.pathname)
    } else {
      data && data.sName && data._id && viewContest(data.sName, data._id, '')
    }
    if (PromoCodes) setPromoCodes(false)
  }

  function callJoinTeamEvent () {
    if (userTeams && match && match.params && match.params.id && location.pathname) {
      joinTeam(userTeams, match.params.id, location.pathname)
    } else {
      userTeams && match && match.params && match.params.id && joinTeam(userTeams, match.params.id, '')
    }
  }

  function getPromocodeList () {
    setPromoCodes(true)
    setModalMessage(false)
    setModalMessage2(false)
    data && data._id && onGetPromocodeList(data._id)
  }

  function clearPromo () {
    setTotalPayFinal(updatedFilterData?.length > 0 ? data?.nPrice * updatedFilterData?.length : data?.nPrice)
    setApplied(false)
    setFinalPromocode('')
  }

  const onFailLoadAds = _ => {
    setShowAds(false)
    setAdsFailedCount(adsFailedCount + 1)
    setAlert(true)
    if (adsFailedCount < 9) {
      setAlertMessage('Failed to Load Video Ad. Try Again')
    } else setAlertMessage("You've Reached Maximum Limit")
  }

  return (
    <>
      {loading && <Loading />}
      {alert && alertMessage ? <Alert isOpen={alert} className='select-all' color="primary">{alertMessage}</Alert> : ''}

      <Fragment>
        {
          showAds
            ? <ShowRewardAds adId={adId} adspotkey={adspotkeyRewarded} dataSource={dataSource} onClose={setShowAds} adViewed={setIsAdsViewed} adLoadFailed = {onFailLoadAds}/>
            : null
        }
        {
          data && data.bPrivateLeague && (
            <CardHeader className="mt-3 d-flex justify-content-between private-contest-header">
              {/* {data && data.sName} */}
              <div className='privat-text'>
                <h3>PRIVATE</h3>
              </div>
              {
                matchType === 'upcoming' && ((data.nJoined < data.nMax) || data.bUnlimitedJoin) && (
                  <button onClick={() => props.history.push({
                    pathname: `/create-contest/${(match.params.sportsType).toLowerCase()}/${data.iMatchId}/${data._id}/invite`,
                    search: `?${qs.stringify({
                    homePage: obj?.homePage ? 'yes' : undefined,
                    firstTime: true
                  })}`,
                    state: { matchDetails: matchDetails, matchLeagueDetails: data }
                  })} className="bg-transparent icon-share"><span className='mr-2'><FormattedMessage id='Share_with_friends' /></span></button>
                )
              }
            </CardHeader>
          )
        }
        <div className="card border-0 bg-transparent" onClick={() => callViewContestEvent()}>
          <CardBody className={data && data.bPrivateLeague ? 'border-top-0 bg-white rounded-0' : 'bg-white'}>
            <Link onClick={() => {
              if (props.location.pathname.includes('/leagues/') && activeTab === 1) {
                clickEventPost('cl_ac_ic')
              } else if (props.location.pathname.includes('/leagues/') && activeTab === 2) {
                clickEventPost('cl_mc_ci', data?._id)
              } else {
                clickEventPost('cd_ci')
              }
            }} className="joinleague-link" to={!participated
              ? {
                  pathname: `/leagues-detail/${(match.params.sportsType).toLowerCase()}/${matchID}/${data && data._id}`,
                  search: `?${qs.stringify({
                      homePage: obj?.homePage ? 'yes' : undefined
                    })}`,
                  state: { tab: tab, referUrl: `/leagues/${(match.params.sportsType).toLowerCase()}/${matchID}`, teamList }
                }
              : { pathname: `/leagues-details/${(match.params.sportsType).toLowerCase()}/${match && match.params.id}/${data && data._id}/participated`, state: { ...location.state } }}>
                </Link>
              <ul className="m-0 d-flex align-items-center flex-wrap leagues-card-container">
                <li className='d-flex flex-column align-items-between'>
                  <p><FormattedMessage id="League_prizePool" /></p>
                  <h1 className='m-0 d-flex'>{data?.ePayoutType === 'V' ? <><img src={Voucher} /><span>Reward vouchers</span></> : data?.ePayoutType === 'CW' ? <><img src={CrownUtils} />{data && data.nTotalPayout && currencyConvert(data.nTotalPayout)}</> : <>{currencyLogo}{data && data.nTotalPayout && currencyConvert(data.nTotalPayout)}</>}</h1>
                </li>
                <li className='d-flex flex-column'>
                  {data && data.nLoyaltyPoint !== 0 && data.nLoyaltyPoint && <p>{data.nLoyaltyPoint} Loyalty Points</p>}
                  <div className='d-flex justify-content-end align-items-center'>
                    <p className='mb-0'><FormattedMessage id='League_Entry' /></p>
                    <div className='join-btn' onClick={() => {
                      if (teamPlayerList) {
                        setIsJoinLeague(true)
                      } else {
                        setAlert(true)
                        setAlertMessage('Match Player Does Not Exist')
                      }
                    }}>
                      <button
                        disabled={
                          participated ||
                          (Object.keys(joined).length > 0 && data && data.bPrivateLeague && !data.bMultipleEntry) ||
                          (joined && data && !data.bPrivateLeague && (joined.nJoinedCount >= data.nTeamJoinLimit)) ||
                          (joined && data && data.bPrivateLeague && (joined.nJoinedCount >= data.nMax)) ||
                          (data && data.nJoined && data.nMax && !data.bUnlimitedJoin && data.nJoined === data.nMax) ||
                          (data && joinDetails && !data.bPrivateLeague && (joinDetails.nJoinedCount === data.nTeamJoinLimit))
                        }
                      >
                        {data?.nCrownUtil && data?.nCrownUtil !== 0
                          ? <><img src={CrownUtils} /> {currencyConvert(data?.nCrownUtil)}</>
                          : <>{data?.nPrice
                            ? `${currencyLogo}${data?.nPrice}`
                            : 'FREE'
                            }</>
                        }
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              {
                data && data.nJoined >= 0
                  ? <Fragment>
                    <div className='leagues-card-container'>
                      {(data && data.bUnlimitedJoin)
                        ? <div className="ul-p-bar"></div>
                        : <div className="p-bar"><span style={{ width: data && data.nMax >= 0 && data.nJoined >= 0 && `${((100 * data.nJoined) / data.nMax)}%`, background: constestFill === 'yellow' ? '#F7AB20' : constestFill === 'red' ? '#FF0000' : '#1CA959' }}></span></div>}
                    </div>
                    <div className="t-info d-flex align-items-center justify-content-between leagues-card-container">
                      <span>
                        {
                          constestFill === 'green'
                            ? (data?.nJoined > data.nMax) && (!data && data.bUnlimitedJoin)
                                ? (
                              <Fragment>
                                0 <FormattedMessage id="League_left" />
                              </Fragment>
                                  )
                                : (
                              <Fragment>
                                {data && data.bUnlimitedJoin ? 'ထ ' : amountData && amountData.sKey === 'SUCCESS' && data._id === MatchLeagueId ? (currencyConvert(data.nMax - (amountData?.oValue?.nJoined))) : data && data.nJoined >= 0 && data.nMax >= 0 && (data.nMax - data.nJoined) >= 0 ? currencyConvert(data.nMax - data.nJoined) : 0} <FormattedMessage id="League_left" />
                              </Fragment>
                                  )
                            : constestFill === 'yellow'
                              ? 'Filling Fast'
                              : constestFill === 'red'
                                ? <span className="text-red">Contest Full</span>
                                : ''
                        }
                      </span>
                      <span className='blackColor'>
                        {
                          (data?.nJoined > data.nMax)
                            ? (
                            <Fragment>
                              {data && data.bUnlimitedJoin ? 'ထ' : amountData && amountData.sKey === 'SUCCESS' && data._id === MatchLeagueId ? currencyConvert(amountData?.oValue?.nJoined) : data && data.nJoined === 0 ? 0 : currencyConvert(data.nJoined)} {(<span className='blackColor'><FormattedMessage id="League_spots" /></span>)}
                            </Fragment>
                              )
                            : (
                            <Fragment>
                              {data && data.bUnlimitedJoin ? 'ထ' : data && (data.nMax > data.nJoined) ? currencyConvert(data.nMax) : currencyConvert(data.nJoined)} {(<span className='blackColor'><FormattedMessage id="League_spots" /></span>)}
                            </Fragment>
                              )
                        }
                      </span>
                    </div>
                  </Fragment>
                  : <Fragment>
                    <div className="p-bar"><span style={{ width: data && data.nMax >= 0 && data.nJoined >= 0 ? ((100 * data.nJoined) / data.nMax) : '' }}> </span> </div>
                    <div className="t-info d-flex align-items-center justify-content-between">
                      <span> <FormattedMessage id="League_left" /> </span>
                      <span> <FormattedMessage id="League_teams" /> </span>
                    </div>
                  </Fragment>
              }

            <CardFooter className="leagues-footer">
              {data?._id &&
                <>
                  <div className='footer-item' id={`winPrize-${data?._id}`}>
                    <img src={Medal} />
                    <h4>{firstRankType === 'CW' || firstRankType === 'V' || firstPrize === 0
                      ? firstRankType === 'V' ? <><img src={Voucher} /></> : <><img src={CrownUtils} /></>
                      : <>{firstRankType !== 'E' && firstRankType !== 'CW' && firstPrize !== 0 && currencyLogo}</>
                    } {firstRankType !== 'E' && firstRankType !== 'V' &&
                      currencyConvert(parseFloat(Number((firstPrize)).toFixed(2)))
                    } {firstRankType === 'B' && 'Bonus'} {firstRankType === 'V' && firstPrize} {firstRankType === 'E' && firstPrize} </h4>
                  </div>
                  <UncontrolledTooltip placement="bottom" autohide={false} target={`winPrize-${data?._id}`}>
                    1<sup>st</sup> Prize =
                    {(firstRankType === 'CW' || !firstRankType) ? <><img src={CrownUtils} width="11px" className='ml-1' style={{ marginTop: '-2px' }} /></> : firstRankType === 'V' ? <><img src={Voucher} width="11px" className='ml-1' style={{ marginTop: '-2px' }} /></> : <>{firstRankType !== 'E' && firstRankType !== 'CW' && currencyLogo}</>} {firstRankType !== 'E' && firstRankType !== 'V' && currencyConvert(parseFloat(Number((firstPrize)).toFixed(2)))} {firstRankType === 'B' && 'Bonus'} {firstRankType === 'V' && 'Voucher'} {firstRankType === 'E' && firstPrize}
                  </UncontrolledTooltip>
                  <div className='footer-item' id={`winCount-${data?._id}`}>
                    <img src={Trophy} />
                    <h4>{data && data.nMax && data.nWinnersCount ? (parseInt((data.nWinnersCount || 0) / data.nMax * 100)) : 0}<FormattedMessage id="Common_perc" /></h4>
                  </div>
                  <UncontrolledTooltip placement="bottom" autohide={false} target={`winCount-${data?._id}`}>
                    <span className='mr-1'>
                      {data && data.nMax && data.nWinnersCount ? (parseInt((data.nWinnersCount || 0) / data.nMax * 100)) : 0}<FormattedMessage id="Common_perc" />
                    </span>
                    <FormattedMessage id="Participate_Win_Prize" />
                  </UncontrolledTooltip>
                  <div className='footer-item' id={`teamEntry-${data?._id}`}>
                  {(data && data.nTeamJoinLimit && data.nTeamJoinLimit === 1 && !data.bMultipleEntry)
                    ? <>
                      <img src={Single} />
                      <h4><FormattedMessage id="Single-entry" /></h4>
                    </>
                    : <>
                      <img src={Multi} />
                      <h4><FormattedMessage id="Multi-upto" /> { data?.bPrivateLeague ? (data?.nMax) : data?.nTeamJoinLimit} <FormattedMessage id="Multi-teams" /></h4>
                    </>
                  }
                  </div>
                  <UncontrolledTooltip placement="bottom" autohide={false} target={`teamEntry-${data?._id}`}>
                    <span className='mr-1'>
                      Max&nbsp;
                      { data?.bPrivateLeague ? (data?.nMax) : data?.nTeamJoinLimit}
                    </span>
                    <FormattedMessage id="Team_allowed_contest" />
                  </UncontrolledTooltip>
                  {(data && data.nBonusUtil !== 0 && (!data.bPrivateLeague)) &&
                    <div className='footer-item'>
                      <img src={Diamond} />
                      <h4>{(data && data.nBonusUtil && data.nBonusUtil !== 0 && !data.bPrivateLeague) && data.nBonusUtil}<FormattedMessage id="Common_perc" /></h4>
                    </div>
                  }
                  <div className="footer-item" id={`Guaranteed-${data?._id}`}>
                    {data && data.bConfirmLeague ? <img src={CopyGuaranteed} alt={<FormattedMessage id='Copy' />} /> : data && (!data.bConfirmLeague) && (data.nJoined >= data.nMin) ? <img src={Copy} alt={<FormattedMessage id='Copy' />} /> : <img src={CopyDisable} alt={<FormattedMessage id='Copy' />} />}
                  </div>
                  <UncontrolledTooltip placement="bottom" autohide={false} target={`Guaranteed-${data?._id}`}>
                    {data && data.bConfirmLeague ? <FormattedMessage id='Guaranteed_contest' /> : data && (!data.bConfirmLeague) && (data.nJoined >= data.nMin) ? <FormattedMessage id='Confirmed_contest' /> : <FormattedMessage id='UnConfirmed_contest' />}
                  </UncontrolledTooltip>
                </>
              }
            </CardFooter>
          </CardBody>
          {data && data.userJoined && (matchDetails?.eStatus === 'L' || matchDetails?.eStatus === 'I' || matchDetails?.eStatus === 'CMP') &&
          <Table
              className="bg-white
                  player-list
                  price-table
                  participated-playerlist
                  team-com-on
                  table-radius
                  mt-2
                  "
            >
              {
                loading
                  ? (
                    <SkeletonTable Lines={3} />
                    )
                  : (
                    <Fragment>
                      <thead>
                        <tr>
                          <td><FormattedMessage id="Team_AllTeam" />
                            {/* ({(AllLeaderBoardList && myTeamsLeaderBoardList && AllLeaderBoardList.length + myTeamsLeaderBoardList.length)}) */}
                            &nbsp;({data && data.userJoined?.length})
                          </td>
                          <td className="player_points"><FormattedMessage id="Player_points" /></td>
                          <td><FormattedMessage id="League_Rank" /></td>
                        </tr>
                      </thead>
                      <tbody>
                      {data?.userJoined?.sort((a, b) => a.nRank - b.nRank).map((team, index) => {
                        return (
                          index <= 2
                            ? (
                              <tr key={index} onClick={() => {
                                props.history.push(!participated
                                  ? {
                                      pathname: `/leagues-detail/${(match.params.sportsType).toLowerCase()}/${matchID}/${data && data._id}`,
                                      search: `?${qs.stringify({
                                      homePage: obj?.homePage ? 'yes' : undefined
                                    })}`,
                                      state: { tab: tab, referUrl: `/leagues/${(match.params.sportsType).toLowerCase()}/${matchID}`, teamList }
                                    }
                                  : { pathname: `/leagues-details/${(match.params.sportsType).toLowerCase()}/${match && match.params.id}/${data && data._id}/participated`, state: { ...location.state } })
                              }}>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="l-img cursor-default">
                                      <img src={userData && userData.sProPic ? url + userData.sProPic : ProfileIcon} onError={(e) => handleImgError(e, ProfileIcon)} alt="" />
                                    </div>
                                    <div>
                                      <span className="p-name cursor-default">{team.sTeamName ? team.sTeamName : ''}</span>
                                        {
                                              team.bTeamWinningZone && (
                                                <Fragment>
                                                    <div className='greenText green-color'>
                                                      <img src={Trophy} className='mr-1'></img>
                                                      <span className='mt-2 winning-tex won-txt'>
                                                        <FormattedMessage id="Match_Winning_Zone" />
                                                      </span>
                                                    </div>
                                                </Fragment>
                                              )
                                            }
                                            {matchDetails?.eStatus === 'CMP' &&
                                            <Fragment>
                                              {team && !team.nPrice > 0 && team.nCrownWin > 0 && team && !team?.nBonusWin > 0 && team && team.aExtraWin && team.aExtraWin.length === 0
                                                ? (<p className="won-txt"> <FormattedMessage id="Team_You_Won" /><img src={CrownUtils} /> {parseFloat(Number(team.nCrownWin))} </p>)
                                                : team && team.nPrice > 0 && !team.nCrownWin > 0 && team && !team?.nBonusWin > 0 && team && team.aExtraWin && team.aExtraWin.length === 0
                                                  ? (<p className="won-txt"> <FormattedMessage id="Team_You_Won" />{currencyLogo} {parseFloat(Number(team.nPrice).toFixed(2))} </p>)
                                                  : team && !team.nPrice > 0 && !team.nCrownWin > 0 && team && team?.nBonusWin > 0 && team && team.aExtraWin && team.aExtraWin.length === 0
                                                    ? (<p className="won-txt"> <FormattedMessage id="Team_You_Won" />{currencyLogo} {parseFloat(Number(team.nBonusWin).toFixed(2))} <FormattedMessage id="League_bonus" /> </p>)
                                                    : team && !team.nPrice > 0 && !team.nCrownWin > 0 && team && !team?.nBonusWin > 0 && team && team.aExtraWin && team.aExtraWin.length >= 1
                                                      ? (<p className="won-txt"> <FormattedMessage id="Team_You_Won" />{team.aExtraWin[0]?.eExtraWinType === 'V' && <img src={Voucher} />} {team.aExtraWin[0]?.sInfo} </p>)
                                                      : team && !team.nPrice > 0 && !team.nCrownWin > 0 && team && !team?.nBonusWin > 0 && team && team.aExtraWin && team.aExtraWin.length === 0
                                                        ? ''
                                                        : (<p className='greenText won-txt'><FormattedMessage id="WON_Multiple_Prizes"/> </p>)
                                            }
                                            </Fragment>}
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {team.nTotalPoints > 0 ? team.nTotalPoints : '0'}
                                </td>
                                <td>#{team.nRank ? team.nRank : 0}</td>
                              </tr>
                              )
                            : index === 3 && (
                                  <tr key={index}>
                                    <td colSpan={3}>
                                      <Link to={!participated
                                        ? {
                                            pathname: `/leagues-detail/${(match.params.sportsType).toLowerCase()}/${matchID}/${data && data._id}`,
                                            search: `?${qs.stringify({
                                      homePage: obj?.homePage ? 'yes' : undefined
                                    })}`,
                                            state: { tab: tab, referUrl: `/leagues/${(match.params.sportsType).toLowerCase()}/${matchID}`, teamList }
                                          }
                                        : { pathname: `/leagues-details/${(match.params.sportsType).toLowerCase()}/${match && match.params.id}/${data && data._id}/participated`, state: { ...location.state } }}>
                                        <div className={'mt-footer footerPrizeBreakup d-flex align-items-center justify-content-around'}>
                                          <span>
                                            + {data.userJoined?.length - 3} more
                                          </span>
                                        </div>
                                      </Link>
                                    </td>
                                  </tr>
                            )
                        )
                      })
                      }
                      </tbody>
                    </Fragment>
                    )
              }
            </Table>
          }
        </div>
        {
          modalMessage
            ? <>
              <div className="s-team-bg" onClick={() => {
                setModalMessage(false)
                setSelectedAll(false)
              }}></div>
              <Card className="filter-card select-team switch-team-popup promo-card">
                <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                  {/* <button><FormattedMessage id="Team_SelectTeam" /></button>
                  <button onClick={() => {
                    setSelectedAll(false)
                    setModalMessage(false)
                  }}><img src={close}></img></button> */}
                  <h2 onClick={() => {
                    setModalMessage(false)
                  }}> <span className='icon-left-arrow mr-2'></span> <FormattedMessage id="Team_SelectTeam" /></h2>
                  <div className="wallet-icon">
                    <img className='mr-3' onClick={() => props.history.push({ pathname: `/how-to-play/${match.params.sportsType}`, search: '', state: { goBack: true } })} src={howToPlayIcon}></img>
                    <img onClick={() => openWalletPost()} src={walletIcon}></img>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="popup-content max-height100">
                    {/* <div className="two-button border-0 bg-white m-0 d-flex justify-content-between card-footer">

                      <Button type="submit" className='create-contest-button' onClick={() => props.history.push({
                        pathname: `/create-contest/${(match.params.sportsType).toLowerCase()}/${match.params.id}`,
                        search: `?${qs.stringify({
                          homePage: obj?.homePage ? 'yes' : undefined
                        })}`
                      })}>
                        <img src={createContest} width={20} className='mr-2' alt={<FormattedMessage id='Team_CreatePrivateLeague' />}></img>
                        <FormattedMessage id="Team_CreatePrivateLeague" />
                      </Button>
                    </div> */}
                    {topData && topData.length !== 0 &&
                      <div className='already-join'>
                        <h2><FormattedMessage id="Already_Joined" /></h2>
                            {topData.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                              return (
                                <Suspense key={index} fallback={<Loading />}>
                                  <div className='switch-team-wrap'>
                                  <MyTeam {...props}
                                    join
                                    upcoming
                                    disabledRadio={UpdatedTeamList?.find((item) => item._id === data1._id)?._id}
                                    disabledRadioFlag={UpdatedTeamList?.length !== 0}
                                    teamId={data1._id}
                                    leagueData={data}
                                    noOfJoin={noOfJoin}
                                    userTeams={userTeams}
                                    selectModal={modalMessage}
                                    setUserTeams={(Id) => {
                                      // setUserTeams(Id)
                                      setUserTeamId(Id)
                                      // setSelectedAll(false)
                                    }}
                                    params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={teamList} UserTeamChoice teamPlayerList={teamPlayerList} />
                                    </div>
                                </Suspense>
                              )
                            })}
                      </div>
                    }
                    {bottomData.length !== 0 &&
                    <div className="chooseteam-to-join">
                      <FormattedMessage id="Select_from_your_existing_teams" />
                    </div>}

                    {/* {data && data.bMultipleEntry && bottomData.length !== 0 && ((UpdatedTeamList && UpdatedTeamList.length !== 1) && (teamList && teamList.length !== 1)) && <div className='SelectAll Radio-MyTeam d-flex align-items-center'>
                        <input type="radio" id='name' name="gender" onClick={() => setSelectedAll(!selectedAll)}
                            checked={selectedAll}
                        />
                        <label htmlFor='name'><FormattedMessage id="Team_SelectAll" /> </label>
                      </div>} */}
                      {bottomData && bottomData.length !== 0 &&
                      bottomData.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                        return (
                          <Suspense key={index} fallback={<Loading />}>
                            <div className='switch-team-wrap'>
                            <MyTeam {...props}
                              join
                              upcoming
                              disabledRadio={UpdatedTeamList?.find((item) => item._id === data1._id)?._id}
                              disabledRadioFlag={UpdatedTeamList?.length !== 0}
                              teamId={data1._id}
                              leagueData={data}
                              noOfJoin={noOfJoin}
                              userTeams={userTeams}
                              selectModal={modalMessage}
                              setUserTeams={(Id) => {
                                setUserTeams(Id)
                                setUserTeamId(Id)
                                setSelectedAll(false)
                              }}
                              params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={teamList} UserTeamChoice teamPlayerList={teamPlayerList}
                              onLinesUp={matchDetails && matchDetails.bLineupsOut} />
                              </div>
                          </Suspense>
                        )
                      })}
                      {/* {
                        teamList && teamList.length !== 0 &&
                          teamList.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                            return (
                              <Suspense key={data1._id} fallback={<Loading />}>
                                <div className='switch-team-wrap'>
                                <MyTeam {...props}
                                  join
                                  upcoming
                                  disabledRadio={UpdatedTeamList?.find((item) => item._id === data1._id)?._id}
                                  disabledRadioFlag={UpdatedTeamList?.length !== 0}
                                  teamId={data1._id}
                                  leagueData={data}
                                  noOfJoin={noOfJoin}
                                  userTeams={userTeams}
                                  setUserTeams={(Id) => {
                                    setUserTeams(Id)
                                    setUserTeamId(Id)
                                    setSelectedAll(false)
                                  }}
                                  params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={teamList} UserTeamChoice teamPlayerList={teamPlayerList} />
                                  </div>
                              </Suspense>
                            )
                          })
                      } */}
                  </div>
                </CardBody>
                {userInfo?.nCurrentCrown < (userTeams?.length ? (data?.nCrownUtil * userTeams?.length) : data?.nCrownUtil) &&
                  <div className="lowbalance">
                    <div className="lowbalance-text">
                        <img src={walletIcon} alt="" />
                        <div>
                          <FormattedMessage id="Low_crowns_balance" />
                          <span className="need-crown">{currencyConvert((data?.nCrownUtil * userTeams?.length) - userInfo?.nCurrentCrown)}</span>
                          <FormattedMessage id="Low_crowns_balance_more" />
                        </div>
                    </div>
                    <div className="balance-btn btn btn-primary" onClick={() => lowCrownPost()}>
                        <img src={CrownUtils} className="mr-1" alt="" />
                        <FormattedMessage id="Earn_More" />
                    </div>
                  </div>
                }
                <CardFooter className='btn-bottom border-0 m-0'>
                  <div className="row">
                    <div className="col-6 pr-2">
                      <Button type="submit" color="border" className='w-100' onClick={() => {
                        props.history.replace({
                          pathname: data && data.bPrivateLeague && data.sShareCode
                            ? `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${data._id}/private/${data.sShareCode}`
                            : `/create-team/${(match.params.sportsType).toLowerCase()}/${match.params.id}/join/${data._id}`,
                          search: `?${qs.stringify({
                          homePage: obj?.homePage ? 'yes' : undefined,
                          firstTime: true
                          })}`,
                          state: {
                            activeTab: activeTab,
                            joinLink: true,
                            leagueId: data._id,
                            joinPrivateContest: !!data.bPrivateLeague,
                            shareCode: data.bPrivateLeague ? data?.sShareCode : '',
                            firstTime: true
                          }
                        })
                        clickEventPost('cd_ntm')
                      }}>
                        {/* <img src={createteam} width={20} className='mr-2' alt={<FormattedMessage id='Team_CreateTeam' />}></img> */}
                        <FormattedMessage id="Team_CreateTeam" /></Button>
                    </div>
                    <div className="col-6 pl-2">
                      <Button type="submit" color='primary' className="w-100"
                        disabled={(userTeams && userTeams.length === 0) || (userInfo?.nCurrentCrown < (userTeams?.length ? (data?.nCrownUtil * userTeams?.length) : data?.nCrownUtil))}
                        onClick={() => AddTeams(userTeams)}
                        // onClick={() => {
                        //   if (userTeams.length > (data.nMax - data.nJoined) && (!data.bUnlimitedJoin)) {
                        //     setAlert(true)
                        //     setAlertMessage(<p><FormattedMessage id="Team_Title1" /> {data.nMax - data.nJoined || '-'} <FormattedMessage id="Team_Title2" /></p>)
                        //     setTimeout(() => {
                        //       setAlertMessage('')
                        //       setAlert(false)
                        //     }, 2000)
                        //   } else if (noOfJoin >= userTeams.length) {
                        //     const filterData = teamList.filter(data => userTeams.includes(data._id))
                        //     onGetUserProfile()
                        //     setUpdatedFilterData(filterData)
                        //     setModalMessage2(true)
                        //     setModalMessage(false)
                        //     setFinalPromocode('')
                        //   } else {
                        //     setAlert(true)
                        //     setAlertMessage(<p><FormattedMessage id="Team_Title1" /> {noOfJoin || '-'} <FormattedMessage id="Team_Title2" /></p>)
                        //     setTimeout(() => {
                        //       setAlertMessage('')
                        //       setAlert(false)
                        //     }, 2000)
                        //   }
                        //   setSelectedAll(false)
                        // // }}><FormattedMessage id="League_join" /> (<FormattedMessage id='Pay'/> <FormattedMessage id='Team_Rupee'/>0)</Button>
                        // }}
                        > { data.bJoinByAd === true ? <FormattedMessage id='Join_With_Ads' /> : (data?.nCrownUtil === 0 && data?.nPrice === 0) ? <FormattedMessage id='Join_With_Free' /> : <FormattedMessage id='Join_With' />}{' '}
                        {data?.nCrownUtil && data?.nCrownUtil !== 0
                          ? <><img src={CrownUtils} width="12" style={{ marginTop: '-2px' }} /> {currencyConvert(data?.nCrownUtil * userTeams.length)}</>
                          : <>{data?.nPrice && data?.nPrice !== 0
                            ? `${currencyLogo}${currencyConvert(data?.nPrice * userTeams.length)}`
                            : ''
                              }</>
                          } </Button>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </>
            : modalMessage2 && totalPayFinal >= 0
              ? <>
                {loading && <Loading />}
                <div className="s-team-bg" onClick={() => setModalMessage2(false)}></div>
                <Card className="filter-card select-team promo-card">
                  <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                    <button><FormattedMessage id="League_Payment" /></button>
                    <button onClick={() => {
                      setModalMessage2(false)
                      setModalMessage(true)
                    }}><img src={close}></img></button>
                  </CardHeader>
                  <CardBody className="p-0 teamXShawing">
                    <div className='teamJoin'>
                      {
                        updatedFilterData && updatedFilterData.length && (
                          <h3>
                            {updatedFilterData.length} <FormattedMessage id="League_Teams_Selected" />
                          </h3>
                        )
                      }
                    </div>
                    <div className='selectedTeamList'>
                      {
                        updatedFilterData && updatedFilterData.length !== 0
                          ? updatedFilterData.sort((a, b) => a?.sTeamName?.toString().localeCompare(b?.sTeamName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map((data1, index) => {
                            return (
                              <Suspense key={data1._id} fallback={<Loading />}>
                                <MyTeam {...props} upcoming leagueData={data}
                                  noOfJoin={noOfJoin}
                                  selectModal={modalMessage}
                                  params={match && match.params.id} index={index} teamDetails={data1} key={data1._id} allTeams={updatedFilterData} UserTeamChoice viewOnly />
                              </Suspense>
                            )
                          })
                          : ''
                      }
                    </div>
                    <Table className="m-0 bg-white promocode">
                      <thead>
                        <tr>
                          <th><FormattedMessage id="League_Total_Entry" /></th>
                          <th className='rightAlign'>{currencyLogo}{data && updatedFilterData && data.nPrice * updatedFilterData.length} ( {data && updatedFilterData && `${data.nPrice} X ${updatedFilterData.length}`} )</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(!data.bPrivateLeague) && <Fragment>
                        <tr>
                          <td colSpan='2' className='green'>
                            {finalPromocode
                              ? <div className='d-flex align-items-center'>
                              <h3 className='promocode-text m-0'>{finalPromocode} <FormattedMessage id='Promo_Applied' /></h3>
                              <Button onClick={clearPromo} color='link' className="ml-2"><img src={close} /></Button>
                            </div>
                              : <Button onClick={() => getPromocodeList()} className='p-0 d-flex align-items-center' color='link'>
                              <h3 className='promocode-text m-0'><FormattedMessage id="League_Apply_Promocode" /></h3>
                              <img src={rightGreenArrow} className='ml-1' />
                            </Button>}
                          </td>
                        </tr>
                        {/* <tr>
                          <td colSpan='2' className='green'>
                            <FormGroup className="c-input mt-2 mb-0">
                              <Input onClick={() => getPromocodeList()} type="text" className={classNames({ 'hash-contain': finalPromocode }) } id="Promocode" value={finalPromocode} autoComplete='off' required onChange={(e) => setPromoData(e.target.value)} />
                              <Label className="no-change label m-0" for="Promocode"><FormattedMessage id="User_Promocode" /></Label>
                              {applied && finalPromocode
                                ? <button className="i-icon" onClick={clearPromo}>Remove</button>
                                : <button className="i-icon" onClick={() => applePromoCode(promoData)}><FormattedMessage id="User_Apply" /></button>}
                            </FormGroup>
                          </td>
                        </tr> */}
                       {finalPromocode && <tr>
                          <td><FormattedMessage id="League_Discount" /></td>
                          <td className='rightAlign'>{currencyLogo}{applyPromocodeData && applyPromocodeData.nDiscount ? applyPromocodeData.nDiscount : '0'}</td>
                        </tr>}
                        </Fragment>}
                        <tr>
                          <td><FormattedMessage id="League_In_Wallet" /></td>
                          <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentTotalBalance ? userInfo.nCurrentTotalBalance : 0}</td>
                        </tr>
                      </tbody>
                      {
                        totalPay && currencyLogo
                          ? (
                          <tfoot>
                            <tr>
                              <td><h1><FormattedMessage id="League_To_Pay" /></h1></td>
                              <td className='rightAlign'>{currencyLogo}
                                {totalPay}</td>
                            </tr>
                          </tfoot>
                            )
                          : ''
                      }
                    </Table>
                  </CardBody>
                  <CardFooter className='p-0 border-0 bg-trnsparent m-0 d-flex justify-content-between'>
                    <Button type="submit" color='primary-two' className="w-100"
                      disabled={userTeams && userTeams.length === 0}
                      onClick={AddTeams}>
                      {
                        totalPay > 0
                          ? <FormattedMessage id="User_AddMoney" />
                          : <><FormattedMessage id="League_join" /> (<FormattedMessage id='Pay'/> {' ' + currencyLogo}{totalPayFinal})</>
                      }
                    </Button>
                  </CardFooter>
                </Card>
              </>
              : ''
        }

        {PromoCodes
          ? <>
            <div className="s-team-bg" onClick={() => setPromoCodes(false)}></div>
            <Card className="filter-card show select-team promo-card">
              <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                <button><FormattedMessage id="League_Promocode" /></button>
                <button onClick={() => {
                  setPromoCodes(false)
                  setModalMessage2(true)
                }}><img src={close}></img></button>
              </CardHeader>
              <CardBody className='p-10'>
                {promocodeLoading && <PromocodeLoading />}
                <div className="p-title-2"><FormattedMessage id="User_promocodeForU" /></div>
                {matchPromoCodeList !== {} && matchPromoCodeList && matchPromoCodeList.length > 0
                  ? matchPromoCodeList.map(matchPromo => {
                    return (
                      <div key={matchPromo._id} className="d-flex align-items-center justify-content-between promo-box">
                        <div>
                          <b>{matchPromo.sCode}</b>
                          <p>{matchPromo.sInfo}</p>
                        </div>
                        <Button color="white" onClick={() => applePromoCode(matchPromo.sCode)}><FormattedMessage id="User_Apply" /></Button>
                      </div>
                    )
                  })
                  : (
                    <Fragment>
                      <center>
                        <h2> <FormattedMessage id="Blank_Promocode_List" /> </h2>
                      </center>
                    </Fragment>
                    )
                }
              </CardBody>
              <CardFooter className='p-0 border-0 bg-transparent m-0 d-flex justify-content-between'>
              </CardFooter>
            </Card>
          </>
          : ''
        }
      </Fragment>
    </>
  )
}

League.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sportsType: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func
  }),
  data: PropTypes.shape({
    aLeaguePrize: PropTypes.array,
    nCrownUtil: PropTypes.number,
    iLeagueId: PropTypes.string,
    userJoined: PropTypes.shape([{
      nRank: PropTypes.number,
      nPrice: PropTypes.number,
      nTotalPoints: PropTypes.number,
      bTeamWinningZone: PropTypes.bool,
      sTeamName: PropTypes.string
    }]),
    sName: PropTypes.string,
    eMatchStatus: PropTypes.string,
    bPoolPrize: PropTypes.bool,
    bJoinByAd: PropTypes.bool,
    nBonusUtil: PropTypes.number,
    nWinnersCount: PropTypes.number,
    nMax: PropTypes.number,
    nMin: PropTypes.number,
    nPrice: PropTypes.number,
    nLoyaltyPoint: PropTypes.number,
    nJoined: PropTypes.number,
    nTotalPayout: PropTypes.number,
    nTeamJoinLimit: PropTypes.number,
    _id: PropTypes.string,
    bConfirmLeague: PropTypes.bool,
    bMultipleEntry: PropTypes.bool,
    nJoinedCount: PropTypes.bool,
    bPrivateLeague: PropTypes.bool,
    iMatchId: PropTypes.string,
    sShareCode: PropTypes.string,
    eCashbackType: PropTypes.string,
    bUnlimitedJoin: PropTypes.bool,
    nCashbackAmount: PropTypes.number,
    nMinCashbackTeam: PropTypes.number,
    nDeductPercent: PropTypes.number,
    ePayoutType: PropTypes.string
  }),
  applyPromocodeData: PropTypes.shape({
    nDiscount: PropTypes.string,
    sCode: PropTypes.string
  }),
  userInfo: PropTypes.shape({
    nCurrentTotalBalance: PropTypes.number,
    nCurrentCrown: PropTypes.number
  }),
  home: PropTypes.bool,
  joinContest: PropTypes.func,
  firstPrize: PropTypes.string,
  firstRankType: PropTypes.string,
  lastPrize: PropTypes.string,
  MatchLeagueId: PropTypes.string,
  amountData: PropTypes.object,
  contestList: PropTypes.object,
  participated: PropTypes.bool,
  teamList: PropTypes.shape([{
    iMatchId: PropTypes.string
  }]),
  matchType: PropTypes.string,
  activeTab: PropTypes.string,
  contestJoinList: PropTypes.array,
  joinDetails: PropTypes.object,
  insideleagueDetailsPage: PropTypes.bool,
  toggleMessage: PropTypes.func,
  applyPromo: PropTypes.func,
  onGetUserProfile: PropTypes.func,
  modalMessage: PropTypes.string,
  setModalMessage: PropTypes.func,
  joined: PropTypes.bool,
  getMyTeamList: PropTypes.func,
  onGetPromocodeList: PropTypes.func,
  loading: PropTypes.bool,
  promocodeLoading: PropTypes.bool,
  appliedPromocode: PropTypes.bool,
  upComing: PropTypes.bool,
  showInformation: PropTypes.bool,
  matchID: PropTypes.string,
  currencyLogo: PropTypes.string,
  matchPromoCodeList: PropTypes.shape([{
    sCode: PropTypes.string,
    sInfo: PropTypes.string,
    _id: PropTypes.string
  }]),
  tab: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  }),
  homePage: PropTypes.string,
  setLoading: PropTypes.func,
  url: PropTypes.string,
  teamPlayerList: PropTypes.array,
  getTeamPlayerList: PropTypes.func,
  getLeagueList: PropTypes.func
}

export default JoinContest(League)
