import axios from '../../axios/instanceAxios'
import storage, { SessionID } from '../../localStorage/localStorage'
import {
  SEND_OTP,
  VERIFY_OTP,
  FORGOT_PASSWORD,
  REGISTER_USER,
  CHECK_EXIST,
  VALIDATE_TOKEN,
  LOGIN_USER,
  LOGOUT,
  CLEAR_AUTH_MESSAGE,
  CLEAR_MESSAGE_TYPE,
  CLEAR_SEND_OTP_MESSAGE,
  CLEAR_VERIFY_OTP_MESSAGE,
  CLEAR_CHECKED_MESSAGE,
  GOOGLE_LOGIN_USER,
  FIREBASE_TOKEN,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_REASONS,
  // CLEAR_DELETE_ACCOUNT_MESSAGE,
  SESSION_ID,
  SUBSCRIBE_PUSH_TOKEN
} from '../constants'
import { catchError, sessionVerifiedPost } from '../../helper'
import { encryption } from '../../encryption'

const errMsg = 'Server is unavailable.'

export const sendOTP = (mobileNumber, type, auth) => (dispatch) => { // for send otp
  dispatch({ type: CLEAR_AUTH_MESSAGE })
  dispatch({ type: CLEAR_SEND_OTP_MESSAGE })
  axios.post('/auth/send-otp/v1', { sLogin: mobileNumber, sType: type, sAuth: auth }).then((response) => {
    dispatch({
      type: SEND_OTP,
      payload: {
        resMessage: response.data.message,
        resStatus: true,
        sendOtp: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: SEND_OTP,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg,
        sendOtp: false
      }
    })
  })
}

export const VerifyOTP = (mobileNumber, type, auth, code, ID, FirebaseToken) => (dispatch) => { // for verify otp
  dispatch({ type: CLEAR_AUTH_MESSAGE })
  dispatch({ type: CLEAR_VERIFY_OTP_MESSAGE })
  axios.post('/auth/verify-otp/v2', { sLogin: mobileNumber, sType: type, sAuth: auth, sCode: code, sDeviceId: ID, sPushToken: FirebaseToken }).then((response) => {
    dispatch({
      type: VERIFY_OTP,
      payload: {
        resMessage: response.data.message,
        resStatus: true,
        otpVerified: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: VERIFY_OTP,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg,
        otpVerified: false
      }
    })
  })
}

export const VerificationSendOTP = (mobileNumber, type, auth, token) => (dispatch) => { // for send otp
  dispatch({ type: CLEAR_AUTH_MESSAGE })
  dispatch({ type: CLEAR_SEND_OTP_MESSAGE })
  axios.post('/auth/send-otp/v1', { sLogin: mobileNumber, sType: type, sAuth: auth }, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: SEND_OTP,
      payload: {
        resMessage: response.data.message,
        resStatus: true,
        sendOtp: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: SEND_OTP,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg,
        sendOtp: false
      }
    })
  })
}

export const VerificationVerifyOTP = (mobileNumber, type, auth, code, ID, token, FirebaseToken) => (dispatch) => { // for Verify otp
  dispatch({ type: CLEAR_AUTH_MESSAGE })
  dispatch({ type: CLEAR_VERIFY_OTP_MESSAGE })
  axios.post('/auth/verify-otp/v2', { sLogin: mobileNumber, sType: type, sAuth: auth, sCode: code, sDeviceId: ID, sPushToken: FirebaseToken }, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: VERIFY_OTP,
      payload: {
        resMessage: response.data.message,
        resStatus: true,
        otpVerified: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: VERIFY_OTP,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg,
        otpVerified: false
      }
    })
  })
}

export const ForgotPassword = (mobileNumber, type, auth, code, password) => (dispatch) => { // forgot password
  dispatch({ type: CLEAR_AUTH_MESSAGE })
  // const encryptedPass = encryption(password)
  axios.post('/auth/reset-password/v3', { sLogin: mobileNumber, sType: type, sAuth: auth, sCode: code, sNewPassword: password }).then((response) => {
    dispatch({
      type: FORGOT_PASSWORD,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch(catchError(FORGOT_PASSWORD, error))
  })
}

// Registration
export const Registration = (FirebaseToken, PlatForm, userName, email, mobileNumber, Password, sCode, referralCode, token, socialToken) => (dispatch) => {
  dispatch({ type: CLEAR_AUTH_MESSAGE })
  // const encryptedPass = encryption(Password)
  axios.post('/auth/register/v4', { sPushToken: FirebaseToken, sUsername: userName, sEmail: email, sMobNum: mobileNumber, sDeviceId: token, sPassword: Password, sCode: sCode, sReferCode: referralCode, sSocialToken: socialToken }, { headers: { Platform: PlatForm } }).then(async (response) => {
    if (response.data.Authorization) {
      storage.setItem('Token', response.data.Authorization)
      storage.setItem('userData', JSON.stringify(response.data.data))
      dispatch({
        type: REGISTER_USER,
        payload: {
          loginUser: userName,
          loginPass: Password,
          token: response.data.Authorization,
          resMessage: response.data.message,
          resStatus: true,
          messageType: '',
          registerSuccess: true
        }
      })
    } else {
      dispatch({
        type: REGISTER_USER,
        payload: {
          loginUser: userName,
          loginPass: Password,
          resMessage: response.data.message,
          resStatus: true,
          messageType: '',
          registerSuccess: true
        }
      })
    }
  }).catch((error) => {
    dispatch({
      type: REGISTER_USER,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg,
        messageType: '',
        registerSuccess: false
      }
    })
  })
}

// check email and other field is exist or not
export const CheckExist = (type, value) => (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE_TYPE })
  dispatch({ type: CLEAR_CHECKED_MESSAGE })
  axios.post('/auth/check-exist/v1', { sType: type, sValue: value }).then((response) => {
    dispatch({
      type: CHECK_EXIST,
      payload: {
        messageType: '',
        resStatus: true,
        isChecked: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: CHECK_EXIST,
      payload: {
        resStatus: false,
        isChecked: false,
        resMessage: error.response ? error.response.data.message : errMsg,
        messageType: type
      }
    })
  })
}

// token verify
export const VerifyToken = (pushToken, deviceToken) => (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE_TYPE })
  axios.post('/auth/validate-token/v2', { sPushToken: pushToken, sDeviceId: deviceToken }, { headers: { Authorization: pushToken, sessionId: SessionID } }).then((response) => {
    storage.setItem('tokenVerified', true)
    dispatch({
      type: VALIDATE_TOKEN,
      payload: {
        resStatus: true,
        tokenVerified: true
      }
    })
  }).catch((error) => {
    dispatch(catchError(VALIDATE_TOKEN, error))
  })
}

// firebase token
export const FirebaseToken = (Token) => (dispatch) => {
  dispatch({
    type: FIREBASE_TOKEN,
    payload: {
      FirebaseToken: Token
    }
  })
}

// login
export const Login = (FirebaseToken, PlatForm, userName, Password, loginID) => (dispatch) => {
  dispatch({ type: CLEAR_AUTH_MESSAGE })
  const encryptedPass = encryption(Password)
  axios.post('/auth/login/v2', { sPushToken: FirebaseToken, sLogin: userName, sPassword: encryptedPass, sDeviceToken: loginID }, { headers: { Platform: PlatForm } }).then((response) => {
    if (response.data.Authorization) {
      storage.setItem('Token', response.data.Authorization)
      storage.setItem('userData', JSON.stringify(response.data.data))
      dispatch({
        type: LOGIN_USER,
        payload: {
          loginUser: userName,
          loginPass: Password,
          token: response.data.Authorization,
          data: response.data.data,
          resMessage: response.data.message,
          nOtpSend: response.data.data.nOtpSend,
          resStatus: true
        }
      })
    } else {
      dispatch({
        type: LOGIN_USER,
        payload: {
          loginUser: userName,
          loginPass: Password,
          resMessage: response.data.message,
          nOtpSend: response.data.data.nOtpSend,
          resStatus: true
        }
      })
    }
  }).catch((error) => {
    dispatch(catchError(LOGIN_USER, error))
  })
}

// google login
export const googleLogin = (socialType, token) => (dispatch) => {
  dispatch({ type: CLEAR_AUTH_MESSAGE })
  axios.post('/auth/social-login/v2', {
    sSocialType: socialType,
    sSocialToken: token
  }).then((response) => {
    if (response.data.Authorization) {
      storage.setItem('Token', response.data.Authorization)
      storage.setItem('userData', JSON.stringify(response.data.data))
      dispatch({
        type: GOOGLE_LOGIN_USER,
        payload: {
          token: response.data.Authorization,
          data: response.data.data,
          resMessage: response.data.message,
          resStatus: true
        }
      })
    } else {
      dispatch({
        type: GOOGLE_LOGIN_USER,
        payload: {
          resMessage: response.data.message,
          resStatus: true
        }
      })
    }
  }).catch((error) => {
    if (error && error.response && error.response.data && error.response.data.status === 404) {
      dispatch({
        type: GOOGLE_LOGIN_USER,
        payload: {
          resMessage: error.response.data.message,
          resStatus: true,
          socialRegisterData: error.response.data.data
        }
      })
    } else {
      dispatch(catchError(GOOGLE_LOGIN_USER, error))
    }
  })
}

// logout
export const logout = token => (dispatch) => {
  axios.put('/auth/logout/v1', {}, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    storage.removeItem('Token')
    storage.removeItem('userData')
    dispatch({
      type: LOGOUT,
      payload: {
        resStatus: true,
        resMessage: response.data.message
      }
    })
  }).catch((error) => {
    dispatch(catchError(LOGOUT, error))
  })
}

export const deleteAccount = (reason, token) => async (dispatch) => {
  await axios.delete('/auth/delete-account/v1', { data: { sReason: reason }, headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    storage.removeItem('Token')
    storage.removeItem('userData')
    dispatch({
      type: DELETE_ACCOUNT,
      payload: {
        resMessage: response.data.message,
        resStatus: true,
        accountDeleted: true
      }
    })
  }).catch((error) => {
    dispatch(catchError(DELETE_ACCOUNT, error))
  })
}

export const deleteAccountReasons = token => async (dispatch) => {
  await axios.get('/delete-account-reason/v1', { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: DELETE_ACCOUNT_REASONS,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch(catchError(DELETE_ACCOUNT_REASONS, error))
  })
}

export const getUserToken = (session) => async (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE_TYPE })
  await axios.get('/fs-token-generate/v1', { headers: { sessionId: session } }).then((response) => {
    const token = response.data.Authorization
    localStorage.setItem('Token', token || token.sToken)
    localStorage.setItem('sessionId', session)
    sessionVerifiedPost()
    dispatch({
      type: SESSION_ID,
      payload: {
        resStatus: true,
        tokenVerified: true,
        token
      }
    })
  }).catch((error) => {
    dispatch(catchError(SESSION_ID, error))
  })
}

export const subscribePushToken = (pushToken, token) => async (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE_TYPE })
  await axios.post('/subscribe-push-token/v1', { sPushToken: pushToken, ePlatform: 'W' }, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: SUBSCRIBE_PUSH_TOKEN,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch(catchError(SUBSCRIBE_PUSH_TOKEN, error))
  })
}
