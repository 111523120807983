import React from 'react'
import PropTypes from 'prop-types'
// import { Button } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
// import { Link } from 'react-router-dom'
// import Home from '../../../../assests/images/homeIconWhite.svg'
// import { useSelector } from 'react-redux'
import ScoreCards from './ScoreCards'
import ScoreCardList from '../../../../HOC/SportsLeagueList/ScoreCardList'

function ScorecardIndex (props) {
  const { history } = props
  // const activeSports = useSelector(state => state.activeSports.activeSports)
  // const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)

  return (
    <>
    <div className="league-header u-header">
      <div className="d-flex align-items-center header-i">
        <button onClick={() => history.goBack()} className="btn-link icon-left-arrow"></button>
        {/* <Button className='button-link bg-transparent py-2' tag={Link} to={`/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}`}><img src={Home}></img></Button> */}
        <h1><FormattedMessage id='Scorecard' /></h1>
      </div>
      <ScoreCards {...props} />
    </div>
    </>
  )
}

ScorecardIndex.propTypes = {
  history: PropTypes.object
}

export default ScoreCardList(ScorecardIndex)
