/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/prefer-default-export */
import axios from '../../axios/instanceAxios'
import axios2 from '../../axios/instanceAxios2'
import {
  MATCH_LIST,
  MATCH_DETAILS,
  MY_UPCOMING_MATCH_LIST,
  MY_LIVE_MATCH_LIST,
  MY_COMPLETED_MATCH_LIST,
  GET_HOME_BANNER,
  CLEAR_MATCH_MESSAGE,
  CLEAR_MATCH_LIST,
  GET_BANNER_STATICS,
  MY_UPCOMING_LIST,
  MY_RECENT_MATCH_LIST
} from '../constants'
import { catchBlankData } from '../../helper'
import { SessionID } from '../../localStorage/localStorage'

const errMsg = 'Server is unavailable.'

export const getMatchList = (sportsType) => (dispatch) => { // matches list
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  dispatch({ type: CLEAR_MATCH_LIST })
  axios.get(`/match/list/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: MATCH_LIST,
      payload: {
        resMessage: response.data.message,
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(MATCH_LIST))
  })
}

export const getMatchDetails = (id, sportsType, token) => (dispatch) => { // get one match details
  dispatch({ type: CLEAR_MATCH_MESSAGE })
  axios.get(`/match/${id}/v1?sportsType=${sportsType}`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: MATCH_DETAILS,
      payload: {
        resMessage: response.data.message,
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(MATCH_DETAILS))
  })
}

export const myUpcomingMatchList = (sportsType, token, type) => (dispatch) => { // my upcoming match list
  axios.get(`/my-matches/list/v5?sportsType=${sportsType}&type=${type}&eSource=JG`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: MY_UPCOMING_MATCH_LIST,
      payload: {
        resMessage: response.data.message,
        data: response.data.data,
        // aMatches: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(MY_UPCOMING_MATCH_LIST))
  })
}

export const myRecentMatchList = (sportsType, token) => (dispatch) => { // my upcoming match list
  axios.get(`/my-matches/list-all-recent/v1?sportsType=${sportsType}`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: MY_RECENT_MATCH_LIST,
      payload: {
        resMessage: response.data.message,
        data: response.data.data,
        // aMatches: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(MY_RECENT_MATCH_LIST))
  })
}

export const myUpcomingList = (sportsType, token, type) => (dispatch) => { // my upcoming match list
  axios.get(`/my-matches/list/v5?sportsType=${sportsType}&type=${type}&eSource=JG`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: MY_UPCOMING_LIST,
      payload: {
        resMessage: response.data.message,
        data: response.data.data,
        // aMatches: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(MY_UPCOMING_LIST))
  })
}

export const myLiveMatchList = (sportsType, token, type) => (dispatch) => { // live match list
  axios.get(`/my-matches/list/v5?sportsType=${sportsType}&type=${type}&eSource=JG`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: MY_LIVE_MATCH_LIST,
      payload: {
        resMessage: response.data.message,
        data: response.data.data,
        // aMatches: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(MY_LIVE_MATCH_LIST))
  })
}

export const myCompletedMatchList = (sportsType, token, type) => (dispatch) => { // completed match list
  axios.get(`/my-matches/list/v5?sportsType=${sportsType}&type=${type}&eSource=JG`, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: MY_COMPLETED_MATCH_LIST,
      payload: {
        resMessage: response.data.message,
        data: response.data.data,
        // aMatches: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch(catchBlankData(MY_COMPLETED_MATCH_LIST))
  })
}

export const GetHomeBanner = (place, sportsType) => (dispatch) => { // home banner list
  axios2.get(`/banner/list/${place}/v1?eSportType=${sportsType.toUpperCase()}`).then((response) => {
    const filteredBannerList = response.data.data
    dispatch({
      type: GET_HOME_BANNER,
      payload: {
        bannerData: filteredBannerList,
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GET_HOME_BANNER,
      payload: {
        bannerData: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getBannerStatics = (ID, token) => (dispatch) => { // banner statics
  axios2.post(`/banner/log/${ID}/v1`, {}, { headers: { Authorization: token, sessionId: SessionID } }).then((response) => {
    dispatch({
      type: GET_BANNER_STATICS,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GET_BANNER_STATICS,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}
