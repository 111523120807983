export default {
  'en-US': {
    Static_Share_text: 'Hey there! I dare you to join this challenge. Let’s see who takes the crown.This is exclusive invite only contest of Fansportiz for PCK vs BCC match.Use below contest code or link to join.Contest code: 12564f',
    Static_Title_Text: 'Check out my team for SOU-L vs BAN-L Cricket match. Make your own team and play with me:',
    Static_Facebook: 'Facebook',
    Static_Twitter: 'Twitter',
    Static_Telegram: 'Telegram',
    Static_Share_ReferCode: 'Please use my Refer Code',
    Static_In_Fansportiz: 'in Fansportiz',
    Kyc_Title: 'KYC Verification',
    Refer_a_friend: 'Refer a Friend',
    Extra_Yes: 'Yes',
    Match_Winning_Zone: 'You are in winning zone',
    Extra_No: 'No',
    NotFound_404: '404',
    NotFound_Page: 'Page Not Found!',
    NotFound_GoHome: 'Go Home',
    Cashback_On: 'Cashback',
    Bonus_On: 'Bonus On',
    Version: 'Version',
    FairPlay_Not_available: 'Fair Play is not available',
    No_Filter: 'No Filter Are Available',
    No_Contest: 'No Contests Are Available',
    No_Team: 'No Teams Are Available',
    Leaderboard: 'Leaderboard',
    Extra_PrizeBreakup: 'Prize Breakup',
    Extra_Conditions: 'Conditions',
    Share_Code: 'Share your code to refer and earn bonus',
    Contast_Min_Req: 'Entry Fee cannot be less than 5.',
    Common_EmailError: 'Email address is invalid.',
    Required_Email: 'Email address is required',
    Common_ReachedLimit: 'Reached the limit to join the Contest',
    Common_MobileError: 'Mobile number is invalid.',
    Common_Required: 'Required Field',
    Common_NotMatchPass: 'Password and confirm password must be same.',
    Common_NumberOTP: 'Otp must be number',
    Otp_Length_Number: 'OTP must be 4 to 6 digits.',
    Common_TotalCredits: 'Total Credits',
    Common_RequiredEmail: 'Email address is invalid.',
    Common_RequiredMobile: 'Mobile number is invalid.',
    Common_loginEmailPass: 'Invalid Email/Mobile',
    Common_mobileError: 'Mobile number is already exist.',
    Common_emailError: 'Email is already exist.',
    Common_usernameError: 'Username is already exist.',
    Common_properMobile: 'Mobile number is invalid.',
    Required_Mobile: 'Mobile number is required',
    Common_length3: 'Name must be minimum 3 character.',
    Common_length4: 'Length Should be 4',
    Common_length5: 'Length Should be 5',
    Common_Contest_length4: 'Contest Name must be minimum 4 character',
    Contest_without_special_Character: 'Special character are not allowed in Contest name.',
    Common_Contest_length16: 'Contest Name cannot be larger than 16 characters',
    Common_UserName_length5: 'Username must be 3 to 15 characters and alpha-numeric.',
    Contest_Amount_Between: 'Choose winning amount between 1 and 10000',
    Contest_Amount_Between_2: 'Choose winning amount between ',
    LeagueDetails_NoOffer: 'No offers are available',
    Common_PassLength: 'Password must be 8 to 15 characters with at least 1 small & 1 capital character, 1 number and 1 special character.',
    Common_ConfirmPasswordNotFound: 'Password not match',
    Common_ReqOld: 'Current Password is Required.',
    Common_ReqNew: 'Password is Required',
    Common_Req_RePassword: 'Re-Password is Required',
    Common_ReqConfirm: 'Confirm Password is Required',
    Common_ReqContest_Size: 'Contest Size is Required',
    Common_ReqContest_Price: 'Contest Prize is Required',
    Common_Req_Email_Mobile: 'Email / Mobile is Required',
    Common_Req_Mobile_No: 'Mobile Number is Required',
    Common_Req_Email: 'Email is Required',
    Common_Req_Name: 'Name is Required',
    Common_Req_Username: 'Username is Required',
    Common_ConfirmPassword: 'Confirm Password',
    Common_NewPassword: 'New Password',
    Common_OldPassword: 'Old Password',
    User_Apply: 'Apply',
    Note_Info: 'Note',
    Change_IN: '(In ',
    Change_SEC: 'Sec)',
    Transaction_All: 'All',
    Transaction_Cash: 'Cash',
    Transaction_Bonus: 'Bonus',
    Transaction_Cancel_WT_Req: 'Cancel Withdrawal Request',
    Transaction_Pending_WT_Req: 'You have a Pending Withdrawal request of ₹',
    Transaction_Pending_Req_OF: 'You have a Pending Withdrawal request of ',
    Transaction_Cancel_it_wish: 'You may cancel it if you wish to.',
    User_WON_BY: 'won by',
    User_BOTH_PLAYER: 'Both teams have same points',
    User_VerifiedPend: 'Verification Pending',
    User_VerifiedSucc: 'Verification Successful',
    User_Rejected: 'Verification Reject',
    User_promocodeForU: 'Promocodes For You',
    Have_Promocode: 'Have a Promocode?',
    Enter_Promocode: 'Enter Promocode',
    User_Promocode: 'Promocode',
    User_Promocodes: 'Promocodes',
    Add: 'Add',
    User_AddMoney: 'Add Money',
    User_DCN: 'Debit, Credit & Net Banking',
    User_AMZandCaseback: 'Deposit using Amazon Pay & Get cashback surprise',
    User_validPAN: 'Please enter valid PAN Card Number',
    User_PANCardNumber: 'Please enter PAN Card Number',
    User_PanImage: 'Please add your Pan Card image',
    User_AadharImage: 'Please add your Aadhaar Card image',
    User_ADDPanImage: 'Add PAN Card Photo',
    Complaint_ADDImage: 'Add Image',
    User_PanNumber: 'PAN Card Number',
    User_PanName: 'PAN Card Name',
    User_AadharNumber: 'Aadhaar Card Number',
    User_AadharCardFPic: 'Add Aadhaar Card Photo (Front)',
    User_AadharCardBPic: 'Add Aadhaar Card Photo (Back)',
    User_validAADHAR: 'Please enter valid Aadhaar Card Number',
    User_AADHARCardNumber: 'Please enter Aadhaar Card Number',
    Required_PAN_Card_Number: 'Pan Card Number is required',
    Required_AADHARCardNumber: 'Aadhaar Card Number is required',
    Required_AADHARCard_Number: 'Enter only numbers',
    Required_AADHARCardImage: 'Aadhaar Card Image is required',
    User_PAN: 'Pan Card',
    Ball_Faced: 'Ball Faced',
    Overs_Bowled: 'Overs Bowled',
    User_AADHAAR: 'Aadhaar Card',
    User_deposit: 'Deposit',
    User_withdrawal: 'Withdrawal',
    User_KycVerification: 'KYC Verification',
    User_commission_Private_Contest: 'Commission of private contest',
    User_Maximum: 'Maximum',
    User_Player_From_team: 'players from a team',
    User_profileInfo: 'Edit Profile',
    User_leaderBoard: 'Leaderboard',
    User_transaction: 'Transactions',
    User_changePassword: 'Change Password',
    User_logout: 'Logout',
    profileInfo_userName: 'Username',
    profileInfo_Fullname: 'Full Name',
    profileInfo_Email: 'Email',
    profileInfo_MobileNo: 'Mobile Number',
    profileInfo_Gender: 'Gender',
    profileInfo_Male: 'Male',
    profileInfo_Female: 'Female',
    profileInfo_Other: 'Other',
    Verify_Email: 'Verify Your Email',
    Verify_Mobile: 'Verify Your Mobile',
    Common_and: 'and',
    League_single_entry: 'Single Entry',
    League_Multi_Entry: 'Multi Entry',
    League_multiple_entry: 'Multiple Entry',
    League_Single_Winner: 'Single Winner',
    League_Multi_Winner: 'Multi Winner',
    League_Guaranteed: 'Guaranteed',
    League_Confirmed: 'Confirmed',
    League_Upto: 'Upto',
    League_Points: 'Points',
    League_Entries: 'Entries',
    League_Entry: 'Join',
    Match_IN_Review: '(In-Review)',
    Common_sort: 'Sort',
    League_Clear: 'Clear',
    League_Close: 'Close',
    League_Compare: 'Compare',
    League_Notes: 'Lineups data may not be accurate. please refer other sources as well to confirm.',
    League_Orange_Text: 'Players are in your team',
    League_CompareTeam: 'Compare Team',
    League_Team: 'Team',
    League_CONTEST: 'Contest',
    Deposit_CASHFREE: 'CashFree',
    Deposit_AMAZON_PAY: 'Amazon Pay',
    League_CommonPlayers: 'Common Players',
    League_bothCVCSame: 'Both teams C and VC have Same Points',
    League_myCVCLeadBy: 'Your Teams C & VC lead by',
    League_opponentCVCLeadBy: 'Your opponents C & VC lead by',
    League_bothPlayersLeadBy: 'Both Team players Points Same',
    League_myPlayersLeadBy: 'Your Teams players lead by',
    League_opponentPlayersLeadBy: 'Your opponents players lead by',
    League_selectedTeams: 'Selected Teams',
    Notification_NotFound: 'Notification not found',
    Complaints_NotFound: 'Complaints not found',
    Complaints_Complaints: 'Complaints',
    Complaints_ADD_Complaints: 'Add Complaint/ Feedback',
    Complaints_Feedback: 'Feedback',
    Contest_cSizeGreater2: 'Contest Size should be greater than 2',
    Contest_Contest_Size_between: 'Choose Contest Size between 2 and 500',
    Contest_Size_between: 'Choose Contest Size between',
    Contest_NumberGreater2: 'number should be greater than 2',
    Contest_shouldBeNumber: 'Should be a Number',
    Team_player11: 'Player Should be eleven.',
    Common_ReqWk: 'Wicket-keepers Should be required',
    Common_ReqAll: 'All Rounder Should be required',
    Common_ReqBats: 'Batsman Should be required',
    Common_ReqBall: 'Bowler Should be required',
    Common_MinBats: 'Batsman Should be min',
    Common_MinBowl: 'Bowler Should be min',
    Common_Minwk: 'Wicket-keepers Should be min',
    Common_MinAllR: 'All Rounder Should be min',
    Common_perc: '%',
    Common_percBy: 'C By',
    Common_perVcBy: 'VC By',
    Team_C: 'C',
    Team_2x: '2x',
    Team_1_5x: '1.5x',
    Team_eleven: '11',
    Team_VC: 'VC',
    Team_9CR: '9 Cr',
    Team_0CR: '0 Cr',
    Team_0Pts: '0 Pts',
    Team_0: '0',
    Team_No_Linesup: 'Players are not announced in Lineups',
    Team_Pts: 'Pts',
    Team_Cr: 'Cr',
    Team_Won: 'Won ',
    Team_You_Won: 'You Won ',
    Team_Rupee: '₹',
    PROFILE_AVL_BALANCE: 'Available Balance',
    PROFILE_DEP_BALANCE: 'Deposite Balance',
    PROFILE_WIN_BALANCE: 'Win Balance',
    PROFILE_CASH_BONUS: 'Cash Bonus',
    PROFILE_ADD_CASH: 'Add Cash',
    PROFILE_TOTAL_BALANCE: 'Total Balance',
    PROFILE_BONUS_BALANCE: 'Bonus Balance',
    Team_batsman: 'BATSMAN',
    Team_wk: 'WICKET KEEPER',
    Team_bowler: 'BOWLER',
    Team_allRounder: 'ALL ROUNDER',
    Team_ShareTeam: 'Share Team',
    Team_ScoreData: 'Yet to bat',
    Team_CreateTeam: 'Create New Team',
    AUTO_PICK: 'Auto Pick',
    AUTO_PICK_GURU_MESSAGE_1: 'Let our Guru pick a team for you.',
    AUTO_PICK_GURU_MESSAGE_2: 'Click on Auto Pick',
    AUTO_PICK_LOADING_MESSAGE: 'Please wait till our Guru pick the best team for you',
    Team_CreatePrivateLeague: 'Create Contest',
    Common_Total: 'Total',
    Common_TotalPoints: 'Total Points',
    Match_IPL: 'Indian Premier Contest',
    Common_usernameShouldBeSpace: 'User name must be alpha-numeric.',
    Valid_Pincode: 'Pincode is invalid.',
    Common_username_max_15Char: 'User name must be maximum of 16 characters',
    Common_specialCharacter: 'User name Special character is not allowed to enter.',
    OfferDetails_amazonPay: 'Amazon Pay Offer',
    withDraw_ReqBankName: 'Bank name is required',
    withDraw_ReqBankAccName: 'Bank account name is required',
    withDraw_ReqBankBranchName: 'Bank branch name is required',
    withDraw_AccNotValid: 'Account number is not valid',
    withDraw_IFSCNotValid: 'IFSC code is invalid.',
    withDraw_ReqAccNo: 'Account number is required',
    withDraw_AC_Should_Number: 'Account number should be number',
    withDraw_ReqIFSCNo: 'IFSC code is required',
    withDraw_amountWithdraw: 'Enter the amount you wish to withdraw from your balance.',
    withDraw_withdrawalAmount: 'Withdrawal Method',
    withDraw_WithdrawalBank: 'Bank Withdrawal',
    withDraw_withdrawalPaytm: 'Paytm Withdrawal',
    withDraw_withdrawalInstantPaytm: 'Instant Paytm Withdrawal',
    Add_bank_details: 'Add Bank Details',
    withDraw_viewBankDetails: 'View Bank Details',
    Proceed: 'Proceed',
    withDraw_bankName: 'Bank Name',
    withDraw_bankBranchName: 'Bank Branch Name',
    withDraw_bankAccName: 'Bank Account Holder Name',
    withDraw_AccNo: 'Account Number',
    withDraw_IfscCode: 'IFSC Code',
    withDraw_Save: 'Save',
    withDraw_1500: '1500',
    withDraw_2500: '2500',
    withDraw_5000: '5000',
    deposit_25: '25',
    Team_AllTeam: 'All Teams',
    Team_AllTime: 'All Time',
    League_TeamCompare: 'Select team to compare',
    League_SelectButton: 'Click on select to compare team',
    withDraw_Data1: 'Fee - ₹10, 24-48 Working Hours',
    withDraw_Data2: '3% Paytm charge, 24 hr process',
    withDraw_Data3: '3% Paytm charge (once a week)',
    Deposit_AmountBalance: 'Enter the amount you wish to add to your balance.',
    ShareContest_ccc: 'Copied Successfully',
    ShareContest_notCopyText: 'Could not copy text: ',
    Footer_Home: 'Home',
    Footer_Away: 'Away',
    League_10: '10',
    League_action: 'Action',
    League_Off: 'Off',
    League_Applied: 'Applied!',
    No_Transaction: 'No Transaction are available',
    Footer_Matches: 'My Matches',
    Footer_Profile: 'Profile',
    Footer_More: 'More',
    More_Feedback: 'Complaints & Feedback',
    Complaint_Type: 'Type',
    Complaint_Title: 'Title',
    Complaint_Description: 'Descriptions',
    Home_Cricket: 'Cricket',
    Home_Football: 'Football',
    Home_Kabaddi: 'Kabaddi',
    Home_No_Match: 'No Match Found!',
    Home_Cricket_Msg: 'No Upcoming Cricket matches available',
    Home_Football_Msg: 'No Upcoming Football matches available',
    Home_Kabaddi_Msg: 'No Upcoming Kabaddi matches available',
    Matches_Cricket_Live: 'No Live Cricket matches available',
    Matches_Football_Live: 'No Live Football matches available',
    Matches_Kabaddi_Live: 'No Live Kabaddi matches available',
    Matches_Cricket_Completed: 'No Completed Cricket matches available',
    Matches_Football_Completed: 'No Completed Football matches available',
    Matches_Kabaddi_Completed: 'No Completed Kabaddi matches available',
    Matches_Upcoming: 'Upcoming',
    Matches_Live: 'Live',
    Matches_Watch_Live: 'Watch Live',
    Matches_Watch_Now: 'Watch Now',
    Matches_Completed: 'Completed',
    More_PSystem: 'Point System',
    More_HTP: 'How To Play ?',
    More_offers: 'Offers',
    More_IF: 'Invite Friend',
    More_AboutUs: 'About Us',
    More_ContactUs: 'Contact Us',
    'More_T&C': 'Terms & Conditions',
    More_Policy: 'Policy',
    More_pPolicy: 'Privacy Policy',
    More_fpPolicy: 'Fair Play Policy',
    More_12thmanPolicy: '12th Man Feature',
    More_discPolicy: 'Disclaimer',
    More_legaPolicy: 'Legality',
    More_spotlPolicy: 'Is fantasy sport legal in India ?',
    More_withdrawPolicy: 'How to withdraw ?',
    'More_F&Q': 'FAQ',
    More_AppStore: 'App Store',
    More_HTD: 'How to deposit ?',
    More_HTR: 'How to register ?',
    More_ChatSupport: 'Chat Support',
    Welcome_Invite: 'Invited by a friend?',
    Welcome_EnterCode: 'Enter Code',
    Welcome_AlUser: 'Already a user?',
    Welcome_Login: 'Login',
    Welcome_Khelo: '#khelo',
    Team_Compare: 'Team Compare',
    NO_PointBreakup_Avail: 'No pointBreakup Avaialble',
    Welcome_Hash: '#',
    Congrats_you_win: 'Congratulations you have won in',
    Win_contest: 'contest',
    ProfileInfo_State: 'Select State',
    ProfileInfo_City: 'Select City',
    ProfileInfo_Country: 'Select Country',
    Profile_Country: 'Country',
    Profile_State: 'State',
    Profile_City: 'City',
    Profile_PinCode: 'Pin Code',
    Profile_Address: 'Address',
    ProfileInfo_DOB: 'Date of Birth',
    Welcome_Endless: 'endless',
    Welcome_LetsP: 'Let\'s Play',
    Login_Otp: 'OTP',
    Login_Email: 'Email',
    Login_Password: 'Password',
    Login_ForgetPassword: 'Forgot password?',
    Login_ConfirmPassword: 'Confirm password',
    Login_login: 'Log in',
    Login_withLogin: 'OR LOGIN WITH',
    Login_conGoogle: 'continue with Google',
    Login_conFacebook: 'continue with Facebook',
    Login_noAcc: 'Don’t have an account ?',
    Sign_up: 'Sign Up',
    SignUp_Confirm_18: 'I confirm I am above 18 years and agree to all Age restriction disclamier.',
    SignUp_Age_18: 'Age must be greater than 18.',
    SignUp_Confirm_State: 'I am not a resident of',
    SignUp_Confirm_State1: 'Telangana, Assam, Odisha, Sikkim, Nagaland, Andhra Pradesh, Arunachal Pradesh, Karnataka and ',
    SignUp_Confirm_State2: 'Meghalaya',
    Login_Agreed: 'and agree to all the',
    Login_VerifiedPassword: 'Confirm Password',
    Login_ResendOTP: 'Resend OTP',
    Login_VerifyOtp: 'Verify OTP',
    SignUp_Agree: 'By continuing, you agree to our',
    Signup_Terms: 'Terms & Conditions',
    SignUp_And: 'and',
    SignUp_PrivPolicy: 'Privacy Policy',
    SignUp_Username: 'Username',
    SignUp_Name: 'Name',
    SignUp_MobNo: 'Mobile Number',
    SignUp_VerifyOtp: 'Verify OTP',
    SignUp_GetOtp: 'Get OTP',
    Home_Change: 'Change',
    Transaction_Details: 'Transaction Details',
    Transaction_ID: 'Transaction ID',
    Transaction_Date: 'Transaction Date',
    SignUp_Usernames: 'Email/Mobile',
    SignUp_Ref: 'Referral Code (Optional)',
    ConfirmPass_digitOtp: 'Please enter the OTP to verify account. We have sent you a code to ',
    ConfirmPass_notmatchCode: 'I didn’t get a code',
    ConfirmPass_getStarted: 'Get Started',
    Submit: 'Submit',
    Forget_viaEmail: 'Via Email',
    Forget_viaSms: 'Via SMS',
    Preference_SMS: 'SMS',
    Preference_Sound: 'Sound',
    Preference_Vibration: 'Vibration',
    Forget_rEmail: 'Please enter your registered email address. We’ll send an OTP to reset your password.',
    Forget_rMobile: 'Please enter your registered mobile number. We\'ll send an OTP to reset your password.',
    Verification_verify: 'Verify',
    Verification_getCode: 'I didn’t get a code',
    Verification_4DigitCode: 'Please enter the OTP to verify your account. We have sent you a code to ',
    Player_NoMatchList: 'No stats available for this player yet! you can view this player’s stats in the team’s next match of the tour',
    Contast_NoLeeague1: 'No tournaments found',
    Contast_NoLeeague: 'No contests joined yet! Join one to win prizes.',
    No_Data_Found: 'No Data Found',
    Contast_NoRecord: 'No Record Found',
    Contast_LeagueJoined: 'Leagues joined',
    Contast_NoPlayer: 'No Player Joined Yet',
    Contast_JoinLeeague: 'Join Contest',
    League_join: 'Join',
    League_join_create_contest: 'Create Contest ',
    League_Payment: 'Payment',
    League_Promocode: 'Select Promocode',
    League_Total_Entry: 'Total Entry',
    League_To_Pay: 'To Pay',
    Blank_Promocode_List: 'No Promocode available for this contest.',
    League_Discount: 'Discount',
    League_In_Wallet: 'From wallet',
    League_Apply_Promocode: 'Apply Promocode',
    League_Teams_Selected: 'Teams Selected',
    Switch: 'Switch',
    League_noSwitchTeam: 'No Switch Team Are Available',
    League_joined: 'Joined',
    League_spots: 'Spots',
    League_contest_type: 'Contest Type',
    League_league_type: 'League Type',
    League_clear_all: 'Clear All',
    League_prize_pool: 'Prize Pool',
    League_no_of_teams: 'Number of Teams',
    League_contest: 'All Contest',
    League_myContest: 'My Contest',
    League_myTeams: 'My Teams',
    League_matchTips: 'Match Tips',
    League_powerByTips: 'Match Tips - powered by crictracker',
    League_filterBy: 'Filter By',
    League_announced_Lineups: 'Announced Lineups',
    League_sortBy: 'Sort By',
    League_popularity: 'Popularity',
    // League_spots: 'Spots',
    League_entry: 'Join',
    League_Entry_Fee: 'Entry Fee',
    League_prizePoll: 'Prize Pool',
    League_applyNow: 'Apply Now',
    League_Continue: 'Continue',
    League_myContests: 'my contests',
    League_playerStats: 'Player Stats',
    Scorecard: 'Scorecard',
    League_prizeBreakup: 'Prize Breakup',
    League_leaderboard: 'Leaderboard',
    League_Rank: 'Rank',
    League_Leaders: 'Leaders',
    League_LoyaltyPoints: 'Loyalty Points',
    League_Amazon_Gifts: 'Amazon pay gift card T&C',
    League_Wins: 'Wins',
    League_noContest: 'No Contests are available.',
    League_notification: 'Notification not found',
    Team_SaveTeam: 'Save Team',
    Team_SelectTeam: 'Select Team',
    Team_SelectAll: 'Select All',
    Team_Title1: 'You can select max ',
    Team_Title2: 'teams for this contest.',
    Team_points: 'Points',
    Team_WK: 'WK',
    Team_BAT: 'BAT',
    Team_Batsmen: 'Batters',
    Team_AllRounder: 'All Rounder',
    Team_AR: 'AR',
    Team_BOWL: 'BOWL',
    Teams_noTeam: 'No Team Available',
    Teams_noTeamAdded: 'You haven’t created a team yet!',
    Check_back_after_while: 'No data found. Please check back after while.',
    Teams_noPrizeBreakup: 'No Prize Breakup Found',
    Player_selectedBy: 'Selected By',
    Player_selBy: 'Sel By',
    Player_points: 'Points',
    Player_point: 'Point',
    Player_players: 'players',
    Player_filter: 'Filter',
    Player_credits: 'Credits',
    Player_scoreCard: 'Scorecard',
    Player_c: 'C By',
    Player_vc: 'VC By',
    Player_event: 'Events',
    Player_action: 'Actions',
    Player_matchWise: 'Matchwise Fantasy Stats',
    League_prizePool: 'Prize Pool',
    League_enterCode: 'Join',
    League_createContest: 'Create',
    League_1stPrize: '1st Prize',
    League_Prize: 'Prize',
    League_winners: 'Winners',
    League_left: 'Spots left',
    League_teams: 'Teams',
    League_bonus: 'Bonus',
    League_multi_entry: 'Multiple Teams',
    Matches_LineupsOut: 'Lineups Out',
    Contest_createeam: 'Create A New Team',
    Contest_contestName: 'Contest Name',
    Contest_contestSize: 'Contest Size',
    Contest_contestPrize: 'Max Prize Pool',
    Contest_allowTeam: 'Allow friends to join with multiple teams',
    Contest_allowPool: 'Make contest confirmed',
    Contest_entryFee: 'Entry',
    Contest_joinContest: 'Join Contest',
    Contest_inviteCode: 'Contest Code',
    Contest_inviteCodetoJoinContest: 'If you have a contest invite code, enter it here to join',
    Contest_createContest: 'Create Contest',
    Contest_joinAndcreateContest: 'Join & Create Contest',
    Contest_bonus: 'You Will Get Creator Bonus of ',
    Contest_NoOfWinners: 'Choose Prize Breakup',
    Contest_poolText: 'The contest will take place if 2 players join. Winnings will be readjusted automatically',
    Contest_FeeText: 'Entry Fee is calculated based on contest size & contest prize',
    Team_chooseCaptain: 'Choose your Captain & Vice Captain',
    Team_12thman: 'Captain (C) gets 2x points',
    Team_12thman_2: 'Vice Captain (VC) gets 1.5x points',
    Team_Select: 'Select',
    Team_creditLeft: 'Credits Left',
    Team_players: 'Players',
    other_Continue: 'Continue',
    other_Next: 'Next',
    other_Prev: 'Previous',
    other_shareContest: 'Share the contest code',
    other_copy: 'copy',
    other_shareWhatsapp: 'share on Whatsapp',
    other_sharingOption: 'other sharing options',
    pointSystem_ODI: 'ODI',
    pointSystem_T10: 'T-10',
    pointSystem_T20: 'T-20',
    pointSystem_100BALLS: '100 BALLS',
    pointSystem_TEST: 'TEST',
    pointSystem_attributeName: 'Attribute Name',
    pointSystem_minimum: 'Minimum',
    pointSystem_fromTo: 'From - to',
    pointSystem_From: 'From',
    pointSystem_To: 'To',
    pointSystem_This_Week: 'This Week',
    pointSystem_This_Month: 'This Month',
    pointSystem_This_Year: 'This Year',
    pointSystem_Other_Options: 'Other Options',
    Footer_Bonus: 'Bonus Balance',
    Footer_Wins: 'Wins',
    Profile_Preference: 'Preferences',
    Match_NotStarted: 'Match not started yet!',
    Match_Download: 'Download',
    Match_DownloadTeams: 'Download Teams',
    Match_TeamLocked: 'Teams Locked! Download and track Your Competition.',
    Match_Cancel: 'Cancel',
    Match_VS: 'VS',
    WON: 'WON ₹',
    ONLY_WON: 'WON',
    WON_Multiple_Prize: 'Won Multiple Prizes >',
    WON_Multiple_Prize1: 'Won Multiple Prizes ',
    ONLY_WON_Gadget: 'Won Gadgets',
    YOU_ONLY_WON_Gadget: 'You Won Gadgets',
    WON_Multiple_Prizes: 'Won Multiple Prizes',
    Team: 'Team',
    Contest: 'Contest',
    Apply_filter: 'Apply filter',
    league_Wins: 'Wins',
    OFFER_TITLE: 'Offer Title',
    League_Select_Filter: 'Select Filters',
    Team_BATS: 'BATS',
    Team_ALLR: 'ALLR',
    Team_BWL: 'BWL',
    YOU_WON_Multiple_Prizes: 'You Won Multiple Prizes',
    Common_Should_Be_MIN: 'Should be min',
    Common_Not_Add_MoreThan: 'You cannot add more than',
    Common_Seven_Player: 'You cannot add more than 7 players from the same team.',
    Not_Enough_Credit: 'Not enough credits',
    Common_Should_Be_REQ: 'is required',
    JOIN_ERR: 'Required Filed',
    No_PointBreakup: 'No PointBreakup Available',
    Available_balance: 'Available Balance',
    Deposited_balance: 'Deposit Balance',
    Win_balance: 'Win Balance',
    LeaderBoard_Series: 'Series-Wise',
    LeaderBoard_month: 'Last Month',
    LeaderBoard_Serieswise: 'Series-wise',
    LeaderBoard_allTeams: 'All Teams',
    Select_Series: 'Select Series',
    No_Team_Available: 'No Teams Available to Switch',
    No_Score_Point_Available: 'No Score Points Are Available',
    No_Sports_Available: 'No Sports Are Available',
    Withdraw_addBankDetails: 'Add Your Bank Details',
    Withdraw_fill_BankDetails: 'Please fill your bank details first.',
    Withdraw_greaterThan10: 'Amount Should be greater than 10',
    Withdraw_not_greaterThan_WinBalance: 'Amount Can not Be greater than win Balance',
    Deposit_min_25: 'Minimum amount to deposit is ',
    Withdraw_Between_To: 'Withdraw Amount between',
    Deposit_Between_To: 'Deposit Amount between',
    Deposit_between: 'Between',
    Game_Cricket: 'CRICKET',
    Game_Football: 'FOOTBALL',
    Match_Player_Not_Exist: 'Match Player is not exist.',
    Match_Player_Role_Not_Exist: 'Player Role does not exist',
    No_Team_Available_To_Join: 'No Team Available To Join',
    No_Conditions_Here: 'No Conditions Here',
    Enter_New_Email: 'Enter New Email',
    Enter_New_Mobile: 'Enter New Mobile',
    ScoreCard_None: 'ScoreCard is not available.',
    ScoreCard_Bwl: 'Bowler',
    ScoreCard_Bat: 'Batsman',
    ScoreCard_Runs: 'R',
    ScoreCard_Balls: 'B',
    ScoreCard_4s: '4s',
    ScoreCard_6s: '6s',
    ScoreCard_SR: 'SR',
    ScoreCard_Ov: 'Ov',
    ScoreCard_M: 'M',
    ScoreCard_R: 'R',
    ScoreCard_W: 'W',
    ScoreCard_NB: 'NB',
    ScoreCard_WD: 'WD',
    ScoreCard_Eco: 'Eco',
    ScoreCard_FOW: 'FALL OF WICKETS',
    ScoreCard_RPO: 'Runs Per over',
    ScoreCard_Total: 'Total',
    ScoreCard_Extra: 'Extras',
    Restricted_state: 'Selected state is restricted',
    Match_Disable_Msg: 'Contest for this match will open soon. Stay Tuned...!',
    No_Future_Date: 'Date should not be future date',
    Wrong_birth_year: 'Enter proper birth date!',
    Only_alphabets_allowed: 'Only alphabets are allowed!',
    Match_Live_Msg: 'Times  up!!',
    Playing: 'Player announced',
    My_Refer_Code: 'My Refer Code',
    Pay: 'Pay',
    Enter_the_amount: 'Enter Amount : ',
    Team_name_alpha_numeric: 'Team name must be alpha-numeric',
    Team_name_10_characters: 'Team name must be maximum of 10 characters',
    Profile_deposite_info_msg: 'Money you added will be shown here. You can use it to join contest, but can\'t withdraw.',
    Profile_balance_info_msg: 'Money thay you can withdraw or re-use to join any contest.',
    Valid_Credentials: 'Please enter a valid credentials.',
    Winning_Breakup: 'Winning Breakup',
    Verification: 'Verification',
    Complaint_Details: 'Complaint Details',
    Feedback_Details: 'Feedback Details',
    Chat: 'Chat',
    LeaderBoard: 'LeaderBoard',
    Matches: 'Matches',
    Notifications: 'Notifications',
    Invite_A_Friend: 'Invite a Friend',
    Bank_Details: 'Bank Details',
    Withdraw: 'Withdraw',
    // Verify_Email: 'Verify Email',
    // Verify_Mobile: 'Verify Mobile',
    Fansportiz: 'Fansportiz',
    Success: 'Success',
    Error: 'Error',
    Diamond: 'Diamond',
    Diamond_Disable: 'DiamondDisable',
    Multi: 'Multi',
    Multi_Disable: 'MultiDisable',
    Copy: 'Copy',
    Copy_Disable: 'CopyDisable',
    Loyalty: 'Loyalty',
    Loyalty_Disable: 'LoyaltyDisable',
    Trophy: 'Trophy',
    Player: 'Player',
    Goto_Icon: 'Goto Icon',
    Player_Image: 'Player Image',
    Medal: 'Medal',
    Dollar: 'Dollar',
    Bat: 'Bat',
    Ball: 'Ball',
    Transparent_Logo: 'Transparent Logo',
    Aahdar_Card_Photo: 'Aadhar Card Photo',
    Contests: 'Contests',
    Reply: 'Reply',
    Play_Now: 'Play Now',
    Promo_Applied: 'Applied...!',
    Info: 'Info',
    Enter_Team_Name: 'Enter Team Name',
    Wallet_Details: 'Wallet Details',
    Remove: 'Remove',
    Available_Cash_Balance: 'Available Cash Balance',
    Trophy_Message: 'Trophy is waiting for you. But before that, we need you to login to make your experience even better.',
    notification_icon: 'Notification',
    // Profile_deposite_info_msg: 'Money you added will be shown here. You can use it to join contest, but can\'t withdraw.',
    // Profile_balance_info_msg: 'Money thay you can withdraw or re-use to join any contest.',
    New_User: 'New User?',
    Reset_Password: 'Reset Password',
    Will_received_OTP: 'You will received OTP on ',
    Forgot_Password: 'Forgot Password',
    fullname_validation: 'Full name must be alpha-numeric.',
    Profile_pic_validation: 'Please select a file less than 5MB',
    Will_received_OTP_on_email: 'You will receive an OTP on this email',
    Okay: 'Okay',
    Withdrawal_request: 'Withdrawal request sent!',
    Withdraw_amount: 'Your request for the withdrawal of amount ',
    Sent_success: ' has been sent successfully.',
    // Sent_success: ' has been sent successfully.',
    Confirmation: 'Confirmation',
    Cancel_Withdraw_first: 'Are you sure you want to cancel your withdrawal request of ',
    Cancel_Withdraw_second: '? This can not be undone.',
    // Fansportiz: 'Fansportiz',
    Join_contest: 'Join Contest',
    Create_contest: 'Create contest',
    ConfirmPass_resend: 'Resend OTP',
    Share: 'Share',
    Does_not_support_share: 'Your browser doesn\'t support the Web Share',
    Common_name_length: 'Full name must be 2 to 30 characters.',
    Bankdetails_branchname: 'Branch name must be less than or equal to 35 characters.',
    Bankdetails_acountname: 'Account name must be less than or equal to 35 characters.',
    Bankdetails_acountnumber: 'Account number must be 9 to 18 digits.',
    EditProfile_address: 'Address must be 5 to 50 characters.',
    Complaint_title_err: 'Title must be less than or equal to 35 characters.',
    Complaint_description_err: 'Description must be less than or equal to 250 characters.',
    More_offer_details: 'Offer Details',
    Delete_Account: 'Delete Account',
    Delete: 'Delete',
    Delete_account_confirmation: 'Are you sure you want to delete your account?',
    Select_reason: 'Please select a reason for deleting account.',
    Write_reason: 'Write Reason',
    Delete_confirmation: 'Once you delete the account, you can’t login with your current login credentials.',
    Confirm: 'Confirm',
    Enter_reason: 'Please enter reason',
    Played_Last_Match: 'Played Last Match',
    KYC_Pan_Note: 'Note: Please enter the pan details as per your Pan Card.',
    KYC_pan_msg: 'Your PAN detail ',
    KYC_aadhaar_msg: 'Your Aadhar number ',
    KYC_pending_msg: ' verification is pending.',
    KYC_verified_msg: ' has been successfully verified.',
    KYC_reject_msg: ' verification is rejected.',
    Reason_for_rejection: 'Reason for rejection.',
    Rejection_reason_aadhaar: 'Your Pan Card details does not match with system. Please check your Pan Card details and try again.',
    Submit_again: 'Submit Again',
    OTP_msg_for_aadhaar: 'Note: Please enter the OTP sent to your registered mobile number.',
    // Rejection_reason_aadhaar: 'Your Aadhar Card number does not match with system. Please check your Aaadhar Card number and try again.',
    Invalid_pan: 'PAN number is invalid.',
    Invalid_aadhaar: 'Aadhar number is invalid.',
    Invalid_otp: 'Invalid OTP',
    // Submit_again: 'Submit Again',
    Pending_transactions: 'Pending Transactions',
    Pending_deposit_by_cashfree: 'Pending Deposit by ',
    No_pending_deposit: 'No pending deposit available.',
    // Refer_a_friend: 'Refer a Friend',
    Share_your_code: 'Share your Code',
    Tap_to_copy: 'Tap to copy',
    How_it_works: 'How it works?',
    Bonus_history: 'Bonus History',
    Invite_friends: 'Invite Friends',
    Invite_friends_text: 'Invite your friends to join the Fansportiz with your referral code or Link.',
    Collect_bonus_cash: 'Collect Bonus Cash',
    'Don’t_have_bonus': 'You don’t have any bonus cash. Share this app with your friend and get some bonus cash',
    You_will_get_1: 'You will get ',
    You_will_get_2: ' when you refer the app to a new user, New users will get Refer amount of ',
    You_will_get_3: ' if they register on our app with the referral code/join first contest/make first deposit',
    Reference_to: 'Reference to',
    Ref_msg_1: 'Reference reward ',
    For: ' for ',
    Ref_msg_2: ' remind ',
    Ref_msg_3: ' to  ',
    REGISTER: 'signin with your refferal code',
    FIRST_DEPOSIT: 'make first deposit',
    FIRST_LEAGUE_JOIN: 'join first contest',
    FIRST_PAID_LEAGUE_JOIN: 'join first paid contest',
    Reference_reward_received: 'Reference reward received for ',
    Remind: 'Remind',
    Mega_contest_home: 'Mega Contest',
    Time_remaining: 'Time Remaining',
    'Single-entry': 'Single',
    'Multi-upto': 'Upto ',
    'Multi-teams': ' teams',
    League_Winnings: 'Winnings',
    'Played-last': 'Played last match',
    preview: 'Preview',
    filter_by_teams: 'Filter By Teams',
    both_team: 'Both',
    both_team_info: 'Players from both teams',
    Max_Fill: 'Max Fill*',
    Current_Fill: 'Current Fill',
    Highlight_Team: 'Highlight My Team',
    Dream_Team: 'Dream teams is available now!',
    Check_now: 'Check now',
    Price_Breakup_Applicable: '*Applicable only when all spots are filled',
    Discard_Team: 'Discard Team?',
    Discard_text: 'If You go back, this team will not be saved.',
    discard_team_btn: 'DISCARD TEAM',
    continue_editing: 'CONTINUE EDITING',
    You: 'You',
    Select_more_TeamCompare: 'Select one more team to compare',
    You_won: 'You won',
    Match_Review: 'In-Review',
    Match_Abandoned: 'Abandoned',
    Captains_Vice_Captains: 'Captains & Vice Captains',
    Different_Players: 'Different Players',
    User_leads_BY: 'leads by',
    Compare_Teams: 'Compare Teams',
    Join_Paste: 'Paste',
    Share_with_friends: 'Share with Friends',
    No_contest_joined: 'You haven’t joined a contest yet!',
    No_joined_upcoming_contests: 'You haven’t joined any upcoming contests!',
    View_upcoming_matches: 'VIEW UPCOMING MATCHES',
    Player_Captain_by: 'Captain by',
    Player_Vice_Captain_by: 'Vice-Captain by',
    Reset_btn_txt: 'Reset',
    Add_to_team_txt: 'Add To My Team',
    Match_played_txt: 'Matches Played',
    Avg_points_txt: 'Avg.Points',
    Dream_team_txt: 'Dream Team',
    Batting_points_txt: 'Batting Pts',
    Bowling_points_txt: 'Bowling Pts',
    Other_points_txt: 'Other Pts',
    Create_New_Team: 'Create A Team',
    Switch_Team: 'Switch Team',
    Session_expired: 'Session Expired',
    Already_Joined: 'Already Joined',
    Chooseteam_To_Join: 'Choose a team to replace',
    Current_team: 'Current',
    Switch_to_team: 'Switch to',
    Select_from_your_existing_teams: 'Select from your existing teams or Create new',
    Join_With: 'Join',
    Join_With_Free: 'Join Free',
    Join_With_Ads: 'Watch Ads To Join',
    Low_crowns_balance: 'Low balance, need',
    Low_crowns_balance_more: 'more crowns to enter this contest',
    Lineup_text: 'Lineup',
    Players_not_announced: 'Players are not announced!',
    Player_unannounced: 'Player unannounced',
    Player_Info: 'Player Info',
    No_tournaments_matching: 'No tournaments matching your filter criteria',
    Reset_filters: 'Reset Filters',
    No_Info_available: 'No Info available for this player yet!',
    No_Info_available_des: 'You can view this player’s stats in the teams next match of the tour',
    Fantasy_Point_System: 'Fantasy Point System',
    Fantasy_How_To_Play: 'How to Play',
    Select_Currency: 'Select Currency',
    Click_on_compare: 'Click on compare',
    Max_Price_Poll: 'Max Prize Pool',
    Create_and_Join: 'Create & Join',
    Participate_Win_Prize: 'of participate win prize in this contest',
    Team_allowed_contest: 'team allowed in this contest',
    Guaranteed_contest: 'Guaranteed to take place regardless of spots filled.',
    Confirmed_contest: 'Confirmed, tournament will not be cancelled until match is not cancelled.',
    UnConfirmed_contest: 'Unconfirmed, tournament take place only when min required spots are filled',
    Please_Check_back: 'Please check back after a while.',
    Create_a_league: 'Contest Details',
    Price_Applicable_only: 'Applicable only when all spots are filled',
    Please_check_back: 'Please check back after while.',
    Earn_More: 'Earn More',
    Slider_Live_Message: 'The match is Live and you have not joined any Contest. Please check upcoming matches',
    Slider_Cancel_Message: 'The match is canceled. Please check upcoming matches'
  }
}
