import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { currencyConvert } from '../../../helper'
import CrownUtils from '../../../assests/images/crown-utils.svg'
import Voucher from '../../../assests/images/voucher.svg'

function YouWonReturn ({ data }) {
  const currencyLogo = useSelector(state => state.settings.currencyLogo)

  let count = 0
  if (data?.nWinnings > 0) {
    count = count + 1
  }
  if (data?.nCrownWin > 0) {
    count = count + 1
  }
  if (data?.nBonusWin > 0) {
    count = count + 1
  }
  if (data?.nWinnings > 0) {
    count = count + 1
  }
  if (data?.aExtraWin?.length !== 0) {
    count = count + 1
  }

  if (count > 1) {
    return (<><FormattedMessage id='YOU_WON_Multiple_Prizes' /></>)
  } else if (data?.nWinnings > 0) {
    return (<><FormattedMessage id='You_won' /> {currencyLogo}{currencyConvert(data.nWinnings.toFixed(2))}</>)
  } else if (data?.nBonusWin > 0) {
    return (<><FormattedMessage id='You_won' /> {currencyLogo}{currencyConvert(data.nBonusWin.toFixed(2))}</>)
  } else if (data?.nCrownWin > 0) {
    return (<><FormattedMessage id='You_won' /> <img src={CrownUtils} /> {currencyConvert(data.nCrownWin)}</>)
  } else if (data?.aExtraWin?.length >= 1) {
    return (<><FormattedMessage id='You_won' /> {data.aExtraWin[0]?.eExtraWinType === 'V' && <img src={Voucher} />} {data.aExtraWin[0]?.sInfo}</>)
  } else {
    return (<><FormattedMessage id='Matches_Completed' /></>)
  }
}

YouWonReturn.propTypes = {
  data: PropTypes.object
}

export default YouWonReturn
