import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { createBrowserHistory } from 'history'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes/index'
import Intl from '../src/intl/index'
// import firebase from 'firebase'
// import Ads from './Ads'
import ReactGA from 'react-ga'
// import configs from './config'
import { VerifyToken } from './redux/actions/auth'
import { getUrl } from './redux/actions/url'
import { getCurrency, getBackground, isMaintenanceMode } from './redux/actions/setting'
// import { store } from './redux/store'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
// import { GetNotificationCount } from './redux/actions/notification'
import { GetActiveSports } from './redux/actions/activeSports'
import { GetUserProfile } from './redux/actions/profile'
import { subscribeToFCMTopicPost } from './helper'
import Loading from './component/Loading'
export const history = createBrowserHistory()

// const config = {
//   apiKey: 'AIzaSyAaGJDH6RvCuI0_hhoaZl3pX0clHOpoiB4',
//   authDomain: 'fantasy-wl.firebaseapp.com',
//   projectId: 'fantasy-wl',
//   storageBucket: 'fantasy-wl.appspot.com',
//   messagingSenderId: '218538323308',
//   appId: '1:218538323308:web:0f2ee2f13f110a59db9749',
//   measurementId: 'G-VK4CQMC2N7'
// }
// firebase.initializeApp(config)
// firebase.analytics()
// if (firebase.messaging.isSupported()) {
//   const messaging = firebase.messaging()

//   messaging.onMessage((payload) => {
//     navigator.serviceWorker
//       .getRegistrations()
//       .then((registration) => {
//         registration[0].showNotification(payload && payload.notification && payload.notification.title, {
//           body: payload.notification.body,
//           icon: '/firebase-logo.png'
//         })
//       })
//   })

//   messaging.getToken()
//     .then(function (token) {
//       store.dispatch(FirebaseToken(token))
//     })
//     .catch(function (err) {
//       console.log('Error Occurred. ' + err)
//     })
// }

function App () {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token) || localStorage.getItem('Token')
  // const tokenId = useSelector(state => state.auth.token)
  const userData = useSelector(state => state.profile.userInfo) || JSON.parse(localStorage.getItem('userData'))
  const getUrlLink = useSelector(state => state.url.getUrl)
  const currencyLogo = useSelector(state => state.settings.currencyLogo)
  const backgroundCoverImage = useSelector(state => state.settings.backgroundCoverImage)
  const backgroundImage = useSelector(state => state.settings.backgroundImage)
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const activeSportsResStatus = useSelector(state => state.activeSports.resStatus)
  const profileResStatus = useSelector(state => state.profile.resStatus)
  const activeSport = activeSports && activeSports.length > 0 && activeSports.sort((a, b) => a.sName > b.sName ? -1 : 1).sort((a, b) => (a.nPosition > b.nPosition) ? 1 : -1).map(data => data)
  // const [showingImage, setShowingImage] = useState(true)
  // const [bannerImg, setBannerImg] = useState('')
  // const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    if (window && token) {
      const fp = await FingerprintJS.load()
      // The FingerprintJS agent is ready.
      // Get a visitor identifier when you'd like to.
      const result = await fp.get()
      // This is the visitor identifier:
      const visitorId = result.visitorId
      localStorage.setItem('ID', visitorId)
      if (token && visitorId) {
        dispatch(VerifyToken(token, visitorId))
      }
      ReactGA.initialize('UA-204021649-1')

      dispatch(GetUserProfile(token))
      dispatch({
        type: 'TOKEN_LOGIN',
        payload: {
          token,
          userData
        }
      })

      subscribeToFCMTopicPost('allfantasy')
    }
  }, [token])

  useEffect(() => {
    if (profileResStatus && token) {
      if (!currencyLogo) {
        dispatch(getCurrency())
      }
      if (!backgroundCoverImage || !backgroundImage) {
        dispatch(getBackground())
      }
      dispatch(isMaintenanceMode())
      dispatch(GetActiveSports(token))
      dispatch(getUrl())
    }
  }, [profileResStatus])

  useEffect(() => {
    if (activeSport) {
      if (history.location.pathname === '/login' || history.location.pathname === '/forgot-password' || history.location.pathname === '/signup' || history.location.pathname === '/confirm-password' || history.location.pathname === '/' || history.location.pathname.includes('/v1')) {
        history.push({ pathname: `/home/${activeSport && (activeSport[0]?.sKey).toLowerCase()}` })
      }
    }
  }, [activeSport])

  useEffect(() => {
    if (activeSportsResStatus) {
      setLoading(false)
    }
  }, [activeSportsResStatus])

  // useEffect(() => {
  //   if (getUrlLink && token) {
  //     setUrl(getUrlLink)
  //   }
  // }, [getUrlLink])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     token && dispatch(GetNotificationCount(token))
  //   }, 20000)

  //   return () => clearInterval(interval)
  // }, [token])

  // Git changes

  return (
    <>
    {loading && <Loading />}
    <div className='full-screen' style={{ backgroundImage: getUrlLink && backgroundCoverImage ? `url(${getUrlLink}${backgroundCoverImage})` : '' }}>
      {
        backgroundImage && getUrlLink && (
          <img className='back-Img' src={getUrlLink && backgroundImage ? `${getUrlLink}${backgroundImage}` : ''} />
        )
      }
      <div className='left-bar'>
        {/* {
          configs?.environment && (
            <div className='ui-feedback'>
              <p>{configs.environment}</p>
            </div>
          )
        } */}
        <Intl>
          <Router history={history}>
            {/* <Ads history={history} url={url} showingImage={showingImage} bannerImg={bannerImg} setBannerImg={setBannerImg} setShowingImage={setShowingImage} /> */}
            <div className={'left-bar'}>
              <Routes/>
            </div>
          </Router>
        </Intl>
      </div>
    </div>
    </>
  )
}

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string
    })
  })
}

export default App
