import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFetchLiveInnings } from '../../redux/actions/scoreCard'
import PropTypes from 'prop-types'

export const ScoreCardList = (Component) => {
  const MyComponent = (props) => {
    const { match } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const fullLiveInning = useSelector(state => state.scoreCard.fullLiveInning)
    const resMessage = useSelector(state => state.scoreCard.resMessage)
    const token = useSelector(state => state.auth.token)
    const previousProps = useRef({ resMessage, fullLiveInning }).current
    useEffect(() => {
      if (match && match.params && match.params.id && token) {
        dispatch(getFetchLiveInnings(match.params.id, '', token))
        setLoading(true)
      }
    }, [token])

    useEffect(() => { // handle the response
      if (previousProps.fullLiveInning !== fullLiveInning) {
        if (fullLiveInning) {
          setLoading(false)
        }
      }
      return () => {
        previousProps.fullLiveInning = fullLiveInning
      }
    }, [fullLiveInning])

    return (
      <Component
        {...props}
        joinedLoading={loading}
        fullLiveInning={fullLiveInning}
      />
    )
  }
  MyComponent.propTypes = {
    match: PropTypes.object
  }
  MyComponent.displayName = MyComponent
  return MyComponent
}

export default ScoreCardList
