import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react'
import { Button, Input, Table } from 'reactstrap'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import SkeletonPlayerStatus from '../../../component/SkeletonPlayerStatus'
import PlayerImage from '../../../assests/images/PlayerImage.svg'
import HomeTeam from '../../../assests/images/Team1.png'
import AwayTeam from '../../../assests/images/Team2.png'
import CaptainIcon from '../../../assests/images/captain-team-icon.svg'
import ViceCaptainIcon from '../../../assests/images/viceCaptain-team-icon.svg'
import TeamPlayerInfo from '../TeamPlayerInfo/index'
import { handleImgError } from '../../../helper'
// import qs from 'query-string'
const classNames = require('classnames')

function PlayerStats (props) {
  const { match, url, matchPlayerList, completed, teamList, matchDetails } = props
  const [loading, setLoading] = useState(false)
  const [SortingFrom, setSortingFrom] = useState('points')
  const [sorted, setSorted] = useState(false)
  const [listed, setListed] = useState([])
  const [teamListNames, setTeamListNames] = useState([])
  const [currTeam, setCurrTeam] = useState('')
  const [currCaptain, setCurrCaptain] = useState('')
  const [currViceCaptain, setCurrViceCaptain] = useState('')

  const [playerId, setPlayerId] = useState('')
  const [playerInfo, setPlayerInfo] = useState(false)

  const previousProps = useRef({
    matchPlayerList, teamList, currTeam
  }).current

  useEffect(() => {
    setLoading(true)
    setSorted(false)
    if (matchPlayerList?.length > 1) {
      if (SortingFrom === 'points') {
        let list
        if (sorted) {
          list = matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? 1 : -1)
        } else {
          list = matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? -1 : 1)
        }
        setListed(list)
        setLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    if (previousProps.teamList !== teamList) {
      if (teamList) {
        const arr = []
        teamList.map((team) => {
          arr.push(team)
          return team
        })
        setTeamListNames(arr)
      }
    }

    return () => {
      previousProps.teamList = teamList
    }
  }, [teamList])

  useEffect(() => {
    if (previousProps.currTeam !== currTeam) {
      if (currTeam) {
        let list
        if (sorted) {
          const players = []
          matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.map((player) => {
            const team = teamList?.find((team) => team.sName === currTeam.sName)
            setCurrCaptain(team?.iCaptainId)
            setCurrViceCaptain(team?.iViceCaptainId)
            team.aPlayers.map((item) => {
              if (item === player._id) {
                players.push(player)
              }
              return item
            })
            return player
          })
          list = players.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? 1 : -1)
        } else {
          const players2 = []
          matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.map((player) => {
            const team = teamList?.find((team) => team.sName === currTeam.sName)
            setCurrCaptain(team?.iCaptainId)
            setCurrViceCaptain(team?.iViceCaptainId)
            team?.aPlayers.map((item) => {
              if (item === player._id) {
                players2.push(player)
              }
              return item
            })
            return player
          })
          list = players2.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? -1 : 1)
        }
        setListed(list)
      } else {
        setListed(matchPlayerList)
        setCurrCaptain('')
        setCurrViceCaptain('')
      }
    }

    return () => {
      previousProps.currTeam = currTeam
    }
  }, [currTeam])

  useEffect(() => {
    if (previousProps.matchPlayerList !== matchPlayerList) {
      setLoading(false)
      if (SortingFrom === 'points' && !currTeam) {
        let list
        if (sorted) {
          list = matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? 1 : -1)
        } else {
          list = matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? -1 : 1)
        }
        setListed(list)
      }
    }
    return () => {
      previousProps.matchPlayerList = matchPlayerList
    }
  }, [matchPlayerList])

  const teamData = useMemo(() => {
    if (currTeam) {
      const hello = []
      matchPlayerList && matchPlayerList.length > 0 && matchPlayerList.map((player) => {
        const team = teamList?.find((team) => team.sName === currTeam.sName)
        team.aPlayers.map((item) => {
          if (item === player._id) {
            hello.push(player)
          }
          return item
        })
        return player
      })
      return hello
    } else {
      return matchPlayerList
    }
  }, [listed, currTeam])

  function goToDreamTeam () {
    props.history.push(`/dream-team-preview/${match.params.sportsType}/${matchDetails._id}?activeState=3`)
  }

  const ChangeSorting = (value) => {
    setListed([])
    setSortingFrom(value)
    setSorted(!sorted)
    if (sorted) {
      let listedItem
      if (value === 'players') {
        listedItem = teamData.sort((a, b) => a.sName > b.sName ? -1 : 1)
        setListed(listedItem)
      } else if (value === 'points') {
        listedItem = teamData.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? -1 : 1)
        setListed(listedItem)
      } else if (value === 'selBy') {
        listedItem = teamData.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nSetBy > b.nSetBy ? -1 : 1)
        setListed(listedItem)
      } else if (value === 'C') {
        listedItem = teamData.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nCaptainBy > b.nCaptainBy ? -1 : 1)
        setListed(listedItem)
      } else if (value === 'VC') {
        listedItem = teamData.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nViceCaptainBy > b.nViceCaptainBy ? -1 : 1)
        setListed(listedItem)
      }
    } else {
      let listedItem
      if (value === 'players') {
        listedItem = teamData.sort((a, b) => a.sName > b.sName ? 1 : -1)
        setListed(listedItem)
      } else if (value === 'points') {
        listedItem = teamData.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? 1 : -1)
        setListed(listedItem)
      } else if (value === 'selBy') {
        listedItem = teamData.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nSetBy > b.nSetBy ? 1 : -1)
        setListed(listedItem)
      } else if (value === 'C') {
        listedItem = teamData.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nCaptainBy > b.nCaptainBy ? 1 : -1)
        setListed(listedItem)
      } else if (value === 'VC') {
        listedItem = teamData.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nViceCaptainBy > b.nViceCaptainBy ? 1 : -1)
        setListed(listedItem)
      }
    }
  }
  const sortClass = classNames({ 'icon-up-arrow': sorted === true, 'icon-down-arrow': sorted !== true })

  return (
    <>
      { loading
        ? <SkeletonPlayerStatus numberOfColumns={10} />
        : <Fragment>
            <div className="container-inside">
              {matchDetails?.bDreamTeam &&
                <div className='dream-team d-flex justify-content-between align-items-center'>
                  <h3 className='m-0'><FormattedMessage id="Dream_Team" /></h3>
                  <Button color="primary" onClick={() => goToDreamTeam()} className="btn-sm" ><FormattedMessage id="Check_now" /></Button>
                </div>
              }
              <div className="highlight-team">
                <h3><FormattedMessage id="Highlight_Team" /></h3>
                <div className="highlight-team-wrap">
                  <div className="highlight-team-list">
                    {teamListNames?.sort().map((item, index) => (
                      <div key={index} className="custom-control custom-radio">
                        <Input
                            type="checkbox"
                            autoComplete='off'
                            name="teams"
                            value={item.sName}
                            id={`team${index}`}
                            className="custom-control-input"
                            checked={currTeam.sName === item.sName}
                            onChange={() => {
                              if (item.sName === currTeam.sName) {
                                setCurrTeam('')
                              } else {
                                setCurrTeam(item)
                              }
                            }}
                          />
                          <label className="custom-control-label" htmlFor={`team${index}`}>{item.sName}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='playerStates'>
                <Table className="bg-white player-list player-stats-table m-0">
                  <thead>
                    <tr>
                      <th onClick={() => ChangeSorting('players')}><FormattedMessage id="Player_players" />
                        { SortingFrom === 'players' &&
                          <i className={`${sortClass} asc-dsc`}></i>
                        }
                      </th>
                      {completed &&
                      <th onClick={() => ChangeSorting('points')}><FormattedMessage id="Player_points" />
                        { SortingFrom === 'points' &&
                          <i className={`${sortClass}  asc-dsc`}></i>
                        }
                      </th>
                      }
                      <th onClick={() => ChangeSorting('selBy')}><FormattedMessage id="Player_selBy" />
                        { SortingFrom === 'selBy' &&
                          <i className={`${sortClass} asc-dsc`}></i>
                        }
                      </th>
                      <th onClick={() => ChangeSorting('C')}><FormattedMessage id="Player_c" />
                        { SortingFrom === 'C' &&
                          <i className={`${sortClass} asc-dsc`}></i>
                        }
                      </th>
                      <th onClick={() => ChangeSorting('VC')}><FormattedMessage id="Player_vc" />
                        { SortingFrom === 'VC' &&
                          <i className={`${sortClass} asc-dsc`}></i>
                        }
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      listed && listed.length > 0 && JSON.parse(JSON.stringify(listed)).map(data => {
                        // const ans = uniqueList && uniqueList.length !== 0 && uniqueList.some((data1) => data1 === data._id)
                        const name = data.sName.split(' ')
                        return (
                          <tr key={data._id} onClick={() => {
                            // if (currTeam) {
                            setPlayerInfo(true)
                            setPlayerId(data._id)
                            // }
                          }} >
                          {/* // props.history.push({
                          //   pathname: `/view-playerstat-info/${match.params.sportsType}/${match.params.id}/${data._id}`,
                          //   search: `?${qs.stringify({
                          //     sortBy: SortingFrom || undefined,
                          //     sort: !sorted || undefined,
                          //     playerStat: true || undefined
                          //   })}`,
                          //   state: { playerStat: true }
                          // }) */}
                            {/* <td style={{ backgroundColor: ans ? '#EBFBFF' : '#FFFFFF' }}> */}
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="l-img">
                                  <img src={data && data.sImage && url ? `${url}${data.sImage}` : PlayerImage} alt="" />
                                  {currCaptain && currCaptain === data._id &&
                                    <div className="captain-img">
                                      <img className='' width={18} src={CaptainIcon} />
                                      {/* <img className='' width={18} src={ViceCaptainIcon} /> */}
                                    </div>
                                  }
                                  {currViceCaptain && currViceCaptain === data._id &&
                                    <div className="captain-img">
                                      {/* <img className='' width={18} src={CaptainIcon} /> */}
                                      <img className='' width={18} src={ViceCaptainIcon} />
                                    </div>
                                  }
                                </div>
                                <div>
                                  <h4 className="p-name">{data && data.sName && `${name && name.length >= 2 ? data.sName[0] : name[0]} ${name && name.length === 2 ? name[1] : name && name.length === 3 ? `${name[1]} ${name[2]}` : ''}`}</h4>
                                  <div className="d-flex align-items-center mt-1">
                                    <div className="sel-info width-auto">{data && data.eRole && data.eRole}</div>
                                    <div className="player-t-icon">
                                      <img src={data && data.oTeam && data.oTeam.sImage ? `${url}${data.oTeam.sImage}` : matchDetails?.oHomeTeam?.sShortName === data.oTeam.sShortName ? HomeTeam : AwayTeam} onError={(e) => handleImgError(e, (matchDetails?.oHomeTeam?.sShortName === data?.oTeam?.sShortName ? HomeTeam : AwayTeam))} alt={<FormattedMessage id="Footer_Home" />} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            {
                              completed &&
                              <td>{data && data.nScoredPoints && parseFloat(Number((data.nScoredPoints)).toFixed(2))}</td>
                            }
                            <td>{data && data.nSetBy && parseFloat(Number((data.nSetBy)).toFixed(2))}<FormattedMessage id="Common_perc" /></td>
                            <td>{data && data.nCaptainBy && data.nCaptainBy}<FormattedMessage id="Common_perc" /></td>
                            <td>{data && data.nViceCaptainBy && data.nViceCaptainBy}<FormattedMessage id="Common_perc" /></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
              </div>
            </div>
            {playerInfo
              ? <TeamPlayerInfo {...props} pId={playerId} matchID={match.params.id} userTeamID={currTeam._id} onBackClick={() => setPlayerInfo(false)}></TeamPlayerInfo>
              : ''
            }
      </Fragment>
      }
    </>
  )
}
PlayerStats.propTypes = {
  onPlayerInfoClick: PropTypes.func,
  data: PropTypes.shape({
    nViceCaptainBy: PropTypes.number,
    nCaptainBy: PropTypes.number,
    nScoredPoints: PropTypes.number,
    nSetBy: PropTypes.number,
    eRole: PropTypes.string,
    sName: PropTypes.string
  }),
  uniqueList: PropTypes.shape([{
    nViceCaptainBy: PropTypes.number,
    nCaptainBy: PropTypes.number,
    nScoredPoints: PropTypes.number,
    _id: PropTypes.string
  }]),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sportsType: PropTypes.string
    })
  }),
  matchPlayerList: PropTypes.array,
  completed: PropTypes.bool,
  url: PropTypes.string,
  setPlayers: PropTypes.func,
  history: PropTypes.object,
  teamList: PropTypes.array,
  matchDetails: PropTypes.object
}
export default PlayerStats
