import React, { Fragment, useEffect, useState, useRef } from 'react'
import PlayerDetails from '../../../HOC/SportsLeagueList/PlayerDetails'
import Loading from '../../../component/SkeletonTable'
// import { Link } from 'react-router-dom'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'
// import { cricketStaticImage, footballStaticImage } from '../../../helper'
import { FormattedMessage } from 'react-intl'
import PlayerImage from '../../../../src/assests/images/PlayerImage.svg'
// import HomeImage from '../../../assests/images/homeIconWhite.svg'
import left from '../../../assests/images/left-arrow-white.svg'
import right from '../../../assests/images/right-arrow-white.svg'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import TeamList from '../../../HOC/SportsLeagueList/TeamList'
import qs from 'query-string'
import AwayTeam from '../../../assests/images/Team2.png'
import HomeTeam from '../../../assests/images/Team1.png'
import CloseIcon from '../../../assests/images/close-icon.svg'
import { allSportsRolesSingular, handleImgError } from '../../../helper'

function PlayerInfo (props) {
  const { location, seasonMatch, playerRoles, teamPlayerList, getMatchPlayerList, teamList, getUserTeam, userTeam, playerSeasonNames, matchPlayer, nScoredPoints, playerData, pointBreakUp, token, loading, playerScorePoints, match, matchID, userTeamID, pId, onBackClick, matchDetails } = props
  const url = useSelector(state => state.url.getUrl)
  const [index, setIndex] = useState('')
  const [team, setTeam] = useState([])
  const [playerId, setPlayerId] = useState('')
  const previousProps = useRef({ userTeam, teamList }).current
  const obj = qs.parse(location.search)

  useEffect(() => {
    if (matchID && token) {
      getMatchPlayerList(matchID)
    }
    if (userTeamID && token) {
      getUserTeam(userTeamID)
    }
    if (pId && token) {
      setPlayerId(pId)
      // playerSeasonNames(pId)
    }
  }, [token, match.params])

  useEffect(() => {
    playerSeasonNames(playerId)
  }, [playerId])

  useEffect(() => {
    if (location && location.state && location.state.matchPlayerId) {
      playerScorePoints(location.state.matchPlayerId)
    }
  }, [location.state])

  useEffect(() => {
    if (obj && obj?.sortBy !== 'undefined') {
      const value = obj?.sortBy
      const sorted = obj?.sort
      let listedItem
      if (matchPlayer?.length > 0) {
        if (sorted === 'true') {
          if (value === 'players') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1)
            setTeam(listedItem)
          } else if (value === 'points') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? -1 : 1)
            setTeam(listedItem)
          } else if (value === 'selBy') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nSetBy > b.nSetBy ? -1 : 1)
            setTeam(listedItem)
          } else if (value === 'C') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nCaptainBy > b.nCaptainBy ? -1 : 1)
            setTeam(listedItem)
          } else if (value === 'VC') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nViceCaptainBy > b.nViceCaptainBy ? -1 : 1)
            setTeam(listedItem)
          } else {
            listedItem = matchPlayer
            setTeam(matchPlayer)
          }
        } else {
          if (value === 'players') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? -1 : 1)
            setTeam(listedItem)
          } else if (value === 'points') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => Number(a.nScoredPoints) > Number(b.nScoredPoints) ? 1 : -1)
            setTeam(listedItem)
          } else if (value === 'selBy') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nSetBy > b.nSetBy ? 1 : -1)
            setTeam(listedItem)
          } else if (value === 'C') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nCaptainBy > b.nCaptainBy ? 1 : -1)
            setTeam(listedItem)
          } else if (value === 'VC') {
            listedItem = matchPlayer.sort((a, b) => a.sName > b.sName ? 1 : -1).sort((a, b) => a.nViceCaptainBy > b.nViceCaptainBy ? 1 : -1)
            setTeam(listedItem)
          } else {
            listedItem = matchPlayer
            setTeam(matchPlayer)
          }
        }
      }
      if (playerId) {
        listedItem?.map((player, index) => {
          if (player._id === playerId) {
            setIndex(parseInt(index))
          }
          return index
        })
      }
    }
  }, [matchPlayer, playerId])

  useEffect(() => {
    team?.length > 0 && team?.map((player, index) => {
      if (location?.state?.matchPlayerId) {
        if (player._id === location?.state?.matchPlayerId) {
          setIndex(parseInt(index))
        }
      } else {
        if (player._id === playerId) {
          setIndex(parseInt(index))
        }
      }
      return player._id === playerId
    })
  }, [team])

  useEffect(() => {
    if (obj?.playerLeagueInfo || location?.state?.teamCreationPage) {
      setTeam(seasonMatch)
    } else {
      if ((previousProps.userTeam !== userTeam) || teamPlayerList?.length !== 0) {
        if (userTeamID && userTeam && userTeam.length !== 0 && teamPlayerList) {
          const playerRole = playerRoles?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
          const players = Object.assign({}, ...playerRole?.map(key => ({ [key]: [] })))
          userTeam && userTeam.length !== 0 && userTeam?.aPlayers?.length !== 0 && userTeam?.aPlayers?.map((Player) => {
            const PlayerDetails = teamPlayerList && teamPlayerList.length !== 0 && teamPlayerList.find(player => player._id === Player.iMatchPlayerId)
            Object.entries(players).map(([key, value]) => {
              return (key === PlayerDetails?.eRole) && players[PlayerDetails?.eRole].push(PlayerDetails)
            })
            return players
          })
          const tempData = []
          Object.entries(players).map(([key, value]) => value && value.length > 0 && value.sort((a, b) => a.sName > b.sName ? 1 : -1).map(playerInfo => tempData.push(playerInfo)))
          setTeam(tempData)
        } else {
          setTeam(matchPlayer)
        }
      }
    }
    return () => {
      previousProps.userTeam = userTeam
    }
  }, [userTeam, teamPlayerList, seasonMatch])

  const previous = () => {
    const nextIndex = index === 0 ? team.length - 1 : index - 1
    const matchPlayerId = team[nextIndex]?._id
    if (location?.state?.playerStat) {
      props.history.replace({
        pathname: `/view-playerstat-info/${match.params.sportsType.toLowerCase()}/${matchID}/${matchPlayerId}`,
        search: `?${qs.stringify({
            sortBy: obj?.sortBy || undefined,
            sort: obj?.sort || undefined,
            playerStat: true || undefined
          })}`,
        state: { playerStat: true }
      })
    } else if (location?.state?.teamCreationPage) {
      props.history.replace({
        pathname: `/create-team/view-player-info/${match.params.sportsType.toLowerCase()}/${matchID}/${matchPlayerId}`,
        search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined,
          playerLeagueInfo: obj?.playerLeagueInfo ? 'y' : undefined,
          firstTime: true
          })}`,
        state: { teamCreationPage: true, matchPlayerId }
      })
    } else {
      // props.history.replace({
      //   pathname: `/view-player-Info/${match.params.sportsType.toLowerCase()}/${matchID}/${userTeamID}/${matchPlayerId}`,
      //   search: `?${qs.stringify({
      //       homePage: obj?.homePage ? 'yes' : undefined,
      //       playerLeagueInfo: obj?.playerLeagueInfo ? 'y' : undefined
      //     })}`,
      //   state: { matchPlayerId }
      // })
      setPlayerId(matchPlayerId)
    }
  }

  const next = () => {
    const nextIndex = index === team.length - 1 ? 0 : index + 1
    const matchPlayerId = team[nextIndex]?._id
    if (location?.state?.playerStat) {
      props.history.replace({
        pathname: `/view-playerstat-info/${match.params.sportsType.toLowerCase()}/${matchID}/${matchPlayerId}`,
        search: `?${qs.stringify({
            sortBy: obj?.sortBy || undefined,
            sort: obj?.sort || undefined,
            playerStat: true || undefined
          })}`,
        state: { playerStat: true }
      })
    } else if (location?.state?.teamCreationPage) {
      props.history.replace({
        pathname: `/create-team/view-player-info/${match.params.sportsType.toLowerCase()}/${matchID}/${matchPlayerId}`,
        search: `?${qs.stringify({
          homePage: obj?.homePage ? 'yes' : undefined,
          playerLeagueInfo: obj?.playerLeagueInfo ? 'y' : undefined,
          firstTime: true
          })}`,
        state: { teamCreationPage: true, matchPlayerId }
      })
    } else {
      // props.history.replace({
      //   pathname: `/team-preview/${match.params.sportsType.toLowerCase()}/${matchID}/${userTeamID}/${matchPlayerId}`,
      //   search: `?${qs.stringify({
      //       homePage: obj?.homePage ? 'yes' : undefined,
      //       playerLeagueInfo: obj?.playerLeagueInfo ? 'y' : undefined
      //     })}`,
      //   state: { matchPlayerId }
      // })
      setPlayerId(matchPlayerId)
    }
  }

  return (
    <>
      {loading && <Loading series Lines={7}/>}
      <div className="s-team-bg" onClick={() => onBackClick()}></div>
      <div className="filter-card player-info p-league-info pb-0">
        {/* <div className="league-header u-header">
          <div className="d-flex align-items-center header-i">
            <button onClick={() => {
              onBackClick()
            }}
            className="btn-link icon-left-arrow"></button>
            <Button className='button-link bg-transparent py-2' tag={Link} to='/home/cricket'><img src={HomeImage}></img></Button>
            <div>
              <h1 className="text-uppercase">{(team && team[index]?.sName) || playerData?.sName}</h1>
              <p>{team && team[index]?.oMatch?.sName}</p>
              <p>{(team && team[index]?.sTeamName) || playerData?.sTeamName}{', '}{playerData?.eRole}</p>
            </div>
          </div>
        </div> */}
        <div className='card-header d-flex align-items-center justify-content-between m-0'>
          <h2><FormattedMessage id="League_playerStats" /></h2>
          <button onClick={() => onBackClick()} ><img src={CloseIcon}></img></button>
        </div>
        <div className='popup-content'>
          <div className="container-inside">
            <div className='player-info-card'>
              <div className='player-detail'>
                <div className='player-img'>
                  <img className='h-100 v-100 fullBorderRadius' src={playerData?.sImage && url ? `${url}${playerData?.sImage}` : PlayerImage} alt={(team && team[index]?.sName) || playerData?.sName} />
                </div>
                <div className="player-data">
                  <h2>{(team && team[index]?.sName) || playerData?.sName}</h2>
                  <div className='player-stat'>
                    <div className='d-flex'>
                      <p><FormattedMessage id="Player_points" /> <b>{nScoredPoints}</b></p>
                      <p><FormattedMessage id="Player_credits" /> <b>{team && team[index]?.nFantasyCredit ? team[index].nFantasyCredit : playerData?.nFantasyCredit ? playerData?.nFantasyCredit : 0}</b></p>
                      <p><FormattedMessage id="Player_selectedBy" /> <b>{team && team[index]?.nSetBy ? team[index].nSetBy : playerData?.nSetBy ? playerData?.nSetBy : 0}<FormattedMessage id="Common_perc" /></b></p>
                    </div>
                    <div className='d-flex'>
                      <p><FormattedMessage id="Player_Captain_by" /> <b>{team && team[index]?.nCaptainBy ? team[index].nCaptainBy : playerData?.nCaptainBy ? playerData?.nCaptainBy : 0}<FormattedMessage id="Common_perc" /></b></p>
                      <p><FormattedMessage id="Player_Vice_Captain_by" /> <b>{team && team[index]?.nViceCaptainBy ? team[index].nViceCaptainBy : playerData?.nViceCaptainBy ? playerData?.nViceCaptainBy : 0}<FormattedMessage id="Common_perc" /></b></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='player-bats-info'>
                <div className='county-img mr-2'>
                  <img
                    src={
                      team && team[index]?.oTeam?.sImage
                        ? `${url}${team[index]?.oTeam?.sImage || playerData?.oTeam?.sImage}`
                        : matchDetails?.oHomeTeam?.sShortName === team[index]?.oTeam?.sShortName
                          ? HomeTeam
                          : AwayTeam
                      }
                    onError={(e) => handleImgError(e, (matchDetails?.oHomeTeam?.sShortName === team[index]?.oTeam?.sShortName ? HomeTeam : AwayTeam))}
                    alt={(team && team[index]?.oTeam?.sName) || playerData?.oTeam?.sName}
                  />
                </div>
                <span className='player-role'>{allSportsRolesSingular((team && team[index]?.eRole) || playerData?.eRole)}{team && team[index]
                  ? team && team[index].aPlayingStyle.map((item, index) => {
                    return (
                    <span key={index}>
                      , {item}
                    </span>
                    )
                  })
                  : playerData && playerData.aPlayingStyle.map((item, index) => {
                    return (
                        <span key={index}>
                          , {item}
                        </span>
                    )
                  })
                }</span>
              </div>
            </div>
            <Table className="player-d-t player-list price-table table-radius bg-white mt-3">
              <thead>
                <tr>
                  <td><FormattedMessage id="Player_event" /></td>
                  <td className='text-center'><FormattedMessage id="Player_action" /></td>
                  <td className='text-center'><FormattedMessage id="Player_points" /></td>
                </tr>
              </thead>
              <tbody>
                {
                  loading
                    ? <Loading series={true} Lines={7}/>
                    : pointBreakUp && pointBreakUp.length && pointBreakUp.length !== 0
                      ? pointBreakUp.map(points => {
                        return (
                          <tr key={points._id}>
                            <td>{points && points.sName && points.sName}</td>
                            <td>{points && points.nPoint && points.nScoredPoints ? parseFloat(Number((points.nScoredPoints / points.nPoint)).toFixed(2)) : !points.nPoint ? (points?.nScoredPoints !== 0 ? 'Yes' : 'No') : 0}</td>
                            <td className='text-center'>{points && points.nScoredPoints && points.nScoredPoints} </td>
                          </tr>
                        )
                      })
                      : <Fragment>
                        <tr>
                          <td colSpan='3'>
                            <FormattedMessage id="NO_PointBreakup_Avail" />
                          </td>
                        </tr>
                      </Fragment>}
                      <tr>
                        <td colSpan="2"><FormattedMessage id="Common_Total" /></td>
                        <td className='text-center'>{nScoredPoints}</td>
                      </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="np-bottom position-relative">
          <div className="d-flex align-items-center justify-content-between">
            <div className='custom-btn pl-2' disabled={index === 0}
              onClick={() => previous()}><img src={left} className='pr-2'></img></div>
            <div className='team-data'>{team && index + 1 + '/' + team?.length}</div>
            <div className='custom-btn pr-2 text-right' disabled={team?.length ? index === team.length - 1 : index === matchPlayer?.length - 1} onClick={() => next()}
            ><img src={right} className='pl-2' ></img></div>
          </div>
        </div>
      </div>
    </>
  )
}

PlayerInfo.propTypes = {
  onBackClick: PropTypes.func,
  playerScorePoints: PropTypes.func,
  playerData: PropTypes.shape({
    nFantasyCredit: PropTypes.number,
    nSetBy: PropTypes.number,
    nScoredPoints: PropTypes.number,
    nCaptainBy: PropTypes.number,
    nViceCaptainBy: PropTypes.number,
    eRole: PropTypes.string,
    sName: PropTypes.string,
    sImage: PropTypes.string,
    sTeamName: PropTypes.string,
    oTeam: PropTypes.object,
    aPlayingStyle: PropTypes.array
  }),
  match: PropTypes.object,
  history: PropTypes.object,
  pointBreakUp: PropTypes.array,
  nScoredPoints: PropTypes.number,
  loading: PropTypes.bool,
  token: PropTypes.string,
  matchPlayer: PropTypes.array,
  location: PropTypes.object,
  playerSeasonNames: PropTypes.func,
  userTeam: PropTypes.object,
  getUserTeam: PropTypes.func,
  getMatchPlayerList: PropTypes.func,
  teamList: PropTypes.array,
  teamPlayerList: PropTypes.object,
  matchDetails: PropTypes.object,
  playerRoles: PropTypes.object,
  seasonMatch: PropTypes.object,
  modalOpen: PropTypes.bool,
  pId: PropTypes.string,
  matchID: PropTypes.string,
  userTeamID: PropTypes.string
}
export default compose(TeamList, PlayerDetails)(PlayerInfo)
