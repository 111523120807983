import React, { useEffect, useState } from 'react'
import { Redirect, Switch, Route, useHistory, withRouter } from 'react-router-dom'
import qs from 'query-string'
import NotFound from '../component/NotFound'
import Maintenance from '../component/Maintenance'
import Home from '../views/Home/Home/index'
import Matches from '../views/Home/Matches/index'
import Leagues from '../views/Home/Leagues/index'
import CreateTeam from '../views/Home/CreateTeam/index'
import ScoreCard from '../views/Home/ScoreCard/index'
import LeaguesDetail from '../views/Home/LeaguesDetail'
import JoinContest from '../views/Home/JoinContest'
import CreateContest from '../views/Home/CreateContest'
import PointSystem from '../views/Cms/PointSystem'
import HowToPlay from '../views/Home/HowToPlay'
import LeaguesCompletedPage from '../views/Home/LeaguesCompleted/index'
import LeaguesDetailCompleted from '../views/Home/LeaguesDetailCompleted'
import TeamCompare from '../views/Home/TeamCompare/index'
import TeamPreview from '../views/Home/TeamPreview/index'
import MyTeamsPreview from '../views/Home/MyTeamsPreview/index'
import ViewPlayerLeagueInfo from '../views/Home/TeamPlayerLeagueInfo/index'
import ViewPlayerInfo from '../views/Home/TeamPlayerInfo/index'
import ShareContest from '../views/Home/ShareContest'

import PublicRoutes from './PublicRoutes'
import PrivateRoutes from './PrivateRoutes'
import PrizeBreakups from '../views/Home/CreateContest/PrizeBreakup'
import ScorecardIndex from '../views/Home/components/ScoreCard/index'
import { useDispatch, useSelector } from 'react-redux'
import { subscribePushToken } from '../redux/actions/auth'
import { getMatchDetails } from '../redux/actions/match'
import { userLeagueJoinCount } from '../redux/actions/league'
// import Loading from '../component/Loading'

const Routes = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [link, setLink] = useState('')
  const [pushToken, setPushToken] = useState('')
  const [sportType, setSportType] = useState('')
  // const [loading, setLoading] = useState(true)

  const token = useSelector(state => state.auth.token) || localStorage.getItem('Token')
  const matchDetails = useSelector(state => state.match.matchDetails)
  const countJoinedLeague = useSelector(state => state.league.joinedLeagueCount)
  // const userData = localStorage.getItem('userData')

  useEffect(() => {
    window.onJioGamesFantasyActions = (e) => {
      const link2 = e
      setLink(link2)
    }
    window.onSubscribePushToken = (e) => {
      setPushToken(e)
    }
  }, [])

  // useEffect(() => {
  //   if (token) {
  //     setLoading(true)
  //   }
  // }, [token])

  // useEffect(() => {
  //   if (userData) {
  //     setLoading(false)
  //   }
  // }, [userData])

  function onGetMatchDetails (ID) {
    if (ID && token) {
      dispatch(getMatchDetails(ID, '', token))
      // setLoading(true)
    }
  }

  function onGetLeagueCount (ID) {
    if (ID && token) {
      dispatch(userLeagueJoinCount(ID, '', token))
      // setLoading(true)
    }
  }

  useEffect(() => {
    if (countJoinedLeague && matchDetails && sportType) {
      if (matchDetails.eStatus === 'U') {
        history.push(`/leagues/${sportType}/${matchDetails?._id}?activeTab=1&homePage=yes&toHome=yes`)
      } else if (matchDetails.eStatus === 'L') {
        if (countJoinedLeague?.nMyContestCount > 0) {
          history.push(`/liveleague/${matchDetails?._id}/${sportType}?activeTab=1&homePage=yes&toHome=yes`)
        }
      } else if (matchDetails.eStatus === 'CMP') {
        if (countJoinedLeague?.nMyContestCount > 0) {
          history.push(`/completedleagues/participated/${matchDetails?._id}/${sportType}?activeTab=1&homePage=yes&toHome=yes`)
        }
      }
    }
  }, [countJoinedLeague, matchDetails])

  useEffect(() => {
    const obj = typeof link === 'string' ? qs.parse(link) : link

    if (Object.keys('obj').length > 0 && token) {
      if (obj?.code === '5501') {
        return history.push(`/my-teams-preview/${obj.spType}/${obj.mId}/${obj.tId}?toHome=yes&shareTeam=yes&refUrl=${obj.rCode}`)
      }
      if (obj?.code === '5502') {
        return history.push(`/join-contest/${obj.spType}/${obj.mId}?code=${obj.ccode}&toHome=yes`)
      }
      if (obj?.code === '5505') {
        onGetMatchDetails(obj.mId)
        onGetLeagueCount(obj.mId)
        setSportType(obj.spType)
        // return history.push(`/leagues/${obj.spType}/${obj.mId}?activeTab=1&homePage=yes&toHome=yes`)
      }
    }

    return () => {
      setLink('')
    }
  }, [link])

  useEffect(() => {
    if (pushToken && token) {
      dispatch(subscribePushToken(pushToken, token))
    }

    return () => {
      setPushToken('')
    }
  }, [pushToken])

  return (
    <>
    {/* {loading && <Loading />} */}
    <Switch>
        <PublicRoutes path="/" component={Home} exact />
        {/* Home module */}
        <PrivateRoutes path="/home/:sportsType" component={Home} exact />
        <PublicRoutes path="/home" component={Home} exact />
        <PrivateRoutes path="/matches/:sportsType" component={Matches} exact />
        <PrivateRoutes path="/leagues/:sportsType/:id" component={Leagues} exact />
        <PrivateRoutes path="/completedleagues/participated/:id/:sportsType" component={LeaguesCompletedPage} exact />
        <PrivateRoutes path="/liveleague/:id/:sportsType" component={LeaguesCompletedPage} exact />
        <PrivateRoutes path="/leagues-detail/:sportsType/:id/:id2" component={LeaguesDetail} exact />
        <PrivateRoutes path="/leagues-details/:sportsType/:id/:id2/participated" component={LeaguesDetailCompleted} exact />
        <PrivateRoutes path="/create-team/:sportsType/:id" component={CreateTeam} exact />
        <PrivateRoutes path="/create-team/:sportsType/:id/join/:joinLeague" component={CreateTeam} exact />
        <PrivateRoutes path="/create-team/:sportsType/:id/join/:joinLeague/private/:sShareCode" component={CreateTeam} exact />
        <PrivateRoutes path="/create-team/:sportsType/:id/:id2" component={CreateTeam} exact />
        <PrivateRoutes path="/copy-team/:sportsType/:id/:id2/:content" component={CreateTeam} exact />
        <Route path="/web-score-card/:id" component={ScorecardIndex} exact />
        <Route path="/score-card/:id" component={ScoreCard} exact />
        <PrivateRoutes path="/join-contest/:sportsType/:id" component={JoinContest} exact />
        <PrivateRoutes path="/join-contest" component={JoinContest} exact />
        <PrivateRoutes path="/create-contest/:sportsType/:id" component={CreateContest} exact />
        <PrivateRoutes path="/create-contest/:sportsType/:id/prize-breakups" component={PrizeBreakups} exact />
        <PrivateRoutes path="/create-contest/:sportsType/:id/:id2/invite" component={ShareContest} exact />
        <PrivateRoutes path="/team-compare/:sportsType/:id1/:id2" component={TeamCompare} exact />
        <PrivateRoutes path="/my-teams-preview/:sportsType/:matchID/:userTeamID" component={MyTeamsPreview} exact />
        <PrivateRoutes path="/dream-team-preview/:sportsType/:matchID" component={MyTeamsPreview} exact />
        <PrivateRoutes path="/create-team-preview/:sportsType/:matchID" component={MyTeamsPreview} exact />
        <PrivateRoutes path="/team-preview/:sportsType/:matchID/:userLeagueID/:userTeamID/:index" component={TeamPreview} exact />
        <PrivateRoutes path="/view-player-leagueInfo/:sportsType/:matchID/:userTeamID/:playerId" component={ViewPlayerLeagueInfo} exact />
        <PrivateRoutes path="/create-team/view-player-league-info/:sportsType/:matchID/:playerId" component={ViewPlayerLeagueInfo} exact />
        <PrivateRoutes path="/view-player-Info/:sportsType/:matchID/:userTeamID/:playerId" component={ViewPlayerInfo} exact />
        <PrivateRoutes path="/create-team/view-player-info/:sportsType/:matchID/:playerId" component={ViewPlayerInfo} exact />
        <PrivateRoutes path="/view-playerstat-info/:sportsType/:matchID/:playerId" component={ViewPlayerInfo} exact />
        {/* cms module */}
        <PrivateRoutes path="/point-system" component={PointSystem} exact />
        <PrivateRoutes path="/how-to-play/:sportsType" component={HowToPlay} exact />
        <PublicRoutes path="/point-system/v1" component={PointSystem} exact />
        <Route path="/maintenance-mode" component={Maintenance} exact />
        <Redirect path="/home" to="/home/cricket" exact/>
        <Route path="*" component={NotFound} />
    </Switch>
    </>
  )
}

export default withRouter(Routes)
