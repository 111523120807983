import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompareUserTeam } from '../../redux/actions/team'
import PropTypes from 'prop-types'

export const CompareTeam = (Component) => {
  const MyComponent = (props) => {
    const { FirstTeamId, SecondTeamId } = props
    const dispatch = useDispatch()
    const [url, setUrl] = useState('')
    const [myTeam, setMyTeam] = useState({})
    const [opponentTeam, setOpponentTeam] = useState({})
    const [team1, setTeam1] = useState({})
    const [team2, setTeam2] = useState({})
    const [team1ScorePoint, setTeam1ScorePoint] = useState(0)
    const [team2ScorePoint, setTeam2ScorePoint] = useState(0)
    const [teamA, setTeamA] = useState([])
    const [teamB, setTeamB] = useState([])
    const [loading, setLoading] = useState(false)
    const getUrlLink = useSelector(state => state.url.getUrl)
    const token = useSelector(state => state.auth.token)
    const userCompareTeam = useSelector(state => state.team.userCompareTeam)
    const previousProps = useRef({
      getUrlLink
    }).current
    useEffect(() => {
      // call 2 time apis for each teams
      if (FirstTeamId && SecondTeamId) {
        getUserCompareTeam(FirstTeamId)
        getUserCompareTeam(SecondTeamId)
      }
      if (getUrlLink && token) {
        setUrl(getUrlLink)
      }
    }, [token])

    useEffect(() => { // handle the response
      if (previousProps.userCompareTeam !== userCompareTeam) {
        if (userCompareTeam && userCompareTeam.iUserLeagueId === FirstTeamId) {
          setTeam1(userCompareTeam)
          setTeamA(userCompareTeam.aPlayers)
          let TotalScorePoints1 = 0
          userCompareTeam.aPlayers.map(team => {
            if (team && team.nScoredPoints) {
              if (team.iMatchPlayerId === userCompareTeam.iCaptainId) {
                const newPoints = (Number(team.nScoredPoints) * 2)
                TotalScorePoints1 = TotalScorePoints1 + newPoints
              } else if (team.iMatchPlayerId === userCompareTeam.iViceCaptainId) {
                const newPoints = (Number(team.nScoredPoints) * 1.5)
                TotalScorePoints1 = TotalScorePoints1 + newPoints
              } else { TotalScorePoints1 = TotalScorePoints1 + Number(team.nScoredPoints) }
            }
            return team
          })
          setTeam1ScorePoint(TotalScorePoints1)
        } else if (userCompareTeam && userCompareTeam.iUserLeagueId === SecondTeamId) {
          setTeam2(userCompareTeam)
          setTeamB(userCompareTeam.aPlayers)
          let TotalScorePoints2 = 0
          userCompareTeam.aPlayers.map(team => {
            if (team && team.nScoredPoints) {
              if (team.iMatchPlayerId === userCompareTeam.iCaptainId) {
                const newPoints = (Number(team.nScoredPoints) * 2)
                TotalScorePoints2 = TotalScorePoints2 + newPoints
              } else if (team.iMatchPlayerId === userCompareTeam.iViceCaptainId) {
                const newPoints = (Number(team.nScoredPoints) * 1.5)
                TotalScorePoints2 = TotalScorePoints2 + newPoints
              } else { TotalScorePoints2 = TotalScorePoints2 + Number(team.nScoredPoints) }
            }
            return team
          })
          setTeam2ScorePoint(TotalScorePoints2)
        }
      }
      return () => {
        previousProps.userCompareTeam = userCompareTeam
      }
    }, [userCompareTeam])

    useEffect(() => { // handle the response
      if (previousProps.getUrlLink !== getUrlLink) {
        if (getUrlLink && getUrlLink.length !== 0) {
          setUrl(getUrlLink)
        }
      }
      return () => {
        previousProps.getUrlLink = getUrlLink
      }
    }, [getUrlLink])

    useEffect(() => { // compareTeam with 2 time
      if (teamA && teamB && teamA.length !== 0 && teamB.length !== 0) {
        const SortingTeam1 = compareTeam(teamA, teamB, team1, team2)
        const SortingTeam2 = compareTeam(teamB, teamA, team2, team1)
        setMyTeam(SortingTeam1)
        setOpponentTeam(SortingTeam2)
        setLoading(false)
      }
    }, [teamA, teamB])

    function compareTeam (teamFirst, teamSecond, teamF, teamS) { // call function and set the data of all required fields
      const team = {
        nCapPoints: 0,
        nCommonPoints: 0,
        nDifferentPoints: 0,
        captain: {},
        viceCaptain: {},
        notCommon: [],
        common: []
      }
      teamFirst && teamFirst.length !== 0 && teamFirst.forEach(player => {
        if (teamF.iCaptainId === player.iMatchPlayerId) {
          team.captain = player
          team.nCapPoints += player.nScoredPoints * 2
        } else if (teamF.iViceCaptainId === player.iMatchPlayerId) {
          team.viceCaptain = player
          team.nCapPoints += player.nScoredPoints * 1.5
        } else if (teamSecond && teamSecond.length !== 0 && teamSecond.some(player2 => player.iMatchPlayerId === player2.iMatchPlayerId && player.iMatchPlayerId !== teamF.iViceCaptainId && player.iMatchPlayerId !== teamF.iCaptainId && player.iMatchPlayerId !== teamS.iViceCaptainId && player.iMatchPlayerId !== teamS.iCaptainId)) {
          team.common = [...team.common, player]
          team.nCommonPoints += player.nScoredPoints
        } else {
          team.notCommon = [...team.notCommon, player]
          team.nDifferentPoints += player.nScoredPoints
        }
      })
      return team
    }

    function getUserCompareTeam (userTeamId) {
      token && dispatch(getCompareUserTeam(userTeamId, token))
      setLoading(true)
    }

    return (
      <Component
        {...props}
        url={url}
        loading={loading}
        team1={team1}
        team2={team2}
        myTeam={myTeam}
        opponentTeam={opponentTeam}
        team1ScorePoint={team1ScorePoint}
        team2ScorePoint={team2ScorePoint}
      />
    )
  }
  MyComponent.propTypes = {
    match: PropTypes.object,
    FirstTeamId: PropTypes.string,
    SecondTeamId: PropTypes.string
  }
  MyComponent.displayName = MyComponent
  return MyComponent
}

export default CompareTeam
