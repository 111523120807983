import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { Card, CardFooter } from 'reactstrap'

function SkeletonLeague (props) {
  const { leagueDetails, length } = props
  return (
    <Fragment>{
      leagueDetails
        ? <Fragment>
        {Array(length)
          .fill()
          .map((index) => (
            <Card key={index} className='mt-1 ml-2 mr-2 leagues-card'>
              <div className="match-box" key={`div+${index}`}>
                <div className='d-flex justify-content-between'>
                  <Skeleton height={10} width={50} />
                  <Skeleton height={10} width={50} />
                </div>
                <div className='d-flex justify-content-between'>
                  <div>
                    <Skeleton height={20} width={20} circle={true} className="mr-1" />
                    <Skeleton height={20} width={50} />
                  </div>
                  <div className='d-flex align-items-center'>
                    <Skeleton height={20} width={30} className="mr-1" />
                    <Skeleton height={20} width={50} />
                  </div>
                </div>
                <div>
                  <Skeleton className='mt-3' height={10} width="100%" />
                </div>
                <div className='d-flex justify-content-between'>
                  <Skeleton height={8} width={50} />
                  <Skeleton height={8} width={50} />
                </div>
              </div>
              <CardFooter className='eagues-footer'>
                <div className='d-flex justify-content-between pl-3 pr-3'>
                  <div>
                    <Skeleton height={20} width={20} className='mr-2' />
                    <Skeleton height={20} width={20} className='mr-2' />
                    <Skeleton height={20} width={20} />
                  </div>
                  <Skeleton height={20} width={50} />
                </div>
              </CardFooter>
            </Card>
          ))}
      </Fragment>
        : <Fragment>
        {Array(3)
          .fill()
          .map((index) => (
            <Fragment key={index}>
              <Card className='mt-3 leagues-card justify-content-between'>
                <div>
                  <Skeleton className='mb-2 ml-2' circle={true} height={20} width={20} />
                  <Skeleton className='mb-2 ml-2 mt-2' height={20} width={300}/>
                </div>
              </Card>
              {
                Array(2)
                  .fill()
                  .map((index) => (
                    <Card key={index} className='mt-3 leagues-card'>
                      <div className="match-box" key={`div+${index}`}>
                        <div className='d-flex justify-content-between'>
                          <Skeleton height={10} width={50} />
                          <Skeleton height={10} width={50} />
                        </div>
                        <div className='d-flex justify-content-between'>
                          <div>
                            <Skeleton height={20} width={20} circle={true} className="mr-1" />
                            <Skeleton height={20} width={50} />
                          </div>
                          <div className='d-flex align-items-center'>
                            <Skeleton height={20} width={30} className="mr-1" />
                            <Skeleton height={20} width={50} />
                          </div>
                        </div>
                        <div>
                          <Skeleton className='mt-3' height={10} width="100%" />
                        </div>
                        <div className='d-flex justify-content-between'>
                          <Skeleton height={8} width={50} />
                          <Skeleton height={8} width={50} />
                        </div>
                      </div>
                      <CardFooter className='eagues-footer'>
                        <div className='d-flex justify-content-between pl-3 pr-3'>
                          <div>
                            <Skeleton height={20} width={20} className='mr-2' />
                            <Skeleton height={20} width={20} className='mr-2' />
                            <Skeleton height={20} width={20} />
                          </div>
                          <Skeleton height={20} width={50} />
                        </div>
                      </CardFooter>
                    </Card>
                  ))
              }
            </Fragment>
          ))}
      </Fragment>
      }
    </Fragment>
  )
}

SkeletonLeague.propTypes = {
  leagueDetails: PropTypes.bool,
  length: PropTypes.number
}

export default SkeletonLeague
