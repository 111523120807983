/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import { useQueryState } from 'react-router-use-location-state'
import LeagueHeader from '../components/LeagueHeader'
import { useSelector } from 'react-redux'
import LeaguesDetailCompletedPage from './LeaguesDetailCompleted'

function LeaguesDetailCompleted (props) {
  const { location, match } = props
  const [activeState, setActiveState] = useQueryState('activeState', '2')
  const [VideoStream, setVideoStream] = useState(false)
  const [activeTab, setActiveTab] = useState('2')
  const matchDetails = useSelector(state => state.match.matchDetails)
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }
  useEffect(() => {
    const obj = qs.parse(location.search)
    if (obj) {
      if (obj.activeState) {
        const active = obj.activeState
        setActiveState(active)
        toggle(active)
      }
    }
  }, [])

  return (
    <>
      <LeagueHeader completed={true}
        setVideoStream={setVideoStream}
        VideoStream={VideoStream}
        goToBack={location?.state?.backHome ? `/home/${match.params.sportsType}` : (matchDetails?.eStatus === 'L' || matchDetails?.eStatus === 'I') ? `/liveleague/${match.params.id}/${match.params.sportsType}` : (matchDetails?.eStatus === 'CMP' ? `/completedleagues/participated/${match.params.id}/${match.params.sportsType}` : '')}
        {...props}/>
      <LeaguesDetailCompletedPage {...props} activeTab={activeTab} toggle={toggle} setActiveState={setActiveState} leaguesInfo/>
    </>
  )
}

LeaguesDetailCompleted.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.object
  }),
  match: PropTypes.object
}

export default LeaguesDetailCompleted
