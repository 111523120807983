/* eslint-disable multiline-ternary */
import React, { useState, Fragment, useEffect, useRef } from 'react'
import { Button, Nav, NavItem, NavLink, TabContent, Table, TabPane, Card, CardBody, CardHeader, CardFooter, Alert } from 'reactstrap'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import Scorecard from '../components/Scorecard'
import { currencyConvert, handleImgError, isUpperCase, pageViewPost } from '../../../helper'
import PlayerStats from '../components/PlayerStats'
// import PlayerInfo from '../components/PlayerInfo'
import PlayerInfo2 from '../components/PlayerInfo2'
import PlayerImage from '../../../assests/images/profile.png'
// import TrophyImg from '../../../assests/images/trophy.svg'
// import Cash from '../../../assests/images/cash.svg'
// import Bonus from '../../../assests/images/Bonus.svg'
import NavPrize from '../../../assests/images/league-nav-prize-breakup.svg'
import NavPrizeDisabled from '../../../assests/images/league-nav-prize-breakup-disabled.svg'
import NavLeaderboard from '../../../assests/images/league-nav-leaderboard.svg'
import NavLeaderboardDisabled from '../../../assests/images/league-nav-leaderboard-disabled.svg'
import Voucher from '../../../assests/images/voucher.svg'
import crownUtils from '../../../assests/images/crown-utils.svg'
// import { getRandomColor, createImageFromInitials } from '../components/LetterImage'
import SkeletonTable from '../../../component/SkeletonTable'
import LeagueCompletedComponent from '../../../HOC/SportsLeagueList/LeagueCompleted'
import CloseIcon from '../../../assests/images/close-icon.svg'
import TeamCompare from '../TeamCompare/TeamCompare'
import Slider from '../components/Slider'

function LeaguesDetailCompletedPage (props) {
  const {
    matchDetails,
    firstTeam,
    secondTeam,
    addCompare,
    loading,
    // clearTeamSelected,
    selectedTeamCompare,
    // playerScorePoints,
    matchPlayerList,
    leagueDetails,
    activeTab,
    toggle,
    allLeaderBoardList,
    setLoading,
    myTeamsLeaderBoardList,
    myAllTeamPagination,
    uniquePlayerList,
    match,
    setActiveState,
    url,
    otherTeamJoinList,
    currencyLogo,
    bCached,
    bFullResponse,
    getMyTeamLeaderBoardListFunc,
    loadingScorecard,
    allTeams,
    setSelectedTeamCompare
  } = props
  const [players, setPlayers] = useState([])
  // const [teamView, setTeamView] = useState(0)
  const [value, setValue] = useState(0)
  const [playerId, setPlayerId] = useState('')
  const [userTeamId, setUserTeamId] = useState('')
  const [data, setData] = useState('')
  const [loadingJoined, setJoinedLeague] = useState(true)
  const [refresh, setRefresh] = useState(true)
  const [playerInfo, setPlayerInfo] = useState(false)
  const [downloadRecord, setDownloadRecord] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [selectedTeamCompareOn, setSelectedTeamCompareOn] = useState(false)
  const [AllLeaderBoardList, setAllLeaderBoardList] = React.useState([])
  const [allUserLeagues, setAllUserLeagues] = useState([])
  const [myTeams, setMyTeams] = React.useState([])
  const [limit] = React.useState(50)
  const [compareTeam, setCompareTeam] = useState(false)
  const myTeamIds = myTeams && myTeams.map(data => data._id)
  const previousProps = useRef({
    activeTab, allLeaderBoardList, playerInfo, AllLeaderBoardList
  }).current
  useEffect(() => { // handle the response
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      isUpperCase(sport) && props.history.push(`/leagues-details/${sport.toLowerCase()}/${match.params.id}/${match.params.id2}/participated`)
    }
    pageViewPost('cd')
    setAllLeaderBoardList([])
  }, [])

  useEffect(() => {
    setAllUserLeagues([])
    setAllLeaderBoardList([])
  }, [match.params.id])

  useEffect(() => {
    // if (previousProps.AllLeaderBoardList !== AllLeaderBoardList) {
    setAllUserLeagues([...myTeams.sort((a, b) => b.nTotalPoints - a.nTotalPoints), ...AllLeaderBoardList.sort((a, b) => b.nTotalPoints - a.nTotalPoints)])
    // }

    return () => {
      previousProps.AllLeaderBoardList = AllLeaderBoardList
    }
  }, [AllLeaderBoardList, myTeams, match.params.id])

  useEffect(() => { // handle the response
    if (previousProps.activeTab !== activeTab) {
      if (activeTab) {
        setValue(0)
        if (activeTab === '2') {
          setRefresh(true)
          setJoinedLeague(true)
        } else {
          setAllLeaderBoardList([])
          setRefresh(false)
        }
      }
    }
    return () => {
      previousProps.activeTab = activeTab
    }
  }, [activeTab])

  // useEffect(() => { // handle the response
  //   if (leagueDetails) {
  //     if (leagueDetails && leagueDetails.aLeaguePrize && leagueDetails.aLeaguePrize.length !== 0) {
  //       setLoading1(false)
  //     }
  //     let fPrize = ''
  //     if (leagueDetails && leagueDetails._id) {
  //       if (leagueDetails && leagueDetails.bPoolPrize && leagueDetails.nDeductPercent !== null && (!leagueDetails.bPrivateLeague)) {
  //         fPrize = Math.floor(Number(((leagueDetails.nPrice * leagueDetails.nJoined * 100) / (((leagueDetails && leagueDetails.nDeductPercent) || 0) + 100))))
  //         setPoolPrize(fPrize)
  //       }
  //     }
  //   }
  // }, [leagueDetails])

  function handleScroll (e) {
    const element = e.target
    if (((element.scrollHeight - element.scrollTop) <= (element.clientHeight + 150)) && activeTab === '2') {
      if (otherTeamJoinList && !(otherTeamJoinList.length < limit)) {
        setValue(value => value + limit)
      }
    }
  }
  useEffect(() => { // handle the response
    if (selectedTeamCompareOn && selectedTeamCompare && selectedTeamCompare.length === 0) {
      // props.history.push({ pathname: `/team-compare/${match && match.params && (match.params.sportsType).toLowerCase()}/${firstTeam._id}/${secondTeam._id}`, state: { path: match.url, firstRank: firstTeam.nRank, secondRank: secondTeam.nRank } })
      // setAllLeaderBoardList([])
      setSelectedTeamCompareOn(false)
    }
  }, [selectedTeamCompare])

  function openCompareTeam () {
    if (selectedTeamCompare && selectedTeamCompare.length === 2) {
      // props.history.push({ pathname: `/team-compare/${match && match.params && (match.params.sportsType).toLowerCase()}/${firstTeam._id}/${secondTeam._id}`, state: { path: match.url, firstRank: firstTeam.nRank, secondRank: secondTeam.nRank } })
      // setAllLeaderBoardList([])

      setCompareTeam(true)
    }
  }

  useEffect(() => { // handle the response
    if (previousProps.value !== value) {
      if (value) {
        myAllTeamPagination(limit, value)
        getMyTeamLeaderBoardListFunc()
      }
    }
    return () => {
      previousProps.value = value
    }
  }, [value, match.params.id])

  useEffect(() => { // handle the response
    if ((previousProps.allLeaderBoardList !== allLeaderBoardList) || (refresh && allLeaderBoardList?.length >= 1)) {
      if (allLeaderBoardList && allLeaderBoardList.length !== 0 && bFullResponse) {
        setAllLeaderBoardList(() => [...allLeaderBoardList])
        setJoinedLeague(false)
      } else if (allLeaderBoardList && allLeaderBoardList.length !== 0) {
        setAllLeaderBoardList(AllLeaderBoardList => [...AllLeaderBoardList, ...allLeaderBoardList])
        setJoinedLeague(false)
      }
    }
    return () => {
      previousProps.allLeaderBoardList = allLeaderBoardList
    }
  }, [allLeaderBoardList])

  useEffect(() => { // handle the response
    if (leagueDetails && leagueDetails.sFairPlay) {
      setData(leagueDetails.sFairPlay)
    }
  }, [leagueDetails])

  useEffect(() => { // handle the response
    if (myTeamsLeaderBoardList && myTeamsLeaderBoardList.length >= 0) {
      setMyTeams(myTeamsLeaderBoardList)
      setJoinedLeague(false)
    }
  }, [myTeamsLeaderBoardList, match.params.id])
  // const componentRef = useRef()
  // function errorImage () {
  //   componentRef.current.src = Cash
  // }

  return (
    <>
      {alert && alertMessage && alertMessage.length ? <Alert isOpen={alert} color="primary">{alertMessage}</Alert> : ''}
      {(matchDetails?.oHomeTeam?.nScore || matchDetails?.oAwayTeam?.nScore) &&
        <Scorecard loadingScorecard={loadingScorecard} matchDetails={matchDetails} url={url} matchSport={matchDetails?.eCategory} />
      }
        <div className="league-detail">
          <Nav className="league-details-live-tabs">
            <NavItem className="text-center">
              <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => {
                toggle('1')
                setActiveState('1')
              }} >
                {activeTab === '1' ? <img className='mr-2' src={NavPrize} /> : <img className='mr-2' src={NavPrizeDisabled} />}
                <FormattedMessage id="League_prizeBreakup" />
              </NavLink>
            </NavItem>
            <NavItem className="text-center">
              <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => {
                toggle('2')
                setActiveState('2')
              }} >
                {activeTab === '2' ? <img className='mr-2' src={NavLeaderboard} /> : <img className='mr-2' src={NavLeaderboardDisabled} />}
                <FormattedMessage id="League_leaderboard" />
              </NavLink>
            </NavItem>
            {/* <NavItem className="text-center">
              <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => {
                toggle('3')
                setActiveState('3')
              }} ><FormattedMessage id="League_playerStats" /></NavLink>
            </NavItem> */}
          </Nav>
          <div className={`league-container league-completed ${((AllLeaderBoardList && AllLeaderBoardList.length === 0 && myTeams && myTeams.length === 0 && activeTab === 2)) ? 'inCenterContain' : ''}`}>
            <TabContent activeTab={activeTab} >
              <TabPane tabId="1">
                <div className="container-inside mt-1">
                  {
                    leagueDetails && leagueDetails.aLeaguePrize &&
                      (
                      <Table className="m-0 player-list player-list2 price-table bg-white table-radius">
                        <thead>
                          <tr>
                            <td><FormattedMessage id="League_Rank" /></td>
                            <td className='text-right'><FormattedMessage id="League_Winnings" /></td>
                          </tr>
                        </thead>
                        <tbody>
                          {loading
                            ? <SkeletonTable Lines={7} />
                            : leagueDetails && leagueDetails.aLeaguePrize && leagueDetails.aLeaguePrize.sort((a, b) => a.nRankFrom > b.nRankFrom ? 1 : -1).map(data => {
                              return (
                                <tr key={data._id}>
                                  <td>
                                    <b><FormattedMessage id="Welcome_Hash" /> </b><span className='textt-black'>{data && data.nRankFrom && data.nRankTo && data.nRankFrom === data.nRankTo ? `${data.nRankFrom}` : `${data.nRankFrom} - ${data.nRankTo}`}</span>
                                  </td>
                                  <td className='text-right'>
                                    <div className="m-0 align-items-center d-flex  justify-content-end">
                                      {/* <div className='p-img inside-img'>
                                        <img src={data.eRankType === 'E' && data.sImage ? url + data.sImage : data.eRankType === 'R' ? Cash : Bonus} alt="" />
                                      </div> */}
                                      {
                                        data && data.eRankType === 'E'
                                          ? <span className='ml-2 textt-black d-flex'> {data && data.sInfo ? data.sInfo : ''} </span>
                                          : data.eRankType === 'V'
                                            ? <span className='ml-2 textt-black d-flex'> <img className='mr-2' src={Voucher} /> {data && data.sInfo ? data.sInfo : ''} </span>
                                            : <Fragment>
                                            <span className='ml-2 textt-black d-flex'>
                                            {/* {leagueDetails && leagueDetails.bPoolPrize && leagueDetails.nDeductPercent !== null && (!leagueDetails.bPrivateLeague)
                                                ? data && data.nPrize && `${parseFloat(Number(((data.nPrize * poolPrize) / 100)).toFixed(2))}`
                                                : (leagueDetails && leagueDetails.bPoolPrize && leagueDetails.bPrivateLeague)
                                                    ? `${parseFloat(Number(((data.nPrize * leagueDetails.nTotalPayout) / 100)).toFixed(2))}`
                                                    : data && data.nPrize
                                                      ? data.nPrize
                                                      : 0} */}
                                              {data && data.eRankType !== 'CW' ? currencyLogo : <img className='mr-1' src={crownUtils} />} {data && data.nPrize ? currencyConvert(data.nPrize) : 0}
                                            </span>
                                          </Fragment>
                                      }
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </Table>
                      )
                  }
                </div>
              </TabPane>
              <TabPane tabId="2">
                {alert && alertMessage && alertMessage.length ? <Alert isOpen={alert} color="primary">{alertMessage}</Alert> : ''}
                <div className="team-com d-flex align-items-center justify-content-between">
                  {!selectedTeamCompareOn
                    ? <p><FormattedMessage id="League_SelectButton" /></p>
                    : selectedTeamCompare?.length === 2
                      ? <p> <FormattedMessage id="Click_on_compare" /></p>
                      : selectedTeamCompare?.length
                        ? <p> <FormattedMessage id="Select_more_TeamCompare" /></p>
                        : <p> <FormattedMessage id="League_TeamCompare" /></p>
                  }
                  {selectedTeamCompare && selectedTeamCompare.length === 2
                    ? <button className="bg-transparent" onClick={openCompareTeam} >
                      <FormattedMessage id="League_Compare" />
                    </button>
                    : <button className="bg-transparent" onClick={() => setSelectedTeamCompareOn(true)} >
                      <FormattedMessage id="Team_Select" />
                    </button>
                  }
                </div>
                <div className="container-inside container-inside-3 pb-2" onScroll={handleScroll}>
                  <Table
                    className="bg-white
                        player-list
                        price-table
                        participated-playerlist
                        team-com-on
                        table-radius
                        "
                  >
                    {
                      loading || loadingJoined
                        ? (
                          <SkeletonTable Lines={7} />
                          )
                        : (
                          <Fragment>
                            <thead>
                              <tr>
                                <td><FormattedMessage id="Team_AllTeam" /> ({allTeams})</td>
                                <td className="player_points"><FormattedMessage id="Player_points" /></td>
                                <td><FormattedMessage id="League_Rank" /></td>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                allUserLeagues && allUserLeagues.length > 0 && allUserLeagues.map((team, index) => {
                                  let finalRank, i
                                  if (index >= 1 && bCached) {
                                    for (i = index; i >= 1; i--) {
                                      if (allUserLeagues[i - 1].nTotalPoints === team.nTotalPoints) {
                                        finalRank = allUserLeagues[i - 1].nRank
                                      }
                                    }
                                  }

                                  return (
                                    <tr
                                      // style={{ backgroundColor: myTeamIds.includes(team._id) && '#F0F0F0' }}
                                      className={selectedTeamCompare.includes(team._id) && selectedTeamCompareOn ? 'selected' : ''}
                                      onClick={() => {
                                        if (selectedTeamCompareOn) {
                                          addCompare(team)
                                        } else {
                                          props.history.push({
                                            pathname: `/team-preview/${(match?.params?.sportsType).toLowerCase()}/${match?.params?.id}/${match.params.id2}/${team.iUserTeamId}/${index}`,
                                            state: { allUserLeagues: allUserLeagues?.length }
                                          })
                                          setUserTeamId(team.iUserTeamId)
                                          setLoading(true)
                                        }
                                      }
                                      }
                                      key={index}>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <div className="l-img">
                                            <img src={team && team.sProPic ? url + team.sProPic : PlayerImage} onError={(e) => handleImgError(e, PlayerImage)} alt="" />
                                            {
                                              selectedTeamCompare.includes(team._id) && selectedTeamCompareOn &&
                                              <i className="icon-verified i-check text-success"></i>
                                            }
                                          </div>
                                          <div>
                                            <span className="p-name">{team && team.sUserName + ` (${team.sTeamName})`}</span>
                                            {
                                                matchDetails && (matchDetails.eStatus === 'CMP') &&
                                                  (
                                                    <Fragment>
                                                      {
                                                        team && !team.nPrice > 0 && team.nCrownWin > 0 && team && !team?.nBonusWin > 0 && team && team.aExtraWin && team.aExtraWin.length === 0
                                                          ? (<p className="won-txt"> {myTeamIds.includes(team._id) ? <FormattedMessage id="Team_You_Won" /> : <FormattedMessage id="Team_Won" />}<img src={crownUtils} /> {parseFloat(Number(team.nCrownWin))} </p>)
                                                          : team && team.nPrice > 0 && !team.nCrownWin > 0 && team && !team?.nBonusWin > 0 && team && team.aExtraWin && team.aExtraWin.length === 0
                                                            ? (<p className="won-txt"> {myTeamIds.includes(team._id) ? <FormattedMessage id="Team_You_Won" /> : <FormattedMessage id="Team_Won" />}{currencyLogo} {parseFloat(Number(team.nPrice).toFixed(2))} </p>)
                                                            : team && !team.nPrice > 0 && !team.nCrownWin > 0 && team && team?.nBonusWin > 0 && team && team.aExtraWin && team.aExtraWin.length === 0
                                                              ? (<p className="won-txt"> {myTeamIds.includes(team._id) ? <FormattedMessage id="Team_You_Won" /> : <FormattedMessage id="Team_Won" />}{currencyLogo} {parseFloat(Number(team.nBonusWin).toFixed(2))} <FormattedMessage id="League_bonus" /> </p>)
                                                              : team && !team.nPrice > 0 && !team.nCrownWin > 0 && team && !team?.nBonusWin > 0 && team && team.aExtraWin && team.aExtraWin.length >= 1
                                                                ? (<p className="won-txt"> {myTeamIds.includes(team._id) ? <FormattedMessage id="Team_You_Won" /> : <FormattedMessage id="Team_Won" />}{team.aExtraWin[0]?.eExtraWinType === 'V' && <img src={Voucher} />} {team.aExtraWin[0]?.sInfo} </p>)
                                                                : team && !team.nPrice > 0 && !team.nCrownWin > 0 && team && !team?.nBonusWin > 0 && team && team.aExtraWin && team.aExtraWin.length === 0
                                                                  ? ''
                                                                  : (
                                                                  <Fragment>
                                                                    {/* <Tooltip placement="top" isOpen={tooltipOpen} target="TooltipExample" toggle={toggleToolTip}>
                                                                      {
                                                                        team && team.nPrice > 0 && (<span><FormattedMessage id="Team_Won" /> {currencyLogo} {parseFloat(Number(team.nPrice).toFixed(2))}</span>)
                                                                      }
                                                                      {
                                                                        team && team.nBonusWin > 0 && (<Fragment>
                                                                          <br />
                                                                          <span><FormattedMessage id="Team_Won" /> {currencyLogo} {parseFloat(Number(team.nBonusWin).toFixed(2))} <FormattedMessage id="League_bonus" /></span>
                                                                        </Fragment>
                                                                        )
                                                                      }
                                                                      {
                                                                        team && team.aExtraWin && team.aExtraWin.length >= 1 && (
                                                                          <Fragment>
                                                                            <br />
                                                                            <span><FormattedMessage id="Team_Won" /> {
                                                                              team.aExtraWin.map((data, index) => { return (`${data.sInfo}${(index > 1 || team.aExtraWin.length < index) ? ', ' : ''}`) })
                                                                            }</span>
                                                                          </Fragment>
                                                                        )
                                                                      }
                                                                    </Tooltip> */}
                                                                    <p className="won-txt toolPTag" id="TooltipExample"> <i className="icon-trophy"></i><FormattedMessage id="WON_Multiple_Prize" /> </p>
                                                                    <p className='toolShaw'>
                                                                      {
                                                                        team && team.nPrice > 0 && (<span><FormattedMessage id="Team_Won" /> {currencyLogo} {parseFloat(Number(team.nPrice).toFixed(2))}</span>)
                                                                      }
                                                                      {
                                                                        team && team.nBonusWin > 0 && (<Fragment>
                                                                          <br />
                                                                          <span><FormattedMessage id="Team_Won" /> {currencyLogo} {parseFloat(Number(team.nBonusWin).toFixed(2))} <FormattedMessage id="League_bonus" /></span>
                                                                        </Fragment>
                                                                        )
                                                                      }
                                                                      {
                                                                        team && team.aExtraWin && team.aExtraWin.length >= 1 && (
                                                                          <Fragment>
                                                                            <br />
                                                                            <span><FormattedMessage id="Team_Won" /> {
                                                                              team.aExtraWin.map((data, index) => { return (`${data.eRankType === 'V' && <img src={Voucher} />} ${data.sInfo}${(index > 1 || team.aExtraWin.length < index) ? ', ' : ''}`) })
                                                                            }</span>
                                                                          </Fragment>
                                                                        )
                                                                      }
                                                                    </p>
                                                                  </Fragment>
                                                                    )
                                                      }
                                                    </Fragment>
                                                  )
                                            }
                                          </div>
                                        </div>
                                      </td>
                                      <td>{team && team.nTotalPoints ? team.nTotalPoints : '0'}</td>
                                      <td>{(finalRank && `#${finalRank}`) || (team && team.nRank ? `#${team.nRank}` : '#0')}</td>
                                    </tr>
                                  )
                                })
                              }
                              {
                                // AllLeaderBoardList && AllLeaderBoardList.length > 0 && AllLeaderBoardList.map((allTeam, index) => {
                                //   let finalRank, i
                                //   if (index >= 1 && bCached && AllLeaderBoardList[index - 1].nTotalPoints === allTeam.nTotalPoints) {
                                //     for (i = index; i >= 1; i--) {
                                //       if (AllLeaderBoardList[i - 1].nTotalPoints === allTeam.nTotalPoints) {
                                //         finalRank = AllLeaderBoardList[i - 1].nRank
                                //       }
                                //     }
                                //   }
                                //   return (
                                //     <tr
                                //       className={selectedTeamCompare.includes(allTeam._id) && selectedTeamCompareOn ? 'selected' : ''}
                                //       onClick={() => {
                                //         if (selectedTeamCompareOn) {
                                //           addCompare(allTeam)
                                //         } else {
                                //           setPreviewCricket(true)
                                //           setUserTeamId(allTeam.iUserTeamId)
                                //           setSelectTeam([allTeam])
                                //           setLoading(true)
                                //         }
                                //       }
                                //       }
                                //       key={index}>
                                //       <td>
                                //         <div className="d-flex align-items-center">
                                //           <div className="p-img">
                                //             <img src={allTeam && allTeam.sProPic ? url + allTeam.sProPic : createImageFromInitials(500, allTeam.sUserName, getRandomColor())} alt="" />
                                //             {
                                //               selectedTeamCompare.includes(allTeam._id) && selectedTeamCompareOn &&
                                //               <i className="icon-verified i-check text-success"></i>
                                //             }
                                //           </div>
                                //           <div>
                                //             <span className="p-name">{allTeam && allTeam.sUserName + ` (${allTeam.sTeamName})`}</span>
                                //             {
                                //               matchDetails && (matchDetails.eStatus === 'CMP') && (
                                //                 <Fragment>
                                //                   {
                                //                     allTeam && allTeam.nPrice > 0 && allTeam && !allTeam?.nBonusWin > 0 && allTeam && allTeam.aExtraWin && allTeam.aExtraWin.length === 0
                                //                       ? (<p className="won-txt"> <i className="icon-trophy"></i><FormattedMessage id="Team_Won" /> {currencyLogo} {parseFloat(Number(allTeam.nPrice).toFixed(2))} </p>)
                                //                       : allTeam && !allTeam.nPrice > 0 && allTeam && allTeam?.nBonusWin > 0 && allTeam && allTeam.aExtraWin && allTeam.aExtraWin.length === 0
                                //                         ? (<p className="won-txt"> <i className="icon-trophy"></i><FormattedMessage id="Team_Won" /> {currencyLogo} {parseFloat(Number(allTeam.nBonusWin).toFixed(2))} <FormattedMessage id="League_bonus" /> </p>)
                                //                         : allTeam && !allTeam.nPrice > 0 && allTeam && !allTeam?.nBonusWin > 0 && allTeam && allTeam.aExtraWin && allTeam.aExtraWin.length === 1
                                //                           ? (<p className="won-txt"> <i className="icon-trophy"></i><FormattedMessage id="Team_Won" /> {allTeam.aExtraWin[0].sInfo} </p>)
                                //                           : allTeam && !allTeam.nPrice > 0 && allTeam && !allTeam?.nBonusWin > 0 && allTeam && allTeam.aExtraWin && allTeam.aExtraWin.length === 0
                                //                             ? ''
                                //                             : (
                                //                             <Fragment>
                                //                               {/* <Tooltip placement="top" isOpen={tooltipOpen2} target="TooltipExampleAllTeam" toggle={toggleToolTip2}>
                                //                                 {
                                //                                   allTeam && allTeam.nPrice > 0 && (<span><FormattedMessage id="Team_Won" /> {currencyLogo} {parseFloat(Number(allTeam.nPrice).toFixed(2))}</span>)
                                //                                 }
                                //                                 {
                                //                                   allTeam && allTeam.nBonusWin > 0 && (
                                //                                   <Fragment>
                                //                                     <br /><span><FormattedMessage id="Team_Won" /> {currencyLogo} {parseFloat(Number(allTeam.nBonusWin).toFixed(2))} <FormattedMessage id="League_bonus" /></span>
                                //                                   </Fragment>)
                                //                                 }
                                //                                 {
                                //                                   allTeam && allTeam.aExtraWin && allTeam.aExtraWin.length >= 1 && (
                                //                                     <Fragment>
                                //                                       <br />
                                //                                       <span><FormattedMessage id="Team_Won" /> {
                                //                                         allTeam.aExtraWin.map((data, index) => { return (`${data.sInfo}${(index > 1 || allTeam.aExtraWin.length < index) ? ', ' : ''}`) })
                                //                                       }</span>
                                //                                     </Fragment>
                                //                                   )
                                //                                 }
                                //                               </Tooltip> */}
                                //                               <p className="won-txt toolPTag" id='TooltipExampleAllTeam'><i className="icon-trophy"></i><FormattedMessage id="WON_Multiple_Prize" /> </p>
                                //                               <p className='toolShaw'>
                                //                                 {
                                //                                   allTeam && allTeam.nPrice > 0 && (<span><FormattedMessage id="Team_Won" /> {currencyLogo} {parseFloat(Number(allTeam.nPrice).toFixed(2))}</span>)
                                //                                 }
                                //                                 {
                                //                                   allTeam && allTeam.nBonusWin > 0 && (
                                //                                   <Fragment>
                                //                                     <br /><span><FormattedMessage id="Team_Won" /> {currencyLogo} {parseFloat(Number(allTeam.nBonusWin).toFixed(2))} <FormattedMessage id="League_bonus" /></span>
                                //                                   </Fragment>)
                                //                                 }
                                //                                 {
                                //                                   allTeam && allTeam.aExtraWin && allTeam.aExtraWin.length >= 1 && (
                                //                                     <Fragment>
                                //                                       <br />
                                //                                       <span><FormattedMessage id="Team_Won" /> {
                                //                                         allTeam.aExtraWin.map((data, index2) => { return (`${data.sInfo}${(index2 >= 1 && allTeam.aExtraWin.length !== index2 + 1) ? ', ' : ' '}`) })
                                //                                       }</span>
                                //                                     </Fragment>
                                //                                   )
                                //                                 }
                                //                               </p>
                                //                             </Fragment>
                                //                               )
                                //                   }
                                //                 </Fragment>
                                //               )
                                //             }
                                //           </div>
                                //         </div>
                                //       </td>
                                //       <td>{allTeam && allTeam.nTotalPoints ? allTeam.nTotalPoints : '0'}</td>
                                //       <td>{(finalRank && `#${finalRank}`) || (allTeam && allTeam.nRank ? `#${allTeam.nRank}` : '-')}</td>
                                //     </tr>
                                //   )
                                // })
                              }
                              {allUserLeagues && allUserLeagues.length === 0 && (
                                  <tr>
                                    <td colSpan='3'>
                                      <center>
                                        <div className="no-team d-flex align-items-center justify-content-center">
                                          <div className='marLeft50'>
                                            <i className="icon-trophy"></i>
                                            <h6 className="m-3"><FormattedMessage id="Contast_NoPlayer" /></h6>
                                          </div>
                                        </div>
                                      </center>
                                    </td>
                                  </tr>
                              )}
                            </tbody>
                          </Fragment>
                          )
                    }
                  </Table>
                </div>
                <Slider />
                {downloadRecord
                  ? <Card className={`filter-card select-team ${downloadRecord ? 'show' : ''}`}>
                    <CardHeader className='d-flex align-items-center justify-content-between'>
                      <h1><FormattedMessage id="Match_DownloadTeams" /></h1>
                    </CardHeader>
                    <CardBody>
                      <ul className='m-0 d-flex align-item-center flex-wrap'>
                      </ul>
                      <h3>
                        <FormattedMessage id="Match_TeamLocked" />
                      </h3>
                    </CardBody>

                    <CardFooter className='two-button border-0 bg-white m-0 d-flex justify-content-between'>
                      <Button type="submit" color='border'
                        onClick={() => {
                          if (data) {
                            setDownloadRecord(false)
                            window.open(`${url}${data}`)
                          } else {
                            setDownloadRecord(false)
                            setAlert(true)
                            setAlertMessage('Fair Play is not available')
                            setTimeout(() => {
                              setAlert(false)
                            }, 2000)
                          }
                        }}
                      >
                        <i className="icon-down-arrow mr-2" />
                        <FormattedMessage id="Match_Download" />
                      </Button>
                      <Button type="submit" color='primary' onClick={() => setDownloadRecord(false)}><FormattedMessage id="Match_Cancel" /></Button>
                    </CardFooter>
                  </Card>
                  : ''
                }
                {/* {
                  selectedTeamCompareOn && (
                    <div className="selected-t">
                      <p className="st-title"><FormattedMessage id="League_selectedTeams" /></p>
                      <div className="d-flex align-items-center">
                        {
                          ((firstTeam && firstTeam.sTeamName) || (secondTeam && secondTeam.sTeamName)) && (
                            <Fragment>
                              <div className="team-box d-flex align-items-center">
                                <div className="img">
                                  <img src={firstTeam && firstTeam.sImage ? firstTeam.sImage : firstTeam.sUserName ? createImageFromInitials(500, firstTeam.sUserName, getRandomColor()) : PlayerImage} alt="" />
                                  <button className="bg-transparent icon-remove"></button>
                                </div>
                                <div>
                                  {
                                    firstTeam && firstTeam.sUserName && firstTeam.sTeamName && (
                                      <Fragment>
                                        <p>{firstTeam && firstTeam.sUserName} ({firstTeam && firstTeam.sTeamName})</p>
                                        <p><FormattedMessage id="Welcome_Hash" />{firstTeam && firstTeam.nRank ? firstTeam.nRank : ''}</p>
                                      </Fragment>
                                    )
                                  }
                                </div>
                              </div>
                              <div className="vs"><FormattedMessage id="Match_VS" /></div>
                              <div className="team-box d-flex align-items-center justify-content-end">
                                <div>
                                  {
                                    secondTeam && secondTeam.sUserName && secondTeam.sTeamName && (
                                      <Fragment>
                                        <p>{secondTeam && secondTeam.sUserName} ({secondTeam && secondTeam.sTeamName})</p>
                                        <p><FormattedMessage id="Welcome_Hash" />{secondTeam && secondTeam.nRank ? secondTeam.nRank : ''}</p>
                                      </Fragment>
                                    )
                                  }
                                </div>
                                <div className="img">
                                  <img src={secondTeam && secondTeam.sImage ? secondTeam.sImage : secondTeam.sUserName ? createImageFromInitials(500, secondTeam.sUserName, getRandomColor()) : PlayerImage} alt='' />
                                  <button className="bg-transparent icon-remove"></button>
                                </div>
                              </div>
                            </Fragment>
                          )
                        }
                      </div>
                      <div className="st-bottom d-flex align-items-center">
                        <Button color="gray fullWidth" onClick={() => {
                          setSelectedTeamCompareOn(false)
                          clearTeamSelected()
                        }}><FormattedMessage id="League_Clear" /></Button>
                      </div>
                    </div>
                  )
                } */}
                {compareTeam &&
                  <>
                    <div className="s-team-bg" onClick={() => {
                      setCompareTeam(false)
                      setSelectedTeamCompareOn(false)
                      setSelectedTeamCompare([])
                    }}>
                    </div>
                      <Card className="filter-card filter-teams">
                        <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                          <h2><FormattedMessage id="Compare_Teams" /></h2>
                          <button onClick={() => {
                            setCompareTeam(false)
                            setSelectedTeamCompareOn(false)
                            setSelectedTeamCompare([])
                          }} ><img src={CloseIcon}></img></button>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className='popup-content'>
                            <TeamCompare sportsType={match && match.params && (match.params.sportsType).toLowerCase()} FirstTeamId={firstTeam._id} firstTeam={firstTeam} SecondTeamId={secondTeam._id} secondTeam={secondTeam} />
                          </div>
                        </CardBody>
                      </Card>
                      </>
                      }
              </TabPane>
              <TabPane tabId="3">
                <PlayerStats {...props} completed onPlayerInfoClick={(id) => {
                  // playerScorePoints(id)
                  setPlayerId(id)
                  setPlayerInfo(true)
                }} matchPlayerList={matchPlayerList}
                  setPlayers={setPlayers}
                  matchDetails={matchDetails}
                  uniqueList={uniquePlayerList} />
              </TabPane>
            </TabContent>
          </div>
        </div>
        {playerInfo
          ? <PlayerInfo2
          {...props}
          userTeamId={userTeamId}
          tempPlayers={players}
          onBackClick={() => {
            setPlayerInfo(false)
          }}
          matchPlayerList={matchPlayerList}
          url={url} pId={playerId} setPlayerId={setPlayerId} />
          : ''}
    </>
  )
}

LeaguesDetailCompletedPage.propTypes = {
  matchDetails: PropTypes.shape({
    id: PropTypes.string,
    eStatus: PropTypes.string,
    sportsType: PropTypes.string,
    bScorecardShow: PropTypes.bool,
    eCategory: PropTypes.string,
    oHomeTeam: PropTypes.object,
    oAwayTeam: PropTypes.object
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      id2: PropTypes.string,
      sportsType: PropTypes.string
    }),
    url: PropTypes.string
  }),
  history: PropTypes.object,
  teamList: PropTypes.array,
  joinedLeagueList: PropTypes.array,
  matchPlayerList: PropTypes.array,
  leagueDetails: PropTypes.object,
  activeTab: PropTypes.string,
  toggle: PropTypes.func,
  selectedTeamCompare: PropTypes.array,
  firstTeam: PropTypes.object,
  secondTeam: PropTypes.object,
  setActiveState: PropTypes.object,
  addCompare: PropTypes.func,
  currencyLogo: PropTypes.string,
  clearTeamSelected: PropTypes.func,
  myAllTeamPagination: PropTypes.func,
  allLeaderBoardList: PropTypes.array,
  myTeamsLeaderBoardList: PropTypes.array,
  uniquePlayerList: PropTypes.array,
  otherTeamJoinList: PropTypes.array,
  loadingDetails: PropTypes.bool,
  loading: PropTypes.bool,
  bCached: PropTypes.bool,
  bFullResponse: PropTypes.bool,
  url: PropTypes.string,
  token: PropTypes.string,
  setLoading: PropTypes.bool,
  getMyTeamLeaderBoardListFunc: PropTypes.func,
  loadingScorecard: PropTypes.bool,
  allTeams: PropTypes.number,
  setSelectedTeamCompare: PropTypes.func
}

export default LeagueCompletedComponent(LeaguesDetailCompletedPage)
