// ActionTypes

export const SEND_OTP = 'SEND_OTP'
export const VERIFY_OTP = 'VERIFY_OTP'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const REGISTER_USER = 'REGISTER_USER'
export const CHECK_EXIST = 'CHECK_EXIST'
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT = 'LOGOUT'
export const CREATE_CONTEST = 'CREATE_CONTEST'
export const CREATE_JOIN_CONTEST = 'CREATE_JOIN_CONTEST'
export const JOIN_CONTEST = 'JOIN_CONTEST'
export const VERIFY_CODE = 'VERIFY_CODE'
export const FEE_CALCULATE = 'FEE_CALCULATE'
export const GET_FULL_SCORED = 'GET_FULL_SCORED'
export const CLEAR_SCORECARD_MESSAGE = 'CLEAR_SCORECARD_MESSAGE'
export const GET_FETCH_LIVE_INNING = 'GET_FETCH_LIVE_INNING'
export const CLEAR_GET_FULL_SCORED = 'CLEAR_GET_FULL_SCORED'
export const CLEAR_GET_FETCH_LIVE_INNING = 'CLEAR_GET_FETCH_LIVE_INNING'
export const CLEAR_FEE_CALCULATE = 'CLEAR_FEE_CALCULATE'
export const GENERATE_PRIZE_BREAKUP = 'GENERATE_PRIZE_BREAKUP'
export const ALL_LEADERBOARD_LIST = 'ALL_LEADERBOARD_LIST'
export const MY_TEAMS_LEADERBOARD_LIST = 'MY_TEAMS_LEADERBOARD_LIST'
export const MATCH_LEAGUE_LIST = 'MATCH_LEAGUE_LIST'
export const MATCH_LEAGUE_DETAILS = 'MATCH_LEAGUE_DETAILS'
export const JOIN_LEAGUE = 'JOIN_LEAGUE'
export const JOIN_LEAGUE_LIST = 'JOIN_LEAGUE_LIST'
export const MATCH_LIST = 'MATCH_LIST'
export const MATCH_DETAILS = 'MATCH_DETAILS'
export const MY_UPCOMING_MATCH_LIST = 'MY_UPCOMING_MATCH_LIST'
export const MY_LIVE_MATCH_LIST = 'MY_LIVE_MATCH_LIST'
export const MY_COMPLETED_MATCH_LIST = 'MY_COMPLETED_MATCH_LIST'
export const GET_HOME_BANNER = 'GET_HOME_BANNER'
export const MORE_LIST = 'MORE_LIST'
export const CONTEST_SLUG_DETAILS = 'CONTEST_SLUG_DETAILS'
export const COMPLAINT_LIST = 'COMPLAINT_LIST'
export const COMPLAINT_DETAILS = 'COMPLAINT_DETAILS'
export const CLEAR_COMPLAINT_LIST = 'CLEAR_COMPLAINT_LIST'
export const CLEAR_COMPLAINT_DETAILS = 'CLEAR_COMPLAINT_DETAILS'
export const CLEAR_ADD_COMPLAINT = 'CLEAR_ADD_COMPLAINT'
export const ADD_COMPLAINT = 'ADD_COMPLAINT'
export const GET_SCORE_POINTS = 'GET_SCORE_POINTS'
export const GET_OFFER_LIST = 'GET_OFFER_LIST'
export const GET_ADS_LIST = 'GET_ADS_LIST'
export const GET_NOTIFICATION = 'GET_NOTIFICATION'
export const NOTIFICATION_COUNT = 'NOTIFICATION_COUNT'
export const GET_PLAYER_SCORE_POINT = 'GET_PLAYER_SCORE_POINT'
export const GET_PLAYER_SEASON_NAME = 'GET_PLAYER_SEASON_NAME'
export const GET_LIST_MATCH_PLAYER = 'GET_LIST_MATCH_PLAYER'
export const GET_AUTO_PICK_LIST_PLAYER = 'GET_AUTO_PICK_LIST_PLAYER'
export const CLEAR_AUTO_PICK_LIST_PLAYER = 'CLEAR_AUTO_PICK_LIST_PLAYER'
export const ERROR_AUTO_PICK_LIST_PLAYER = 'ERROR_AUTO_PICK_LIST_PLAYER'
export const CLEAR_GET_LIST_MATCH_PLAYER = 'CLEAR_GET_LIST_MATCH_PLAYER'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const KYC_DETAIL = 'KYC_DETAIL'
export const ADD_KYC = 'ADD_KYC'
export const UPDATE_KYC = 'UPDATE_KYC'
export const BANK_DETAIL = 'BANK_DETAIL'
export const ADD_BANK_DETAIL = 'ADD_BANK_DETAIL'
export const UPDATE_BANK_DETAIL = 'UPDATE_BANK_DETAIL'
export const WITHDRAW_LIMIT = 'WITHDRAW_LIMIT'
export const CLEAR_WITHDRAW_MESSAGE = 'CLEAR_WITHDRAW_MESSAGE'
export const ADD_DEPOSIT = 'ADD_DEPOSIT'
export const ADD_WITHDRAW = 'ADD_WITHDRAW'
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const GET_PROMO_CODE = 'GET_PROMO_CODE'
export const APPLY_PROMO_CODE = 'APPLY_PROMO_CODE'
export const GET_PAYMENT_OPTIONS = 'GET_PAYMENT_OPTIONS'
export const GET_PREFERENCE_DETAILS = 'GET_PREFERENCE_DETAILS'
export const APPLY_MATCH_PROMO_CODE = 'APPLY_MATCH_PROMO_CODE'
export const CLEAR_APPLY_PROMOCODE = 'CLEAR_APPLY_PROMOCODE'
export const GET_BALANCE_DETAILS = 'GET_BALANCE_DETAILS'
export const UPDATE_PREFERENCE_DETAILS = 'UPDATE_PREFERENCE_DETAILS'
export const GET_MATCH_PROMO_CODE = 'GET_MATCH_PROMO_CODE'
export const CLEAR_GET_MATCH_PROMO_CODE = 'CLEAR_GET_MATCH_PROMO_CODE'
export const CLEAR_PROFILE_MESSAGE = 'CLEAR_PROFILE_MESSAGE'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const GET_CURRENCY = 'GET_CURRENCY'
export const GET_BACKGROUND = 'GET_BACKGROUND'
export const CLEAR_BACKGROUND_MESSAGE = 'CLEAR_BACKGROUND_MESSAGE'
export const CLEAR_CURRENCY_MESSAGE = 'CLEAR_CURRENCY_MESSAGE'
export const CLEAR_TEAM_LIST = 'CLEAR_TEAM_LIST'
export const CLEAR_CONTEST_LIST = 'CLEAR_CONTEST_LIST'
export const CLEAR_CONTEST_JOIN_LIST = 'CLEAR_CONTEST_JOIN_LIST'
export const CLEAR_USER_TEAM = 'CLEAR_USER_TEAM'
export const TEAM_LIST = 'TEAM_LIST'
export const USER_TEAM = 'USER_TEAM'
export const GET_UNIQUE_PLAYER = 'GET_UNIQUE_PLAYER'
export const GET_UNIQUE_PLAYER_LEAGUE = 'GET_UNIQUE_PLAYER_LEAGUE'
export const CONTEST_LIST = 'CONTEST_LIST'
export const CONTEST_JOIN_LIST = 'CONTEST_JOIN_LIST'
export const TEAM_PLAYER_LIST = 'TEAM_PLAYER_LIST'
export const SWITCH_USER_TEAM = 'SWITCH_USER_TEAM'
export const CREATE_TEAM = 'CREATE_TEAM'
export const CLEAR_CREATE_TEAM = 'CLEAR_CREATE_TEAM'
export const EDIT_TEAM = 'EDIT_TEAM'
export const CLEAR_TEAM_PLAYER_LIST = 'CLEAR_TEAM_PLAYER_LIST'
export const CLEAR_TEAM_MESSAGE = 'CLEAR_TEAM_MESSAGE'
export const CLEAR_EDIT_TEAM = 'CLEAR_EDIT_TEAM'
export const TRANSACTION_LIST = 'TRANSACTION_LIST'
export const GET_URL = 'GET_URL'
export const GET_KYC_URL = 'GET_KYC_URL'
export const CLEAR_AUTH_MESSAGE = 'CLEAR_AUTH_MESSAGE'
export const CLEAR_VERIFY_OTP_MESSAGE = 'CLEAR_VERIFY_OTP_MESSAGE'
export const CLEAR_CHECKED_MESSAGE = 'CLEAR_CHECKED_MESSAGE'
export const CLEAR_MESSAGE_TYPE = 'CLEAR_MESSAGE_TYPE'
export const CLEAR_SEND_OTP_MESSAGE = 'CLEAR_SEND_OTP_MESSAGE'
export const GOOGLE_LOGIN_USER = 'GOOGLE_LOGIN_USER'
export const FIREBASE_TOKEN = 'FIREBASE_TOKEN'
export const CLEAR_CONTEST_MESSAGE = 'CLEAR_CONTEST_MESSAGE'
export const TOKEN_LOGIN = 'TOKEN_LOGIN'
export const CLEAR_ALL_LEADERBOARD_LIST = 'CLEAR_ALL_LEADERBOARD_LIST'
export const CLEAR_LEADERBOARD_MESSAGE = 'CLEAR_LEADERBOARD_MESSAGE'
export const CLEAR_MY_TEAMS_LEADERBOARD_MESSAGE = 'CLEAR_MY_TEAMS_LEADERBOARD_MESSAGE'
export const SERIES_LEADERBOARD_LIST = 'SERIES_LEADERBOARD_LIST'
export const GET_SERIES_CATEGORY = 'GET_SERIES_CATEGORY'
export const LEADERSHIP_BOARD_LIST = 'LEADERSHIP_BOARD_LIST'
export const GET_LEADERBOARD_ALL_RANK = 'GET_LEADERBOARD_ALL_RANK'
export const GET_LEADERBOARD_MY_RANK = 'GET_LEADERBOARD_MY_RANK'
export const CLEAR_LEAGUE_MESSAGE = 'CLEAR_LEAGUE_MESSAGE'
export const USER_COMPARE_TEAM = 'USER_COMPARE_TEAM'
export const CLEAR_MATCH_MESSAGE = 'CLEAR_MATCH_MESSAGE'
export const CLEAR_MATCH_LIST = 'CLEAR_MATCH_LIST'
export const GET_BANNER_STATICS = 'GET_BANNER_STATICS'
export const GET_ACTIVE_SPORTS = 'GET_ACTIVE_SPORTS'
export const CLEAR_CONTEST_SLUG_MESSAGE = 'CLEAR_CONTEST_SLUG_MESSAGE'
export const MATCH_TIPS_DETAILS = 'MATCH_TIPS_DETAILS'
export const CLEAR_PRIVATE_LEAGUE_VALIDATION = 'CLEAR_PRIVATE_LEAGUE_VALIDATION'
export const PRIVATE_LEAGUE_VALIDATION = 'PRIVATE_LEAGUE_VALIDATION'
export const CLEAR_MORE_LIST = 'CLEAR_MORE_LIST'
export const CLEAR_OFFER_LIST = 'CLEAR_OFFER_LIST'
export const CLEAR_SCORE_POINTS = 'CLEAR_SCORE_POINTS'
export const CLEAR_SCORE_POINT = 'CLEAR_SCORE_POINT'
export const CLEAR_NOTIFICATION_MESSAGE = 'CLEAR_NOTIFICATION_MESSAGE'
export const NOTIFICATION_TYPE_LIST = 'NOTIFICATION_TYPE_LIST'
export const CLEAR_PLAYER_MESSAGE = 'CLEAR_PLAYER_MESSAGE'
export const CLEAR_TRANSACTION_MESSAGE = 'CLEAR_TRANSACTION_MESSAGE'
export const CLEAR_TRANSACTION_LIST = 'CLEAR_TRANSACTION_LIST'
export const CANCEL_WITHDRAW = 'CANCEL_WITHDRAW'
export const CLEAR_SEASON_NAME = 'CLEAR_SEASON_NAME'
export const CLEAR_JOIN_LEAGUE_MESSAGE = 'CLEAR_JOIN_LEAGUE_MESSAGE'
export const CLEAR_CALCUALTE_FEE = 'CLEAR_CALCUALTE_FEE'
export const CLEAR_CONTEST = 'CLEAR_CONTEST'
export const RESET_CONTEST = 'RESET_CONTEST'
export const CLEAR_JOIN_CONTEST = 'CLEAR_JOIN_CONTEST'
export const GET_LEADERBOARD_CATEGORY_DETAILS = 'GET_LEADERBOARD_CATEGORY_DETAILS'
export const CLEAR_DEPOSIT_MESSAGE = 'CLEAR_DEPOSIT_MESSAGE'
export const CLEAR_ADD_WITHDRAW = 'CLEAR_ADD_WITHDRAW'
export const CLEAR_ADD_KYC = 'CLEAR_ADD_KYC'
export const CLEAR_UPDATE_KYC = 'CLEAR_UPDATE_KYC'
export const CLEAR_JOIN_LEAGUE_LIST = 'CLEAR_JOIN_LEAGUE_LIST'
export const CLEAR_GET_SERIES_CATEGORY = 'CLEAR_GET_SERIES_CATEGORY'
export const CLEAR_SERIES_LEADERBOARD_LIST = 'CLEAR_SERIES_LEADERBOARD_LIST'
export const CREATE_PAYMENT = 'CREATE_PAYMENT'
export const CLEAR_PAYMENT = 'CLEAR_PAYMENT'
export const ADD_CASHFREE = 'ADD_CASHFREE'
export const CLEAR_ADD_CASHFREE = 'CLEAR_ADD_CASHFREE'
export const CLEAR_DEPOSIT_VALIDATION = 'CLEAR_DEPOSIT_VALIDATION'
export const DEPOSIT_VALIDATION = 'DEPOSIT_VALIDATION'
export const GET_STREAM_BUTTON = 'GET_STREAM_BUTTON'
export const CLEAR_GET_STREAM_BUTTON = 'CLEAR_GET_STREAM_BUTTON'
export const CLEAR_VERIFY_CONTEST = 'CLEAR_VERIFY_CONTEST'
export const CITY_LIST = 'CITY_LIST'
export const STATE_LIST = 'STATE_LIST'
export const GET_LIVE_STREAM_LIST = 'GET_LIVE_STREAM_LIST'
export const CLEAR_GET_LIVE_STREAM_LIST = 'CLEAR_GET_LIVE_STREAM_LIST'
export const CLEAR_PAYMENT_OPTION = 'CLEAR_PAYMENT_OPTION'
export const PAYMENT_OPTION = 'PAYMENT_OPTION'
export const CLEAR_PROFILE_STATISTICS = 'CLEAR_PROFILE_STATISTICS'
export const GET_USER_STATISTICS = 'GET_USER_STATISTICS'
export const CLEAR_JOIN_DETAILS = 'CLEAR_JOIN_DETAILS'
export const JOIN_DETAILS = 'JOIN_DETAILS'
export const CLEAR_MAINTENANCE_MODE = 'CLEAR_MAINTENANCE_MODE'
export const MAINTENANCE_MODE = 'MAINTENANCE_MODE'
export const BANK_LIST = 'BANK_LIST'
export const CLEAR_BANK_LIST = 'CLEAR_BANK_LIST'
export const GET_DISCLAIMER = 'GET_DISCLAIMER'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_REASONS = 'DELETE_ACCOUNT_REASONS'
export const SEND_KYC_OTP = 'SEND_KYC_OTP'
export const PENDING_DEPOSITS = 'PENDING_DEPOSITS'
export const CLEAR_PENDING_DEPOSIT_LIST = 'CLEAR_PENDING_DEPOSIT_LIST'
export const CURRENT_REFER_RULE = 'CURRENT_REFER_RULE'
export const USERS_REFERRAL_LIST = 'USERS_REFERRAL_LIST'
export const REMIND_REFER_USER = 'REMIND_REFER_USER'
export const GET_SCORECARD = 'GET_SCORECARD'
export const CLEAR_SCORE_CARD = 'CLEAR_SCORE_CARD'
export const SESSION_ID = 'SESSION_ID'
export const MY_UPCOMING_LIST = 'MY_UPCOMING_LIST'
export const DREAM_TEAM = 'DREAM_TEAM'
export const SHARE_TEAM = 'SHARE_TEAM'
export const CLEAR_SHARE_TEAM = 'CLEAR_SHARE_TEAM'
export const GET_SHARE_TEAM = 'GET_SHARE_TEAM'
export const SUBSCRIBE_PUSH_TOKEN = 'SUBSCRIBE_PUSH_TOKEN'
export const MY_RECENT_MATCH_LIST = 'MY_RECENT_MATCH_LIST'
export const JOIN_LEAGUE_COUNT = 'JOIN_LEAGUE_COUNT'
