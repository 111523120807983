import React, { Fragment, useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Lottie from 'react-lottie'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Carousel, CarouselIndicators, CarouselItem } from 'reactstrap'
import { getAdsConfig, getAdsUniqueId, sliderLink } from '../../../helper'
import CloseIcon from '../../../assests/images/close-icon.svg'
import timesUp from '../../../assests/animations/times-up.json'
import { useDispatch, useSelector } from 'react-redux'
import { getBannerStatics, GetHomeBanner, getMatchDetails } from '../../../redux/actions/match'
import { getMatchLeagueDetails, userLeagueJoinCount } from '../../../redux/actions/league'
import { useHistory, useParams } from 'react-router-dom'

function Slider (props) {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const [url, setUrl] = useState('')
  const [bannerImg, setBannerImg] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [matchData, setMatchData] = useState({})
  const [currBannerImg, setCurrBannerImg] = useState({})
  const [currSlider, setCurrSlider] = useState({})
  const [sliderModal, setSliderModal] = useState(false)
  const [timeUpModal, setTimeUpModal] = useState(false)
  const [count, setCount] = useState(0)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')

  const bannerData = useSelector(state => state.match.bannerData)
  const matchDetails = useSelector(state => state.match.matchDetails)
  const matchLeagueDetails = useSelector(state => state.league.matchLeagueDetails)
  const countJoinedLeague = useSelector(state => state.league.joinedLeagueCount)
  const token = useSelector(state => state.auth.token)
  const getUrlLink = useSelector(state => state.url.getUrl)

  const { adspotkeyBillBoard, dataSource } = getAdsConfig()

  useEffect(() => {
    if (token) { // call initialize
      if (history.location.pathname.includes('/matches/')) {
        dispatch(GetHomeBanner('MM', params?.sportsType))
      } else if (history.location.pathname.includes('/leagues/')) {
        dispatch(GetHomeBanner('MD', params?.sportsType))
      } else if (history.location.pathname.includes('/leagues-details/') || history.location.pathname.includes('/leagues-detail/')) {
        dispatch(GetHomeBanner('CD', params?.sportsType))
      } else {
        dispatch(GetHomeBanner('H', params?.sportsType))
      }
    }
    if (getUrlLink) {
      setUrl(getUrlLink)
    }
  }, [token])

  useEffect(() => {
    if (countJoinedLeague) {
      setCount(countJoinedLeague?.nMyContestCount)
    }
  }, [countJoinedLeague])

  useEffect(() => { // Handle response
    if (previousProps.getUrlLink !== getUrlLink) {
      if (getUrlLink) {
        setUrl(getUrlLink)
      }
    }
    return () => {
      previousProps.getUrlLink = getUrlLink
    }
  }, [getUrlLink])

  useEffect(() => { // handle the response
    if (previousProps.matchDetails !== matchDetails) {
      if (Object.keys(currSlider).length > 0) {
        if (currSlider.bShowDescription) {
          setSliderModal(true)
        } else {
          sliderRedirect()
        }
      }
    }
    return () => {
      previousProps.matchDetails = matchDetails
    }
  }, [matchDetails])

  useEffect(() => {
    if (previousProps.bannerData !== bannerData) {
      if (bannerData) { // Handle the response
        const items = []
        if (bannerData.length > 0) {
          bannerData.sort((a, b) => a.nPosition - b.nPosition).map((data) => {
            items.push({ src: data.sImage, eCategory: data.eCategory, eScreen: data.eScreen, iMatchId: data.iMatchId, iMatchLeagueId: data.iMatchLeagueId, key: data._id, sLink: data.sLink, eType: data.eType, sDescription: data.sDescription, bShowDescription: data.bShowDescription })
            return items
          })
          // eslint-disable-next-line react/prop-types
          if (props?.sliderCount?.constructor === Function) {
          // eslint-disable-next-line react/prop-types
            props.sliderCount(items.length)
          }
        }
        setBannerImg(items)
      }
    }
    return () => {
      previousProps.bannerData = bannerData
    }
  }, [bannerData])

  useEffect(() => {
    if (alertMessage) {
      setTimeout(() => {
        setAlertMessage(false)
      }, 2000)
    }
  }, [alertMessage])

  function onGetLeagueDetails (ID) {
    if (ID && token) {
      dispatch(getMatchLeagueDetails(ID, token))
      // setLoading(true)
    }
  }

  function onGetMatchDetails (ID) {
    if (ID && token) {
      dispatch(getMatchDetails(ID, '', token))
      // setLoading(true)
    }
  }

  function onGetLeagueCount (ID) {
    if (ID && token) {
      dispatch(userLeagueJoinCount(ID, '', token))
      // setLoading(true)
    }
  }

  function onBannerStatictics (ID) {
    if (ID && token) {
      dispatch(getBannerStatics(ID, token))
      // setLoading(true)
    }
  }

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === bannerImg.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? bannerImg.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  async function imageShawing (data) { // redirect to the click on banner
    setMatchData(data)
    if (data && data.eType === 'L') {
      if (data.sLink.includes('/play.jiogames.com/')) {
        sliderLink(data.sLink)
      } else {
        const newWindow = window.open(data.sLink, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
      }
    }
    if (data && data.eType === 'S' && data.eScreen === 'D') {
      history.push('/deposit')
    }
    if (data && data.eType === 'S' && data.eScreen === 'S') {
      history.push({ pathname: '/page', state: { contentDetails: data?.sDescription, image: `${url}${data?.src}` } })
    }
    if (data && data.eType === 'S' && data.eScreen === 'CR') {
      if (data && data.iMatchId && data.iMatchLeagueId) {
        await onGetLeagueDetails(data && data.iMatchLeagueId)
        await onGetMatchDetails(data && data.iMatchId)
        await onGetLeagueCount(data && data.iMatchId)
      } else if (data && data.iMatchId) {
        await onGetMatchDetails(data && data.iMatchId)
        await onGetLeagueCount(data && data.iMatchId)
      }
      setCurrSlider(data)
    }
    data && data.key && onBannerStatictics(data && data.key)
  }

  function sliderRedirect () {
    if (matchDetails && matchDetails._id) {
      if (matchDetails.eStatus === 'U') {
        if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
          history.push({ pathname: `/leagues-detail/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}`, state: { backHome: true } })
        } else if (matchData && matchData.iMatchId) {
          history.push({ pathname: `/leagues/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}`, state: { backHome: true } })
        }
      } else if (matchDetails.eStatus === 'CMP') {
        if (count > 0) {
          if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
            history.push({ pathname: `/leagues-details/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}/participated`, state: { backHome: true } })
          } else if (matchData && matchData.iMatchId) {
            history.push({ pathname: `/completedleagues/participated/${matchData.iMatchId}/${(matchData.eCategory).toLowerCase()}`, state: { backHome: true } })
          }
          setSliderModal(false)
        } else {
          setAlert(true)
          setAlertMessage(<FormattedMessage id='Slider_Live_Message' />)
        }
      } else if (matchDetails.eStatus === 'L' || matchDetails.eStatus === 'I') {
        if (count > 0) {
          if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
            history.push({ pathname: `/leagues-details/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}/participated`, state: { backHome: true } })
          } else if (matchData && matchData.iMatchId) {
            history.push({ pathname: `/liveleague/${matchData.iMatchId}/${(matchData.eCategory).toLowerCase()}`, state: { backHome: true } })
          }
          setSliderModal(false)
        } else {
          setAlert(true)
          setAlertMessage(<FormattedMessage id='Slider_Live_Message' />)
        }
      } else if (matchDetails.eStatus === 'CNCL') {
        setAlert(true)
        setAlertMessage(<FormattedMessage id='Slider_Live_Message' />)
      }
    } else if (matchDetails && matchDetails._id && (matchDetails.eStatus === 'L' || matchDetails.eStatus === 'CMP' || matchDetails.eStatus === 'I') && history.location.pathname.includes('/home/')) {
      setSliderModal(false)
      setTimeUpModal(true)
    }

    // else if (matchDetails && matchDetails._id && matchDetails.eStatus === 'L') {
    //   if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchData.eTransactionType !== 'Play-Return' && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
    //     history.push({ pathname: `/leagues-details/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}/participated`, state: { backHome: true } })
    //   } else if (matchData && matchData.iMatchId) {
    //     history.push({ pathname: `/liveleague/${matchData.iMatchId}/${(matchData.eCategory).toLowerCase()}`, state: { backHome: true } })
    //   }
    // } else if (matchDetails && matchDetails._id && (matchDetails.eStatus === 'CMP' || matchDetails.eStatus === 'I')) {
    //   if (matchData && matchData.iMatchId && matchData.iMatchLeagueId && matchData.eTransactionType !== 'Play-Return' && matchLeagueDetails && !matchLeagueDetails.bCancelled) {
    //     history.push({ pathname: `/leagues-details/${(matchData.eCategory).toLowerCase()}/${matchData.iMatchId}/${matchData.iMatchLeagueId}/participated`, state: { backHome: true } })
    //   } else if (matchData && matchData.iMatchId) {
    //     history.push({ pathname: `/completedleagues/participated/${matchData.iMatchId}/${(matchData.eCategory).toLowerCase()}`, state: { backHome: true } })
    //   }
    // }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: timesUp,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const slides = bannerImg.map((item) => {
    return (
      <CarouselItem
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        {
        item.eType === 'JGAD'
          ? <ins
          id={getAdsUniqueId()}
          data-adspot-key={adspotkeyBillBoard}
          data-source={dataSource}
          data-ad-sizes="320x50,300x50"
        ></ins>
          : <img src={item?.src ? `${url}${item?.src}` : ''} key={`${item.key}`} alt={'banner'} onClick={() => {
            imageShawing(item)
            setCurrBannerImg(item)
          }} />}
      </CarouselItem>
    )
  })

  const previousProps = useRef({
    matchDetails
  }).current

  return (
    <Fragment>
      {alert && alertMessage ? <Alert isOpen={alert} className='select-all' color="primary">{alertMessage}</Alert> : ''}
      {bannerImg && bannerImg.length > 0 &&
        (
          <div>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              className='home-banner'
            >
              <CarouselIndicators items={bannerImg.sort((a, b) => a.nPosition - b.nPosition)} activeIndex={activeIndex} onClickHandler={goToIndex} />
              {slides}
            </Carousel>
          </div>
        )
      }
      {sliderModal && <>
        <div className="s-team-bg" onClick={() => setSliderModal(false)} ></div>
        <Card className="filter-card">
          <CardHeader className='d-flex align-items-center justify-content-between'>
            <h2><FormattedMessage id='Create_a_league' /></h2>
            <button onClick={() => { setSliderModal(false) }} ><img src={CloseIcon}></img></button>
          </CardHeader>
          <CardBody className='popup-content'>
            <div className="create-league-popup">
              <div className='create-league-banner'>
                <img src={`${url}${currBannerImg.src}`} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: currBannerImg && currBannerImg.sDescription }}></div>

            </div>
          </CardBody>
          <CardFooter className='btn-bottom border-0 m-0 position-relative'>
            <Button color='primary' onClick={() => sliderRedirect()} className='w-100'>Got it</Button>
          </CardFooter>
        </Card>
      </>}
      {timeUpModal
        ? <>
          <div className="s-team-bg"></div>
          <div className="alertpopup-wrap">
            <div className="alertpopup">
              <h3>Time up! Contest has started</h3>
              <p className="mb-2">Don’t worry, you can participate in contests from upcoming matches!</p>
              <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
              <Button className="" color='primary' onClick={() => setTimeUpModal(false)}>View Upcoming Matches</Button>
            </div>
          </div>
        </>
        : ''}
    </Fragment>
  )
}

export default Slider
