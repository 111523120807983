/* eslint-disable linebreak-style */
import {
  CHANGE_LANGUAGE,
  GET_CURRENCY,
  GET_BACKGROUND,
  CLEAR_BACKGROUND_MESSAGE,
  MAINTENANCE_MODE,
  CLEAR_MAINTENANCE_MODE
} from '../constants'
import storage from '../../localStorage/localStorage'

let language = storage.getItem('language')
if (!language) {
  language = 'en-US'
}
export default (state = { language: language }, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload.language
      }
    case GET_CURRENCY:
      return {
        ...state,
        currencyLogo: action.payload.currencyLogo
      }
    case GET_BACKGROUND:
      return {
        ...state,
        backgroundImage: action.payload.sImage,
        backgroundCoverImage: action.payload.sBackImage
        // currencyLogo: null
      }
    case CLEAR_BACKGROUND_MESSAGE:
      return {
        ...state,
        backgroundImage: null,
        backgroundCoverImage: null
      }
    case CLEAR_MAINTENANCE_MODE:
      return {
        ...state,
        maintenanceMode: null
      }
    case MAINTENANCE_MODE:
      return {
        ...state,
        maintenanceMode: action.payload.data
      }
    default:
      return state
  }
}
