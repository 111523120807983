import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Button } from 'reactstrap'

const AutoPick = (props) => {
  const { showHeader = false, showButton = false, showSmallButton = false, disableBtn = false, onClickBtn = () => {} } = props
  return (
    <div className='auto-pick-container w-100'>
      {showHeader && <div className="m-3 d-flex flex-col font-bold auto-pick-container-message">
        <FormattedMessage id="AUTO_PICK_GURU_MESSAGE_1" />
        <FormattedMessage id="AUTO_PICK_GURU_MESSAGE_2" />
      </div>}

       {showButton && <Button disabled={disableBtn} onClick={onClickBtn} className="btn btn-orange-lg">
          <FormattedMessage id="AUTO_PICK" />
        </Button>}
       {showSmallButton && <Button disabled={disableBtn} onClick={onClickBtn} className="btn btn-primary-theme-orange w-100">
          <FormattedMessage id="AUTO_PICK" />
        </Button>}
    </div>
  )
}

AutoPick.propTypes = {
  showHeader: PropTypes.bool,
  showButton: PropTypes.bool,
  showSmallButton: PropTypes.bool,
  disableBtn: PropTypes.bool,
  onClickBtn: PropTypes.func
}

export default AutoPick
