/* eslint-disable promise/param-names */
import React, { useEffect, useState, useRef, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import CommonInviteFriend from '../../../HOC/SportsLeagueList/CommonInviteFriend'
// import {
//   LinkedinShareButton, LinkedinIcon, WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon, FacebookMessengerShareButton, FacebookMessengerIcon, TwitterShareButton, TwitterIcon,
//   EmailIcon, EmailShareButton, TelegramShareButton, TelegramIcon
// } from 'react-share'
import { Alert, Button, Card } from 'reactstrap'
// import config from '../../../config'
import { isUpperCase, shareContestPost } from '../../../helper'
import Loading from '../../../component/Loading'
// import share from '../../../assests/images/shareWhite.svg'
import CreateContestLeague from '../components/CreateContestLeague'
import SuccessIcon from '../../../assests/images/success-icon.svg'
import moment from 'moment'
const Match = lazy(() => import('../components/Match'))
const _ = require('lodash')

function ShareContestPage (props) {
  const { loading, location, match, getDetails, getLeague, token } = props
  const [matchDetails, setMatchDetails] = useState({})
  const [matchLeagueDetails, setMatchLeagueDetails] = useState({})
  const [redirectData, setRedirectData] = useState({})
  const matchList = useSelector(state => state.match.matchList)
  const matchLeagueDetail = useSelector(state => state.league.matchLeagueDetails)
  const amountData = useSelector(state => state.contest.joincontestDetails)
  const [copied, setCopied] = useState(false)
  // const [modalMessage, setModalMessage] = useState(false)
  // const [message, setMessage] = useState('')
  const myRef = useRef()
  const previousProps = useRef({ matchList, matchLeagueDetail, amountData }).current

  useEffect(() => {
    if (location && location.state && location.state.matchDetails && location.state.matchLeagueDetails) {
      setMatchDetails(location.state.matchDetails)
      setMatchLeagueDetails(location.state.matchLeagueDetails)
      getLeague(location.state.matchLeagueDetails._id)
    } else {
      getDetails(match && match.params && match.params.id2, 'Cricket')
    }
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      isUpperCase(sport) && props.history.push(`/create-contest/${sport.toLowerCase()}/${match.params.id}/${match.params.id2}/invite`)
    }
  }, [token])

  useEffect(() => { // handle the response
    if (previousProps.matchList !== matchList) {
      setMatchDetails(matchList)
      if (matchList && matchList.length > 0 && match.params && match.params.id) {
        const matchData = matchList.filter(data => data._id === match.params.id)
        if (matchData && matchData.length > 0) {
          setMatchDetails(matchData[0])
        }
      }
    }
    return () => {
      previousProps.matchList = matchList
    }
  }, [matchList])

  useEffect(() => {
    if (redirectData.nAmount) {
      props.history.push({
        pathname: '/deposit',
        state: {
          amountData: redirectData,
          message: 'Insufficient Balance'
          // joinData: {
          //   userTeams: data.userTeams, verifiedId: data.id, finalPromocode: data.finalPromocode
          // }
        }
      })
    }
  }, [redirectData])

  useEffect(() => { // handle the response
    if (previousProps.amountData !== amountData) {
      if (amountData && amountData?.oValue?.nAmount > 0) {
        if (redirectData.nAmount !== amountData.oValue.nAmount) {
          setRedirectData(amountData?.oValue)
        } else {
          setRedirectData({})
        }
      }
    }
    return () => {
      previousProps.amountData = amountData
    }
  }, [amountData])

  useEffect(() => {
    if (previousProps.matchLeagueDetail !== matchLeagueDetail) {
      setMatchLeagueDetails(matchLeagueDetail)
    }
    return () => {
      previousProps.matchLeagueDetail = matchLeagueDetail
    }
  }, [matchLeagueDetail])

  function copyToClipboard (textToCopy) {
    if (matchLeagueDetails && matchLeagueDetails.sShareCode) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(textToCopy).then(() => {
          setCopied(true)
          setTimeout(() => setCopied(false), 2000)
        })
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = textToCopy
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        return new Promise(function (res, rej) {
          if (document.execCommand('copy')) {
            setCopied(true)
            setTimeout(() => setCopied(false), 2000)
          }
          textArea.remove()
        })
      }
    }
  }

  function shareContestClick () {
    const eFee = matchLeagueDetails?.ePayoutType === 'R' ? matchLeagueDetails?.nPrice : matchLeagueDetails?.nCrownUtil
    const startDate = moment(matchDetails?.dStartDate).format('X')

    shareContestPost(matchDetails?._id, matchLeagueDetails?.sShareCode, match?.params?.sportsType, matchLeagueDetails?.sName, eFee, matchLeagueDetails?.nMax, startDate, matchDetails?.oHomeTeam?.sShortName, matchDetails?.oAwayTeam?.sShortName)
  }

  // async function handleShare ({ text, url }) {
  //   const shareData = {
  //     // title: 'Title',
  //     text: text,
  //     url: url
  //   }
  //   if (navigator.canShare) {
  //     try {
  //       await navigator.share(shareData)
  //     } catch (error) {
  //       setMessage(error?.message)
  //       setModalMessage(true)
  //       setTimeout(() => setModalMessage(false), 3000)
  //     }
  //   }
  // }

  function Copied () {
    if (matchLeagueDetails && matchLeagueDetails.sShareCode) {
      copyToClipboard(matchLeagueDetails.sShareCode)
    }
  }
  return (
    <div className="user-container no-footer">
      { loading && <Loading />}
      { copied && <Alert color="success"><img className="mr-1" src={SuccessIcon} alt="SuccessIcon" /> {<FormattedMessage id="ShareContest_ccc" />}</Alert>}
      {/* {modalMessage && message ? <Alert isOpen={modalMessage} color="primary">{message}</Alert> : ''} */}
      {
        !_.isEmpty(matchDetails) &&
        <Suspense fallback={<Loading />}>
          <Match {...props} data={matchDetails} loading={false} noRedirect/>
        </Suspense>
      }
      {
        <Card className="leagues-card border-0 bg-transparent my-3">
          <CreateContestLeague matchType='upcoming' data={matchLeagueDetails} {...props} showInformation insideleagueDetailsPage/>
        </Card>
      }
      <div className="share-box pt-0">
        {/* <p className="share-t text-center"><FormattedMessage id="other_shareContest" /></p> */}
        <div className="code-b d-flex align-items-center justify-content-between">
          <p className="m-0" ref={myRef}>{matchLeagueDetails && matchLeagueDetails.sShareCode}</p>
          <button onClick={Copied}><FormattedMessage id="other_copy" /></button>
        </div>
        <div className='mt-4'>
          <Button color='primary' className="w-100" onClick={() => shareContestClick()}>
            <FormattedMessage id="Share" />
          </Button>
        </div>
      </div>
      {/* {navigator.canShare
        ? <div className='native-contest-share-box'>
          <Button color='primary-orange' className="w-100" onClick={() => handleShare(
            {
              text: `${config.shareMessage1} ${matchDetails && matchDetails.sName} ${config.shareMessage2} ${matchLeagueDetails && matchLeagueDetails.sShareCode}`,
              url: `${matchLeagueDetails?.sShareLink ? matchLeagueDetails?.sShareLink : ''}`
            }
          )}><img src={share} className='float-left ml-3' /><span className='text-center'><FormattedMessage id="Share" /></span></Button></div>
        : <div className="share-box text-center">
        <div>
          <WhatsappShareButton
            url={matchLeagueDetails?.sShareLink ? matchLeagueDetails?.sShareLink : ''}
            title={config.shareMessage1 + `${matchDetails && matchDetails.sName} ` + config.shareMessage2 + `${matchLeagueDetails && matchLeagueDetails.sShareCode} `}
            separator=":"
            className="Demo__some-network__share-button mr-2 mt-2"
          >
            <WhatsappIcon size={40} round />
          </WhatsappShareButton>
          <FacebookShareButton
            appId={config.facebookAppID}
            url={matchLeagueDetails?.sShareLink ? matchLeagueDetails?.sShareLink : ''}
            quote={config.shareMessage1 + `${matchDetails && matchDetails.sName} ` + config.shareMessage2 + `${matchLeagueDetails && matchLeagueDetails.sShareCode} `}
            className="Demo__some-network__share-button mr-2 mt-2"
          >
            <FacebookIcon size={40} round />
          </FacebookShareButton>
          <TwitterShareButton
            url={matchLeagueDetails?.sShareLink ? matchLeagueDetails?.sShareLink : ''}
            title={config.shareMessage1 + `${matchDetails && matchDetails.sName} ` + config.shareMessage2 + `${matchLeagueDetails && matchLeagueDetails.sShareCode} `}
            className="Demo__some-network__share-button mr-2 mt-2"
          >
            <TwitterIcon size={40} round />
          </TwitterShareButton>
          <LinkedinShareButton
            url={matchLeagueDetails?.sShareLink ? matchLeagueDetails?.sShareLink : ''}
            title={config.shareMessage1 + `${matchDetails && matchDetails.sName} ` + config.shareMessage2 + `${matchLeagueDetails && matchLeagueDetails.sShareCode} `}
            className="Demo__some-network__share-button mr-2 mt-2">
            <LinkedinIcon size={40} round />
          </LinkedinShareButton>
          <FacebookMessengerShareButton
            url={matchLeagueDetails?.sShareLink ? matchLeagueDetails?.sShareLink : ''}
            title={config.shareMessage1 + `${matchDetails && matchDetails.sName} ` + config.shareMessage2 + `${matchLeagueDetails && matchLeagueDetails.sShareCode} `}
            appId={config.facebookAppID}
            className="Demo__some-network__share-button mr-2 mt-2"
          >
            <FacebookMessengerIcon size={40} round />
          </FacebookMessengerShareButton>
          <TelegramShareButton
            url={matchLeagueDetails?.sShareLink ? matchLeagueDetails?.sShareLink : ''}
            title={config.shareMessage1 + `${matchDetails && matchDetails.sName} ` + config.shareMessage2 + `${matchLeagueDetails && matchLeagueDetails.sShareCode} `}
            className="Demo__some-network__share-button mr-2 mt-2"
          >
            <TelegramIcon size={40} round />
          </TelegramShareButton>
          <EmailShareButton
            url={matchLeagueDetails?.sShareLink ? matchLeagueDetails?.sShareLink : ''}
            body={config.shareMessage1 + `${matchDetails && matchDetails.sName} ` + config.shareMessage2 + `${matchLeagueDetails && matchLeagueDetails.sShareCode} `}
            separator=' '
            openShareDialogOnClick={true}
            onShareWindowClose={true}
            resetButtonStyle={false}
            className="Demo__some-network__share-button mr-2 mt-2"
            onClick={ (_, link) => {
              window.open(link, '_blank')
            }}
          >
            <EmailIcon size={40} round />
          </EmailShareButton>
        </div>
      </div>} */}
    </div>
  )
}

ShareContestPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      matchDetails: PropTypes.object,
      matchLeagueDetails: PropTypes.object
    }),
    search: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      id2: PropTypes.string,
      sportsType: PropTypes.string
    })
  }),
  getDetailsFun: PropTypes.func,
  getDetails: PropTypes.func,
  token: PropTypes.string,
  url: PropTypes.string,
  copyToClipboard: PropTypes.func,
  setLoading: PropTypes.func,
  loading: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  getLeague: PropTypes.func
}

export default CommonInviteFriend(ShareContestPage)
