import React, { useState, Fragment, useEffect, useRef } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, Table, Alert, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
// import { useQueryState } from 'react-router-use-location-state'
import { clickEventPost, currencyConvert, handleImgError, isUpperCase, maxValue, pageViewPost } from '../../../helper'
import qs from 'query-string'
import League from '../../Home/components/League'
// import { getRandomColor, createImageFromInitials } from '../components/LetterImage'
import SkeletonLeague from '../../../component/SkeletonLeague'
import SkeletonTable from '../../../component/SkeletonTable'
import ContestDetails from '../../../HOC/SportsLeagueList/ContestDetails'
import MyTeam from '../components/MyTeam'
// import Cash from '../../../assests/images/cash.svg'
// import Bonus from '../../../assests/images/Bonus.svg'
import PlayerImage from '../../../assests/images/profile.png'
// import close from '../../../assests/images/close.svg'
import NavPrize from '../../../assests/images/league-nav-prize-breakup.svg'
import NavPrizeDisabled from '../../../assests/images/league-nav-prize-breakup-disabled.svg'
import Voucher from '../../../assests/images/voucher.svg'
import NavLeaderboard from '../../../assests/images/league-nav-leaderboard.svg'
import NavLeaderboardDisabled from '../../../assests/images/league-nav-leaderboard-disabled.svg'
// import prizebreakupTrophy from '../../../assests/images/prizebreakupTrophy.svg'
import crownUtils from '../../../assests/images/crown-utils.svg'
import switchIcon from '../../../assests/images/switch-icon.svg'
import Lottie from 'react-lottie'
import noContestJoined from '../../../assests/animations/no-contest-joined.json'
import SuccessIcon from '../../../assests/images/success-icon.svg'
import Slider from '../components/Slider'

const classNames = require('classnames')

function LeaguesDetailPage (props) {
  const {
    match,
    activeTab,
    onTabChange,
    paymentSlide, setPaymentSlide,
    onGetUserProfile,
    loading,
    teamList,
    contestJoinList,
    allLeaderBoardList,
    myTeamsLeaderBoardList,
    matchLeagueDetails,
    leaderboardLoading,
    setSwitchTeamModal,
    switchTeamModal,
    onSwitchTeam,
    userInfo,
    resjoinMessage,
    resStatus,
    url,
    switchTeamSuccess,
    otherTeamJoinList,
    message,
    setOffset,
    currencyLogo,
    teamPlayerList,
    onFetchMatchPlayer,
    joinDetails,
    location
  } = props
  const [userTeamId, setUserTeamId] = useState('')
  const [switchAlert, setSwitchAlert] = useState(false)
  const [userLeagueId, setUserLeagueId] = useState('')
  const [alertMessage, setMessage] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [poolPrize, setPoolPrize] = useState('')
  const [SwitchTeamList, setSwitchTeamList] = useState([])
  // eslint-disable-next-line no-unused-vars
  // const [activeState, setActiveState] = useQueryState('activePage', 2)
  const [activePrizeBr, setActivePrizeBr] = useState(1)
  const [UpdatedTeamList, setUpdatedTeamList] = useState([])
  const [alertShawing, setAlertShawing] = useState(false)
  const [SwitchTeamTopData, setSwitchTeamTopData] = useState([])
  const [SwitchTeamBottomData, setSwitchTeamBottomData] = useState([])
  const [SwitchCurrentTeam, setSwitchCurrentTeam] = useState()
  const [currSwitchTeam, setCurrSwitchTeam] = useState('')
  const [limit] = React.useState(50)
  const [sliderCount, setSliderCount] = React.useState(0)
  const amountData = useSelector(state => state.league.amountData)
  const previousProps = useRef({
    amountData, allLeaderBoardList, resjoinMessage, resStatus, switchTeamSuccess, contestJoinList, teamList, UpdatedTeamList
  }).current
  const obj = qs.parse(location.search)

  useEffect(() => {
    if (props?.location?.state?.currSwitchTeam) {
      setCurrSwitchTeam(props?.location?.state?.currSwitchTeam)
    }
    pageViewPost('cd')
  }, [])

  // const componentRef = useRef()
  // function errorImage () {
  //   componentRef.current.src = Cash
  // }
  useEffect(() => { // handle the response
    if (obj) {
      if (obj.activePage) {
        const active = obj.activePage
        // setActiveState(parseInt(active))
        onTabChange(parseInt(active))
      }
    }
    if (props?.location?.state?.switchTeamModal) {
      setSwitchTeamModal(true)
      onSwitch(props.location.state.currSwitchTeam)
    } else {
      setSwitchTeamModal(false)
    }
    if (!teamPlayerList) {
      onFetchMatchPlayer(match.params.id)
    }
    if (match?.params?.sportsType) {
      const sport = match.params.sportsType
      isUpperCase(sport) && props.history.push({ pathname: `/leagues-detail/${sport.toLowerCase()}/${match.params.id}/${match.params.id2}`, state: { ...props.location.state } })
    }
  }, [])

  useEffect(() => { // handle the response
    if (previousProps.resjoinMessage !== resjoinMessage) {
      if (resjoinMessage && resStatus) {
        setAlertShawing(true)
        setMessage(resjoinMessage)
      }
    }
    return () => {
      previousProps.resjoinMessage = resjoinMessage
    }
  }, [resjoinMessage, resStatus])

  useEffect(() => { // handle the response
    if (previousProps.matchLeagueDetails !== matchLeagueDetails) {
      let fPrize = ''
      if (matchLeagueDetails && matchLeagueDetails._id) {
        if (matchLeagueDetails && matchLeagueDetails.bPoolPrize && matchLeagueDetails.nDeductPercent !== null && matchLeagueDetails.eMatchStatus === 'U' && (!matchLeagueDetails.bPrivateLeague)) {
          fPrize = parseFloat(Number(((matchLeagueDetails.nPrice * maxValue(matchLeagueDetails.nMin, matchLeagueDetails.nJoined) * 100) / (((matchLeagueDetails && matchLeagueDetails.nDeductPercent) || 0) + 100))).toFixed(1))
          setPoolPrize(fPrize)
        } else if (matchLeagueDetails && matchLeagueDetails.bPoolPrize && matchLeagueDetails.nDeductPercent !== null && matchLeagueDetails.eMatchStatus !== 'U' && (!matchLeagueDetails.bPrivateLeague)) {
          fPrize = parseFloat(Number(((matchLeagueDetails.nPrice * matchLeagueDetails.nJoined * 100) / (((matchLeagueDetails.nDeductPercent) || 0) + 100))).toFixed(1))
          setPoolPrize(fPrize)
        }
      }
    }
    return () => {
      previousProps.matchLeagueDetails = matchLeagueDetails
    }
  }, [matchLeagueDetails])

  // when user have unSoficient balance
  useEffect(() => {
    if (previousProps.amountData !== amountData) {
      if (amountData && amountData?.oValue?.nAmount > 0) {
        // const data = JSON.parse(localStorage.getItem('LeagueData'))
        props.history.push({
          pathname: '/deposit',
          state: {
            amountData: amountData?.oValue,
            message: 'Insufficient Balance'
            // joinData: {
            //   userTeams: data.userTeams, verifiedId: data.id, finalPromocode: data.finalPromocode
            // }
          }
        })
      }
    }
    return () => {
      previousProps.amountData = amountData
    }
  }, [amountData])

  useEffect(() => { // handle the response
    if (previousProps.switchTeamSuccess !== switchTeamSuccess) {
      if (switchTeamSuccess) {
        setSwitchAlert(true)
        setTimeout(() => {
          setSwitchAlert(false)
        }, 2500)
      }
    }
    return () => {
      previousProps.switchTeamSuccess = switchTeamSuccess
    }
  }, [switchTeamSuccess])

  useEffect(() => {
    if (previousProps.SwitchTeamList !== SwitchTeamList || previousProps.UpdatedTeamList !== UpdatedTeamList) {
      if (SwitchTeamList?.length > 0 && UpdatedTeamList.length > 0) {
        const bottomData = []

        SwitchTeamList && SwitchTeamList.length !== 0 && SwitchTeamList.sort((a, b) => a.sName > b.sName ? 1 : -1).map((data, index) => {
          UpdatedTeamList?.map((item) => {
            if (item._id === data._id) {
            // setSwitchTeamBottomData((prev) => [...prev, data])
              bottomData.push(data)
            }
            return item
          })
          return data
        })

        const unique1 = SwitchTeamList?.filter((o) => UpdatedTeamList?.indexOf(o) === -1)
        const unique2 = UpdatedTeamList?.filter((o) => SwitchTeamList?.indexOf(o) === -1)

        const unique = unique1?.concat(unique2)

        setSwitchTeamTopData(unique)
        setSwitchTeamBottomData(bottomData)
      }
    }

    return () => {
      previousProps.SwitchTeamList = SwitchTeamList
      previousProps.UpdatedTeamList = UpdatedTeamList
    }
  }, [SwitchTeamList, UpdatedTeamList, currSwitchTeam, teamList])

  useEffect(() => {
    if (SwitchTeamBottomData?.length > 0) {
      setUserTeamId(SwitchTeamBottomData[0]?._id)
    }
  }, [SwitchTeamBottomData])

  function OtherTeamClick () {
    setAlertShawing(true)
    setMessage(<FormattedMessage id="Match_NotStarted" />)
  }
  function handleScroll (e) {
    const element = e.target
    if (((element.scrollHeight - element.scrollTop - 1) === element.clientHeight) && activeTab === 2) {
      if (otherTeamJoinList && !(otherTeamJoinList.length < limit)) {
        setOffset(value => value + limit)
      }
    }
  }

  useEffect(() => {
    if (alertShawing) {
      setTimeout(() => {
        setAlertShawing(false)
      }, 2000)
    }
  }, [alertShawing])

  useEffect(() => {
    if (previousProps.teamList !== teamList) {
      if (teamList?.length > 0) {
        setSwitchTeamList(teamList)
      }
    }

    return () => {
      previousProps.teamList = teamList
    }
  }, [teamList])

  useEffect(() => {
    if (matchLeagueDetails && teamList) {
      const contestData = joinDetails && joinDetails.iMatchLeagueId ? joinDetails : contestJoinList && contestJoinList.length !== 0 && matchLeagueDetails && contestJoinList.find(contest => contest && contest.iMatchLeagueId === matchLeagueDetails._id)
      if (contestData) {
        const FilterTeam = contestData && contestData.aUserTeams && teamList && teamList.length !== 0 && teamList.filter(team => contestData && contestData.aUserTeams && !contestData.aUserTeams.includes(team._id))
        if (FilterTeam && FilterTeam.length !== 0) {
          setUpdatedTeamList(FilterTeam)
        } else {
          // setAlertShawing(true)
          // setMessage(<FormattedMessage id="No_Team_Available" />)
        }
      }
    }
  }, [contestJoinList, teamList, matchLeagueDetails, joinDetails])

  function onSwitchedTeam () {
    const contestData = joinDetails && joinDetails.iMatchLeagueId ? joinDetails : contestJoinList && contestJoinList.length !== 0 && matchLeagueDetails && contestJoinList.find(contest => contest && contest.iMatchLeagueId === matchLeagueDetails._id)
    if (contestData) {
      const FilterTeam = contestData && contestData.aUserTeams && teamList && teamList.length !== 0 && teamList.filter(team => contestData && contestData.aUserTeams && !contestData.aUserTeams.includes(team._id))
      if (FilterTeam && FilterTeam.length !== 0) {
        setUpdatedTeamList(FilterTeam)
        setSwitchTeamList(teamList)
        setSwitchTeamModal(true)
      } else {
        // setAlertShawing(true)
        // setMessage(<FormattedMessage id="No_Team_Available" />)
      }
    } else {
      if (teamList && teamList.length !== 0) {
        setSwitchTeamModal(true)
      } else {
        // setAlertShawing(true)
        // setMessage(<FormattedMessage id="No_Team_Available" />)
      }
    }
  }

  function onSwitch (myTeam) {
    onSwitchedTeam()
    setUserLeagueId(myTeam?._id)
    setUserTeamId('')
    setSwitchCurrentTeam(myTeam?.sTeamName)
    pageViewPost('swt')
  }

  useEffect(() => {
    if (paymentSlide) {
      onGetUserProfile()
    }
  }, [paymentSlide])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noContestJoined,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  const getSliderCount = (count) => {
    setSliderCount(count)
  }

  return (
    <Fragment>
      {
        switchAlert
          ? <Fragment>
            <Alert isOpen={switchAlert} color="success"><img className="mr-1" src={SuccessIcon} alt="SuccessIcon" /> {message}</Alert>
          </Fragment>
          : ''
      }
      {
        alertShawing
          ? <Fragment>
            <Alert isOpen={alertShawing} color={(resStatus && alertMessage?.props?.id !== 'Match_NotStarted') ? 'success' : 'primary'}>{(resStatus && alertMessage?.props?.id !== 'Match_NotStarted') && <img className="mr-1" src={SuccessIcon} alt="SuccessIcon" />} {alertMessage}</Alert>
          </Fragment>
          : ''
      }
      <div className={`league-container no-tabs league-detail ${matchLeagueDetails && matchLeagueDetails.nCashbackAmount && matchLeagueDetails.nMinCashbackTeam ? 'no-Scroll' : ''}`}>
        <Card className="leagues-card border-0 bg-transparent my-3">
          {
            loading
              ? <SkeletonLeague leagueDetails />
              : <League matchType='upcoming' data={matchLeagueDetails} contestJoinList={contestJoinList} {...props} showInformation insideleagueDetailsPage/>
          }
        </Card>
        {matchLeagueDetails && matchLeagueDetails.bPrivateLeague &&
          <div className='private-league-hostedby d-flex justify-content-between'>
            <p>Hosted By</p>
            <div className="d-flex align-items-center">
              <img src={(matchLeagueDetails?.sProPic && url) ? `${url}${matchLeagueDetails?.sProPic}` : PlayerImage} onError={(e) => handleImgError(e, PlayerImage)} />
              <h3>{matchLeagueDetails?.sUsername}</h3>
            </div>
          </div>
        }
        <Nav className="league-details-live-tabs two-tabs">
          <NavItem className="text-center">
            <NavLink className={classnames({ active: activeTab === 1 })} onClick={() => {
              onTabChange(1)
              clickEventPost('cd_pb')
              // setActiveState(parseInt(1))
            }} >
              {activeTab === 1 ? <img src={NavPrize} /> : <img src={NavPrizeDisabled} />}
              <FormattedMessage id="League_prizeBreakup" />
            </NavLink>
          </NavItem>
          <NavItem className="text-center">
            <NavLink className={classnames({ active: activeTab === 2 })} onClick={() => {
              onTabChange(2)
              clickEventPost('cd_lb')
              pageViewPost('ldb')
              // setActiveState(parseInt(2))
            }}>
              {activeTab === 2 ? <img src={NavLeaderboard} /> : <img src={NavLeaderboardDisabled} />}
              <FormattedMessage id="League_leaderboard" />
            </NavLink>
          </NavItem>
        </Nav>
        {matchLeagueDetails?.bPoolPrize && !matchLeagueDetails?.bUnlimitedJoin && activeTab === 1 &&
          <div className='bg-white p-2'>
            <Nav className="live-tabs-home two-tabs">
              <NavItem className="text-center">
                <NavLink className={classnames({ active: activePrizeBr === 1 })} onClick={() => {
                  // onTabChange(1)
                  // setActiveState(parseInt(1))
                  setActivePrizeBr(1)
                }} >
                  <FormattedMessage id="Max_Fill" />
                </NavLink>
              </NavItem>
              <NavItem className="text-center">
                <NavLink className={classnames({ active: activePrizeBr === 2 })} onClick={() => {
                  // onTabChange(2)
                  // setActiveState(parseInt(2))
                  setActivePrizeBr(2)
                }}>
                  <FormattedMessage id="Current_Fill" />
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        }
        <TabContent activeTab={activeTab} className={`league-detail-container ${(
          (matchLeagueDetails && matchLeagueDetails.aLeaguePrize && matchLeagueDetails.aLeaguePrize.length < 1 && activeTab === 1)
          // (myTeamsLeaderBoardList && myTeamsLeaderBoardList.length === 0 && allLeaderBoardList && allLeaderBoardList.length === 0 && activeTab === 2)
          )
          ? 'inCenterContain'
          : ''} ${matchLeagueDetails && matchLeagueDetails.bPrivateLeague && 'isPrivate'} ${matchLeagueDetails?.nCashbackAmount > 0 ? 'cashback-include' : ''}`} onScroll={handleScroll}>
          <TabPane tabId={1} className='league-detail-tab m-0'>
            <div className="container-inside mt-1">
              {
                activePrizeBr === 1 && (
                  <>
                  {matchLeagueDetails && matchLeagueDetails.aLeaguePrize &&
                    (
                      <Table className="m-0 player-list player-list3 price-table bg-white table-radius">
                        <thead>
                          <tr>
                            <td><FormattedMessage id="League_Rank" /></td>
                            <td className='text-right'><FormattedMessage id="League_Winnings" /></td>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            loading
                              ? (
                                <SkeletonTable />
                                )
                              : (
                                <Fragment>
                                  {
                                    matchLeagueDetails && matchLeagueDetails.aLeaguePrize && matchLeagueDetails.aLeaguePrize.sort((a, b) => a.nRankFrom > b.nRankFrom ? 1 : -1).map(data => {
                                      return (
                                        <tr key={data._id}>
                                          <td>
                                            <b>#</b><span className='textt-black'>{data && data.nRankFrom && data.nRankTo && data.nRankFrom === data.nRankTo ? `${data.nRankFrom}` : `${data.nRankFrom} - ${data.nRankTo}`}</span>
                                          </td>
                                          <td className='text-right'>
                                            <div className="m-0 align-items-center">
                                                {/* <img className='m-0 p-img eImage flex-shrink-0' src={data.eRankType === 'E' && data.sImage ? url + data.sImage : data.eRankType === 'B' ? Bonus : Cash } /> */}
                                                {
                                                  data && data.eRankType === 'V'
                                                    ? <><img src={Voucher} /> <span className='ml-2 textt-black'> {data && data.sInfo ? data.sInfo : ''} </span></>
                                                    : data.eRankType === 'E'
                                                      ? <span className='ml-2 textt-black'> {data && data.sInfo ? data.sInfo : ''} </span>
                                                      : <Fragment>
                                                      <span className='ml-2 textt-black'>
                                                        {data && data.eRankType !== 'CW' ? currencyLogo : <img className='mr-1' src={crownUtils} width="12px" style={{ marginTop: '-3px' }} />}{data && data.nPrize ? currencyConvert(data.nPrize) : 0}
                                                        {/* {currencyLogo} {matchLeagueDetails && matchLeagueDetails.bPoolPrize && matchLeagueDetails.nDeductPercent !== null && matchLeagueDetails.eMatchStatus === 'U' && (!matchLeagueDetails.bPrivateLeague)
                                                          ? data && data.nPrize && `${parseFloat(Number(((data.nPrize * poolPrize) / 100))).toFixed(1)}`
                                                          : matchLeagueDetails && matchLeagueDetails.bPoolPrize && matchLeagueDetails.nDeductPercent !== null && matchLeagueDetails.eMatchStatus !== 'U' && (!matchLeagueDetails.bPrivateLeague)
                                                            ? `${parseFloat(Number(((data.nPrize * poolPrize) / 100))).toFixed(1)}`
                                                            : matchLeagueDetails && matchLeagueDetails.bPoolPrize && matchLeagueDetails.bPrivateLeague
                                                              ? `${((data.nPrize * matchLeagueDetails.nTotalPayout) / 100).toFixed(2)}`
                                                              : data && data.nPrize ? data.nPrize : 0}{
                                                          data && data.eRankType === 'B' ? ' Bonus' : ''
                                                        } */}
                                                      </span>
                                                    </Fragment>
                                                }
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                  {matchLeagueDetails?.bPoolPrize && !matchLeagueDetails?.bUnlimitedJoin &&
                                    <tr><td style={{ backgroundColor: '#F7F7F7' }} colSpan={2}><FormattedMessage id='Price_Breakup_Applicable' /></td></tr>
                                  }
                                </Fragment>
                                )
                          }
                        </tbody>
                      </Table>
                    )}
                  </>
                )
              }
              {
                activePrizeBr === 2 && (
                  <>
                  {matchLeagueDetails && matchLeagueDetails.aLeaguePrize &&
                    (
                      <Table className="m-0 player-list player-list2 price-table bg-white table-radius">
                        <thead>
                          <tr>
                            <td><FormattedMessage id="League_Rank" /></td>
                            <td className='text-right'><FormattedMessage id="League_Winnings" /></td>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            loading
                              ? (
                                <SkeletonTable />
                                )
                              : (
                                <Fragment>
                                  {
                                    matchLeagueDetails && matchLeagueDetails.aLeaguePrize && matchLeagueDetails.aLeaguePrize.sort((a, b) => a.nRankFrom > b.nRankFrom ? 1 : -1).map(data => {
                                      return (
                                        <tr key={data._id}>
                                          <td>
                                            <b>#</b><span className='textt-black'>{data && data.nRankFrom && data.nRankTo && data.nRankFrom === data.nRankTo ? `${data.nRankFrom}` : `${data.nRankFrom} - ${data.nRankTo}`}</span>
                                          </td>
                                          <td className='text-right'>
                                            <div className="m-0 align-items-center">
                                                {/* <img className='m-0 p-img eImage flex-shrink-0' src={data.eRankType === 'E' && data.sImage ? url + data.sImage : data.eRankType === 'B' ? Bonus : Cash } /> */}
                                                {
                                                  data && data.eRankType === 'E'
                                                    ? <span className='ml-2 textt-black'> {data && data.sInfo ? data.sInfo : ''} </span>
                                                    : data.eRankType === 'V'
                                                      ? <span className='ml-2 textt-black d-flex'> <img className='mr-2' src={Voucher} /> {data && data.sInfo ? data.sInfo : ''} </span>
                                                      : <Fragment>
                                                      <span className='ml-2 textt-black'>
                                                        {data && data.eRankType !== 'CW' ? currencyLogo : <img className='mr-1' src={crownUtils} width="12px" style={{ marginTop: '-3px' }} />}{data && data.nCurrentPrize ? data.nCurrentPrize : 0}
                                                        {
                                                          data && data.eRankType === 'B' ? ' Bonus' : data.eRankType === 'R' ? ' Cash' : ''
                                                        }
                                                        {/* {currencyLogo} {matchLeagueDetails && matchLeagueDetails.bPoolPrize && matchLeagueDetails.nDeductPercent !== null && matchLeagueDetails.eMatchStatus === 'U' && (!matchLeagueDetails.bPrivateLeague)
                                                          ? data && data.nPrize && `${parseFloat(Number(((data.nPrize * poolPrize) / 100))).toFixed(1)}`
                                                          : matchLeagueDetails && matchLeagueDetails.bPoolPrize && matchLeagueDetails.nDeductPercent !== null && matchLeagueDetails.eMatchStatus !== 'U' && (!matchLeagueDetails.bPrivateLeague)
                                                            ? `${parseFloat(Number(((data.nPrize * poolPrize) / 100))).toFixed(1)}`
                                                            : matchLeagueDetails && matchLeagueDetails.bPoolPrize && matchLeagueDetails.bPrivateLeague
                                                              ? `${((data.nPrize * matchLeagueDetails.nTotalPayout) / 100).toFixed(2)}`
                                                              : data && data.nPrize ? data.nPrize : 0}{
                                                          data && data.eRankType === 'B' ? ' Bonus' : ''
                                                        } */}
                                                      </span>
                                                    </Fragment>
                                                }
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                  {/* {matchLeagueDetails?.bPoolPrize &&
                                    <tr><td style={{ backgroundColor: '#F7F7F7' }} colSpan={2}><FormattedMessage id='Price_Breakup_Applicable' /></td></tr>
                                  } */}
                                </Fragment>
                                )
                          }
                        </tbody>
                      </Table>
                    )}
                  </>
                )
              }
            </div>
          </TabPane>
          <TabPane tabId={2} className={`${activeTab === 2 ? 'full-width' : ''}`}>
            <div className={'container-inside container-inside-2 mt-1' + `${sliderCount >= 1 ? ' container-inside-2-with-height' : ''}`} onScroll={handleScroll}>

                {
                  leaderboardLoading
                    ? (
                      <SkeletonTable />
                      )
                    : (
                      <Fragment>
                        {
                          myTeamsLeaderBoardList && myTeamsLeaderBoardList.length >= 0 && allLeaderBoardList && allLeaderBoardList.length >= 0
                            ? (
                              <Fragment>
                                <Table className="bg-white player-list price-table table-radius" id='leaderboard'>
                                {((myTeamsLeaderBoardList && myTeamsLeaderBoardList.length > 0) || (allLeaderBoardList && allLeaderBoardList.length > 0)) &&
                                  <thead>
                                    <tr>
                                      <td><FormattedMessage id="Welcome_Hash" /></td>
                                      <td><FormattedMessage id="Team_AllTeam" /> ({matchLeagueDetails && matchLeagueDetails?.nJoined})</td>
                                      <td></td>
                                    </tr>
                                  </thead>
                                }
                                <tbody>
                                  {
                                    myTeamsLeaderBoardList && myTeamsLeaderBoardList.length !== 0 && myTeamsLeaderBoardList.map((myTeam, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>
                                            <FormattedMessage id="You" />
                                          </td>
                                          <td onClick={() => props.history.push({
                                            pathname: obj?.homePage ? `/team-preview/${(match?.params?.sportsType).toLowerCase()}/${match?.params?.id}/${match.params.id2}/${myTeam.iUserTeamId}/${index}?homePage=yes` : `/team-preview/${(match?.params?.sportsType).toLowerCase()}/${match?.params?.id}/${match.params.id2}/${myTeam.iUserTeamId}/${index}`, state: { allUserLeagues: myTeamsLeaderBoardList?.length }
                                          })}>
                                            <div className="d-flex align-items-center">
                                              <div className="l-img mr-2">
                                                <img src={myTeam && myTeam.sProPic && url ? `${url}${myTeam.sProPic}` : PlayerImage} onError={(e) => handleImgError(e, PlayerImage)} alt={myTeam && myTeam.sUserName} />
                                              </div>
                                              {myTeam && myTeam.sUserName} ({myTeam && myTeam.sTeamName})
                                            </div>
                                          </td>
                                          <td className='switch-class'>
                                            <Button onClick={() => {
                                              onSwitch(myTeam)
                                              setCurrSwitchTeam(myTeam)
                                            }
                                              } color="border" disabled={myTeamsLeaderBoardList?.length >= teamList?.length}>
                                              <img className='mr-1' src={switchIcon} alt={<FormattedMessage id="Switch" />} />
                                              <FormattedMessage id="Switch" />
                                            </Button>
                                          </td>
                                        </tr>
                                      )
                                    })
                                  }
                                  {
                                    allLeaderBoardList && allLeaderBoardList.length !== 0 && allLeaderBoardList.map((allOtherTeam, index) => {
                                      return (
                                        <tr key={index} onClick={OtherTeamClick}>
                                          <td>
                                            {myTeamsLeaderBoardList?.length + index + 1}
                                          </td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <div className="l-img mr-2">
                                                <img src={allOtherTeam && allOtherTeam.sProPic && url ? `${url}${allOtherTeam.sProPic}` : PlayerImage} alt={allOtherTeam && allOtherTeam.sTeamName} onError={(e) => handleImgError(e, PlayerImage)} />
                                              </div>
                                              {allOtherTeam && allOtherTeam.sUserName} ({allOtherTeam && allOtherTeam.sTeamName})
                                            </div>
                                          </td>
                                          <td></td>
                                        </tr>
                                      )
                                    }
                                    )
                                  }
                                  </tbody>
                                </Table>
                                  {
                                    myTeamsLeaderBoardList && myTeamsLeaderBoardList.length === 0 && allLeaderBoardList && allLeaderBoardList.length === 0 && (
                                      <div className="no-team d-flex align-items-center justify-content-center fixing-width">
                                        <div className="no-team-inside">
                                          <Lottie width={174} options={defaultOptions} isClickToPauseDisabled={true} />
                                          <h6 className="m-4">
                                            <FormattedMessage id="Contast_NoPlayer" />
                                          </h6>
                                        </div>
                                      </div>
                                    )
                                  }

                              </Fragment>
                              )
                            : (
                              <div className="no-team d-flex align-items-center justify-content-center fixing-width">
                                <div className="no-team-inside">
                                  <Lottie width={174} options={defaultOptions} isClickToPauseDisabled={true} />
                                  <h6 className="m-4">
                                    <FormattedMessage id="Contast_NoPlayer" />
                                  </h6>
                                </div>
                              </div>
                              )
                        }
                      </Fragment>
                      )
                }
            </div>
            <Slider sliderCount={getSliderCount} />
          </TabPane>
        </TabContent>
      </div>
      {
        switchTeamModal
          ? <>
            <div className="s-team-bg" onClick={() => setSwitchTeamModal(false)}></div>
            <Card className="filter-card select-team promo-car switch-team-popup">
              <CardHeader className='d-flex align-items-center justify-content-between m-0'>
                <h2 onClick={() => { setSwitchTeamModal(false) }}> <span className='icon-left-arrow mr-2'></span> <FormattedMessage id="Switch_Team" /></h2>
                {/* <button onClick={() => { setSwitchTeamModal(false) }} ><img src={close}></img></button> */}
              </CardHeader>
              <CardBody className="p-0">
                <div className='popup-content'>
                  <div className='already-join'>
                    <h2><FormattedMessage id="Already_Joined" /></h2>
                    {SwitchTeamTopData.length > 0 && SwitchTeamTopData?.map((data, index) => {
                      return (
                        <div className='switch-team-wrap' key={index} >
                          <MyTeam {...props}
                            join
                            disabledRadio={UpdatedTeamList?.find((item) => item._id === data._id)?._id}
                            disabledRadioFlag={UpdatedTeamList?.length !== 0}
                            teamId={data._id}
                            // noOfJoin={noOfJoin}
                            upcoming
                            userTeamId={userTeamId}
                            setUserTeamId={(Id) => setUserTeamId(Id)}
                            params={match && match.params.id}
                            index={index}
                            teamDetails={data}
                            allTeams={teamList}
                            currSwitchTeam={currSwitchTeam}
                            UserTeamChoice
                            disableButton />
                        </div>
                      )
                    })}
                  </div>
                  <div className='chooseteam-to-join'>
                    <FormattedMessage id="Chooseteam_To_Join" />
                    <span className='ml-1'>
                      {SwitchCurrentTeam}
                    </span>
                  </div>
                  {SwitchTeamBottomData.length > 0 && SwitchTeamBottomData?.map((data, index) => {
                    return (
                      <div className='switch-team-wrap' key={data._id} >
                        <MyTeam {...props}
                          join
                          disabledRadio={UpdatedTeamList?.find((item) => item._id === data._id)?._id}
                          disabledRadioFlag={UpdatedTeamList?.length !== 0}
                          teamId={data._id}
                          // noOfJoin={noOfJoin}
                          upcoming
                          userTeamId={userTeamId}
                          setUserTeamId={(Id) => setUserTeamId(Id)}
                          params={match && match.params.id}
                          index={index}
                          teamDetails={data}
                          allTeams={teamList}
                          currSwitchTeam={currSwitchTeam}
                          UserTeamChoice />
                      </div>
                    )
                  })}
                </div>
              </CardBody>
              <CardFooter className='border-0 btn-bottom m-0 d-flex justify-content-between align-items-center'>
                <div className='d-flex align-items-center'>
                  <div className='switchteam-name'>
                    <h4><FormattedMessage id="Current_team" /></h4>
                    {<h3>{SwitchCurrentTeam || props?.location?.state?.currSwitchTeam?.sTeamName}</h3>}
                  </div>
                  <div className='ml-2 mr-2'>
                    <img src={switchIcon} width="20" alt="" />
                  </div>
                  <div className='switchteam-name'>
                    <h4><FormattedMessage id="Switch_to_team" /></h4>
                    <h3>{SwitchTeamBottomData?.find(item => item._id === userTeamId)?.sName}</h3>
                  </div>
                </div>
                <Button type="submit" color='primary' className="" disabled={!userTeamId} onClick={() => onSwitchTeam(userLeagueId, userTeamId)}><FormattedMessage id="Switch" /></Button>
              </CardFooter>
            </Card>
          </>
          : ''
      }
      {paymentSlide
        ? <>
            <div className="s-team-bg" onClick={() => setPaymentSlide(false)}></div>
            <Card className={classNames('filter-card', { show: paymentSlide })}>
              <CardHeader className='d-flex align-items-center justify-content-between'>
                <button onClick={() => { setPaymentSlide(false) }}><FormattedMessage id='Wallet_Details' /></button>
                <button className='red-close-btn' onClick={() => setPaymentSlide(false)}><FormattedMessage id='League_Close' /></button>
              </CardHeader>
              <CardBody className='payment-box'>

              <Table className="m-0 bg-white payment">
                        <thead>
                          <tr className='text-center'> <th colSpan='2'><FormattedMessage id="PROFILE_TOTAL_BALANCE" /></th> </tr>
                          <tr className='text-center'> <th colSpan='2'>{currencyLogo}{userInfo && userInfo.nCurrentTotalBalance ? userInfo && userInfo.nCurrentTotalBalance : 0} </th> </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><FormattedMessage id="PROFILE_DEP_BALANCE" /></td>
                            <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentDepositBalance ? userInfo && userInfo.nCurrentDepositBalance : 0}</td>
                          </tr>
                          <tr>
                            <td><FormattedMessage id="PROFILE_WIN_BALANCE" /></td>
                            <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentWinningBalance ? userInfo && userInfo.nCurrentWinningBalance : 0}</td>
                          </tr>
                          <tr>
                            <td><FormattedMessage id="PROFILE_CASH_BONUS" /></td>
                            <td className='rightAlign'>{currencyLogo}{userInfo && userInfo.nCurrentBonus ? userInfo && userInfo.nCurrentBonus : 0}</td>
                          </tr>
                        </tbody>
                      </Table>
              </CardBody>
              <CardFooter className='p-0 border-0 bg-white'>
                <Button color='primary-two' onClick={() => props.history.push('/deposit')} className='w-100'><FormattedMessage id="PROFILE_ADD_CASH" /></Button>
              </CardFooter>
            </Card>
          </>
        : ''
        }
    </Fragment >
  )
}
LeaguesDetailPage.propTypes = {
  mainIndex: PropTypes.number,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      id2: PropTypes.string,
      sportsType: PropTypes.string
    })
  }),
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
    state: PropTypes.object
  }),
  contestJoinList: PropTypes.array,
  teamList: PropTypes.array,
  SwitchTeamList: PropTypes.array,
  otherTeamJoinList: PropTypes.array,
  onSwitchTeam: PropTypes.func,
  loading: PropTypes.bool,
  leaderboardLoading: PropTypes.bool,
  alertShawing: PropTypes.bool,
  userInfo: PropTypes.object,
  joinDetails: PropTypes.object,
  toggleMessage: PropTypes.string,
  modalMessage: PropTypes.string,
  setModalMessage: PropTypes.func,
  onTabChange: PropTypes.func,
  activeTab: PropTypes.string,
  allLeaderBoardList: PropTypes.array,
  myTeamsLeaderBoardList: PropTypes.array,
  setAlertShawing: PropTypes.func,
  switchTeam: PropTypes.func,
  matchLeagueDetails: PropTypes.shape({
    aLeaguePrize: PropTypes.shape([
      { eRankType: PropTypes.string }
    ]),
    _id: PropTypes.string,
    bPrivateLeague: PropTypes.bool,
    bUnlimitedJoin: PropTypes.bool,
    nMinCashbackTeam: PropTypes.Number,
    nCashbackAmount: PropTypes.Number,
    nJoined: PropTypes.Number,
    nPrice: PropTypes.Number,
    nMin: PropTypes.Number,
    bPoolPrize: PropTypes.bool,
    nDeductPercent: PropTypes.Number,
    nTotalPayout: PropTypes.number,
    eMatchStatus: PropTypes.string,
    sUsername: PropTypes.string,
    sProPic: PropTypes.string
  }),
  setSwitchTeamModal: PropTypes.func,
  switchTeamModal: PropTypes.bool,
  resStatus: PropTypes.bool,
  paymentSlide: PropTypes.bool,
  setPaymentSlide: PropTypes.func,
  onGetUserProfile: PropTypes.func,
  resjoinMessage: PropTypes.string,
  url: PropTypes.string,
  message: PropTypes.string,
  switchTeamLoader: PropTypes.bool,
  switchTeamSuccess: PropTypes.bool,
  switchAlert: PropTypes.bool,
  teamPlayerList: PropTypes.array,
  onFetchMatchPlayer: PropTypes.func,
  currencyLogo: PropTypes.string,
  setOffset: PropTypes.func
}
export default ContestDetails(LeaguesDetailPage)
