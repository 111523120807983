import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import AutoPick from '../AutoPick/AutoPick'

const TeamBottomSection = (props) => {
  const { onClickAutoPick, clickEventPost, match, activeTab } = props
  return (
    <div className="d-flex text-center mt-5 bg-white bottom-p-0 pos-absolute w-100 pd-top-12 pd-bottom-12 border-top-1">
      <div className="flex-half">
        <div className="auto-pick w-100 pd-left-16 pd-right-16">
          <AutoPick showSmallButton onClickBtn={onClickAutoPick} />
        </div>
      </div>
      <div className="flex-half pd-left-16 pd-right-16">
        <Link
          onClick={() => clickEventPost('cl_mt_ct')}
          className="btn btn-primary w-100"
          to={{
            pathname: `/create-team/${match.params.sportsType.toLowerCase()}/${
              match.params.id
            }`,
            search: `${props.location.search}&firstTime=true`,
            state: {
              activeTab: activeTab,
              referUrl: `/leagues/${
                match && match.params && match.params.sportsType.toLowerCase()
              }/${match.params.id}`,
              nextStep: true,
              withoutCurrency: true
            }
          }}
        >
          <FormattedMessage id="Create_New_Team" />
        </Link>

      </div>
    </div>
  )
}
TeamBottomSection.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      sportsType: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      activeTab: PropTypes.number,
      tab: PropTypes.number,
      message: PropTypes.string,
      selectTeamModal: PropTypes.bool
    }),
    search: PropTypes.string,
    pathname: PropTypes.string
  }),
  onClickAutoPick: PropTypes.func,
  clickEventPost: PropTypes.func,
  activeTab: PropTypes.string
}
export default TeamBottomSection
