import React, { useState } from 'react'
import LeagueHeader from '../components/LeagueHeader'
import LeaguesDetailPage from './LeaguesDetail'
import PropTypes from 'prop-types'
import qs from 'query-string'

function LeaguesDetail (props) {
  const { match, location } = props
  const [paymentSlide, setPaymentSlide] = useState(false)
  const [VideoStream, setVideoStream] = useState(false)
  const obj = qs.parse(location.search)
  return (
    <>
      <LeagueHeader {...props}
        goToBack={location?.state?.backHome ? `/home/${match.params.sportsType}` : `/leagues/${match.params.sportsType}/${match.params.id}`}
        search={obj?.homePage ? 'homePage=yes' : ''}
        paymentSlide={paymentSlide}
        setVideoStream={setVideoStream}
        backTab={props?.location?.state?.tab || 2}
        VideoStream={VideoStream}
        setPaymentSlide={setPaymentSlide} insideLeagueDetails showBalance/>
      <LeaguesDetailPage {...props} leagueDetailsPage switching
        paymentSlide={paymentSlide}
        setPaymentSlide={setPaymentSlide}/>
    </>
  )
}

LeaguesDetail.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({
      tab: PropTypes.number,
      backHome: PropTypes.bool
    })
  }),
  match: PropTypes.object,
  homePage: PropTypes.string
}

export default LeaguesDetail
