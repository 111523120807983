import React, { Fragment } from 'react'
import { Card, CardBody } from 'reactstrap'
import PropTypes from 'prop-types'

const ShowRewardAds = (props) => {
  const { onClose, adspotkey, dataSource, adId, adViewed, adLoadFailed } = props

  const JioAds = window.JioAds

  JioAds.setConfiguration({
    initialUnMute: true
  })

  JioAds.onAdMediaEnd = function (placementId, isVideoCompleted, reward) {
    if (isVideoCompleted && reward) {
      adViewed(true)
      onClose(false)
    }
  }

  JioAds.onAdClosed = function (placementId, isVideoCompleted, reward) {
    if (reward) {
      adViewed(true)
      onClose(false)
    }
  }

  JioAds.onAdFailedToLoad = function (placementId, option) {
    const { errorCode } = option
    if (errorCode) {
      adLoadFailed()
    }
  }

  return (
    <Fragment>
      <div className="s-team-bg"></div>
      <Card className="show-ads">
        <CardBody className="p-10">
            <div className="ads-div">
              <ins
                id={adId}
                data-adspot-key={adspotkey}
                data-source={dataSource}
              ></ins>
            </div>
        </CardBody>
      </Card>
    </Fragment>
  )
}

ShowRewardAds.propTypes = {
  onClose: PropTypes.func,
  adViewed: PropTypes.func,
  adLoadFailed: PropTypes.func,
  dataSource: PropTypes.string,
  adspotkey: PropTypes.string,
  adId: PropTypes.string
}

export default ShowRewardAds
