import React, { useEffect, useState, Fragment, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Alert, Input } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import PlayerInfo from './PlayerInfo'
import PlayerLeagueInfo from './PlayerLeagueInfo'
import PropTypes from 'prop-types'
import PlayerImage from '../../../assests/images/PlayerImage.svg'
import edit from '../../../assests/images/my-team-edit.svg'
import copy from '../../../assests/images/copyTeam.svg'
import share from '../../../assests/images/my-team-share.svg'
import CaptainIcon from '../../../assests/images/captain-team-icon.svg'
import ViceCaptainIcon from '../../../assests/images/viceCaptain-team-icon.svg'
import HomeTeam from '../../../assests/images/Team1.png'
import AwayTeam from '../../../assests/images/Team2.png'
// import { cricketStaticImage, footballStaticImage } from '../../../helper'
import { useDispatch, useSelector } from 'react-redux'
import qs from 'query-string'
import { clickEventPost, shareTeamPost } from '../../../helper'
import { shareYourTeam } from '../../../redux/actions/team'
import moment from 'moment'
// import { handleImgError } from '../../../helper'
const classNames = require('classnames')

function MyTeam (props) {
  const {
    params, teamDetails, allTeams, index, match, setUserTeamId, userTeamId, onLinesUp,
    setUserTeams, userTeams, teamPlayerList, noOfJoin,
    onPreviewTeam, history, viewOnly, leagueData, join, disabledRadio, teamId, disabledRadioFlag, location, selectModal, disableButton
  } = props
  const [data, setData] = useState([])
  const [playerPoints, setPlayerPoints] = useState()
  const [inLinesUp, setInLinesUp] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [previewCricket, setPreviewCricket] = useState(false)
  const [captainName, setCaptainName] = useState('')
  const [captainData, setCaptainData] = useState({})
  const [viceCaptainData, setViceCaptainData] = useState({})
  const [viceCaptainName, setViceCaptainName] = useState('')
  const [shouldMarqueeCaptain, setShouldMarqueeCaptain] = useState(false)
  const [shouldMarqueeViceCaptain, setShouldMarqueeViceCaptain] = useState(false)
  const [playerInfo, setPlayerInfo] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [Message, setMessage] = useState('')
  const [playerLeagueInfo, setPlayerLeagueInfo] = useState(false)
  const [disabledTeam, setDisabledTeam] = useState(false)
  const [teamList, setTeamList] = useState({})
  const [currSwitchTeam, setCurrSwitchTeam] = useState('')
  const [currShareTeam, setCurrShareTeam] = useState('')
  const activeSports = useSelector(state => state.activeSports.activeSports)
  const matchDetails = useSelector(state => state.match.matchDetails)
  const shareTeamResStatus = useSelector(state => state.team.shareTeamResStatus)
  const shareTeamRefferalCode = useSelector(state => state.team.shareTeamRefferalCode)
  const token = useSelector(state => state.auth.token)
  const url = useSelector(state => state.url.getUrl)
  const playerRoles = activeSports && activeSports.find(sport => sport.sKey === ((match.params.sportsType).toUpperCase()))?.aPlayerRoles
  const obj = qs.parse(location.search)
  const marqueeCaptainRef = useRef(null)
  const marqueeViceCaptainRef = useRef(null)

  const previousProps = useRef({ currShareTeam }).current

  const dispatch = useDispatch()

  useEffect(() => {
    const checkOverflow = () => {
      if (marqueeCaptainRef.current) {
        const isOverflowing = marqueeCaptainRef.current.scrollWidth > marqueeCaptainRef.current.clientWidth
        setShouldMarqueeCaptain(isOverflowing)
      }
      if (marqueeViceCaptainRef.current) {
        const isOverflowing = marqueeViceCaptainRef.current.scrollWidth > marqueeViceCaptainRef.current.clientWidth
        setShouldMarqueeViceCaptain(isOverflowing)
      }
    }
    checkOverflow()
  }, [captainName, viceCaptainName])

  useEffect(() => {
    if (props?.currSwitchTeam) {
      setCurrSwitchTeam(props?.currSwitchTeam)
    }
  }, [])

  useEffect(() => {
    if (!disabledRadioFlag) {
      setDisabledTeam(false)
    } else {
      if (disabledRadio === teamId) {
        setDisabledTeam(false)
      } else {
        setDisabledTeam(true)
      }
    }
  }, [disabledRadio, teamId, disabledRadioFlag])

  useEffect(() => {
    // setUserTeams && setUserTeams([])
    setInLinesUp(1)
    setMessage('')
    if (teamDetails && teamDetails?.aPlayers?.length !== 0 && teamPlayerList && playerRoles) {
      const playerRole = playerRoles?.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })).sort((a, b) => a?.nPosition?.toString().localeCompare(b?.nPosition?.toString(), 'en', { numeric: true, sensitivity: 'base' })).map(Role => Role.sName)
      const players = Object.assign({}, ...playerRole?.map(key => ({ [key]: [] })))
      const teamList = Object.assign({}, ...teamPlayerList?.map(key => ({ [key?.oTeam?.sShortName]: [] })))
      let count = 0
      let points = 0
      teamDetails.aPlayers && teamDetails.aPlayers.length !== 0 && teamDetails.aPlayers.map((data) => {
        const PlayerDetails = teamPlayerList && teamPlayerList.length !== 0 && teamPlayerList.find(player => player._id === data)
        Object.entries(players).map(([key, value]) => {
          return (key === PlayerDetails?.eRole) && players[PlayerDetails?.eRole].push(PlayerDetails)
        })
        Object.entries(teamList).map(([key, value]) => {
          return (key === PlayerDetails?.oTeam?.sShortName) && teamList[PlayerDetails?.oTeam?.sShortName].push(PlayerDetails)
        })
        if (teamPlayerList && teamPlayerList.length !== 0 && teamPlayerList.some(data2 => data2._id === data && !data2.bShow)) {
          count = count + 1
        }
        if (data === teamDetails.iCaptainId) {
          const playerName = PlayerDetails?.sName.split(' ')
          const name = playerName ? playerName?.length === 1 ? playerName : `${PlayerDetails?.sName[0]} ${playerName[playerName.length - 1]}` : ''
          setCaptainName(name)
          setCaptainData(PlayerDetails)
        } else {
          if (data === teamDetails.iViceCaptainId) {
            const playerName = PlayerDetails?.sName.split(' ')
            const name = playerName ? playerName?.length === 1 ? playerName : `${PlayerDetails?.sName[0]} ${playerName[playerName.length - 1]}` : ''
            setViceCaptainName(name)
            setViceCaptainData(PlayerDetails)
          }
        }
        if (PlayerDetails && PlayerDetails.nScoredPoints) {
          if (data === teamDetails.iCaptainId) {
            const newPoints = (Number(PlayerDetails.nScoredPoints) * 2)
            points = points + newPoints
          } else if (data === teamDetails.iViceCaptainId) {
            const newPoints = (Number(PlayerDetails.nScoredPoints) * 1.5)
            points = points + newPoints
          } else { points = points + Number(PlayerDetails.nScoredPoints) }
        }
        return players
      })
      setPlayerPoints(points)
      setInLinesUp(count)
      setData(players)
      setTeamList(teamList)
    }
  }, [teamDetails, teamPlayerList, playerRoles])

  useEffect(() => {
    if (modalMessage) {
      setTimeout(() => {
        setModalMessage(false)
      }, 2000)
    }
  }, [modalMessage])

  useEffect(() => {
    if (shareTeamRefferalCode && currShareTeam === teamDetails?._id) {
      if (previousProps.shareTeamRefferalCode !== shareTeamRefferalCode) {
        if (shareTeamResStatus && shareTeamRefferalCode && currShareTeam === teamDetails?._id) {
          const startDate = moment(matchDetails?.dStartDate).format('X')
          shareTeamPost(matchDetails?._id, teamDetails?._id, match?.params?.sportsType, startDate, matchDetails?.oHomeTeam?.sShortName, matchDetails?.oAwayTeam?.sShortName, shareTeamRefferalCode)
        }
      }

      return () => {
        setCurrShareTeam('')
      }
    }
    // if (shareTeamResStatus && shareTeamRefferalCode && currShareTeam === teamDetails?._id) {
    //   if (previousProps.currShareTeam !== currShareTeam) {
    //     shareTeamPost(matchDetails?._id, teamDetails?._id, match?.params?.sportsType, matchDetails?.dStartDate, matchDetails?.oHomeTeam?.sShortName, matchDetails?.oAwayTeam?.sShortName, shareTeamRefferalCode)
    //   }
    // }

    return () => {
      if (shareTeamRefferalCode) {
        previousProps.shareTeamRefferalCode = shareTeamRefferalCode
      }
    }
  }, [shareTeamResStatus, shareTeamRefferalCode])

  function OnSubmitting (id) {
    if (userTeams.includes(id)) {
      const newData = userTeams.filter(x => x !== id)
      setUserTeams(newData)
    } else {
      if (noOfJoin === 1) {
        setUserTeams([id])
      } else {
        // if (leagueData && leagueData.bUnlimitedJoin) {
        //   setUserTeams([...userTeams, id])
        // }
        if ((userTeams.length + 1) <= noOfJoin) {
          setUserTeams([...userTeams, id])
        } else {
          setMessage('You can select max ' + noOfJoin + ' teams for this contest')
          setModalMessage(true)
        }
      }
    }
  }

  function shareTeamClick () {
    if (teamDetails?._id) {
      setCurrShareTeam(teamDetails?._id)
      dispatch(shareYourTeam(teamDetails?._id, token))
    }

    if (props.location.pathname.includes('/liveleague/') || props.location.pathname.includes('/completedleagues/')) {
      clickEventPost('my_myt_s', teamDetails?._id)
    }
  }

  return (
    <>
      {
        modalMessage
          ? <Fragment>
            <Alert isOpen={modalMessage} color="primary">{Message}</Alert>
          </Fragment>
          : ''
      }
      {join && !disabledTeam &&
      <div
        className={classNames('align-items-center', disabledTeam && 'pointer-none')}
        onClick={() =>
          !viewOnly
            ? setUserTeams
              ? OnSubmitting(teamDetails._id)
              : setUserTeamId
                ? setUserTeamId(teamDetails._id)
                : !onPreviewTeam && setPreviewCricket(true)
            : ''}
      >
       {!props.points
         ? setUserTeams
           ? <div className='Radio-MyTeam'>
                    <Input type="checkbox" disabled={disabledTeam} id={`teamDetails${teamDetails._id}`} name="gender" value={`${teamDetails._id}`}
                      checked={userTeams.includes(teamDetails._id)}
                    />
                    <label htmlFor={`teamDetails${teamDetails._id}`}><FormattedMessage id="Team_SelectTeam" /></label>
                  </div>
           : setUserTeamId
             ? <div className='Radio-MyTeam'>
                      <Input type="radio" disabled={disabledTeam} id={`teamDetails${teamDetails._id}`} name="gender" value={`${teamDetails._id}`}
                        checked={teamDetails._id === userTeamId}
                      />
                      <label htmlFor={`teamDetails${teamDetails._id}`}><FormattedMessage id="Team_SelectTeam" /></label>
                    </div>
             : ''
         : ''}
       </div>}
      <div className={classNames('my-team', join && 'team-custom-width', onLinesUp && inLinesUp > 0 && 'unannounced-wrap', { selectTeamPreview: viewOnly })}>
        <button className={`w-100 p-0 m-0 d-block bg-transparent ${onLinesUp && inLinesUp > 0 && 'unannounced-player'}`} onClick={() => {
          if (!disableButton && !viewOnly) {
            setUserTeams ? OnSubmitting(teamDetails._id) : setUserTeamId ? setUserTeamId(teamDetails._id) : !onPreviewTeam && setPreviewCricket(true)
          }

          if (props.location.pathname.includes('/leagues/') && !selectModal) {
            clickEventPost('cl_mt_tm')
          }

          if (props.location.pathname.includes('/liveleague/') || props.location.pathname.includes('/completedleagues/')) {
            clickEventPost('mt_myt_ti', teamDetails?._id)
          }
        }}>
            {
              !props.points && (setUserTeams || setUserTeamId)
                ? <div className="mt-header d-flex align-items-center justify-content-between">
                    <span className='team-name'>{teamDetails && teamDetails.sName}</span>
                    <div>
                      <Link to={{
                        pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${params}/${teamDetails._id}`,
                        search: `?${qs.stringify({
                                  homePage: obj?.homePage ? 'yes' : undefined,
                                  firstTime: true
                                })}`,
                        state: props.location.pathname.includes('/create-contest/')
                          ? {
                              ...props.location.state,
                              backToSwitchTeam: props.location.pathname.includes('/leagues-detail/') && !selectModal,
                              backToLeagueSelectTeam: props.location.pathname.includes('/leagues/'),
                              backToLeagueDetailsSelectTeam: props.location.pathname.includes('/leagues-detail/') && selectModal,
                              backToCreateContest: props.location.pathname.includes('/create-contest/'),
                              backToJoinContest: props.location.pathname.includes('/join-contest/'),
                              joinContestCode: props.code,
                              verifiedId: props.verifiedId,
                              currSwitchTeam: currSwitchTeam,
                              leagueId: match.params.id2 || leagueData?.iLeagueId,
                              editTeam: true
                            }
                          : {
                              backToSwitchTeam: props.location.pathname.includes('/leagues-detail/') && !selectModal,
                              backToLeagueSelectTeam: props.location.pathname.includes('/leagues/'),
                              backToLeagueDetailsSelectTeam: props.location.pathname.includes('/leagues-detail/') && selectModal,
                              backToCreateContest: props.location.pathname.includes('/create-contest/'),
                              backToJoinContest: props.location.pathname.includes('/join-contest/'),
                              joinContestCode: props.code,
                              verifiedId: props.verifiedId,
                              currSwitchTeam: currSwitchTeam,
                              leagueId: match.params.id2 || leagueData?.iLeagueId,
                              editTeam: true
                            }
                      }} ><img src={edit} width={20}></img></Link>
                      <Link to={{
                        pathname: `/copy-team/${(match.params.sportsType).toLowerCase()}/${params}/${teamDetails._id}/copy`,
                        search: `?${qs.stringify({
                          homePage: obj?.homePage ? 'yes' : undefined,
                          firstTime: true
                        })}`,
                        state: props.location.pathname.includes('/create-contest/')
                          ? {
                              ...props.location.state,
                              backToSwitchTeam: props.location.pathname.includes('/leagues-detail/') && !selectModal,
                              backToLeagueSelectTeam: props.location.pathname.includes('/leagues/'),
                              backToLeagueDetailsSelectTeam: props.location.pathname.includes('/leagues-detail/') && selectModal,
                              backToCreateContest: props.location.pathname.includes('/create-contest/'),
                              backToJoinContest: props.location.pathname.includes('/join-contest/'),
                              joinContestCode: props.code,
                              verifiedId: props.verifiedId,
                              currSwitchTeam: currSwitchTeam,
                              leagueId: match.params.id2 || match.params.id,
                              editTeam: false,
                              withoutCurrency: true
                            }
                          : {
                              backToSwitchTeam: props.location.pathname.includes('/leagues-detail/') && !selectModal,
                              backToLeagueSelectTeam: props.location.pathname.includes('/leagues/'),
                              backToLeagueDetailsSelectTeam: props.location.pathname.includes('/leagues-detail/') && selectModal,
                              backToCreateContest: props.location.pathname.includes('/create-contest/'),
                              backToJoinContest: props.location.pathname.includes('/join-contest/'),
                              joinContestCode: props.code,
                              verifiedId: props.verifiedId,
                              currSwitchTeam: currSwitchTeam,
                              leagueId: match.params.id2 || match.params.id,
                              editTeam: false,
                              withoutCurrency: true
                            }
                      }} ><img src={copy} width={20}></img></Link>
                      <span onClick={() => shareTeamClick()}><img src={share} width={20}></img></span>
                    </div>
                  </div>
                : !viewOnly && !props.points
                    ? <div className="mt-header d-flex align-items-center justify-content-between">
                        <span className='team-name'>{teamDetails && teamDetails.sName}</span>
                        <div>
                          <Link to={{
                            pathname: `/create-team/${(match.params.sportsType).toLowerCase()}/${params}/${teamDetails._id}`,
                            search: `?${qs.stringify({
                                      homePage: obj?.homePage ? 'yes' : undefined,
                                      firstTime: true
                                    })}`,
                            state: {
                              editTeam: true
                            }
                          }} ><img src={edit} width={20}></img></Link>
                          <Link to={{
                            pathname: `/copy-team/${(match.params.sportsType).toLowerCase()}/${params}/${teamDetails._id}/copy`,
                            search: `?${qs.stringify({
                              homePage: obj?.homePage ? 'yes' : undefined,
                              firstTime: true
                            })}`,
                            state: {
                              withoutCurrency: true
                            }
                          }} ><img src={copy} width={20}></img></Link>
                          <span onClick={() => shareTeamClick()}><img src={share} width={20}></img></span>
                        </div>
                    </div>
                    : props.points
                      ? <div className="mt-header d-flex align-items-center justify-content-between"><span className='team-name'>{teamDetails && teamDetails.sName}</span>
                      <span onClick={() => shareTeamClick()}><img src={share} width={20}></img></span>
                      {/* <span className="points"><FormattedMessage id="Team_points" />: <span className='p-points'>{teamDetails && teamDetails.nTotalPoints ? parseFloat(Number((teamDetails.nTotalPoints)).toFixed(2)) : (playerPoints || 0)}</span></span> */}
                    </div>
                      : <div></div>
            }
            <div onClick={() => onPreviewTeam
              ? history.push({
                pathname: `/my-teams-preview/${(match.params.sportsType).toLowerCase()}/${match.params.id}/${teamDetails._id}`,
                search: `?${qs.stringify({
                            activeTab: obj?.activeTab || undefined,
                            homePage: obj?.homePage ? 'yes' : undefined
                          })}`,
                state: {
                  allTeams: allTeams.sort((a, b) => a?.sName?.toString().localeCompare(b?.sName?.toString(), 'en', { numeric: true, sensitivity: 'base' })),
                  index,
                  data: { teamDetails },
                  match
                }
              })
              : !viewOnly && setPreviewCricket(true)}>
                <div>
                <div className="team-p d-flex justify-content-between">
                  <div className='team-p-left'>
                    <div className="player">
                      <div className="img">
                        <img style={{ height: '36px', width: '36px' }} src={captainData?.sImage && url ? `${url}${captainData?.sImage}` : PlayerImage} alt="Player" />
                        {/* <div className="position"><FormattedMessage id="Team_C" /></div> */}
                      </div>
                      <div className='captain'>
                        <h3 ref={marqueeCaptainRef} className={shouldMarqueeCaptain ? 'marquee-captain' : ''}>{captainName}</h3>
                      </div>
                      <img className='ml-2' width={18} src={CaptainIcon} />
                    </div>
                    <div className="player">
                      <div className="img">
                        <img style={{ height: '36px', width: '36px' }} src={viceCaptainData?.sImage && url ? `${url}${viceCaptainData?.sImage}` : PlayerImage} alt="Player" />
                        {/* <div className="position"><FormattedMessage id="Team_VC" /></div> */}
                      </div>
                      <div className='vicecaptain'>
                        <h3 ref={marqueeViceCaptainRef} className={shouldMarqueeViceCaptain ? 'marquee-vicecaptain' : ''}>{viceCaptainName}</h3>
                      </div>
                      <img className='ml-2' width={18} src={ViceCaptainIcon} />
                    </div>
                  </div>
                  <div className="team-p-right">
                    {props?.matchDetails?.eStatus !== 'I' && props?.matchDetails?.eStatus !== 'L' && props?.matchDetails?.eStatus !== 'CMP'
                      ? Object.keys(teamList).map((key, index) => {
                        const team = teamList[key]
                        const teamImg = team[0]?.oTeam.sImage ? `${url}${team[0]?.oTeam.sImage}` : index === 0 ? HomeTeam : AwayTeam
                        return (
                        <div className="team-p-right-group" key={index}>
                          <img width={16} src={teamImg} />
                          <h2>{key} - {teamList[key].length} <FormattedMessage id='Player' /></h2>
                          {/* <p>{teamList[key].length} <FormattedMessage id='Player' /></p> */}
                        </div>
                        )
                      })
                      : <div className='team-p-right-points'>
                        <p>Points</p>
                        <h1>{playerPoints}</h1>
                      </div>
                    }
                  </div>
                </div>
                </div>
                <div className="mt-footer d-flex align-items-center">
                  {
                    Object.entries(data).map(([key, value]) => (
                      <span key={value._id} className='player-role-class'>{key}<b>
                        {
                          value && value.length ? value.length : <FormattedMessage id="Team_0" />
                        }
                      </b></span>
                    ))
                  }
                </div>
            </div>
          </button>
          {
            onLinesUp && inLinesUp > 0 &&
            <div className="mt-footer d-flex backRed">
              <span className='lineups-class'> <span className="unannounced-icon mr-1">U</span> {inLinesUp} <FormattedMessage id="Team_No_Linesup" /></span>
            </div>
          }
        </div>

      {playerLeagueInfo ? <PlayerLeagueInfo {...props} isSeasonPoint={true} onBackClick={() => setPlayerLeagueInfo(false)} onPlayerInfoClick={() => setPlayerInfo(true)}></PlayerLeagueInfo> : ''}
      {playerInfo ? <PlayerInfo onBackClick={() => setPlayerInfo(false)}></PlayerInfo> : ''}
    </>
  )
}

MyTeam.propTypes = {
  params: PropTypes.string,
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func
  }),
  matchDetails: PropTypes.object,
  points: PropTypes.string,
  teamDetails: PropTypes.shape({
    aPlayers: PropTypes.shape([{
      aPlayers: PropTypes.func,
      push: PropTypes.func
    }]),
    _id: PropTypes.string,
    sName: PropTypes.string,
    length: PropTypes.string,
    iViceCaptainId: PropTypes.string,
    iCaptainId: PropTypes.string,
    nTotalPoints: PropTypes.number
    // _id: PropTypes.string
  }),
  leagueData: PropTypes.shape({
    _id: PropTypes.string,
    bUnlimitedJoin: PropTypes.string,
    nTeamJoinLimit: PropTypes.number,
    iLeagueId: PropTypes.string
  }),
  match: PropTypes.object,
  allTeams: PropTypes.array,
  index: PropTypes.number,
  noOfJoin: PropTypes.number,
  url: PropTypes.string,
  setUserTeamId: PropTypes.func,
  userTeamId: PropTypes.string,
  matchType: PropTypes.string,
  playerScorePoints: PropTypes.func,
  setUserTeams: PropTypes.func,
  userTeams: PropTypes.array,
  teamPlayerList: PropTypes.shape([{
    _id: PropTypes.string
  }]),
  onLinesUp: PropTypes.bool,
  onPreviewTeam: PropTypes.bool,
  viewOnly: PropTypes.bool,
  join: PropTypes.bool,
  homePage: PropTypes.string,
  disabledRadio: PropTypes.object,
  teamId: PropTypes.id,
  disabledRadioFlag: PropTypes.bool,
  playerRoles: PropTypes.object,
  location: PropTypes.object,
  currSwitchTeam: PropTypes.object,
  selectModal: PropTypes.bool,
  disableButton: PropTypes.bool,
  code: PropTypes.string,
  verifiedId: PropTypes.string
}

export default MyTeam
