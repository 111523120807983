import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
// import UserHeader from '../../User/components/UserHeader'
// import { callEvent } from '../../../Analytics.js'
import Loading from '../../../component/Loading'
// import { FormattedMessage } from 'react-intl'
import qs from 'query-string'
import LeagueHeader from '../components/LeagueHeader'
const CreateContestPage = lazy(() => import('./CreateContest'))

function CreateContest (props) {
  const { match, location } = props
  const obj = qs.parse(location.search)
  // useEffect(() => {
  //   callEvent('create_private_contest', 'Create a Private Contest', location.pathname)
  // }, [])
  return (
    <>
    <LeagueHeader {...props} goToBack={obj?.homePage ? `/leagues/${match.params.sportsType}/${match.params.id}` : `/leagues/${match.params.sportsType}/${match.params.id}`} backTab={1} createContest/>
      <Suspense fallback={<Loading />}>
        <CreateContestPage {...props}/>
      </Suspense>
    </>
  )
}

CreateContest.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sportsType: PropTypes.string,
      id: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  })
}

export default CreateContest
