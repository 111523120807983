import React, { useState, lazy, Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import HomeHeader from '../components/HomeHeader'
// import HomeFooter from '../components/HomeFooter'
import Loading from '../../../component/Loading'
import qs from 'query-string'
import { getUserToken } from '../../../redux/actions/auth'
import { useDispatch } from 'react-redux'
const HomePage = lazy(() => import('./Home'))

function Home (props) {
  const { location } = props
  const dispatch = useDispatch()
  const [mainIndex, setMainIndex] = useState(0)
  const [paymentSlide, setPaymentSlide] = useState(false)
  const [sessionId, setSessionId] = useState('')
  const obj = qs.parse(location.search)

  useEffect(() => {
    window.onSessionId = (e) => {
      setSessionId(e)
    }
  }, [])

  useEffect(() => {
    if (sessionId || obj?.sessionid) {
      const response = {
        success: true,
        link: sessionId
      }
      window?.DroidHandler?.postMessage(JSON.stringify(response))
      localStorage.setItem('sessionId', sessionId || obj?.sessionid)
      dispatch(getUserToken(sessionId || obj?.sessionid))
    }

    return () => {
      setSessionId('')
    }
  }, [sessionId])

  return (
    <>
      <HomeHeader home {...props}
        paymentSlide={paymentSlide}
        setPaymentSlide={setPaymentSlide}
        setMainIndex={setMainIndex}
        isPublic={props.match?.path === '/home'}
        sportsRequired
        showBalance/>
      <Suspense fallback={<Loading />}>
        <HomePage {...props} homePage='yes' mainIndex={mainIndex} paymentSlide={paymentSlide}
        setPaymentSlide={setPaymentSlide} isPublic={props.match?.path === '/home'}/>
      </Suspense>
      {/* <HomeFooter {...props} isPublic={props.match?.path === '/home/:sportsType/v1'} /> */}
    </>
  )
}

Home.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string
  }),
  location: PropTypes.object
}

export default Home
