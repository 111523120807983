import React from 'react'
import PropTypes from 'prop-types'
import UserHeader from '../../User/components/UserHeader'
import ShareContestPage from './ShareContest'
function ShareContest (props) {
  const { match } = props
  return (
    <>
      <UserHeader
        {...props}
        title="Share Contest"
        subTitle={props?.location?.state?.matchLeagueDetails?.sName}
        backURL={`/leagues/${match?.params?.sportsType}/${match.params?.id}?activeTab=2`}
        shareContest={true}
      />
      <ShareContestPage {...props} matchId={match && match.params && match.params.id}/>
    </>
  )
}

ShareContest.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
}

export default ShareContest
